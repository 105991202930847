let year = [];
var date = new Date().getFullYear();
for (let i = 1980; i <= date; i++) {
  year.push(i);
}
export const subCategoryInput = {
  Electronics: {
    "Refrigerators - Fridge": {
      "Door Style​": [
        " Freezer On Top ​",
        " Side By side​",
        " Single Door​",
        " Triple Door​",
        "Multi Door​",
      ],
      Condition: [" Brand New​", " Gently Used​", "Heavily used​", "Unboxed​"],
      "Brand Name​": [
        " Bosch​",
        " Buyerzone​",
        " D Pex​",
        " LG ​",
        " Onida​",
        " Panasonic​",
        " Samsung​",
        " Sony​",
      ],
      "Capacity​": [
        "Upto 120L​",
        "120-200L​",
        "200-300L​",
        "300-400L​",
        "400L & Above ",
      ],
    },
    "Washing Machines": {
      "Brand Name​": [
        " Bosch​",
        " Buyerzone​",
        " D Pex​",
        " Intex​",
        " LG ​",
        " Onida​",
        " Panasonic​",
        " Sony​",
        "Lloyd​",
        "Others",
        "Videocon​",
        "Voltas​",
        "Whirlpool​",
      ],
      "Capacity​": [
        "4 to 6kg",
        "6 to 8kg​",
        "8 to 10kg​",
        "10 to 12kg​",
        "12 to 16kg",
        "16 to 20kg",
        "20 to 24kg",
        "Above 24kg",
      ],
      "Condition​": [" New​", "Good​", "Fair​", "Unboxed​"],
      "Loading​": ["Front Load​", "Top Load​"],
    },
    "Air Coolers​": {
      "Brand Name  ​": [
        " BMQ​",
        " Bajaj​",
        " Blue Star​",
        " Cello​",
        " Hindware​",
        " Hotstar​",
        " Kelvinator  ​",
        "Havells​",
        "Kenstar​",
        "Khaitan​",
        "Kiran​",
        "Kunstocom​",
        "Mccoy​",
        "Orient​",
        "Others",
      ],
      "Condition ​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed​",
      ],
      " Cooler Capacity​": ["11L-15L​", "16L-20L​", "21L-40L​", "40L & above"],
    },
    "Air Conditioners - AC": {
      "Brand Name​": [
        " Bosch​",
        " Buyerzone​",
        " D Pex​",
        " Intex​",
        " LG ​",
        " Onida​",
        " Panasonic​",
        " Sony​",
        "Lloyd​",
        "Others​",
        "Videocon​",
        "Voltas​",
        "Whirlpool​",
      ],
      "AC Type​": [" Window​", " Split​"],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed​",
      ],
      "Tonnage​": ["1Ton​", "2.5Ton​", "5Ton​", "8Ton​", "8Ton & Above"],
    },
    "Water Heaters - Geysers": {
      "Brand Name ​": [
        " Apson​",
        " Arise​",
        " Bala​",
        " Blue Me​",
        " Havells​",
        " LG ​",
        " Onida​",
        " Panasonic​",
        "Bajaj ​",
        "Easy​",
        "Florex​",
        "Genius​",
        "Happy Home​",
        "Others​",
      ],
      "Heater Type​": [" Instant Water Heater​", " Storage Water Heater​"],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed​",
      ],
      "Heater Capacity​": [
        "11L-15L​",
        "16L-20L​",
        "21L-50L​",
        "50L-100L​",
        "100L & Above",
      ],
    },
    "Laptops Or (Desktops - Computers)": {
      "Brand Name ​": [
        " Hp​",
        " IBM​",
        " Lenovo​",
        " Samsung​",
        " Wipro​",
        " Zenith​",
        "Asus​",
        "Dell​",
        "Others​",
        "Panasonic​",
        "Sharp​",
      ],
      "Product Type​": [" Desktop​", " Laptop​"],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },
    "Monitor Or Printers Or Scanners": {
      "Peripheral Type​": [
        " Access Point​",
        " Battery​",
        " CPU​",
        " Cooling pad​",
        " Head Phones​",
        " Headset​",
        " Keyboard​",
        " Mouse​",
        " Network Card​",
        " Others​",
        " Printer​",
        " Ram​",
        " Router​",
        " Scanner​",
        " Speaker​",
        " USB Wireless Adaptor​",
        " USB Drive​",
        " Webcam​",
        "Data Card​",
      ],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },
    "Microwave Ovens​": {
      "Brand Name ​": ["LG​", " Onida​", " Others​", " Panasonic​", "Samsung​"],
      "Oven Type​": [" Convection​", " Grill​", " Solo​"],
      "Condition ​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed​",
      ],
      "Capacity​": ["Upto 20L​", "21L-25L​", "26L-30L​", "31L & Above"],
    },

    "Water Purifiers": {
      "Brand Name​": [
        "Aquaguard",
        "Bala​",
        " Blue Me​",
        " Havells​",
        " LG ​",
        " Others​",
        " Panasonic​",
        " Kent​",
        "Livpure ​",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", " Unboxed"],
    },

    "Mixer - Grinder - Juicer": {
      "Brand Name": [
        " Activa​",
        " Aditi​",
        " Amazing Bullet ​",
        " Ameet​",
        " Anjani​",
        " Apex​",
        " Arise​",
        " Asian​",
        " BEC​",
        " BTL​",
        " Bala​",
        " Blue me ​",
        " Bluesky​",
        " Bonzeal​",
        " Borosil​",
        " Boss​",
        " Butterfly​",
        " Cpex​",
        " Others​",
      ],
      Condition: [" Brand New​", " Gently Used​", " Heavily Used​", " Unboxed"],
    },
    "Induction Cook Tops​": {
      "Brand Name": [
        " Greenchef​",
        " Havells​",
        " Hilex​",
        " Hindware​",
        " Hotstar ​",
        " Impex​",
        " Jaipan​",
        " Jindal​",
        " Kenwood​",
        " Longer​",
        " Nova​",
        " Orbit ​",
        " Oster​",
        " Others​",
        " Ovastar​",
        " Phillips​",
        " Pigeon​",
        "Orbon​",
      ],
      Condition: [" Brand New​", "Gently Used​", "Heavily Used​", "Unboxed"],
    },
    "Gas Stove": {
      "Brand Name": [
        " Apex​",
        " Longer​",
        " Nova​",
        " Orbit ​",
        " Oster​",
        " Others​",
        " Ovastar​",
        " Phillips​",
        " Pigeon​",
        " Prestige​",
        " Quba​",
        " Skyflame​",
        " Surya​",
        " Usha​",
        " Wonder Chef​",
        " Wonder World​",
        "Orbon​",
        "Skyline​",
      ],
      Condition: ["Brand New", "Gently Used​", "Heavily Used"],
    },
    Cameras: {
      "Brand Name ​": [
        " Casio​",
        " Flip ​",
        " Kodak​",
        " Olympus​",
        " Panasonic​",
        "Canon​",
        "Leica​",
        "Nikon​",
        "Others",
        "Samsung​",
        "Sony​",
      ],
      "Product Type​": [
        " Camcorders",
        "Binocular",
        "DigitalSLR​",
        "Film Cameras​",
        "Telescope​",
      ],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },
    "Camera Accessories": {
      "Accessories Type ​": [
        "Camera Bag​",
        "Filter​",
        "Flash​",
        "Lens Cap​",
        "Lens Cleaner​",
        "Memory Card​",
        "Others​",
        "Strap​",
        "Battery Charger​",
        "Lens​",
      ],
      Condtion: [" Brand New​", " Gently Used​", " Heavily Used​", " Unboxed"],
    },
    TV: {
      "Product Type​": ["TV​", "DVD Player​", "Other​"],
      "Condition​": ["Brand New​", "Gently Used​", "Heavily used​", "Unboxed​"],
      "Brand Name ​": [
        "BPL ​",
        "BenQ​",
        "LG​",
        "Onida​",
        "Panasonic​",
        "Phillips​",
        "Samsung​",
        "Sony​",
        "Videocon​",
        "Others",
      ],
      "Screen Size​": [
        "Upto 23​",
        "24-31​",
        "32-39​",
        "40-47​",
        "48-54​",
        "55 & Above​",
      ],
      "Resolution​": [
        "CRT​",
        "Full HD​",
        "HD Ready​",
        "LCD​",
        "LED​",
        "Other​",
        "Plasma​",
        "QLED",
        "Standard definition​",
        "Ultra Hd(4k)​",
        "WXGA​",
      ],
      // "Screen Size​":[" Upto 23​","24-31​","32-39​","40-47​","48-54​","55&Above​"],
      // "Resolution​":["Full HD​","HD Ready​","Standard definition​","Ultra Hd(4k)​","WXGA​"],
      "Display Technology​": [
        "CRT​",
        "LCD​",
        "LED​",
        "Other​",
        "Plasma​",
        "QLED",
      ],
    },
    "Video Games - Consoles": {
      "Brand Name ​": [
        "Nintendo wii​",
        "SonyPSP​",
        "Others​",
        "Microsoft Xbox",
        "Nintendo Game boy",
        "Nokia N-gage​",
        "PC games​",
        "Sony Play Station​",
      ],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },

    "Music Systems - Home Theatre": {
      "Brand Name": [
        "Canon",
        "Casio​",
        "Flip ​",
        "Kodak​",
        "Leica​",
        "Nikon",
        "Olympus​",
        "Others",
        "Panasonic​",
        "Samsung​",
        "Sony​",
      ],
      "Speaker Type​": [
        "Blueshelf speakers​",
        "Bluetooth Speakers​",
        "Home Theatre systems​",
        "Outdoor Speakers​",
        "Over –Ear Head phones​",
        "Sound bar speakers​",
      ],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },
  },

  BooksAndMedia: {
    Books: {
      Genre: [
        "Fiction",
        "Non-Fiction",
        "Mystery",
        "Science Fiction",
        "Fantasy",
        "Biography",
        "Self-Help",
        "Romance",
        "Others",
      ],
      Language: [
        "English",
        "Hindi",
        "Spanish",
        "French",
        "German",
        "Chinese",
        "Japanese",
        "Others",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used"],
      Author: [
        "J.K. Rowling",
        "Stephen King",
        "Agatha Christie",
        "George R.R. Martin",
        "Paulo Coelho",
        "Others",
      ],
    },
    "E-Books": {
      Genre: [
        "Fiction",
        "Non-Fiction",
        "Mystery",
        "Science Fiction",
        "Fantasy",
        "Biography",
        "Self-Help",
        "Romance",
        "Others",
      ],
      "File Format": ["PDF", "ePub", "Mobi", "AZW", "Others"],
      Language: [
        "English",
        "Hindi",
        "Spanish",
        "French",
        "German",
        "Chinese",
        "Japanese",
        "Others",
      ],
    },
    Magazines: {
      Category: [
        "Fashion",
        "Technology",
        "Business",
        "Lifestyle",
        "Sports",
        "Health",
        "Entertainment",
        "Science",
        "Travel",
        "Others",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used"],
      Language: [
        "English",
        "Hindi",
        "Spanish",
        "French",
        "German",
        "Chinese",
        "Japanese",
        "Others",
      ],
    },
    "Music CDs/DVDs": {
      Genre: [
        "Pop",
        "Rock",
        "Classical",
        "Jazz",
        "Hip-Hop",
        "Country",
        "Blues",
        "Electronic",
        "Others",
      ],
      Language: ["English", "Hindi", "Spanish", "French", "German", "Others"],
      Condition: ["Brand New", "Gently Used", "Heavily Used"],
    },
    "Movies DVDs/Blu-rays": {
      Genre: [
        "Action",
        "Drama",
        "Comedy",
        "Horror",
        "Thriller",
        "Documentary",
        "Science Fiction",
        "Romantic",
        "Others",
      ],
      Language: [
        "English",
        "Hindi",
        "Spanish",
        "French",
        "German",
        "Chinese",
        "Japanese",
        "Others",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used"],
    },
    "Video Games": {
      Platform: ["PC", "PlayStation", "Xbox", "Nintendo", "Mobile", "Others"],
      Genre: [
        "Action",
        "Adventure",
        "Role-Playing",
        "Simulation",
        "Strategy",
        "Sports",
        "Puzzle",
        "Others",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used"],
    },
  },

  HealthFitness: {
    "Treadmills": {
      "Brand Name": [
        "Afton",
        "PowerMax",
        "Durafit",
        "Cockatoo",
        "Fitkit",
        "Lifelong",
        "Welcare",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Motor Type": [
        "DC Motor",
        "AC Motor"
      ],
      "Speed Range": [
        "0-10 km/h",
        "11-15 km/h",
        "16-20 km/h",
        "20+ km/h"
      ],
      "Incline Type": [
        "Manual",
        "Auto"
      ]
    },
    "Exercise Bikes": {
      "Brand Name": [
        "Cosco",
        "Cockatoo",
        "PowerMax",
        "Welcare",
        "Durafit",
        "Lifelong",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Type": [
        "Upright",
        "Recumbent",
        "Spin Bike",
        "Air Bike"
      ]
    },
    "Dumbbells": {
      "Brand Name": [
        "Rubberfit",
        "Aurion",
        "Cosco",
        "Vinex",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used"
      ],
      "Weight Range": [
        "1-5 kg",
        "6-10 kg",
        "11-20 kg",
        "20+ kg"
      ],
      "Material": [
        "Rubber Coated",
        "Iron",
        "Vinyl",
        "Steel"
      ]
    },
    "Yoga Mats": {
      "Brand Name": [
        "Reebok",
        "Cosco",
        "Aurion",
        "Strauss",
        "Amazon Basics",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used"
      ],
      "Material": [
        "PVC",
        "Rubber",
        "Foam",
        "Cotton"
      ],
      "Thickness": [
        "4 mm",
        "6 mm",
        "8 mm",
        "10+ mm"
      ]
    },
    "Fitness Bands": {
      "Brand Name": [
        "Fitbit",
        "Garmin",
        "Mi",
        "Samsung",
        "Apple",
        "Honor",
        "Realme",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Features": [
        "Heart Rate Monitor",
        "Sleep Tracker",
        "Step Counter",
        "GPS",
        "Bluetooth Connectivity",
        "Waterproof"
      ]
    },
    "Home Gyms": {
      "Brand Name": [
        "Bowflex",
        "Durafit",
        "Afton",
        "PowerMax",
        "Kobo",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Station Types": [
        "Single Station",
        "Multi Station"
      ],
      "Weight Stack Capacity": [
        "50-100 kg",
        "101-150 kg",
        "151+ kg"
      ]
    },
    "Elliptical Trainers": {
      "Brand Name": [
        "PowerMax",
        "Cockatoo",
        "Durafit",
        "Fitkit",
        "Lifelong",
        "Welcare",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Type": [
        "Rear Drive",
        "Front Drive",
        "Center Drive"
      ]
    }
  },

  BooksSportsHobbies: {
    "Books": {
      "Genre": [
        "Fiction",
        "Non-Fiction",
        "Romance",
        "Science Fiction",
        "Fantasy",
        "Mystery",
        "Biography",
        "Self-Help",
        "Textbooks",
        "Children's Books",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Author": ["Various Authors", "Specific Authors"]
    },
    "Sports Equipment": {
      "Category": [
        "Cricket",
        "Football",
        "Badminton",
        "Tennis",
        "Table Tennis",
        "Hockey",
        "Basketball",
        "Swimming",
        "Cycling",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "Adidas",
        "Nike",
        "Reebok",
        "Yonex",
        "Spalding",
        "Wilson",
        "Others"
      ]
    },
    "Musical Instruments": {
      "Instrument Type": [
        "Guitar",
        "Piano",
        "Violin",
        "Flute",
        "Drums",
        "Saxophone",
        "Keyboard",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "Yamaha",
        "Roland",
        "Fender",
        "Gibson",
        "Casio",
        "Kawai",
        "Others"
      ]
    },
    "Board Games & Puzzles": {
      "Category": [
        "Chess",
        "Scrabble",
        "Monopoly",
        "Jigsaw Puzzles",
        "Card Games",
        "Trivia Games",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"]
    },
    "Hobby Supplies": {
      "Category": [
        "Art Supplies",
        "Craft Supplies",
        "DIY Kits",
        "Sewing Kits",
        "Knitting Supplies",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"]
    },
    "Outdoor Equipment": {
      "Category": [
        "Camping Gear",
        "Fishing Gear",
        "Hiking Gear",
        "Biking Gear",
        "Climbing Gear",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "Quechua",
        "Wildcraft",
        "Coleman",
        "Decathlon",
        "Others"
      ]
    },
    "Collectibles": {
      "Category": [
        "Stamps",
        "Coins",
        "Action Figures",
        "Model Trains",
        "Others"
      ],
      "Condition": ["Mint", "Good", "Fair", "Poor"]
    },
    "Fitness Equipment": {
      "Equipment Type": [
        "Treadmills",
        "Dumbbells",
        "Yoga Mats",
        "Resistance Bands",
        "Exercise Bikes",
        "Rowing Machines",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "Decathlon",
        "Nike",
        "Adidas",
        "Gold's Gym",
        "Others"
      ]
    }
  },


  EcoFriendlyProducts: {
    "Clothing": {
      "Category": [
        "Organic Cotton",
        "Recycled Fabric",
        "Hemp",
        "Bamboo",
        "Linen",
        "Wool",
        "Upcycled Materials",
        "Fair Trade Certified",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Size": ["XS", "S", "M", "L", "XL", "XXL", "Custom"]
    },
    "Reusable Items": {
      "Type": [
        "Reusable Bags",
        "Reusable Bottles",
        "Reusable Straws",
        "Reusable Utensils",
        "Reusable Food Wraps",
        "Reusable Coffee Cups",
        "Others"
      ],
      "Material": [
        "Metal",
        "Glass",
        "Bamboo",
        "Silicone",
        "Cotton",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"]
    },
    "Furniture": {
      "Type": [
        "Reclaimed Wood Furniture",
        "Bamboo Furniture",
        "Recycled Plastic Furniture",
        "Eco-Friendly Mattresses",
        "Recycled Metal Furniture",
        "Upcycled Furniture",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"]
    },
    "Bags & Accessories": {
      "Type": [
        "Eco-Friendly Backpacks",
        "Recycled Tote Bags",
        "Bamboo Sunglasses",
        "Vegan Leather Wallets",
        "Recycled Watches",
        "Others"
      ],
      "Material": [
        "Recycled Plastic",
        "Organic Cotton",
        "Hemp",
        "Bamboo",
        "Cork",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"]
    },
    "Household Items": {
      "Type": [
        "Compost Bins",
        "Biodegradable Cleaning Supplies",
        "Solar Lamps",
        "Energy-Saving Bulbs",
        "Water-Saving Faucets",
        "Others"
      ],
      "Material": ["Biodegradable", "Recycled", "Renewable"],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"]
    },
    "Beauty & Personal Care": {
      "Category": [
        "Organic Skincare",
        "Vegan Makeup",
        "Biodegradable Hair Products",
        "Plastic-Free Packaging",
        "Natural Deodorants",
        "Others"
      ],
      "Condition": ["Brand New", "Opened but Unused"],
      "Certifications": ["Cruelty-Free", "Vegan", "Organic", "Fair Trade"]
    },
    "Baby Products": {
      "Category": [
        "Organic Cotton Baby Clothes",
        "Reusable Diapers",
        "Bamboo Baby Wipes",
        "Wooden Toys",
        "Non-Toxic Teethers",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used"]
    },
    "Food Items": {
      "Category": [
        "Organic Vegetables",
        "Organic Fruits",
        "Locally Sourced Produce",
        "Fair Trade Coffee",
        "Fair Trade Tea",
        "Others"
      ],
      "Packaging": ["Plastic-Free", "Compostable", "Reusable"],
      "Condition": ["Fresh", "Preserved"]
    },
    "Gardening Supplies": {
      "Category": [
        "Compost",
        "Organic Seeds",
        "Biodegradable Pots",
        "Solar-Powered Tools",
        "Rainwater Harvesting Systems",
        "Others"
      ],
      "Material": ["Biodegradable", "Recycled"],
      "Condition": ["Brand New", "Gently Used"]
    }
  },


  MusicalInstruments: {
    "String Instruments": {
      "Instrument Type": [
        "Guitar",
        "Violin",
        "Cello",
        "Harp",
        "Mandolin",
        "Ukulele",
        "Bass Guitar",
        "Sitar",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "Yamaha",
        "Fender",
        "Gibson",
        "Ibanez",
        "Martin",
        "Taylor",
        "Kala",
        "Epiphone",
        "Others"
      ]
    },
    "Percussion Instruments": {
      "Instrument Type": [
        "Drum Set",
        "Tabla",
        "Djembe",
        "Cajon",
        "Bongos",
        "Congas",
        "Tambourine",
        "Maracas",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "Pearl",
        "Roland",
        "Tama",
        "Yamaha",
        "Ludwig",
        "DW",
        "Meinl",
        "Others"
      ]
    },
    "Wind Instruments": {
      "Instrument Type": [
        "Flute",
        "Clarinet",
        "Saxophone",
        "Trumpet",
        "Trombone",
        "Harmonica",
        "Bagpipes",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "Yamaha",
        "Selmer",
        "Buffet Crampon",
        "Conn-Selmer",
        "Hohner",
        "Jupiter",
        "Others"
      ]
    },
    "Keyboard Instruments": {
      "Instrument Type": [
        "Piano",
        "Electric Keyboard",
        "Organ",
        "Synthesizer",
        "Accordion",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "Yamaha",
        "Casio",
        "Kawai",
        "Roland",
        "Korg",
        "Nord",
        "Others"
      ]
    },
    "Electronic Instruments": {
      "Instrument Type": [
        "Electric Guitar",
        "Electronic Drum Kit",
        "Digital Piano",
        "Synthesizer",
        "Theremin",
        "Sampler",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "Moog",
        "Korg",
        "Roland",
        "Ableton",
        "Native Instruments",
        "Novation",
        "Others"
      ]
    },
    "Folk & Traditional Instruments": {
      "Instrument Type": [
        "Sitar",
        "Tabla",
        "Dhol",
        "Sarod",
        "Veena",
        "Bagpipes",
        "Banjo",
        "Didgeridoo",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Region": [
        "Indian",
        "African",
        "Middle Eastern",
        "European",
        "Asian",
        "Others"
      ]
    },
    "Accessories": {
      "Accessory Type": [
        "Strings",
        "Picks",
        "Capos",
        "Drumsticks",
        "Reeds",
        "Mouthpieces",
        "Tuners",
        "Stands",
        "Cables",
        "Amplifiers",
        "Cases",
        "Others"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used"],
      "Brand Name": [
        "D'Addario",
        "Ernie Ball",
        "Fender",
        "Yamaha",
        "Gibson",
        "Planet Waves",
        "Others"
      ]
    }
  },



  FoodsAndBeverages: {
    "Dairy Products": {
      "Type": [
        "Milk",
        "Cheese",
        "Butter",
        "Yogurt",
        "Cream"
      ],
      "Packaging": [
        "Tetra Pack",
        "Plastic Bottle",
        "Glass Bottle",
        "Pouch",
        "Bulk"
      ],
      "Brand Name": [
        "Amul",
        "Nestle",
        "Mother Dairy",
        "Britannia",
        "Others"
      ],
      "Condition": [
        "Fresh",
        "Frozen",
        "Powdered"
      ]
    },
    "Beverages": {
      "Type": [
        "Juices",
        "Soft Drinks",
        "Energy Drinks",
        "Coffee",
        "Tea"
      ],
      "Packaging": [
        "Can",
        "Bottle",
        "Tetra Pack",
        "Pouch"
      ],
      "Brand Name": [
        "Coca-Cola",
        "Pepsi",
        "Red Bull",
        "Tropicana",
        "Nescafe",
        "Others"
      ],
      "Condition": [
        "Chilled",
        "Room Temperature"
      ]
    },
    "Snacks": {
      "Type": [
        "Chips",
        "Biscuits",
        "Nuts",
        "Popcorn",
        "Others"
      ],
      "Packaging": [
        "Single Pack",
        "Family Pack",
        "Bulk"
      ],
      "Brand Name": [
        "Lays",
        "Bingo",
        "Haldiram's",
        "Pringles",
        "Others"
      ],
      "Condition": [
        "Fresh",
        "Sealed"
      ]
    },
    "Bakery Items": {
      "Type": [
        "Bread",
        "Cakes",
        "Pastries",
        "Cookies",
        "Buns"
      ],
      "Packaging": [
        "Plastic Wrap",
        "Box",
        "Paper Bag",
        "Bulk"
      ],
      "Brand Name": [
        "Britannia",
        "Harvest Gold",
        "Modern",
        "Others"
      ],
      "Condition": [
        "Fresh",
        "Frozen"
      ]
    },
    "Packaged Foods": {
      "Type": [
        "Ready-to-Eat Meals",
        "Instant Noodles",
        "Soups",
        "Sauces",
        "Others"
      ],
      "Packaging": [
        "Box",
        "Pouch",
        "Jar",
        "Can"
      ],
      "Brand Name": [
        "Maggi",
        "Knorr",
        "Kissan",
        "Others"
      ],
      "Condition": [
        "Sealed",
        "Frozen",
        "Room Temperature"
      ]
    },
    "Fruits and Vegetables": {
      "Type": [
        "Fresh Fruits",
        "Fresh Vegetables",
        "Cut & Packed",
        "Frozen"
      ],
      "Packaging": [
        "Loose",
        "Bag",
        "Box"
      ],
      "Condition": [
        "Fresh",
        "Frozen"
      ]
    },
    "Spices and Condiments": {
      "Type": [
        "Whole Spices",
        "Ground Spices",
        "Blended Spices",
        "Condiments"
      ],
      "Packaging": [
        "Jar",
        "Pouch",
        "Bottle",
        "Box"
      ],
      "Brand Name": [
        "MDH",
        "Everest",
        "Catch",
        "Others"
      ],
      "Condition": [
        "Fresh",
        "Sealed"
      ]
    },
    "Cooking Oils": {
      "Type": [
        "Refined Oil",
        "Mustard Oil",
        "Olive Oil",
        "Coconut Oil",
        "Others"
      ],
      "Packaging": [
        "Bottle",
        "Can",
        "Pouch"
      ],
      "Brand Name": [
        "Fortune",
        "Saffola",
        "Dalda",
        "Others"
      ],
      "Condition": [
        "Sealed",
        "Fresh"
      ]
    }
  },





  Cars: {
    Cars: {
      "Brand Name": [
        "Ashok Leyland",
        "Aston Martin",
        "Audi",
        "BMW",
        "Bentley",
        "Bugatti",
        "Caterham",
        "Chevrolet",
        "DC",
        "Daewoo",
        "Datsun",
        "Eicher Polaris",
        "Ferrari",
        "Fiat",
        "Force Motors",
        "Ford",
        "Hindustan Motors",
        "Honda",
        "Hyundai",
        "ICML",
        "Isuzu",
        "Jaguar",
        "Jeep",
        "Kia",
        "Lamborghini",
        "Land Rover",
        "Lexus",
        "MG Motors",
        "Mahindra",
        "Maruti Suzuki",
        "Maserati",
        "Mercedes Benz",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Porsche",
        "Premier",
        "Renault",
        "Rolls Royce",
        "San",
        "Skoda",
        "Ssangyong",
        "Tata",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
      ],
      "Car Type ": ["New Car", "Used Car"],
      "Fuel Type ": ["Petrol", "Diesel", "CNG", "Electric", "LPG", "Hybrid"],
      "Year of Registration": year.map((ye) => ye.toString()),
      // [
      //   "2000",
      //   "2001",
      //   "2002",
      //   "2003",
      //   "2004",
      //   "2005",
      //   "2006",
      //   "2007",
      //   "2008",
      //   "2009",
      //   "2010",
      //   "2011",
      //   "2012",
      //   "2013",
      //   "2014",
      //   "2015",
      //   "2016",
      //   "2017",
      //   "2018",
      //   "2019",
      //   "2020",
      //   "2021",
      //   "2022",
      //   "2023",
      // ],
      KM_Driven: [
        "0-5000",
        "5000-6000",
        "6000-8000",
        "8000-10000",
        "10000-13000",
        "13000-15000",
        "15000-18000",
        "18000-22000",
        "22000-25000",
        "25000-30000",
        "30000-35000",
        "35000-40000",
        "40000-45000",
        "45000-50000",
        "50000-55000",
        "55000-60000",
        "60000-65000",
        "65000-70000",
        "70000-75000",
        "75000-80000",
        "80000-85000",
        "85000-90000",
        "90000-95000",
        "95000 & Above",
      ],
      // "Price":["10000-15000","15000-20000","20000-30000","30000-40000","40000-50000","50000-60000","60000-70000","70000-80000","80000-90000","90000-100000","100000-150000","150000-200000","200000-250000","250000-300000","300000-350000","350000-400000","400000-450000","450000-500000","500000-550000","550000-600000","600000-650000","650000-700000","700000-750000","750000-800000","800000-850000","850000-900000","900000-950000","950000-1000000","10L-11L","11L-12L","12L-13L","13L-14L","14L-15L","15L-16L","16L-17L","17L-18L","18L-19L","19L-20L","21L-22L","22L-23L","23L-24L","24L-25L","25L-26L","26L-27L","27L-28L","28L-29L","29L-30L","30L-31L","31L-32L","32L-33L","33L-34L","34L-35L","35L-36L","36L-37L","38L-39L","39L-40L","40L-41L","41L-42L","42L-43L","43L-44L","44L-45L","45L-46L","46L-47L","47L-48L","48L-49L","49L-50L","50L-51L","51L-52L","52L-53L","53L-54L","54L-55L","55L-56L","57L-58L","59L-60L","60L-61L","61L-62L","62L-63L","64L-65L","65L-66L","66L-67L","67L-68L","68L-69L","70L-71L","71L-72L","73L-74L","74L-75L","75L-76L","76L-77L","77L-78L","78L-79L","79L-80L","80L-81L","81L-82L","82L-83L","83L-84L","84L-85L","85L-86L","86L-87L","87L-88L","89L-90L","90L-91L","91L-92L","92L-93L","93L-94L","94L-95L","95L-96L","96L-97L","97L-98L","98L-99L"],
      Colour: [
        "Beige",
        "Black",
        "Blue",
        "Brown",
        "Cyan",
        "Gray",
        "Green",
        "Indigo",
        "Lime",
        "Magenta",
        "Maroon",
        "Navy Blue",
        "Olive",
        "Orange",
        "Peach",
        "Pink",
        "Purple",
        "Red",
        "Silver",
        "Tan",
        "Teal",
        "Turquoise",
        "Violet",
        "White",
        "Yellow",
      ],
    },

    "Spare Parts - Accessories": {
      "Product Type": [
        "CNG/LPG Kit",
        "Electrical Part",
        "Exterior Part",
        "Interior Accessory",
        "Lubricant",
        "Other Accessory",
        "Product",
        "Tyre/Rubber",
      ],
      // "Price":["10000-15000","15000-20000","20000-30000","30000-40000","40000-50000","50000-60000","60000-700EquipmentSecurity00","70000-80000","80000-90000","90000-100000","100000-150000","150000-200000","200000-250000","250000-300000","300000-350000","350000-400000","400000-450000","450000-500000","500000-550000","550000-600000","600000-650000","650000-700000","700000-750000","750000-800000","800000-850000","850000-900000","900000-950000","950000-1000000","10L-11L","11L-12L","12L-13L","13L-14L","14L-15L","15L-16L","16L-17L","17L-18L","18L-19L","19L-20L","21L-22L","22L-23L","23L-24L","24L-25L","25L-26L","26L-27L","27L-28L","28L-29L","29L-30L","30L-31L","31L-32L","32L-33L","33L-34L","34L-35L","35L-36L","36L-37L","38L-39L","39L-40L","40L-41L","41L-42L","42L-43L","43L-44L","44L-45L","45L-46L","46L-47L","47L-48L","48L-49L","49L-50L","50L-51L","51L-52L","52L-53L","53L-54L","54L-55L","55L-56L","57L-58L","59L-60L","60L-61L","61L-62L","62L-63L","64L-65L","65L-66L","66L-67L","67L-68L","68L-69L","70L-71L","71L-72L","73L-74L","74L-75L","75L-76L","76L-77L","77L-78L","78L-79L","79L-80L","80L-81L","81L-82L","82L-83L","83L-84L","84L-85L","85L-86L","86L-87L","87L-88L","89L-90L","90L-91L","91L-92L","92L-93L","93L-94L","94L-95L","95L-96L","96L-97L","97L-98L","98L-99L"],
    },
  },

  Bikes: {
    Bikes: {
      "Brand Name": [
        "Aprilia",
        "Ather Energy",
        "BMW",
        "Bajaj",
        "Benelli",
        "Ducati",
        "Harley Davidson",
        "Hero",
        "Honda",
        "Hyosung",
        "Indian",
        "Jawa",
        "KTM",
        "Kawasaki",
        "Kinetic Motors",
        "LML ( Piaggio)",
        "Lambretta",
        "Mahindra",
        "Moto Guzzi",
        "Raft",
        "Royal Enfield",
        "Suzuki",
        "TVS",
        "Triumph",
        "UM",
        "YO Bikes",
        "Yamaha",
        "Yezdi",
      ],
      "Bike Type ": ["New Bike", "Used Bike"],
      "Fuel Type ": ["Petrol", "Electric"],

      "Year of Registration": year.map((ye) => ye.toString()),
      // [
      //   "2000",
      //   "2001",
      //   "2002",
      //   "2003",
      //   "2004",
      //   "2005",
      //   "2006",
      //   "2007",
      //   "2008",
      //   "2009",
      //   "2010",
      //   "2011",
      //   "2012",
      //   "2013",
      //   "2014",
      //   "2015",
      //   "2016",
      //   "2017",
      //   "2018",
      //   "2019",
      //   "2020",
      //   "2021",
      //   "2022",
      //   "2023",
      // ],

      "KM_Driven ": [
        "0-5000",
        "5000-6000",
        "6000-8000",
        "8000-10000",
        "10000-13000",
        "13000-15000",
        "15000-18000",
        "18000-22000",
        "22000-25000",
        "25000-30000",
        "30000-35000",
        "35000-40000",
        "40000-45000",
        "45000-50000",
        "50000-55000",
        "55000-60000",
        "60000-65000",
        "65000-70000",
        "70000-75000",
        "75000-80000",
        "80000-85000",
        "85000-90000",
        "90000-95000",
        "95000 & Above",
      ],
      // "Price":["10000-15000","15000-20000","20000-30000","30000-40000","40000-50000","50000-60000","60000-70000","70000-80000","80000-90000","90000-100000","100000-150000","150000-200000","200000-250000","250000-300000","300000-350000","350000-400000","400000-450000","450000-500000","500000-550000","550000-600000","600000-650000","650000-700000","700000-750000","750000-800000","800000-850000","850000-900000","900000-950000","950000-1000000","10L-11L","11L-12L","12L-13L","13L-14L","14L-15L","15L-16L","16L-17L","17L-18L","18L-19L","19L-20L","21L-22L","22L-23L","23L-24L","24L-25L","25L-26L","26L-27L","27L-28L","28L-29L","29L-30L","30L-31L","31L-32L","32L-33L","33L-34L","34L-35L","35L-36L","36L-37L","38L-39L","39L-40L","40L-41L","41L-42L","42L-43L","43L-44L","44L-45L","45L-46L","46L-47L","47L-48L","48L-49L","49L-50L","50L-51L","51L-52L","52L-53L","53L-54L","54L-55L","55L-56L","57L-58L","59L-60L","60L-61L","61L-62L","62L-63L","64L-65L","65L-66L","66L-67L","67L-68L","68L-69L","70L-71L","71L-72L","73L-74L","74L-75L","75L-76L","76L-77L","77L-78L","78L-79L","79L-80L","80L-81L","81L-82L","82L-83L","83L-84L","84L-85L","85L-86L","86L-87L","87L-88L","89L-90L","90L-91L","91L-92L","92L-93L","93L-94L","94L-95L","95L-96L","96L-97L","97L-98L","98L-99L"],
      Colour: [
        "Beige",
        "Black",
        "Blue",
        "Brown",
        "Cyan",
        "Gray",
        "Green",
        "Indigo",
        "Lime",
        "Magenta",
        "Maroon",
        "Navy blue",
        "Olive",
        "Orange",
        "Peach",
        "Pink",
        "Purple",
        "Red",
        "Silver",
        "Tan",
        "Teal",
        "Turquoise",
        "Violet",
        "White",
        "Yellow",
      ],
    },

    "Spare Parts - Accessories": {
      "	Product Type": [
        "CNG/LPG Kit",
        "Electrical Part",
        "Exterior Part",
        "Interior Accessory",
        "Lubricant",
        "Other Accessory",
        "Security Equipment",
        "Tyre/Rubber Product",
      ],
      // "Price":["10000-15000","15000-20000","20000-30000","30000-40000","40000-50000","50000-60000","60000-70000","70000-80000","80000-90000","90000-100000","100000-150000","150000-200000","200000-250000","250000-300000","300000-350000","350000-400000","400000-450000","450000-500000","500000-550000","550000-600000","600000-650000","650000-700000","700000-750000","750000-800000","800000-850000","850000-900000","900000-950000","950000-1000000","10L-11L","11L-12L","12L-13L","13L-14L","14L-15L","15L-16L","16L-17L","17L-18L","18L-19L","19L-20L","21L-22L","22L-23L","23L-24L","24L-25L","25L-26L","26L-27L","27L-28L","28L-29L","29L-30L","30L-31L","31L-32L","32L-33L","33L-34L","34L-35L","35L-36L","36L-37L","38L-39L","39L-40L","40L-41L","41L-42L","42L-43L","43L-44L","44L-45L","45L-46L","46L-47L","47L-48L","48L-49L","49L-50L","50L-51L","51L-52L","52L-53L","53L-54L","54L-55L","55L-56L","57L-58L","59L-60L","60L-61L","61L-62L","62L-63L","64L-65L","65L-66L","66L-67L","67L-68L","68L-69L","70L-71L","71L-72L","73L-74L","74L-75L","75L-76L","76L-77L","77L-78L","78L-79L","79L-80L","80L-81L","81L-82L","82L-83L","83L-84L","84L-85L","85L-86L","86L-87L","87L-88L","89L-90L","90L-91L","91L-92L","92L-93L","93L-94L","94L-95L","95L-96L","96L-97L","97L-98L","98L-99L"],
    },
  },

  CommercialVehicles: {
    "Commercial Vehicles": {
      "Vehicle Type": [
        "Trucks",
        "Buses",
        "Trailers",
        "Mini Trucks",
        "Vans",
        "Pick-Up Trucks",
        "Tankers",
        "Others"
      ],
      "Brand Name": [
        "Ashok Leyland",
        "Tata Motors",
        "Mahindra",
        "Eicher",
        "BharatBenz",
        "Force Motors",
        "Piaggio",
        "Isuzu",
        "Volvo",
        "Scania",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Used",
        "Refurbished",
        "Damaged"
      ],
      "Fuel Type": [
        "Diesel",
        "Petrol",
        "CNG",
        "Electric",
        "Hybrid"
      ],
      "Mileage": [
        "0-10,000 km",
        "10,001-50,000 km",
        "50,001-100,000 km",
        "100,001-500,000 km",
        "500,001 km & above"
      ]
    },
    "Spare Parts": {
      "Part Type": [
        "Engine Components",
        "Transmission Components",
        "Suspension & Steering",
        "Brakes",
        "Electrical Components",
        "Body Parts",
        "Cooling System",
        "Exhaust System",
        "Fuel System",
        "Filters",
        "Tyres & Wheels",
        "Others"
      ],
      "Brand Name": [
        "Bosch",
        "Mahindra Genuine Parts",
        "Tata Genuine Parts",
        "Exide",
        "Bridgestone",
        "Michelin",
        "Apollo",
        "JK Tyre",
        "MRF",
        "Goodyear",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Refurbished",
        "Unboxed"
      ],
      "Compatibility": [
        "Light Commercial Vehicles",
        "Heavy Commercial Vehicles",
        "Mini Trucks",
        "Trailers",
        "Others"
      ],
      "Warranty": [
        "No Warranty",
        "Manufacturer Warranty",
        "Dealer Warranty",
        "Third-Party Warranty"
      ]
    }
  }
  ,

  Fashion: {
    "Men's Clothing": {
      "Product Type": [
        "T-Shirts",
        "Shirts",
        "Jeans",
        "Trousers",
        "Shorts",
        "Blazers",
        "Suits",
        "Kurtas",
        "Sherwanis",
        "Jackets",
        "Sweaters",
        "Tracksuits",
        "Ethnic Wear",
        "Innerwear"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
      "Sizes": ["XS", "S", "M", "L", "XL", "XXL", "Custom"],
      "Fabric": [
        "Cotton",
        "Linen",
        "Denim",
        "Silk",
        "Wool",
        "Polyester",
        "Rayon",
        "Blends"
      ],
      "Brands": [
        "Levi's",
        "Zara",
        "H&M",
        "Adidas",
        "Nike",
        "Raymond",
        "Peter England",
        "Others"
      ],
      "Occasion": [
        "Casual",
        "Formal",
        "Party",
        "Wedding",
        "Sports",
        "Ethnic",
        "Loungewear"
      ]
    },
    "Women's Clothing": {
      "Product Type": [
        "Tops",
        "Dresses",
        "Sarees",
        "Lehengas",
        "Kurtis",
        "Jeans",
        "Skirts",
        "Trousers",
        "Shorts",
        "Blouses",
        "Jackets",
        "Sweaters",
        "Tracksuits",
        "Ethnic Wear",
        "Innerwear"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
      "Sizes": ["XS", "S", "M", "L", "XL", "XXL", "Custom"],
      "Fabric": [
        "Cotton",
        "Silk",
        "Chiffon",
        "Georgette",
        "Linen",
        "Denim",
        "Velvet",
        "Blends"
      ],
      "Brands": [
        "Fabindia",
        "Biba",
        "Zara",
        "H&M",
        "Adidas",
        "Nike",
        "Manyavar",
        "Others"
      ],
      "Occasion": [
        "Casual",
        "Formal",
        "Party",
        "Wedding",
        "Sports",
        "Ethnic",
        "Loungewear"
      ]
    },
    "Footwear": {
      "Product Type": [
        "Formal Shoes",
        "Casual Shoes",
        "Sneakers",
        "Loafers",
        "Sandals",
        "Slippers",
        "Boots",
        "Sports Shoes",
        "Heels",
        "Flats",
        "Ethnic Footwear"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
      "Sizes": ["5", "6", "7", "8", "9", "10", "Custom"],
      "Brands": [
        "Adidas",
        "Nike",
        "Puma",
        "Bata",
        "Woodland",
        "Metro",
        "Clarks",
        "Others"
      ],
      "Material": ["Leather", "Synthetic", "Canvas", "Rubber", "Suede", "Fabric"]
    },
    "Accessories": {
      "Product Type": [
        "Watches",
        "Sunglasses",
        "Belts",
        "Wallets",
        "Hats and Caps",
        "Ties and Bow Ties",
        "Cufflinks",
        "Bracelets",
        "Earrings",
        "Necklaces",
        "Rings",
        "Hair Accessories"
      ],
      "Condition": ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
      "Brands": [
        "Titan",
        "Fossil",
        "Ray-Ban",
        "Daniel Wellington",
        "Casio",
        "Gucci",
        "Others"
      ],
      "Material": [
        "Leather",
        "Metal",
        "Plastic",
        "Fabric",
        "Gold",
        "Silver",
        "Others"
      ]
    },
    "Grooming Products": {
      "Product Type": [
        "Shaving Kits",
        "Beard Care Products",
        "Perfumes",
        "Deodorants",
        "Hair Care Products",
        "Skin Care Products",
        "Makeup",
        "Nail Care"
      ],
      "Condition": ["Brand New", "Unboxed"],
      "Brands": [
        "Nivea",
        "Gillette",
        "L'Oreal",
        "Lakme",
        "Maybelline",
        "MAC",
        "Dove",
        "Others"
      ],
      "Gender": ["Men", "Women", "Unisex"]
    }
  },


  ToysAndGames: {
    "Action Figures": {
      "Brand Name": [
        "Hasbro",
        "Mattel",
        "Bandai",
        "Funko",
        "Playmates",
        "LEGO",
        "Spin Master",
        "Other"
      ],
      "Material": [
        "Plastic",
        "Vinyl",
        "Metal",
        "Wood",
        "Other"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Age Group": [
        "3-5 Years",
        "6-8 Years",
        "9-12 Years",
        "Teenagers",
        "Adults"
      ]
    },
    "Educational Toys": {
      "Brand Name": [
        "LeapFrog",
        "Melissa & Doug",
        "Fisher-Price",
        "VTech",
        "Hape",
        "ThinkFun",
        "Other"
      ],
      "Material": [
        "Plastic",
        "Wood",
        "Metal",
        "Fabric",
        "Other"
      ],
      "Skill Focus": [
        "STEM (Science, Tech, Engineering, Math)",
        "Language & Literacy",
        "Motor Skills",
        "Cognitive Development",
        "Creativity & Imagination"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Age Group": [
        "Infants",
        "Toddlers",
        "3-5 Years",
        "6-8 Years",
        "9-12 Years"
      ]
    },
    "Board Games": {
      "Brand Name": [
        "Hasbro",
        "Mattel",
        "Ravensburger",
        "Asmodee",
        "Milton Bradley",
        "Spin Master",
        "Other"
      ],
      "Game Type": [
        "Strategy",
        "Family",
        "Trivia",
        "Card-Based",
        "Cooperative",
        "Party"
      ],
      "Number of Players": [
        "1 Player",
        "2 Players",
        "2-4 Players",
        "4-6 Players",
        "6+ Players"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Age Group": [
        "3-5 Years",
        "6-8 Years",
        "9-12 Years",
        "Teenagers",
        "Adults"
      ]
    },
    "Puzzles": {
      "Brand Name": [
        "Ravensburger",
        "Melissa & Doug",
        "Buffalo Games",
        "Clementoni",
        "Eurographics",
        "Other"
      ],
      "Puzzle Type": [
        "Jigsaw Puzzle",
        "3D Puzzle",
        "Brain Teaser",
        "Wooden Puzzle",
        "Other"
      ],
      "Number of Pieces": [
        "Less than 50",
        "50-100",
        "100-500",
        "500-1000",
        "1000+"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Age Group": [
        "Toddlers",
        "3-5 Years",
        "6-8 Years",
        "9-12 Years",
        "Teenagers",
        "Adults"
      ]
    }
  },

  HealthAndBeauty: {
    "Skincare Products": {
      "Brand Name": [
        "Cetaphil",
        "Neutrogena",
        "The Ordinary",
        "La Roche-Posay",
        "Olay",
        "Nivea",
        "Other"
      ],
      "Product Type": [
        "Moisturizers",
        "Cleansers",
        "Serums",
        "Sunscreens",
        "Face Masks",
        "Exfoliators",
        "Toners",
        "Other"
      ],
      "Skin Type": [
        "Normal",
        "Dry",
        "Oily",
        "Combination",
        "Sensitive"
      ],
      "Condition": [
        "Brand New",
        "Opened but Unused",
        "Used"
      ]
    },
    "Haircare Products": {
      "Brand Name": [
        "L'Oréal",
        "Tresemme",
        "Pantene",
        "Garnier",
        "Herbal Essences",
        "Head & Shoulders",
        "Other"
      ],
      "Product Type": [
        "Shampoos",
        "Conditioners",
        "Hair Oils",
        "Hair Serums",
        "Hair Masks",
        "Styling Products",
        "Other"
      ],
      "Hair Type": [
        "Normal",
        "Dry",
        "Oily",
        "Curly",
        "Straight",
        "Damaged"
      ],
      "Condition": [
        "Brand New",
        "Opened but Unused",
        "Used"
      ]
    },
    "Makeup & Cosmetics": {
      "Brand Name": [
        "MAC",
        "Maybelline",
        "L'Oréal",
        "Revlon",
        "NYX",
        "Huda Beauty",
        "Other"
      ],
      "Product Type": [
        "Foundation",
        "Lipstick",
        "Eyeliner",
        "Mascara",
        "Blush",
        "Eyeshadow",
        "Highlighter",
        "Other"
      ],
      "Skin Tone": [
        "Fair",
        "Medium",
        "Tan",
        "Dark",
        "Other"
      ],
      "Condition": [
        "Brand New",
        "Opened but Unused",
        "Lightly Used"
      ]
    },
    "Personal Care Appliances (Trimmers, Hair Dryers)": {
      "Brand Name": [
        "Philips",
        "Braun",
        "Panasonic",
        "Dyson",
        "Remington",
        "Other"
      ],
      "Appliance Type": [
        "Trimmers",
        "Hair Dryers",
        "Straighteners",
        "Curling Irons",
        "Epilators",
        "Other"
      ],
      "Power Source": [
        "Corded",
        "Rechargeable",
        "Battery Operated"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used"
      ]
    },
    "Fitness Equipment (Yoga Mats, Weights)": {
      "Brand Name": [
        "Nike",
        "Adidas",
        "Decathlon",
        "CAP Barbell",
        "ProForm",
        "Other"
      ],
      "Equipment Type": [
        "Yoga Mats",
        "Dumbbells",
        "Kettlebells",
        "Resistance Bands",
        "Jump Ropes",
        "Other"
      ],
      "Material": [
        "Rubber",
        "PVC",
        "Foam",
        "Metal",
        "Other"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used"
      ]
    },
    "Supplements & Vitamins": {
      "Brand Name": [
        "GNC",
        "Optimum Nutrition",
        "Nature's Bounty",
        "Himalaya",
        "Amway",
        "Other"
      ],
      "Supplement Type": [
        "Multivitamins",
        "Protein Powders",
        "Herbal Supplements",
        "Omega-3",
        "Probiotics",
        "Other"
      ],
      "Target Group": [
        "Adults",
        "Children",
        "Seniors",
        "Pregnant Women",
        "Athletes"
      ],
      "Condition": [
        "Brand New",
        "Sealed",
        "Opened but Unused"
      ]
    }
  },

  FarmingAndGardening: {
    "Farm Equipment": {
      "Equipment Type": [
        "Tractors",
        "Plows",
        "Harvesters",
        "Seed Drills",
        "Sprayers",
        "Irrigation Systems",
        "Other"
      ],
      "Brand Name": [
        "John Deere",
        "Kubota",
        "Mahindra",
        "New Holland",
        "Case IH",
        "Other"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Needs Repair"
      ],
      "Power Source": [
        "Diesel",
        "Electric",
        "Manual",
        "Other"
      ]
    },
    "Plants & Seeds": {
      "Plant Type": [
        "Flowering Plants",
        "Fruit Plants",
        "Vegetable Plants",
        "Herbs",
        "Indoor Plants",
        "Shrubs",
        "Other"
      ],
      "Seed Type": [
        "Vegetable Seeds",
        "Fruit Seeds",
        "Flower Seeds",
        "Herb Seeds",
        "Organic Seeds",
        "Hybrid Seeds",
        "Other"
      ],
      "Condition": [
        "Fresh",
        "Packed",
        "Dry",
        "Other"
      ],
      "Growth Stage": [
        "Seedlings",
        "Saplings",
        "Fully Grown"
      ]
    },
    "Gardening Tools": {
      "Tool Type": [
        "Shovels",
        "Pruners",
        "Hoes",
        "Rakes",
        "Gloves",
        "Watering Cans",
        "Sprinklers",
        "Other"
      ],
      "Material": [
        "Metal",
        "Plastic",
        "Wood",
        "Rubber",
        "Other"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used"
      ]
    },
    "Livestock": {
      "Animal Type": [
        "Cattle",
        "Sheep",
        "Goats",
        "Poultry",
        "Pigs",
        "Horses",
        "Fish",
        "Other"
      ],
      "Breed": [
        "Local Breed",
        "Crossbreed",
        "Exotic Breed",
        "Other"
      ],
      "Age Group": [
        "Newborn",
        "Juvenile",
        "Adult"
      ],
      "Condition": [
        "Healthy",
        "Sick",
        "Other"
      ]
    },
    "Organic Produce": {
      "Product Type": [
        "Fruits",
        "Vegetables",
        "Grains",
        "Herbs",
        "Other"
      ],
      "Certification": [
        "Certified Organic",
        "Non-Certified Organic"
      ],
      "Packaging": [
        "Loose",
        "Packed"
      ],
      "Condition": [
        "Fresh",
        "Dry",
        "Other"
      ]
    }
  },

  PhotographyEquipment: {
    "Cameras": {
      "Camera Type": [
        "DSLR",
        "Mirrorless",
        "Point and Shoot",
        "Instant Cameras",
        "Action Cameras",
        "Film Cameras",
        "Other"
      ],
      "Brand Name": [
        "Canon",
        "Nikon",
        "Sony",
        "Fujifilm",
        "Panasonic",
        "Olympus",
        "Leica",
        "GoPro",
        "Other"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Megapixel Range": [
        "Below 10 MP",
        "10-20 MP",
        "21-30 MP",
        "Above 30 MP"
      ]
    },
    "Lenses": {
      "Lens Type": [
        "Wide Angle",
        "Standard",
        "Telephoto",
        "Macro",
        "Prime",
        "Zoom",
        "Fisheye",
        "Tilt-Shift",
        "Other"
      ],
      "Brand Name": [
        "Canon",
        "Nikon",
        "Sony",
        "Sigma",
        "Tamron",
        "Fujifilm",
        "Zeiss",
        "Leica",
        "Other"
      ],
      "Mount Type": [
        "Canon EF/EF-S",
        "Nikon F",
        "Sony E/FE",
        "Micro Four Thirds",
        "Fujifilm X",
        "Leica L",
        "Other"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Focal Length Range": [
        "Below 35mm",
        "35-70mm",
        "70-200mm",
        "200mm and Above"
      ]
    },
    "Tripods": {
      "Tripod Type": [
        "Travel Tripod",
        "Studio Tripod",
        "Mini Tripod",
        "Monopod",
        "Other"
      ],
      "Material": [
        "Aluminum",
        "Carbon Fiber",
        "Plastic",
        "Other"
      ],
      "Max Height": [
        "Below 4ft",
        "4-6ft",
        "Above 6ft"
      ],
      "Load Capacity": [
        "Below 5kg",
        "5-10kg",
        "Above 10kg"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used"
      ]
    },
    "Accessories": {
      "Accessory Type": [
        "Camera Bags",
        "Memory Cards",
        "Lens Filters",
        "Battery Chargers",
        "Extra Batteries",
        "Flashes",
        "Remote Shutters",
        "Lens Hoods",
        "Other"
      ],
      "Brand Name": [
        "Canon",
        "Nikon",
        "Sony",
        "Manfrotto",
        "Lowepro",
        "Sandisk",
        "Hoya",
        "Other"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ]
    }
  },


  Furniture: {
    "Furniture For Home & Office": {
      "Furnishing Type": [
        "Bar Chairs & Stools",
        "Bar Units & Cabinets",
        "Bean Bag",
        "Bed Set",
        "Bedside Tables",
        "Benches",
        "Book Shelves",
        "Box",
        "Bunk Bed",
        "Cabinet",
        "Center Table",
        "Computer Tables",
        "Conference Table Dining Chairs",
        "Dining Table",
        "Diwans",
        "Door",
        "Dressing Table",
        "Inflatable Bed",
        "J-Template & Player Units",
        "Massage Chair",
        "Office Chair",
        "Office Table",
        "Outdoor Furniture Sets",
        "Plastic Chairs",
        "Recliner",
        "Rocking Chair",
        "Sofa Cum Bed",
        "Sofa Set",
        "Stool",
        "Study Table",
        "Swings",
        "TV Units",
        "Wardrobe",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },

    "Home Decor - Furnishings": {
      "Furnishing Type": [
        "Aquarium",
        "Bed Sheet",
        "Blanket/Quilt",
        "Candle",
        "Candle Stand",
        "Carpet/Rug/Mat",
        "Curtain ",
        "Digital Photo Frame",
        "Door Mat",
        "Key Holders",
        "Mattress",
        "Mirrors",
        "Modular Frames",
        "Modular Kitchens",
        "Photo Frames",
        "Pillow ",
        "Sofa Cover",
        "Sofa Curtains",
        "Stand ",
        "Steel Rack",
        "Table Lamp",
        "Wall Accents",
        "Wall Clock",
        "Wall Shelves",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },
    Household: {
      "Product Type": [
        "Air Freshner",
        "Bath Tub",
        "Drying Stand",
        "Emergency  Light",
        "Garden Pose",
        "Garden Sprinkles",
        "Grass Trimmers",
        "Iron Board",
        "Iron Box",
        "Lamp Shade",
        "Pepper Spray",
        "Shoe Rack",
        "Step Ladders & Stools",
        "Storage Containers",
        "Torch",
        "Umbrella",
        "Water Cans",
        "Water Drums & Trunks",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },
    Kitchenware: {
      "Product Type": [
        "Barbeque Grills",
        "Cooking Utensils",
        "Cutlery Sets",
        "Food Streamers",
        "Glassware Kitchen Items",
        "Kitchen Storage Container",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },

    Painting: {
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },
  },
  Jobs: {
    "Full Time": {
      "Min Salary": ["8k to 10k", "10k to 12k", "12k to 15k", "15k to 20k"],
      "Max Salary": [
        "12k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 40k",
        "40k to 50k",
        "50k to 70k",
        "70k to 90k",
        "90k to 1Lakh",
      ],
      "Min Experience": [
        "6month to 1Year",
        "1Year to 1.5Year",
        "1.5Year to 2Year",
      ],
      "Max Experience": [
        "1Year to 1.5Year",
        "1.5Year to 2Year",
        "2Year to 2.5Year",
        "2.5Year to 3Year",
        "3Year to 5Year",
      ],
      "Industry ": [
        "Advertising/PR/Events",
        "Agriculture/Dairy/Forestry/Fishing",
        "Airlines/Aviation/Aerospace",
        "Animation",
        "Architecture/Interior Design",
        "Automotive/Automobile/Ancillaries",
        "Banking/Accounting/Financial Services",
        "Beverages/Liquor",
        "Bio Technology & Life Sciences",
        "Cement/Concrete/Readymix",
        "Ceramics & Sanitary Ware",
        "Chemicals/Petrochemicals",
        "Construction & Engineering",
        "Consulting/Advisory Services",
        "Consumer Electronics/Durables/Appliances",
        "Courier/Freight/Transportation",
        "E-Learning",
        "Education",
        "Electrical/Switchgears",
        "Electronics Manufacturing",
        "Engineering & Design",
        "Engineering/Procurement/Construction",
        "Entertainment/Media/Publishing",
        "Environmental Service",
        "Escalators/Elevators",
        "FMCG​",
        "Facility Management",
        "Fertilizer/Pesticides",
        "Food & Packaged Food",
        "GLASS",
        "Gems & Jewellery",
        "General Trading/Import/Export",
        "Government/PSU/Defence",
        "Heat Ventilation Air Conditioning (HVAC)",
        "Hospitals/Healthcare/Diagnostics",
        "Hotels/Hospitality/Restaurant",
        "ISP",
        "IT/Computers - Hardware & Networking",
        "IT/Computers - Software",
        "ITES/BPO",
        "Insurance​",
        "Internet/E-commerce",
        "Iron/Steel",
        "KPO/Research/Analytics",
        "Law Enforcement/Security Services",
        "Leather",
        "Legal/Law Firm",
        "Machinery/Equipment Mfg.",
        "Market Research",
        "Medical Transcription",
        "Mining",
        "NGO/Social Services",
        "Non-Ferrous Metals (Aluminium/Zinc etc.)",
        "Office Equipment/Automation",
        "Oil/Gas/Petroleum",

        "Paints",
        "Paper",
        "Pharmaceutical",
        "Plastic/Rubber",
        "Power/Energy",
        "Printing/Packaging",
        "Public Relations (PR)",
        "Railways Specialty/Infrastructure",
        "Real Estate",
        "Recruitment/Staffing/RPO",
        "Retailing",
        "Semiconductor​",
        "Shipping/Marine Services",
        "Social Media",
        "Sugar",
        "Telecom",
        "Textiles/Yarn/Fabrics/Garments",
        "Travel/Tourism",
        "Tyres",
        "Wellness/Fitness/Sports",
        "Wood",
        "Any",
        "Other",
      ],
      Qualification: ["B.Tech", "MBA"],
    },
    "Part Time": {
      "Min Salary": ["8k to 10k", "10k to 12k", "12k to 15k", "15k to 20k"],
      "Max Salary": [
        "12k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 40k",
        "40k to 50k",
        "50k to 70k",
        "70k to 90k",
        "90k to 1Lakh",
      ],
      "Min Experience": [
        "6month to 1Year",
        "1Year to 1.5Year",
        "1.5Year to 2Year",
      ],
      "Max Experience": [
        "1Year to 1.5Year",
        "1.5Year to 2Year",
        "2Year to 2.5Year",
        "2.5Year to 3Year",
        "3Year to 5Year",
      ],
      "Industry ": [
        "Advertising/PR/Events",
        "Agriculture/Dairy/Forestry/Fishing",
        "Airlines/Aviation/Aerospace",
        "Animation",
        "Any",
        "Architecture/Interior Design",
        "Automotive/Automobile/Ancillaries",
        "Banking/Accounting/Financial Services",
        "Beverages/Liquor",
        "Bio Technology & Life Sciences",
        "Cement/Concrete/Readymix",
        "Ceramics & Sanitary Ware",
        "Chemicals/Petrochemicals",
        "Construction & Engineering",
        "Consulting/Advisory Services",
        "Consumer Electronics/Durables/Appliances",
        "Courier/Freight/Transportation",
        "E-Learning",
        "Education",
        "Electrical/Switchgears",
        "Electronics Manufacturing",
        "Engineering & Design",
        "Engineering/Procurement/Construction",
        "Entertainment/Media/Publishing",
        "Environmental Service",
        "Escalators/Elevators",
        "FMCG​",
        "Facility management",
        "Fashion/Apparels",
        "Fertilizer/Pesticides",
        "Food & Packaged Food",
        "GLASS",
        "Gems & Jewellery",
        "General Trading/Import/Export",
        "Government/PSU/Defence",
        "Heat Ventilation Air Conditioning (HVAC)",
        "Hospitals/Healthcare/Diagnostics",
        "Hotels/Hospitality/Restaurant",
        "ISP",
        "IT/Computers - Hardware & Networking",
        "IT/Computers - Software",
        "ITES/BPO",
        "Insurance​",
        "Internet/E-commerce",
        "Iron/Steel",
        "KPO/Research/Analytics",
        "Law Enforcement/Security Services",
        "Leather",
        "Legal/Law Firm",
        "Machinery/Equipment Mfg.",
        "Market Research",
        "Medical Transcription",
        "Mining",
        "NGO/Social Services",
        "Non-Ferrous Metals (Aluminium/Zinc etc.)",
        "Office Equipment/Automation",
        "Oil/Gas/Petroleum",
        "Other",
        "Paints",
        "Paper",
        "Pharmaceutical",
        "Plastic/Rubber",
        "Power/Energy",
        "Printing/Packaging",
        "Public Relations (PR)",
        "Railways Specialty/Infrastructure",
        "Real Estate",
        "Recruitment/Staffing/RPO",
        "Retailing",
        "Semiconductor​",
        "Shipping/Marine Services",
        "Social Media",
        "Sugar",
        "Telecom",
        "Textiles/Yarn/Fabrics/Garments",
        "Travel/Tourism",
        "Tyres",
        "Wellness/Fitness/Sports",
        "Wood",
      ],
      Qualification: ["B.Tech", "MBA"],
    },
    Internships: {
      "Min Salary": ["8k to 10k", "10k to 12k", "12k to 15k", "15k to 20k"],
      "Max Salary": [
        "12k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 40k",
        "40k to 50k",
        "50k to 70k",
        "70k to 90k",
        "90k to 1Lakh",
      ],
      "Min Experience": [
        "6month to 1Year",
        "1Year to 1.5Year",
        "1.5Year to 2Year",
      ],
      "Max Experience": [
        "1Year to 1.5Year",
        "1.5Year to 2Year",
        "2Year to 2.5Year",
        "2.5Year to 3Year",
        "3Year to 5Year",
      ],
      "Industry ": [
        "Advertising/PR/Events",
        "Agriculture/Dairy/Forestry/Fishing",
        "Airlines/Aviation/Aerospace",
        "Animation",
        "Any",
        "Architecture/Interior Design",
        "Automotive/Automobile/Ancillaries",
        "Banking/Accounting/Financial Services",
        "Beverages/Liquor",
        "Bio Technology & Life Sciences",
        "Cement/Concrete/Readymix",
        "Ceramics & Sanitary Ware",
        "Chemicals/Petrochemicals",
        "Construction & Engineering",
        "Consulting/Advisory Services",
        "Consumer Electronics/Durables/Appliances",
        "Courier/Freight/Transportation",
        "E-Learning",
        "Education",
        "Electrical/Switchgears",
        "Electronics Manufacturing",
        "Engineering & Design",
        "Engineering/Procurement/Construction",
        "Entertainment/Media/Publishing",
        "Environmental Service",
        "Escalators/Elevators",
        "FMCG​",
        "Facility management",
        "Fashion/Apparels",
        "Fertilizer/Pesticides",
        "Food & Packaged Food",
        "GLASS",
        "Gems & Jewellery",
        "General Trading/Import/Export",
        "Government/PSU/Defence",
        "Heat Ventilation Air Conditioning (HVAC)",
        "Hospitals/Healthcare/Diagnostics",
        "Hotels/Hospitality/Restaurant",
        "ISP",
        "IT/Computers - Hardware & Networking",
        "IT/Computers - Software",
        "ITES/BPO",
        "Insurance​",
        "Internet/E-commerce",
        "Iron/Steel",
        "KPO/Research/Analytics",
        "Law Enforcement/Security Services",
        "Leather",
        "Legal/Law Firm",
        "Machinery/Equipment Mfg.",
        "Market Research",
        "Medical Transcription",
        "Mining",
        "NGO/Social Services",
        "Non-Ferrous Metals (Aluminium/Zinc etc.)",
        "Office Equipment/Automation",
        "Oil/Gas/Petroleum",
        "Other",
        "Paints",
        "Paper",
        "Pharmaceutical",
        "Plastic/Rubber",
        "Power/Energy",
        "Printing/Packaging",
        "Public Relations (PR)",
        "Railways Specialty/Infrastructure",
        "Real Estate",
        "Recruitment/Staffing/RPO",
        "Retailing",
        "Semiconductor​",
        "Shipping/Marine Services",
        "Social Media",
        "Sugar",
        "Telecom",
        "Textiles/Yarn/Fabrics/Garments",
        "Travel/Tourism",
        "Tyres",
        "Wellness/Fitness/Sports",
        "Wood",
      ],
      Qualification: ["B.Tech", "MBA"],
    },
    "Work From Home": {
      "Min Salary": ["8k to 10k", "10k to 12k", "12k to 15k", "15k to 20k"],
      "Max Salary": [
        "12k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 40k",
        "40k to 50k",
        "50k to 70k",
        "70k to 90k",
        "90k to 1Lakh",
      ],
      "Min Experience": [
        "6month to 1Year",
        "1Year to 1.5Year",
        "1.5Year to 2Year",
      ],
      "Max Experience": [
        "1Year to 1.5Year",
        "1.5Year to 2Year",
        "2Year to 2.5Year",
        "2.5Year to 3Year",
        "3Year to 5Year",
      ],
      "Industry ": [
        "Advertising/PR/Events",
        "Agriculture/Dairy/Forestry/Fishing",
        "Airlines/Aviation/Aerospace",
        "Animation",
        "Any",
        "Architecture/Interior Design",
        "Automotive/Automobile/Ancillaries",
        "Banking/Accounting/Financial Services",
        "Beverages/Liquor",
        "Bio Technology & Life Sciences",
        "Cement/Concrete/Readymix",
        "Ceramics & Sanitary Ware",
        "Chemicals/Petrochemicals",
        "Construction & Engineering",
        "Consulting/Advisory Services",
        "Consumer Electronics/Durables/Appliances",
        "Courier/Freight/Transportation",
        "E-Learning",
        "Education",
        "Electrical/Switchgears",
        "Electronics Manufacturing",
        "Engineering & Design",
        "Engineering/Procurement/Construction",
        "Entertainment/Media/Publishing",
        "Environmental Service",
        "Escalators/Elevators",
        "FMCG​",
        "Facility management",
        "Fashion/Apparels",
        "Fertilizer/Pesticides",
        "Food & Packaged Food",
        "GLASS",
        "Gems & Jewellery",
        "General Trading/Import/Export",
        "Government/PSU/Defence",
        "Heat Ventilation Air Conditioning (HVAC)",
        "Hospitals/Healthcare/Diagnostics",
        "Hotels/Hospitality/Restaurant",
        "ISP",
        "IT/Computers - Hardware & Networking",
        "IT/Computers - Software",
        "ITES/BPO",
        "Insurance​",
        "Internet/E-commerce",
        "Iron/Steel",
        "KPO/Research/Analytics",
        "Law Enforcement/Security Services",
        "Leather",
        "Legal/Law Firm",
        "Machinery/Equipment Mfg.",
        "Market Research",
        "Medical Transcription",
        "Mining",
        "NGO/Social Services",
        "Non-Ferrous Metals (Aluminium/Zinc etc.)",
        "Office Equipment/Automation",
        "Oil/Gas/Petroleum",
        "Other",
        "Paints",
        "Paper",
        "Pharmaceutical",
        "Plastic/Rubber",
        "Power/Energy",
        "Printing/Packaging",
        "Public Relations (PR)",
        "Railways Specialty/Infrastructure",
        "Real Estate",
        "Recruitment/Staffing/RPO",
        "Retailing",
        "Semiconductor​",
        "Shipping/Marine Services",
        "Social Media",
        "Sugar",
        "Telecom",
        "Textiles/Yarn/Fabrics/Garments",
        "Travel/Tourism",
        "Tyres",
        "Wellness/Fitness/Sports",
        "Wood",
      ],
      Qualification: ["B.Tech", "MBA"],
    },
  },
  Pets: {
    "Pet Food": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
    "Pet Shop": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
    "Pet Clinics": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
    "Pet Training & Grooming​​": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
    "Pet Care - Accessories": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
  },

  RealEstate: {
    "Rent-Residential": {
      "Property Type": [
        "Apartment",
        "Independent House/Villa",
        "Gated Community  Villa",
      ],
      " BHK Type": ["1RK​", "1BHK​", "2BHK​", "3BHK​", "4BHK​", "5BHK & Above"],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years​",
        "3 to 5 Years​",
        "5 to 10 Years​",
        "10 to 15 Years​",
        "15 Years & Above ",
      ],
      Facing: [
        "North",
        "South",
        "West",
        "East​",
        "North-East",
        "South-East",
        "North-West",
        "South-East",
      ],
      "Build Up Area": [
        "100 to 300 sqft",
        "300 to 600 sqft",
        "600 to 1000 sqft",
        "1000 to 1500 sqft",
        "1500 to 2000 sqft",
        "2000 to 2500 sqft",
        "2500 to 3000 sqft",
        "3000 to 4000 sqft",
        "4000 to 5000 sqft",
        "5000 sqft & Above",
      ],
      "Expected Rent ": [
        "5k to 10k",
        "10k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 35k",
        "35k to 40k",
        "40k to 45k",
        "45k to 50k",
        "50k to 60k",
        "60k to 70k",
        "70k to 80k",
        "80k to 90k",
        "90k to 1Lac",
        "1Lac to 2Lac",
        "2Lac to 3Lac",
        "3Lac to 4Lac",
        "4Lac to 5Lac",
        "5Lac & Above",
      ],
      "Expected Deposit": [
        "0 to 50k",
        "50k to 1Lac",
        "1Lac to 1.5Lac",
        "1.5Lac to 2Lac",
        "2Lac to 3Lac",
        "3Lac to 4Lac",
        "4Lac to 5Lac",
        "5Lac & Above",
      ],
      "Rent Negotiable": ["Yes", "No"],
      Maintenance: ["Maintenance Extra", "Maintenance Included​"],
      "Available From Date": [],
      "Preffered Tenants ": [
        "Doesn’t Matter",
        "Family",
        "Bachelors",
        "Company",
      ],
      "Furnishing Type ": ["Fully Furnished", "Semi Furnished", "Unfurnished"],
      Parking: ["Car", "Bike", "Both Car And Bike", "None "],
      "Bath Rooms": ["Yes", "No"],
      Balcony: ["Yes", "No"],
      "Water Supply": ["Corporation", "Bore well", "Both"],
      "Gated Community": ["Yes", "No"],
      "Who Will Show The Property": [
        "Need Help",
        "I Will Show",
        "Neighbours",
        "Friends/Relatives",
        "Security",
        "Tenants",
        "Others",
      ],
      Lift: ["Yes", "No"],
      "Internet Services": ["Yes", "No"],
      "Air Conditioner": ["Yes", "No"],
      Intercom: ["Yes", "No"],
      "Swimming Pool": ["Yes", "No"],
      "Children Play Area": ["Yes", "No"],
      "Servant Room": ["Yes", "No"],
      "Shopping Center": ["Yes", "No"],
      "Gas Pipeline ": ["Yes", "No"],
      Park: ["Yes", "No"],
      "Rain water Harvesting": ["Yes", "No"],
      "Sewage Treatment Plant": ["Yes", "No"],
      "House Keeping": ["Yes", "No"],
      "Visitors Parking": ["Yes", "No"],
      "Power Backup": ["Yes", "No"],
    },
    "Buy-Residential": {
      "Property Type": [
        "Apartment",
        "Independent House/Villa",
        "Gated Community/Villa",
        "Standalone Building",
      ],
      "BHK Type": ["1RK", "1BHK", "2BHK", "3BHK", "4BHK", "5BHK & Above"],
      "Ownership Type": ["On Lease", "Self Owned"],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],

      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Floor Type": [
        "Vitrified Tiles",
        "Mosaic",
        "Marble /Granite",
        "Wooden",
        "Cement",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to 15 Years",
        "15 Years & Above",
      ],
      Facing: [
        "North",
        "South",
        "East",
        "West",
        "North-East",
        "South-East",
        "North-West",
        "South-West",
      ],
      "Build Up Area": [
        "100 to 300 sqft",
        "300 to 600 sqft",
        "600 to 1000 sqft",
        "1000 to 1500 sqft",
        "1500 to 2000 sqft",
        "2000 to 2500 sqft",
        "2500 to 3000 sqft",
        "3000 to 4000 sqft",
        "4000 to 5000 sqft",
        "5000 sqft & Above",
      ],
      "Carpet Area": [
        "100 to 300 sqft",
        "300 to 600 sqft",
        "600 to 1000 sqft",
        "1000 to 1500 sqft",
        "1500 to 2000 sqft",
        "2000 to 2500 sqft",
        "2500 to 3000 sqft",
        "3000 to 4000 sqft",
        "4000 to 5000 sqft",
        "5000 sqft & Above",
      ],
      "Expected Price": [
        "5Lac to 10Lac",
        "10Lac to 20Lac",
        "20Lac to 30Lac",
        "30Lac to 40Lac",
        "40Lac to 50Lac",
        "50Lac to 75Lac",
        "75Lac to 1Cr",
        "1Cr & Above",
      ],
      "Maintenance Cost/Month": [
        "1k to 10k",
        "10k to 11k",
        "11k to 12k",
        "12k to 13k",
        "13k to 14k",
        "14k to 15k",
        "15k & Above",
      ],
      "Currently Under Loan": ["Yes", "No"],
      "Kitchen Type ": ["Modular", "Covered Shelves", "Open Shelves"],
      "Available From Date": [],
      "Furnishing Type": ["Fully Furnished", "Semi-Furnished", "Unfurnished"],
      Parking: ["Car", "Bike", "Both", "None"],
      "Bath Rooms": ["Yes", "No"],
      Balcony: ["Yes", "No"],
      "Water Supply": ["Corporation", "Borewell", "Both"],
      "Gated Community": ["Yes", "No"],
      "Power Backup": ["Full", "Partial", "None"],
      "Who Will Show The Property ": [
        "Need Help",
        "I Will Show",
        "Neighbours",
        "Friends/Relatives",
        "Security",
        "Tenants",
        "Others",
      ],
      "available amenities": [
        "Lift",
        "Club House",
        "Internet Services",
        "Park",
        "Intercom",
        "Swimming Pool",
        "Children Play Area",
        "Servant Room",
        "Shopping Center",
        "Gas Pipeline ",
        "Sewage Treatment Plant",
        "House Keeping",
      ],

      "Do You Have Khata Certificate?": ["Yes", "No", "Dont Know"],
      "Do You Have Sales Deed Certificate?": ["Yes", "No", "Dont Know"],
      "Do You Have Occupancy Certificate?": ["Yes", "No", "Dont Know"],
      "Have You Paid Property Tax?": ["Yes", "No", "Dont Know"],
    },
    "PG-Hostel": {
      "Place Is Available For": ["Male", "Female", "Anyone"],
      "Preffered Guests": ["  Working Professional", "Student", "Both"],
      "Available From Date": [],
      "Food Included": ["Yes", "No"],
      "PG Rules": [
        "No Smoking ",
        "No Guardians Stay",
        "No Girls Stay",
        "No Drinking",
        "No Non Veg",
      ],
      "Gate Close Time": ["9pm", "10pm", "11pm", "12pm"],
      "Looking For": [
        "Single Room",
        "Double Room",
        "Triple Room",
        "Four Room",
        "Five Rooms & Above",
      ],
      "Expected Rent Per Person": [
        "5k to 7k",
        "7k to 9k",
        "9k to 11k",
        "11k to 13k",
        "13k to 15k",
        "15k to 20k",
        "20k to 25k",
      ],
      "Expected Deposit Per Person": [
        "Below 5k",
        "5k to 7k",
        "7k to 9k",
        "9k to 11k",
        "11k to 13k",
        "13k to 15k",
        "15k to 20k",
        "20k to 25k",
      ],
      "Room Amenities": [
        "Cupboard",
        "TV",
        "Bedding",
        "Geyser",
        "Ac",
        "Attached Bathroom",
      ],
      Laundry: ["Yes", "No"],
      "Room Cleaning": ["Yes", "No"],
      "Water Facility": ["Yes", "No"],
      "Warden Facility": ["Yes", "No"],
      Parking: ["Car", "Bike", "Both", "None"],
      "PG amenities": [
        "Cupboard",
        "Common TV",
        "Mess",
        "Lift",
        "Wi-Fi",
        "Cooking Allowed",
        "Power Backup",
        "Refrigerator",
      ],
    },
    "Rent-Commercial": {
      "Property Type": [
        "Office Space ",
        "CO-Working",
        "Shop",
        "Showroom",
        "Godown/Warehouse",
        "Industrial Shed",
        "Industrial Building",
        "Other Business",
      ],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Floor Type": [
        "Vitrified Tiles",
        "Mosaic",
        "Marble /Granite",
        "Wooden",
        "Cement",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to 15 Years",
        "15 Years & Above",
      ],
      "Furnishing Type": ["Fully Furnished", "Semi-Furnished", "Unfurnished"],
      "Build Up Area": [
        "100 to 300 sqft",
        "300 to 600 sqft",
        "600 to 1000 sqft",
        "1000 to 1500 sqft",
        "1500 to 2000 sqft",
        "2000 to 2500 sqft",
        "2500 to 3000 sqft",
        "3000 to 4000 sqft",
        "4000 to 5000 sqft",
        "5000 sqft & Above",
      ],
      "Expected Rent": [
        "5k to 10k",
        "10k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 35k",
        "35k to 40k",
        "40k to 45k",
        "45k to 50k",
        "50k to 60k",
        "60k to 70k",
        "70k to 80k",
        "80k to 90k",
        "90k to 1Lac",
        "1Lac to 2Lac",
        "2Lac to 3Lac",
        "3Lac to 4Lac",
        "4Lac to 5Lac",
        "5Lac & Above",
      ],
      "Expected Deposit": [
        "50000 to 70000",
        "70000 to 90000",
        "90000 to 120000",
        "120000 to 150000",
        "150000 to 170000",
        "170000 to 200000",
      ],
      "Deposit Negotiable": ["Yes", "No"],
      "Rent Negotiable": ["Yes", "No"],
      Maintenance: ["Maintenance Extra", "Maintenance Included"],
      "Available From Date": [],
      "Lease Duration(Years)": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to 15 Years",
        "15 Years & Above",
      ],
      Bathrooms: ["Western", "Indian"],
      Balcony: ["Yes", "No"],
      "Water Storage Facility": ["Yes", "No"],
      Washrooms: ["Shared", "Private", "No Washrooms"],
      Lift: ["Common", "Private", "None"],
      "Power Backup": ["Full", "DG Backup", "Need To Arrange"],
      Parking: ["None", "Public And Reserved", "Reserved", "Public"],
      Security: ["Yes", "No"],
      Wifi: ["Yes", "No"],
    },
    "Buy-Commercial": {
      "Property Type": [
        "Office Space ",
        "CO-Working",
        "Shop",
        "Showroom",
        "Godown/Warehouse",
        "Industrial Shed",
        "Industrial Building",
        "Other Business",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to 15 Years",
        "15 Years & Above",
      ],
      "Furnishing Type": ["Fully Furnished", "Semi-Furnished", "Unfurnished"],
      "Build Up Area": [
        "100 to 150 sqft",
        "151 to 200 sqft",
        "201 to 250 sqft",
        "251 to 300 sqft",
        "301 to 350 sqft",
        "351 to 400 sqft",
        "401 to 450 sqft",
      ],
      "Carpet Area": [
        "100 to 150 sqft",
        "151 to 200 sqft",
        "201 to 250 sqft",
        "251 to 300 sqft",
        "301 to 350 sqft",
        "351 to 400 sqft",
        "401 to 450 sqft",
      ],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Expected Price": [
        "200000 to 500000",
        "500000 to 1000000",
        "1000000 to 2000000",
        "2000000 to 2500000",
        "2500000 to 3000000",
      ],
      "Price Negotiable ": ["Yes", "No"],
      "Ownership Type": ["On Lease", "Self Owned"],
      "Available From Date": [],
      "Water Storage Facility": ["Yes", "No"],
      Washrooms: ["Shared", "Private", "No Washrooms"],
      Lift: ["Common", "Private", "None"],
      "Power Backup": ["Full", "DG Backup", "Need To Arrange"],
      Parking: ["None", "Public And Reserved", "Reserved", "Public"],
      Security: ["Yes", "No"],
      Wifi: ["Yes", "No"],
    },
  },

  //Mobiles and Tablets main category abd sub category
  Mobiles: {
    "Mobile Phones": {
      "Brand Name": [
        "Apple",
        "Blackberry",
        "Blueberry",
        "Centric",
        "Creo",
        "HTC",
        "Lava",
        "MI",
        "Micromax",
        "Nokia",
        "One Plus",
        "Oppo",
        "Samsung",
        "Sony",
        "Others",
      ],
      "Year of Purchase": [
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
      ],
      "Physical Condition": ["Excellent", "Good", "Fair"],
      Model: ["New", "Old"], //Text box,
    },
    Accessories: {
      "Accessory Type": [
        "Anti Radiation  Stickers",
        "Battery",
        "Cables",
        "Car Accessory",
        "Cases/Cover",
        "Charger",
        "Earphones",
        "Head phones",
        "Memory Card",
        "Mobile Body Panels",
        "Mobile Flashes",

        "Power Banks",
        "Screen Guard",
        "Selfie Sticks",
        "Sim Adaptor",
        "Speaker",
        "Others",
      ],

      "Brand Name": [
        "Boat",
        "Iphone",
        "Mivi",
        "Noise",
        "Nokia",
        "One Plus",
        "Oppo",
        "Other",
        "Samsung",
        "Sony",
        "Vivo",
        "Xiaomi",
        "pTron",
      ], //Textbox,
    },
    Tablets: {
      "Brand Name": [
        "Apple",
        "Blackberry",
        "Bluberry",
        "Centric",
        "Creo",
        "Google",
        "HTC",
        "Hp",
        "Lava",
        "MI",
        "Micromax",
        "Oppo",
        "Others",
        "Samsung",
        "Sony",
      ],
      Condition: ["Brand New", "Used"],
    },
  },

  Services: {
    Electrician: {
      "available amenities": [
        "Installation",
        "Repair",
        "General Electronic Work",
        "Complete Wiring",
        "Fans & Lights",
        "Switches",
        "Water Motor",
      ],
    },

    carpenter: {
      "available amenities": [
        "Macking a New Furniture",
        "Assembly/disassembly of Current Furniture",
        "Vornishing - Polishing",
        "Repair of old Furniture(Wooden Work) ",
        "General Corpentary Work",
      ],
      "Location Of Service": ["Home", "Office", "Others"],
    },

    Plumber: {
      "available amenities": [
        "General Plumbing Work",
        "Repair",
        "Leakages",
        "Installation",
        "Home ",
        "Office ",
        "Others",
      ],
    },
    "Computer Repair": {
      "available amenities": ["1", "2"],
    },

    Waterproofing: {
      "available amenities": [
        "Leakages",
        "Dampness",
        "Cracks",
        "Fungal formation",
        "Others",
        "Bathroom",
        "Terrace",
        "Basement",
        "Sump",
        "Over Tank",
        "Kitchen sink",
        "Roof",
        "Others",
      ],
    },

    "Air Cooler Repair": {
      "available amenities": [
        "Nor Turning On At All",
        "Not Cooling",
        "Water Leaks",
        "Fan Not Working",
        "Swing Not Working",
        "My Issue Is Not In List",
        "Generic",
        "Bajaj",
        "Havells",
        "Khaitan",
        "Maharaja",
        "Orient",
        "Singer",
        "Sympony",
        "Videocom",
        "Voltas",
        "Others",
      ],
    },

    "Air conditioner": {
      "available amenities": [
        "Split AC",
        "Window AC",
        "Cassette AC",
        "Installation",
        "Gas Filling",
        "Pipe Leakage",
        "Ice Formation",
        "Servicing - Normal",
        "Servicing - Chemical Cleaning",
        "Stabilizer Fitting",
        "blue Star",
        "Carrier",
        "Daikin",
        "Samsung",
        "Electrolux",
        "Godrej",
        "Haier",
        "Hitachi",
        "IFB",
        "LG",
        "Onida",
        "Panasonic",
        "Videocon",
        "Voltas",
        "Whirlpool",
        "Others",
      ],
    },

    "Washing Machine": {
      "available amenities": [
        "Installation",
        "Repair",
        "Removal",
        "Top Load - Fully Automatic",
        "Top Load - Semi Automatic",
        "Front Load",
        "Daikin",
        "Samsung",
        "Electrolux",
        "Godrej",
        "Haier",
        "Hitachi",
        "IFB",
        "LG",
        "Onida",
        "Panasonic",
        "Videcon",
        "voltas",
        "Whirlpool",
        "Others",
      ],
    },

    Refrigerator: {
      "available amenities": ["1 Day", "1 Week", "1 Month", "1 Year", "Others"],
    },

    "Appliance Repair": {
      Types: [
        "Air Conditioner",
        "Washing machine",
        "Refrigerator",
        "Geyser Repair",
      ],
    },

    "Home Cleaning": {
      Types: [
        "Full Home Deep Cleaning",
        "Basic Home Cleaning",
        "Bathroom Cleaning",
        "Sofa-set Cleaning",
        "Kitchen Cleaning",
        "Water-tank cleaning",
        "Sanitation services",
      ],
    },

    "Home Repair": {
      Types: ["Carpenter", "Electrician", "Plumber", "Water Proofing"],
    },

    "Interior Designing & Home improvements": {
      Types: [
        "Modular Kitchen",
        "Interior Design",
        "Painting",
        "Furniture",
        "Metal Fabrication",
        "Home Automation",
      ],
    },

    "Invertor & Batteries": {
      Types: [
        "Invertor Installation",
        "Invertor Repair & Maintenance",
        "Battery Repair",
        "Service",
      ],
    },

    "Kitchen Appliance Repair": {
      Types: [
        "MicroWave-Oven Repair",
        "RO-Water Purifier",
        "Electric Chimney",
        "HOB-Stove",
      ],
    },
    "Mobile & Tablet Repair": {
      Types: ["Mobile Repair", "Tablet Repair", "iphone-ipad Repair"],
    },

    "Packers and Movers": {
      Types: ["Within City", "Between Cities", "International"],
    },

    "Home Help": {
      Types: ["Cook", "Baby Sitter", "Driver", "Home nurse", "Maid"],
    },

    Service4: {
      "Property Type": [
        "Office Space ",
        "CO-Working",
        "Shop",
        "Showroom",
        "Godown/Warehouse",
        "Industrial Shed",
        "Industrial Building",
        "Other Business",
      ],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Floor Type": [
        "Vitrified Tiles",
        "Mosaic",
        "Marble /Granite",
        "Wooden",
        "Cement",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to 15 Years",
        "15 Years & Above",
      ],
      "Furnishing Type": ["Fully Furnished", "Semi-Furnished", "Unfurnished"],
      "Build Up Area": [
        "100 to 300 sqft",
        "300 to 600 sqft",
        "600 to 1000 sqft",
        "1000 to 1500 sqft",
        "1500 to 2000 sqft",
        "2000 to 2500 sqft",
        "2500 to 3000 sqft",
        "3000 to 4000 sqft",
        "4000 to 5000 sqft",
        "5000 sqft & Above",
      ],
      "Expected Rent": [
        "5k to 10k",
        "10k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 35k",
        "35k to 40k",
        "40k to 45k",
        "45k to 50k",
        "50k to 60k",
        "60k to 70k",
        "70k to 80k",
        "80k to 90k",
        "90k to 1Lac",
        "1Lac to 2Lac",
        "2Lac to 3Lac",
        "3Lac to 4Lac",
        "4Lac to 5Lac",
        "5Lac & Above",
      ],
      "Expected Deposit": [
        "50000 to 70000",
        "70000 to 90000",
        "90000 to 120000",
        "120000 to 150000",
        "150000 to 170000",
        "170000 to 200000",
      ],
      "Deposit Negotiable": ["Yes", "No"],
      "Rent Negotiable": ["Yes", "No"],
      Maintenance: ["Maintenance Extra", "Maintenance Included"],
      "Available From Date": [],
      "Lease Duration(Years)": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to 15 Years",
        "15 Years & Above",
      ],
      Bathrooms: ["Western", "Indian"],
      Balcony: ["Yes", "No"],
      "Water Storage Facility": ["Yes", "No"],
      Washrooms: ["Shared", "Private", "No Washrooms"],
      Lift: ["Common", "Private", "None"],
      "Power Backup": ["Full", "DG Backup", "Need To Arrange"],
      Parking: ["None", "Public And Reserved", "Reserved", "Public"],
      Security: ["Yes", "No"],
      Wifi: ["Yes", "No"],
    },
    Service5: {
      "Property Type": [
        "Office Space ",
        "CO-Working",
        "Shop",
        "Showroom",
        "Godown/Warehouse",
        "Industrial Shed",
        "Industrial Building",
        "Other Business",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to  15 Years",
        "15 Years & Above",
      ],
      "Furnishing Type": ["Fully Furnished", "Semi-Furnished", "Unfurnished"],
      "Build Up Area": [
        "100 to 150 sqft",
        "151 to 200 sqft",
        "201 to 250 sqft",
        "251 to 300 sqft",
        "301 to 350 sqft",
        "351 to 400 sqft",
        "401 to 450 sqft",
      ],
      "Carpet Area": [
        "100 to 150 sqft",
        "151 to 200 sqft",
        "201 to 250 sqft",
        "251 to 300 sqft",
        "301 to 350 sqft",
        "351 to 400 sqft",
        "401 to 450 sqft",
      ],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Expected Price": [
        "200000 to 500000",
        "500000 to 1000000",
        "1000000 to 2000000",
        "2000000 to 2500000",
        "2500000 to 3000000",
      ],
      "Price Negotiable ": ["Yes", "No"],
      "Ownership Type": ["On Lease", "Self Owned"],
      "Available From Date": [],
      "Water Storage Facility": ["Yes", "No"],
      Washrooms: ["Shared", "Private", "No Washrooms"],
      Lift: ["Common", "Private", "None"],
      "Power Backup": ["Full", "DG Backup", "Need To Arrange"],
      Parking: ["None", "Public And Reserved", "Reserved", "Public"],
      Security: ["Yes", "No"],
      Wifi: ["Yes", "No"],
    },
  },
  ScienceAndTech: {
    "Artificial Intelligence": {
      "Fields": [
        "Machine Learning",
        "Natural Language Processing",
        "Computer Vision",
        "Robotics",
        "Reinforcement Learning",
        "Expert Systems",
        "Speech Recognition",
        "AI Ethics",
        "Others"
      ],
      "Applications": [
        "Autonomous Vehicles",
        "Healthcare Diagnostics",
        "Virtual Assistants",
        "Recommendation Systems",
        "Predictive Analytics",
        "Fraud Detection",
        "Others"
      ],
      "Condition": ["Emerging", "Established", "Rapidly Advancing"]
    },
    "Space Exploration": {
      "Fields": [
        "Planetary Science",
        "Astrobiology",
        "Astrophysics",
        "Spacecraft Engineering",
        "Satellite Technology",
        "Space Robotics",
        "Others"
      ],
      "Missions": [
        "Lunar Exploration",
        "Mars Missions",
        "Deep Space Probes",
        "International Space Station Research",
        "Space Tourism",
        "Others"
      ],
      "Organizations": [
        "NASA",
        "ESA",
        "ISRO",
        "SpaceX",
        "Blue Origin",
        "CNSA",
        "Roscosmos",
        "Others"
      ]
    },
    "Environmental Science": {
      "Fields": [
        "Climate Science",
        "Ecology",
        "Environmental Chemistry",
        "Geosciences",
        "Conservation Biology",
        "Others"
      ],
      "Applications": [
        "Pollution Control",
        "Renewable Energy",
        "Sustainable Agriculture",
        "Waste Management",
        "Biodiversity Conservation",
        "Others"
      ],
      "Condition": ["Critical", "Developing", "Under Research"]
    },
    "Robotics": {
      "Types": [
        "Industrial Robots",
        "Humanoid Robots",
        "Medical Robots",
        "Service Robots",
        "Military Robots",
        "Exploration Robots",
        "Others"
      ],
      "Applications": [
        "Manufacturing Automation",
        "Surgical Assistance",
        "Search and Rescue",
        "Space Exploration",
        "Agricultural Robotics",
        "Others"
      ],
      "Condition": ["Advanced", "Emerging", "Prototype"]
    }
  },
  TravelAndAdventure: {
    "Camping Gear": {
      "Item Type": [
        "Shelter and Sleeping Gear",
        "Cooking and Food Gear",
        "Clothing and Footwear",
        "Lighting and Navigation",
        "Safety and Survival Gear",
        "Furniture and Comfort Gear",
        "Entertainment and Activities",
        "Hygiene and Personal Care",
        "Tech and Gadgets",
        "Specialized Camping Gear",
        "Others"
      ],
      "Brand Name": [
        "Coleman",
        "REI",
        "The North Face",
        "Quechua",
        "Ozark Trail",
        "Big Agnes",
        "MSR",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed",
      ],
      "Size Options": ["Small", "Medium", "Large", "Extra Large", "One Size", "Customizable"]
    },
    "Travel Accessories (Luggage, Backpacks)": {
      "Accessory Type": [
        "Suitcases",
        "Duffel Bags",
        "Garment Bags",
        "Trolley Bags",
        "Expandable Luggage",
        "Cabin Luggage",
        "Travel Backpacks",
        "Daypacks",
        "Laptop Backpacks",
        "Hiking Backpacks",
        "Anti-Theft Backpacks",
        "Convertible Backpacks",
        "Packing Cubes",
        "Compression Bags",
        "Shoe Bags",
        "Laundry Bags",
        "Toiletry Bags",
        "Electronic Organizers",
        "Luggage Locks",
        "Luggage Tags",
        "Strap Belts",
        "RFID Blocking Wallets",
        "Travel Safes",
        "Travel Blankets",
        "Luggage Scales",
        "Foldable Bags",
        "Camera Bags",
        "Cooler Bags",
        "Sports Bags",
        "Beach Bags",
        "Bike Bags",
        "Kid-Sized Luggage",
        "Child Carriers",
        "Activity Backpacks",
        "Reusable Tote Bags",
        "Sustainable Luggage",
        "Solar Backpacks",
        "Other"
      ],
      "Brand Name": [
        "Samsonite",
        "American Tourister",
        "VIP",
        "Wildcraft",
        "Tumi",
        "Skybags",
        "Delsey",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed",
      ],
      "Size Options": ["Small", "Medium", "Large", "Extra Large", "One Size", "Expandable"]
    },
    "Maps & Guides": {
      "Map Type": [
        "Geographical Maps",
        "Navigational Maps",
        "Outdoor and Adventure Maps",
        "Thematic Maps",
        "Travel Guides",
        "Historical and Educational Maps",
        "Interactive and Digital Maps",
        "Specialty Maps",
        "Others"
      ],
      "Publisher": [
        "Lonely Planet",
        "National Geographic",
        "Rough Guides",
        "Michelin",
        "Insight Guides",
        "Rand McNally",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed",
      ],
      "Size Options": ["Pocket", "Standard", "Large", "Foldable", "Customizable"]
    },
    "Adventure Sports Equipment (Kayaks, Bikes)": {
      "Equipment Type": [
        "Kayaks",
        "Canoes",
        "Paddleboards",
        "Surfboards",
        "Scuba Diving Gear",
        "Snorkeling Sets",
        "Rafts",
        "Wetsuits and Dry Suits",
        "Life Jackets",
        "Mountain Bikes",
        "Road Bikes",
        "Hybrid Bikes",
        "Electric Bikes",
        "Bike Helmets",
        "Cycling Shoes",
        "Protective Gear",
        "Bike Repair Kits",
        "Hydration Backpacks",
        "Climbing Ropes",
        "Harnesses",
        "Carabiners",
        "Climbing Shoes",
        "Belay Devices",
        "Chalk Bags",
        "Ice Axes",
        "Crampons",
        "Helmets",
        "Skis",
        "Snowboards",
        "Ski Poles",
        "Snowshoes",
        "Ski Goggles",
        "Snow Helmets",
        "Thermal Clothing",
        "Paragliders",
        "Hang Gliders",
        "Wingsuits",
        "Skydiving Parachutes",
        "Helmets",
        "Altimeters",
        "Harnesses",
        "Trekking Poles",
        "Backpacks",
        "Hiking Boots",
        "GPS Devices",
        "Trail Maps",
        "Portable Water Filters",
        "Multi-tools",
        "Tents",
        "Sleeping Bags",
        "Cooking Stoves",
        "Headlamps",
        "Survival Kits",
        "Fire Starters",
        "First Aid Kits",
        "ATVs (All-Terrain Vehicles)",
        "Dirt Bikes",
        "Riding Helmets",
        "Goggles",
        "Protective Jackets",
        "Gloves",
        "Knee Guards",
        "Skateboards",
        "Longboards",
        "Inline Skates",
        "Bungee Cords for Jumping",
        "Parkour Gear",
        "Protective Pads",
        "Fishing Rods and Reels",
        "Kitesurfing Equipment",
        "Sandboarding Gear",
        "Caving Equipment",
        "Ziplining Harnesses",
        "Obstacle Course Equipment",
        "Others"
      ],
      "Brand Name": [
        "GoPro",
        "Osprey",
        "Black Diamond",
        "Red Paddle",
        "Cannondale",
        "Trek",
        "Salomon",
        "Burton",
        "Others"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed",
      ],
      "Size Options": ["Small", "Medium", "Large", "Extra Large", "One Size", "Customizable"]
    }
  }
  ,
  TechGadgets: {
    "Smartphones": {
      "Brand Name​": [
        "Apple​",
        "Samsung​",
        "OnePlus​",
        "Xiaomi​",
        "Realme​",
        "Oppo​",
        "Vivo​",
        "Google​",
        "Nokia​",
        "Sony​",
        "Motorola​",
        "Others​"
      ],
      "OperatingSystems": [
        "Android",
        "iOS",
        "Others"
      ],
      "Condition​": [
        "Brand New​",
        "Gently Used​",
        "Heavily Used​",
        "Unboxed​"
      ],
      "Storage Capacity​": [
        "16GB​",
        "32GB​",
        "64GB​",
        "128GB​",
        "256GB​",
        "512GB​",
        "1TB​"
      ],
      "RAM​": [
        "2GB​",
        "4GB​",
        "6GB​",
        "8GB​",
        "12GB​",
        "16GB​"
      ],
      "Screen Size​": [
        "5.0-5.4 inches​",
        "5.5-5.9 inches​",
        "6.0-6.4 inches​",
        "6.5-6.9 inches​",
        "7.0 inches & above​"
      ]
    },
    "Tablets & E-Readers": {
      "Brand Name​": [
        "Apple​",
        "Samsung​",
        "Lenovo​",
        "Amazon Kindle​",
        "Microsoft​",
        "Huawei​",
        "Asus​",
        "Others​"
      ],
      "OperatingSystems": [
        "Android",
        "iOS",
        "Windows",
        "Others"
      ],
      "Condition​": [
        "Brand New​",
        "Gently Used​",
        "Heavily Used​",
        "Unboxed​"
      ],
      "Screen Size​": [
        "7 inches & below​",
        "8 inches​",
        "10 inches​",
        "12 inches & above​"
      ],
      "Storage Capacity​": [
        "16GB​",
        "32GB​",
        "64GB​",
        "128GB​",
        "256GB​",
        "512GB​"
      ],
      "Connectivity​": [
        "WiFi Only​",
        "WiFi + Cellular​"
      ]
    },
    "Laptops": {
      "Brand Name​": [
        "Apple​",
        "Dell​",
        "HP​",
        "Lenovo​",
        "Asus​",
        "Acer​",
        "Microsoft​",
        "Samsung​",
        "MSI​",
        "Others​"
      ],
      "OperatingSystems": [
        "Windows",
        "macOS",
        "Linux",
        "Others"
      ],
      "Condition​": [
        "Brand New​",
        "Gently Used​",
        "Heavily Used​",
        "Unboxed​"
      ],
      "Screen Size​": [
        "13 inches & below​",
        "14 inches​",
        "15 inches​",
        "16 inches & above​"
      ],
      "RAM​": [
        "4GB​",
        "8GB​",
        "16GB​",
        "32GB​",
        "64GB​"
      ],
      "Storage Capacity​": [
        "256GB​",
        "512GB​",
        "1TB​",
        "2TB​"
      ]
    },
    "Smartwatches": {
      "Brand Name​": [
        "Apple​",
        "Samsung​",
        "Garmin​",
        "Fitbit​",
        "Amazfit​",
        "Huawei​",
        "Realme​",
        "Xiaomi​",
        "Others​"
      ],
      "Condition​": [
        "Brand New​",
        "Gently Used​",
        "Heavily Used​",
        "Unboxed​"
      ],
      "Display Type​": [
        "AMOLED​",
        "LCD​",
        "E-Ink​"
      ],
      "Features​": [
        "GPS​",
        "Heart Rate Monitoring​",
        "Step Tracking​",
        "Bluetooth Calling​",
        "Music Control​"
      ]
    },
    "Gaming Consoles": {
      "Brand Name​": [
        "Sony PlayStation​",
        "Microsoft Xbox​",
        "Nintendo Switch​",
        "Valve Steam Deck​",
        "Others​"
      ],
      "Condition​": [
        "Brand New​",
        "Gently Used​",
        "Heavily Used​",
        "Unboxed​"
      ],
      "Storage Capacity​": [
        "500GB​",
        "1TB​",
        "2TB​"
      ],
      "Features​": [
        "4K Support​",
        "HDR​",
        "VR Ready​",
        "Portable​"
      ]
    }
  },
  ChildrenBooks: {
    "Picture Books": {
      "Age Group": [
        "0-3 years",
        "3-5 years",
        "5-7 years",
        "7-9 years",
        "9-12 years"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Illustration Style": [
        "Cartoon",
        "Realistic",
        "Abstract",
        "Watercolor",
        "Sketch"
      ]
    },
    "Chapter Books": {
      "Age Group": [
        "0-3 years",
        "3-5 years",
        "5-7 years",
        "7-9 years",
        "9-12 years"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Genre": [
        "Adventure",
        "Fantasy",
        "Mystery",
        "Science Fiction",
        "Historical Fiction",
        "Animal Stories",
        "Others"
      ]
    },
    "Educational Stories": {
      "Age Group": [
        "0-3 years",
        "3-5 years",
        "5-7 years",
        "7-9 years",
        "9-12 years"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Learning Focus": [
        "Math",
        "Science",
        "Literacy",
        "Social Skills",
        "Emotional Development",
        "Creativity",
        "Others"
      ]
    },
    "Activity Books": {
      "Age Group": [
        "0-3 years",
        "3-5 years",
        "5-7 years",
        "7-9 years",
        "9-12 years"
      ],
      "Condition": [
        "Brand New",
        "Gently Used",
        "Heavily Used",
        "Unboxed"
      ],
      "Activity Type": [
        "Coloring",
        "Puzzles",
        "Word Games",
        "Stickers",
        "Drawing",
        "Games & Quizzes",
        "Others"
      ]
    }
  }
};
