import React, { useState } from 'react';
import MyGallery from './MyGallery';

import './FoodsAndBeverages.css'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { searchApi } from '../../../../store/ToggleSearchSlice';


function FoodsAndBeverages() {
    const [isOpen, setIsOpen] = useState(false);
    const [isLocationOpen, setIsLocationOpen] = useState(false);
    const [minBudget, setMinBudget] = useState('');
    const [maxBudget, setMaxBudget] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(''); // To track selected category
    const [SearchValue, setSearchValue] = useState("");
    const searchArray = () => {
      if (SearchValue?.trim()) {
        dispatch(searchApi(SearchValue));
      }
    };
  
    const dispatch = useDispatch();
    // Categories Data
    const categories = [
    'Foods',
    'Beverages'
    ];
  
    const locations = [
      'New York',
      'Los Angeles',
      'Chicago',
      'Houston',
      'Miami',
      'Other Location',
    ];
  


    // Ads Data with category included
    const ads = [
      { title: 'Soccer FootBall', price: '₹ 3,000', location: 'S.A.S. Nagar, Punjab', date: 'Nov 14', image: 'https://th.bing.com/th/id/R.df5ce408155989a725f40d27d61c5458?rik=DjZzATnlySYbLg&riu=http%3a%2f%2fimages.unsplash.com%2fphoto-1487004121828-9fa15a215a7a%3fcrop%3dentropy%26cs%3dtinysrgb%26fit%3dmax%26fm%3djpg%26ixid%3dMnwxMjA3fDB8MXxzZWFyY2h8MXx8Zm9vZCUyMGFuZCUyMGJldmVyYWdlc3x8MHx8fHwxNjI5MTU4MzQ5%26ixlib%3drb-1.2.1%26q%3d80%26w%3d1080&ehk=joDd4B7%2f7VtjnVb04x65KLnWgX1PyTwnR6OWieDNjB4%3d&risl=&pid=ImgRaw&r=0', featured: true, category: 'Foods' },
      { title: 'Maurice Lacroix brand new Swiss watch', price: '₹ 1,70,000', location: 'PNB Enclave, Dehradun', date: 'Nov 06', image: 'https://wallpapercave.com/wp/wp4665328.jpg', featured: true, category: 'Beverages' },
      { title: 'Book', price: '₹ 500', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://th.bing.com/th/id/OIP.19oE-gPLv1UaldqUk1SoFgHaEo?rs=1&pid=ImgDetMain', featured: true, category: 'Beverages' },
      { title: 'Puma shoe (motorsport edition)', price: '₹ 3,000', location: 'Rathnapuri, Coimbatore', date: 'Today', image: 'https://wallpapercave.com/wp/wp7029319.jpg', featured: false, category: 'Foods' },
      { title: 'Ajanta Wall Clock', price: '₹ 200', location: 'Sahastradhara Road, Dehradun', date: 'Today', image: 'https://th.bing.com/th/id/OIP.OM_YQDFEEZ6NPj0XapYwFgHaEo?rs=1&pid=ImgDetMain', featured: false, category: 'Foods' },
      { title: 'Badminton-sport-shuttlecocks', price: '₹ 2,600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://www.pixelstalk.net/wp-content/uploads/2016/08/Food-best-hd-photos.jpg', featured: false, category: 'Foods' },
      { title: 'Badminton-sport-shuttlecocks', price: '₹ 2,600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://c8.alamy.com/comp/WX0H5B/bottles-of-global-soft-drink-brands-including-products-of-coca-cola-company-and-pepsico-WX0H5B.jpg', featured: false, category: 'Beverages' },
      { title: 'Badminton-sport-shuttlecocks', price: '₹ 2,600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://c0.wallpaperflare.com/preview/505/815/775/beverages-carbonated-carbonated-drink-carbonated-water.jpg', featured: false, category: 'Beverages' },
      { title: 'Book', price: '₹ 500', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://cdn.pixabay.com/photo/2018/07/01/20/01/music-3510326_1280.jpg', featured: true, category: 'Musical Instruments' },
  
      // Add more ads here with their respective categories...
    ];
  
    // Filter ads based on the selected category
    const filteredAds = selectedCategory
      ? ads.filter(ad => ad.category === selectedCategory)
      : ads;
  
    // Toggle dropdown visibility
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const toggleLocationDropdown = () => {
      setIsLocationOpen(!isLocationOpen);
    };
  
    // Handle the apply button click for budget
    const handleApply = () => {
      if (minBudget && maxBudget) {
        alert(`Min Budget: ${minBudget}, Max Budget: ${maxBudget}`);
      } else {
        alert('Please enter both minimum and maximum budget values.');
      }
    };
  
    return (
      <>
        <div className="image-box" style={{ position: 'relative' }}>
      
            <h1 className="text99-header-awi-24">
           Foods And Beverages
            </h1>
        

          <img className="hobbies1" src="https://delisophy.com/wp-content/uploads/2020/03/food-and-beverages.jpg" alt="Full Width" />
          <div className="wholemid">
          <div className="filetr2234">
            <div className="furnifil">
              <input
                name="Brand Name"
                required
                className="realserac"
                style={{
                  borderRadius: "20px",
                
                  textTransform: "uppercase",
                  border: "none",
                  
                }}
                placeholder="Search your Fashion"
                onChange={(e) => setSearchValue(e.target.value.trim())}
                
              />
              <div className="tildiv">
                <Link to={`/ads-listing1/`} onClick={searchArray}>
                  <button className="search-btn-furniture">Search</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        </div>
  
        <div className="main">
          <div className="leftctr">
            {/* Categories Section - Now as Buttons */}
            <div className="Categories">
              <h3 className="headinghb">Categories</h3>
              <div className="category-buttons">
                {categories.map((category, index) => (
                  <button
                    key={index}
                    className={`category-button ${selectedCategory === category ? 'selected' : ''}`}
                    onClick={() => setSelectedCategory(category === selectedCategory ? '' : category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>
  
            {/* Location Section */}
            <div className="Categories">
              <h3 className="headinghb" onClick={toggleLocationDropdown}>
                Location
                <span style={{ marginLeft: '20px', cursor: 'pointer' }}>
                  {isLocationOpen ? '▲' : '▼'}
                </span>
              </h3>
              {isLocationOpen && (
                <ul className="scrollable-list">
                  {locations.map((location, index) => (
                    <li key={index} onClick={() => alert(`${location} selected`)}>
                      {location}
                    </li>
                  ))}
                </ul>
              )}
            </div>
  
            {/* Budget Section */}
            <div className="Categories">
              <h3 className="budget-heading">Budget</h3>
              <div className="budget-inputs">
                <div className="input-group">
                  <label className="lbl" htmlFor="minBudget">Min:</label>
                  <input
                    className="inputbgt"
                    type="number"
                    id="minBudget"
                    value={minBudget}
                    onChange={(e) => setMinBudget(e.target.value)}
                    placeholder="min value"
                  />
                </div>
                <div className="input-group">
                  <label className="lbl" htmlFor="maxBudget">Max:</label>
                  <input
                    className="inputbgt"
                    type="number"
                    id="maxBudget"
                    value={maxBudget}
                    onChange={(e) => setMaxBudget(e.target.value)}
                    placeholder="max value"
                  />
                </div>
                <button className="apply-btn" onClick={handleApply}>
                  Apply
                </button>
              </div>
            </div>
          </div>
  
          <div className="rightctr">
            {/* Main Content */}
            <div className="main-content">
              {/* Ads Grid */}
              <div className="ads-grid">
                {filteredAds.map((ad, index) => (
                  <div key={index} className="ad-card">
                    {ad.featured && (
                      <div className="featured-badge">Featured</div>
                    )}
                    <img
                      src={ad.image}
                      alt={ad.title}
                      className="ad-image"
                    />
                    <h3 className="ad-price">{ad.price}</h3>
                    <p className="ad-title">{ad.title}</p>
                    <p className="ad-details">
                      {ad.location} • {ad.date}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
  
        <MyGallery selectedCategory={selectedCategory || "All"} />
      </>
    );
  }

export default FoodsAndBeverages