import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./Scroll.css";
import sofa from "../../images/sofa.webp";
import ghar from "../../images/ghar.png";
import Dog from "../../images/Dog.png";
import bike from "../../images/bike.webp";
import car from "../../images/car.png";
import electronics from "../../images/electronics.webp";
import mobiles from "../../images/mobiles.png";
import Services from "../../images/Service1.png";
import Science from "../../images/Science.png";
import Travel from "../../images/Travel.avif";
import Tech from "../../images/Tech.jpg";
import Child from "../../images/Child.jpg";
import Hobbies from "../../images/hobbies1.png";
import Education from "../../images/Education.png";
import Job from "../../images/job111.png";
import { isMobile } from "react-device-detect";
import { Jobs } from "../../env";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import TrendingAds from "../Trending Ads/TrendingAds";
import Manufacturing from "./Manufacturing/Manufacturing";

const Scroll = () => {
  const mobileStyle = {
    backgroundSize: "cover",
    width: "60px",
    height: "60px",
  };
  const desktopStyle = {
    backgroundSize: "cover",
    width: "70px",
    height: "70px",
  };

  const images = [
    {
      url:
        "https://img.freepik.com/free-photo/close-up-hand-holding-smartphone_23-2149148857.jpg",
      heading: "Nature",
    },
    {
      url:
        "https://s.yimg.com/uu/api/res/1.2/8clWz6qZzhCb3idQpmwmRA--~B/Zmk9ZmlsbDtoPTYzMDtweW9mZj0wO3c9MTIwMDthcHBpZD15dGFjaHlvbg--/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2022-09/1dd547a0-349d-11ed-9eeb-9d420115ca52.cf.jpg",
      heading: "Animals",
    },
    {
      url:
        "https://assets.mspimages.in/gear/wp-content/uploads/2022/02/oppo-launched.jpg",
      heading: "Architecture",
    },
    // Add more dummy image URLs and headings as needed
  ];

  // Slider settings
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 1000, // Adjust the speed of animation (lower value for faster animation)
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Set to true to enable automatic sliding
    autoplaySpeed: 3000, // Set the interval time (in milliseconds) for each slide
    fade: true, // Use fade effect for transition
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 9,
    initialSlide: 0,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 7,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
    ],
  };
  SwiperCore.use([Navigation]);
  return (
    <div>
      {/* <div className="p-2">
        <Slider {...settings}>
            {arr.map((images,index)=>{
                console.log("images",images)
                return(
                    <div >
                   <img
                   alt="imag" src={images.img}  style={{backgroundImage:`url(${images.img})`,backgroundSize:'cover',
                    borderRadius:'50px',width:'100px',height:'100px'}}/>
                    <p className="text-center" >{images.text}</p>
                    </div>
                )
     
            })}
         </Slider>
      </div> */}

<Manufacturing/>

      <div
        className="mainwrapper"
        style={{
          marginBottom: "-20px",
          backgroundColor: "#FFFFFF",
        }}
      >
        {/* <h3 className="mb-4 mt-2">
          <b>
            <ins>OUR TOP CATEGORIES</ins>
          </b>
        </h3> */}

          <>     
     {/* <div className="slider-container">
        <Slider {...settings1}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image.url} alt={`Slide ${index + 1}`} />
              <h2 className={`heading-animation-${index + 1}`}>{image.heading}</h2>
            </div>
          ))}
        </Slider>
      </div> */}





        </>

        <div className="wrapper2001">
          <Slider {...settings}>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/RealEstate" title="Category RealEstates">
                  <img
                    alt="imag"
                    // src="https://freesvg.org/img/7c3dc245.png"
                    src="https://thumbs.dreamstime.com/b/d-render-modern-house-isolated-white-background-modern-house-d-rendering-luxury-style-isolated-white-background-131073093.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Real Estate</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/furniture" title="Category Furnitures">
                  <img
                    alt="imag"
                    // src={sofa}
                    src="https://images2.imgix.net/p4dbimg/11/images/030c-10_650-72_fx9219.jpg?fit=fill&bg=FFFFFF&trim=color&trimtol=5&trimcolor=FFFFFF&w=384&h=288&fm=pjpg&auto=format"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Furnitures</p>
                </Link>
              </div>
            </center>

            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/Pets" title="Category Pets">
                  <img
                    alt="imag"
                    // src={Dog}
                    src="https://media.istockphoto.com/id/513133900/photo/golden-retriever-sitting-in-front-of-a-white-background.webp?b=1&s=170667a&w=0&k=20&c=ENgM9IIlC7-7xH_4WHBWoQNeoO0u2TUnlhYsqka694c="
                    style={isMobile ? mobileStyle : desktopStyle}
                    // className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Pets</p>
                </Link>
              </div>
            </center>

            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/Bikes" title="Category Bikes">
                  <img
                    alt="imag"
                    // src={bike}
                    src="https://static.autox.com/uploads/2024/02/Kawasaki-Z900-Ebony-Metallic-Matte-Graphene-Steel-Gray-500x261.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Bikes</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/Cars" title="Category Cars">
                  <img
                    alt="imag"
                    // src={car}
                    src="https://media.zigcdn.com/media/model/2021/Apr/polo-2022_360x240.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Cars</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/electronics" title="Category Electronics">
                  <img
                    alt="imag"
                    // src={electronics}
                    src="https://media.istockphoto.com/id/1265168245/photo/home-appliances.jpg?s=612x612&w=0&k=20&c=agZt_DSpigHeKjLLC8_vmeV-cXJKH1ZstjYLLTiL7cY="
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Electronics</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/Mobiles" title="Category Mobiles">
                  <img
                    alt="imag"
                    // src={mobiles}
                    src="https://images.samsung.com/is/image/samsung/assets/uk/mobile-phone-buying-guide/best-samsung-a-series-phone/Section1_featurebenefit2_720x304.jpg?$FB_TYPE_B_JPG$"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Mobiles</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/education1" title="Category Jobs">
                  <img
                    alt="imag"
                    src={Education}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Education</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/jobs" title="Category Jobs">
                  <img
                    alt="imag"
                    src={Job}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Jobs</p>
                </Link>
              </div>
            </center>

            <center>
              <div className="wrapper pt-3">
                <Link to="/Cat/Services1" title="Category Services">
                  <img
                    alt="imag"
                    src={Services}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Services</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/Cat/Fashion" title="Category Fashion">
                  <img
                    alt="imag"
                    src="https://cdn-icons-png.flaticon.com/512/5934/5934507.png"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Fashion</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/Vehicles"
                  title="Category commercial vehicles and spares"
                >
                  <img
                    alt="imag"
                    src="https://media.istockphoto.com/id/516503046/vector/vector-truck-spares-concept.jpg?s=612x612&w=0&k=20&c=RyWW1-iTPy2zFKFLZq_NqFUULQ0WFJG6DAzuFOC3FvE="
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Vehicle&Spare</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/Cat/ToyAndGames" title="Category Toy & Games">
                  <img
                    alt="imag"
                    src="https://cdn-icons-png.flaticon.com/512/7058/7058443.png"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Toys&Games</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/HealthAndBeauty"
                  title="Category Health & Beauty"
                >
                  <img
                    alt="imag"
                    src="https://icon-library.com/images/health-and-beauty-icon/health-and-beauty-icon-7.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Health&Beauty</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/FarmingAndGardening"
                  title="Category Farming & Gardening"
                >
                  <img
                    alt="imag"
                    src="https://cdn-icons-png.freepik.com/512/4388/4388950.png"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Farm&Garden</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/PhotographyEquipment"
                  title="Category Photography Equipment"
                >
                  <img
                    alt="imag"
                    src="https://elements-resized.envatousercontent.com/elements-cover-images/40123861-bf87-47da-8ba7-c5393d1d8c18?w=433&cf_fit=scale-down&q=85&format=auto&s=a31c64abc8803356a90ea5407f446689e627ebca0a0f147178d1a5bb98c10702"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Photography</p>
                </Link>
              </div>
            </center>

            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/scienceandtechcategory1"
                  title="Category ScienceAndTech"
                >
                  <img
                    alt="imag"
                    src={Science}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Sci & Tech</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/travelandadventurecategory1"
                  title="Category TravelAndAdventure"
                >
                  <img
                    alt="imag"
                    src={Travel}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Travel & Adv</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/techgadgetscategory1"
                  title="Category TechGadgets"
                >
                  <img
                    alt="imag"
                    src={Tech}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Tech Gadget</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/BooksSportsHobbiesCat"
                  title="Category BooksSportsHobbiesCat"
                >
                  <img
                    alt="imag"
                    src={Hobbies}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0"> Hobbies</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/childrenbookscategory1"
                  title="Category ChildrenBooks"
                >
                  <img
                    alt="imag"
                    src={Child}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Child Books</p>
                </Link>
              </div>
            </center>

            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/FoodsAndBeverages"
                  title="Category FoodsAndBeverages"
                >
                  <img
                    alt="imag"
                    src="https://www.pngall.com/wp-content/uploads/8/Cuisine-PNG-Free-Download.png"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Foods & Beverages</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/Cat/HealthFitness" title="Category HealthFitness">
                  <img
                    alt="imag"
                    src="https://png.pngtree.com/png-vector/20220409/ourmid/pngtree-gym-gymnasium-body-building-exercise-training-fitness-workout-png-image_4503753.png"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Health & Fitness</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/Cat/BooksAndMedia" title="Category BooksAndMedia">
                  <img
                    alt="imag"
                    src="https://png.pngtree.com/png-vector/20240325/ourmid/pngtree-cartoon-how-to-sell-book-png-image_12196643.png"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Books & Media</p>
                </Link>
              </div>
            </center>
            <center>
              
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/EcoFriendlyProducts"
                  title="Category Eco-Friendly Products"
                >
                  <img
                    alt="imag"
                    src="https://png.pngtree.com/png-vector/20220730/ourmid/pngtree-circle-eco-friendly-logo-symbol-png-image_6093131.png"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">
                    Eco-Friendly Products
                  </p>
                </Link>
              </div>
            </center>

            <center>
              <div className="wrapper pt-3">
                <Link
                  to="/Cat/MusicalInstruments"
                  title="Category Musical Instruments"
                >
                  <img
                    alt="imag"
                    src="https://thumbs.dreamstime.com/b/musical-instruments-orchestra-collage-music-38504603.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Musical Instruments</p>
                </Link>
              </div>
            </center>

            {/*  <center>
              <div className="wrapper pt-3">
                <Link to="/Cat/Services1" title="Category Services">
                  <img
                    alt="imag"
                    src={Services}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">sample3</p>
                </Link>
              </div>
            </center>

            <center>
              <div className="wrapper pt-3">
                <Link to="/Cat/Services1" title="Category Services">
                  <img
                    alt="imag"
                    src={Services}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">sample4</p>
                </Link>
              </div>
            </center>  */}

            {/* <div>
            <Link to="/cat/Education" title="Category Education">
              <img
                alt="imag"
                src={Education}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p className="text-center fs-12 mb-0">Education</p>
            </Link>
          </div> */}
          </Slider>
        </div>

        <div>
          <h1></h1>
        </div>
        <div>
          <h1></h1>
        </div>
      </div>
    </div>
  );
};

export default Scroll;
