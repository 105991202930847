import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { url } from "../../env";
import { remove, add1 } from "../../../store/wishlistSlice";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import "./ProductDetails.css";
import AddComment from "../../Addcommentblog/AddComment";
import { MessageAdsChat } from "../messageAdsChat/MessageAdsChat";
import { AiFillDelete } from "react-icons/ai";
import Report from "../../Pages/Report/Report";
import { localUrl } from "../../env";
import Owldemo1 from "../../../component/Owldemo1";
import Spiner from "../../Spiner";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MapLocationAds from "./mapadslocation/MapLocationAds";
import Box from "@mui/material/Box";
import { Popover } from "antd";
import Button from "@mui/material/Button";
import Carousel from "react-grid-carousel";
import { useDispatch, useSelector } from "react-redux";
import { add } from "../../../store/Track/trackUserSlice";
import CategoryExtraField from "../../../Shared/CategoryExtraField";
import YoutubeMagic from "../../ContentLoader/YoutubeMagic";
import { BsFillBookmarkFill } from "react-icons/bs";
import { addUuid } from "../../../store/ChatSlice";
import TrendingAds from "../Trending Ads/TrendingAds";
import { isMobile } from "react-device-detect";
import Owldemo2 from "../../Owldemo2";
import { Verified } from "@mui/icons-material";
import { getExtraField } from "../../../store/ProductFilterSlice";
import { Education1 } from './../../env';



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 10,
};

const mobilestyle = {
  width: "70%",
  height: "70%",
};
const desktopstyle = {
  width: "50%",
  height: "50%",
};
const ProductDetails = (props) => {
  const dispatch = useDispatch();
  const handleClick = (category, subCategoryType, subCategoryValue) => {
    const obj = {
      category,
      ...(subCategoryType !== undefined ? { subCategoryType } : {}),
      ...(subCategoryValue !== undefined ? { subCategoryValue } : {}),
      extraField: {},
    };
    dispatch(getExtraField(obj));
  };


  const wishlistData = useSelector((state) => state.wishlistSlice);
  const addingWishlist = (value, e) => {
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
    }
    if (wishlistData?.data?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add1({ value: value }));
    }

    console.log("wishlist value", value, wishlistData);
  };

  const changeAds = useParams();
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [name, setName] = useState();
  const [locality1, setlocality1] = useState();
  const [city1, setcity1] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const handleClose = () => setOpen(false);

  const [error, setError] = useState(null);
  const { id } = useParams();
  // const greeting = product.category;product.category
  const idads = { id };
  const [imagesmutlipleads, setimagesmutlipleads] = useState([]);
  const [qrcode, setQrcode] = useState(null);
  const [locationLink, setlocationLink] = useState(
    "https://www.google.com/maps/search/?api=1"
  );
  console.log("userparams()", useParams());
  const navigate = useNavigate();

  const disptach = useDispatch();
  const mobstyle = {
    marginLeft: "-10px",
    marginRight: "-10px",
  };
  const deskstyle = {
    padding: "20px",
    marginBottom: "-10px",
    marginRight: "10rem",
    marginLeft: "10rem",
  };
  const mobi = {
    marginLeft: "-10px",
    marginRight: "-10px",
  };
  const deski = {
    marginLeft: "6rem",
    marginRight: "6rem",
  };

  const mobuse = {
    marginLeft: "5px",
    marginRight: "5px",
  };
  const deskuse = {
    marginLeft: "11.5rem",
    marginRight: "11.5rem",
  };

  // allAds geetings start
  const greeting0 = "RealEstate";
  const greeting1 = "Furniture";
  const greeting2 = "Electronics";
  const greeting3 = "Cars";
  const greeting4 = "Bikes";
  const greeting5 = "Pets";
  const greeting6 = "Mobiles";
  const greeting7 = "Services";
  const greeting8 = "Education";
  const greeting9 = "Jobs";
  //  allAds geetings end

  useEffect(() => {
    disptach(add({ view: ["ProductDetails"] }));
  }, []);

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("product_id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://demoadmin.hola9.com/api/adsapi/qrcode", requestOptions)
      .then(response => response.blob())
      .then(blob => {
        // Create a URL object from the received blob data
        const imageUrl = URL.createObjectURL(blob);
        setQrcode(imageUrl);
      })
      .catch(error => console.log("error", error));
  }, []);
  const [userID, setUserId] = useState(null);
  const [verified, setVerified] = useState(false);
  const userid = localStorage.getItem("userid");

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("userid", userid);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/adsapi/checkVerified", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setVerified(result.verifiedCustomer);
        console.log(result, "resvar");
      })
      .catch((error) => console.log("error", error));
  }, []);


  // ---------------------------------------------------------------------------for recentvisited ads logic 

  // useEffect(() => {
  //   var formdata = new FormData();
  //   formdata.append("user", userid);
  //   formdata.append("ad", id);

  //   var requestOptions = {
  //     method: "POST",
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(url + "api/adsapi/useradsvisited", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result, "resvar");
  //     })
  //     .catch((error) => console.log("error", error));
  // }, []);


  useEffect(() => {
    // Check if access token is not null
    if (localStorage.getItem("access_token") !== null) {
      // If access token is not null, make the API call
      var formdata = new FormData();
      formdata.append("user", userid);
      formdata.append("ad", id);
  
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
  
      fetch(url + "api/adsapi/useradsvisited", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result, "resvar");
        })
        .catch((error) => console.log("error", error));
    }
  }, []);
  
    // const [isLoggedInAwi, setIsLoggedInAwi] = useState(false);
  
    // const checkLoginStatus = () => {
    //   // Logic to check if the user is logged in (e.g., check if there's a name in localStorage)
    //   const tokenAwi = localStorage.getItem('name'); // Example: You may have stored a token in localStorage upon login
    //   if (tokenAwi !==" ") {
    //     setIsLoggedInAwi(true); 
    //   } else {
    //     setIsLoggedInAwi(false); 
    //   }
    // };



        
    // useEffect(() => {
    //   checkLoginStatus(); // Check login status when component mounts
    // }, []); // Empty dependency array ensures this effect runs only once after the initial render
   



    // useEffect(() => {
    //   if (isLoggedInAwi) {
    //     // If user is logged in, make the API call
    //     var formdata = new FormData();
    //     formdata.append("user", userid);
    //     formdata.append("ad", id);
  
    //     var requestOptions = {
    //       method: "POST",
    //       body: formdata,
    //       redirect: "follow",
    //     };
  
    //     fetch(url + "api/adsapi/useradsvisited", requestOptions)
    //       .then((response) => response.json())
    //       .then((result) => {
    //         console.log(result, "resvar");
    //       })
    //       .catch((error) => console.log("error", error));
    //   }
    // }, [isLoggedInAwi]); 


// ---------------------------------------------------------------------------for recentvisited ads logic 
  const OpenModalHandler = () => {
    setOpen(true);
  };

  const getSingleProduct = async () => {
    setLoading(true);

    const profileUser = (userid) => {
      console.log("!!!!!userid", userid);
      var formdata = new FormData();
      formdata.append("user", userid);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(localUrl + "user/userProfileDetailsApi/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("!!!result", result);
          console.log("!!!result", result[0].fields.name);

          setName(result[0].fields.name);
          setEmail(result[0].fields.email);
          setlocality1(result[0].fields.locality);
          setcity1(result[0].fields.city);
        })
        .catch((error) => console.log("error", error));
    };
    try {
      const { data } = await axios.get(localUrl + `adsapi/${id}/`);
      setLoading(false);

      setlocationLink(
        "https://www.google.com/maps/search/?api=1&query=" +
        product.lati +
        "," +
        product.long
      );
      
      console.log(data, "dd");
      setProduct(data);
      console.log("data", data?.uuid);
      dispatch(addUuid({ data: data?.uuid, phoneNumber: data?.phoneNumber }));
      profileUser(data.user);
      setUserId(data.user);
    } catch (error) {
      setError("Something went wrong!!");
    }
  };

  console.log("~~~~", userID);

  var rn = require("random-number");

  //phone details

  const [PhoneNumbertemp, setPhoneNumbertemp] = useState(true);
  const [otpverifychecklocal, setotpverifychecklocal] = useState(null);
  const [username, setUserName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [qualification, setQualification] = useState("");
  const [jobtitle, setJobtitle] = useState("");
  const [adsid, setAdsid] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [resumebase, setResumebase] = useState("");
  const [percentage, setPercentage] = useState("");
  const [resume, setResume] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [uploadStatus, setUploadStatus] = useState(""); // To track upload status

  const fileChangeHandler = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFile(selectedFile);
  };

  // const storePhoneNumer = (phone) => {
  //   var formdata = new FormData();
  //   formdata.append("productID", product.id);
  //   formdata.append("phoneNubmer", phone);

  //   var requestOptions = {
  //     method: "POST",
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(localUrl + "adsapi/collectVisitPhoneNumber", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => console.log(result))
  //     .catch((error) => console.log("error", error));
  // };
  const [isLoggedIn, setIsLoggedIn] = useState(false); // isLoggedIn (awi)
  const [isNewUser, setIsNewUser] = useState(false); // isNewUser (awi)
  const [errorAwi, setErrorAwi] = useState(false); // error (awi)
 

  useEffect(() => {
    // Check if the user is logged in by checking localStorage
    const accessToken = localStorage.getItem("access_token");
    setIsLoggedIn(accessToken !== null);
  }, []);

// const valAwi = localStorage.getItem("userid")
  useEffect(() => {
    if (isLoggedIn) {
      // If user is logged in, check if they are a new user or if their plan has expired
      fetch('https://demoadmin.hola9.com/api/newuserplan/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any authorization headers if required
        },
        // You may need to send some data in the request body based on your API requirements
        body: JSON.stringify({
          // Add any required data here
          // user: valAwi
          user:233
        }),
      })
        .then(response => response.json())
        .then(data => {
          // Check if the response indicates that the user's plan has expired
          if (data.message === "Your plan has expired. Please purchase a new plan.") {
            // Redirect the user to the business form if their plan has expired
            setIsNewUser(false);
          } else {
            // Show the "Send OTP" button if the user's plan has not expired
            setIsNewUser(true);
          }
        })
        .catch(ErrorAwi => {
          console.error('Error:', ErrorAwi);
          setErrorAwi(true);
        });
    }
  }, [isLoggedIn]);

  const SendOtp = (e) => {
    // const history = useHistory();

    e.preventDefault();
    var gen = "1231";
    console.log("phonenumber", PhoneNumber);

    setotpverifychecklocal(gen);
    axios({
      method: "POST",
      url: url + "api/phone/sendOTP/",
      data: {
        number: PhoneNumber,
      },
    })
      .then((res) => {
        if (true) {
          if (res.data.OTPSent === true) {
            setPhoneNumbertemp(false);
            // storePhoneNumer(PhoneNumber);
          } else {
            alert("OTP Was Not sent");
          }
        } else {
          alert("Enter Valid 10 Digits Number Only");
        }
      })
      .catch((e) => console.log(e));
  };









  const [otpVeriftemp, setotpVeriftemp] = useState(true);

  const [Otpdetails, setOtpdetails] = useState(null);
  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;

    // Validate phone number
    const isValid = /^\d{10}$/.test(input);

    if (!isValid) {
      setError("Please enter a valid 10-digit phone number");
    } else {
      setError("");
    }

    setPhoneNumber(input);
  };

  const handleKeyDown = (e) => {
    // Allow backspace
    if (e.key === "Backspace") {
      return;
    }

    // Prevent entering non-numeric characters
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const otpverifysumbit = (e) => {
    // console.log(typeof Otpdetails);
    console.log(Otpdetails);
    e.preventDefault();
    axios({
      method: "PUT",
      url: url + "api/phone/checkOTP/",
      data: {
        number: PhoneNumber,
        otp: Otpdetails,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          //calling registe  method

          setotpVeriftemp(false);
        } else {
          alert("Incorrect OTP");
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("adsId", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/adsapi/adsUpload", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);

        var multipleImage = [];

        result.map((result) => {
          let tempObjImage = {};
          tempObjImage["url"] = result.fields.image;
          multipleImage.push(tempObjImage);
        });
        console.log("!!!!image ", multipleImage);
        setimagesmutlipleads(multipleImage);
      })
      .catch((error) => console.log("error", error));
  }, [changeAds]);

  useEffect(() => {
    getSingleProduct();
  }, [changeAds]);

  useEffect(() => {
    let productExtrafile = product.extraField
      ? JSON.parse(product.extraField)
      : null;
    console.log("####product", product, productExtrafile);
    console.log(product.uuid);
    dispatch(
      addUuid({ data: product?.uuid, phoneNumber: product?.phoneNumber })
    );
  }, [product]);
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "csrftoken=EmLuPRUNkf6K6gJITHLqCb44GCBa3XdZbwQ9z0697rglSv3GfLbtztOqBKdfAxaB"
  );

  var formdata = new FormData();
  formdata.append("adsID", id);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  fetch(url + "api/user/viewsupdate/", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  document.title = "Hola9 - ProductDetails";

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const submitHandler = async (e) => {
    e.preventDefault();

    // Create a JavaScript object with the form data
    const formData = {
      posted_by: name,
      name: username,
      // date_of_birth: dateOfBirth,
      qualification: qualification,
      percentage: percentage,
      resume: selectedFile,
      phone_number: PhoneNumber,
      jobtitle: jobtitle,
      ads_id: adsid,
      company_name: companyname,
      resume_base64: resumebase,
    };
    try {
      const response = await fetch("https://demoadmin.hola9.com/api/user/contacts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setUploadStatus("Upload successful!"); // Set success message
      } else {
        setUploadStatus("Failed to upload."); // Set error message
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="load">
      {loading && (
        <div className="spinner-overlay">
          <Spiner />
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p
            onClick={handleClose}
            style={{ color: "red", fontSize: "20px", float: "right" }}
          >
            X
          </p>
          <h1 style={{ fontFamily: "verdana", fontSize: "34px" }}>
            Basic Information
          </h1>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="form-container-job">
              <form onSubmit={submitHandler}>
                <div className="jobd">
                  <div className="jobdd">
                    <label>Posted By</label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={name}
                      required
                    />
                  </div>
                  <div className="jobdd">
                    <label>Name</label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="jobdd">
                    <label>Qualification</label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(e) => setQualification(e.target.value)}
                      required
                    />
                  </div>
                  <div className="jobdd">
                    <label>Percentage</label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(e) => setPercentage(e.target.value)}
                      required
                    />
                  </div>

                  <div className="jobdd">
                    <label>Company Name</label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(e) => setCompanyname(e.target.value)}
                      required
                    />
                  </div>

                  <div className="jobdd">
                    <label>Job Title</label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(e) => setJobtitle(e.target.value)}
                      required
                    />
                  </div>

                  <div className="jobdd">
                    <label>Ads ID</label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(e) => setAdsid(e.target.value)}
                      required
                    />
                  </div>

                  <div className="jobdd">
                    <label>Upload Resume</label>
                    <input type="file" onChange={fileChangeHandler} required />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    {PhoneNumbertemp ? (
                      <div className="form-group mx-sm-3 mb-2 mt-1">
                        <label>Mobile Number</label>
                        <input
                          type="number"
                          className="form-control"
                          id="text"
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                          placeholder="Mobile Number"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                        />
                        {PhoneNumber.length < 10 || PhoneNumber.length > 10 ? (
                          <div style={{ color: "red" }}>
                            * Please Enter 10 Digit Mobile Number Only{" "}
                          </div>
                        ) : null}
                        <button
                          className="btn-sm btn-1 mt-2 mb-2 fs-14 text-white"
                          onClick={SendOtp}
                        >
                          Submit
                        </button>
                      </div>
                    ) : otpVeriftemp ? (
                      <div>
                        <form className="form-inline">
                          <div
                            className="col-lg-12"
                            style={{ marginTop: "10px" }}
                          >
                            <label htmlFor="input" className="sr-only">
                              Enter Your otp
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="text"
                              onChange={(e) => {
                                setOtpdetails(e.target.value);
                              }}
                              placeholder="Enter Your otp"
                            />
                          </div>
                          <button
                            className="btn-sm btn-1 mt-3 mx-3 text-white"
                            onClick={otpverifysumbit}
                          >
                            Click here
                          </button>
                        </form>
                      </div>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="jobdd">
                          <label>PhoneNumber</label>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={PhoneNumber}
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            variant="contained"
                            className="btn-sm btn-1 mt-3 mx-3 text-white w-100"
                          >
                            Upload
                          </button>
                          {uploadStatus && <p>{uploadStatus}</p>}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Typography>
        </Box>
      </Modal>

      <div className="content-container">
        {loading ? null : (
          <section className=" single-post-page mx-3 mt-2">
            <div>
              <div className="row">
                <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12 mt-2">
                  {/* single post */}
                  <div className="single-post">
                    {/* post title*/}
                    {/* <div className="single-post-title"> */}
                    <div>
                      {/* <div className="post-price visible-xs visible-sm">
                    <h4>₹&nbsp; {product.price} </h4>
                  </div> */}
                      <h4
                        className="detailFont"
                        style={{
                          fontFamily: "serif",
                          fontStyle: "oblique",
                          color: "black",
                        }}
                      >
                        {product.title}
                        {/* {imagesmutlipleads.length} */}
                      </h4>

                      <p
                        className="post-category"
                        style={{ fontFamily: "serif" }}
                      >
                        <i
                          className="far fa-folder-open"
                          style={{ color: "green" }}
                        />
                        :<span>{product.category}</span>&nbsp;&nbsp;
                        <i
                          className="fas fa-map-marker-alt"
                          style={{ color: "red" }}
                        />
                        :<span>{product.City}</span>
                      </p>
                    </div>
                    {/* post title*/}
                    {/* <i
        width={896}
        height={504}
        images={imagesmutlipleads}
        showBullets={true}
        showNavs={true}
      /> */}
                    <div
                      style={{
                        border: "1px solid #00000012",
                        height: "300px",
                        borderRadius: "5px",
                      }}
                    >
                      <Carousel
                        cols={imagesmutlipleads.length > 1 ? 2 : 2}
                        rows={imagesmutlipleads.length > 3 ? 1 : 1}
                        gap={10}
                        loop
                        autoplay={3000}
                      >
                        {imagesmutlipleads.map((img, index) => {
                          return (
                            <Carousel.Item>
                              <img
                                alt=""
                                width="100%"
                                style={{
                                  height: "300px",
                                  backgroundRepeat: "fill",
                                }}
                                src={img.url}
                                onClick={() => openModal(index)}
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                    <Modal open={modalOpen} onClose={closeModal}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <button
                          onClick={closeModal}
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "40px",
                            border: "none",
                            float: "right",
                            margin: "10px",
                          }}
                        >
                          X
                        </button>
                        <center
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                          }}
                        >
                          <Carousel
                            cols={imagesmutlipleads.length > 1 ? 2 : 2}
                            rows={imagesmutlipleads.length > 3 ? 1 : 1}
                            gap={10}
                            loop
                            autoplay={10000}
                          >
                            {imagesmutlipleads.map((img, index) => {
                              return (
                                <Carousel.Item>
                                  <img
                                    alt=""
                                    width="100%"
                                    style={{
                                      height: "500px",
                                      backgroundRepeat: "fill",
                                    }}
                                    src={img.url}
                                    onClick={() => openModal(index)}
                                  />
                                </Carousel.Item>
                              );
                            })}
                          </Carousel>
                        </center>
                      </div>
                    </Modal>
                    {/* single post carousel*/} {/* ad deails */}
                    <div className="p-4 border details mt-4">
                      <h4
                        className="mt-0 d-flex "
                        style={{ color: "navy", fontFamily: "serif" }}
                      >
                        <ins>Ad Details &nbsp;</ins>
                        <i className="far fa-file-alt h5" />
                        &nbsp;
                        <hr />
                      </h4>

                      <div className="post-details">
                        <ul className="list-unstyled clearfix">
                          <li>
                            <p className="clearfix">
                              Ad ID:
                              <span className="pull-right flip">
                                <i className="fas fa-hashtag IDIcon" />
                                {product.id}
                              </span>
                            </p>
                          </li>
                          {/*PostDate*/}
                          <li className="classiera_pdate">
                            <p className="clearfix">
                              Added:
                              {/* <span className="pull-right flip">  {datemanage(product.date_created)}</span> */}
                              <span className="pull-right flip">
                                {" "}
                                {new Date(product.date_created)
                                  .toGMTString()
                                  .slice(0, 12)}
                              </span>
                            </p>
                          </li>
                          {/*PostDate*/}
                          {/*Price Section */}
                          <li>
                            {product.category == "Jobs" ? null : (
                              <p className="clearfix">
                                Sale Price:
                                <span className="pull-right flip cl__price">
                                  ₹&nbsp;{product.price}
                                </span>
                              </p>
                            )}
                          </li>
                          {/*Sale Price*/}
                          <li>
                            {product.category == "Jobs" ? null : (
                              <p className="clearfix">
                                Regular Price:
                                <span className="pull-right flip cl__price">
                                  ₹&nbsp; {product.price}
                                </span>
                              </p>
                            )}
                          </li>
                          {/*Regular Price*/}
                          {/*Price Section */}
                          <li>
                            {product.category == "Jobs" ? null : (
                              <p className="clearfix">
                                Condition:
                                <span className="pull-right flip">
                                  {product.condition}
                                </span>
                              </p>
                            )}
                          </li>
                          {/*Condition*/}
                          <li>
                            <p className="clearfix">
                              State:
                              <span className="pull-right flip">
                                {product.state}
                              </span>
                            </p>
                          </li>
                          {/*Location*/}
                          <li>
                            <p className="clearfix">
                              City:
                              <span className="pull-right flip">
                                {product.City}
                              </span>
                            </p>
                          </li>
                          {/*state*/}
                          {/* <li>
                        <p className="clearfix">
                          City:
                          <span className="pull-right flip">Bellingham</span>
                        </p>
                      </li> */}
                          {/*City*/}
                          <li className="classiera_views">
                            <p className="clearfix">
                              Views:
                              <span
                                className="pull-right flip"
                                style={{ color: "green" }}
                              >
                                {product.viewsproduct}{" "}
                              </span>
                            </p>
                          </li>

                          <li>
                            <p className="clearfix">
                              Time:
                              <span className="pull-right flip">
                                {" "}
                                {new Date(product.date_created)
                                  .toGMTString()
                                  .slice(17, 22)}
                              </span>
                            </p>
                          </li>
                          {/*test*/}
                          {product.length && (
                            <li>
                              <p className="clearfix">
                                Model:
                                <span className="pull-right flip">
                                  {product.title}
                                </span>
                              </p>
                            </li>
                          )}
                          {/*test*/}
                          <li>
                            <p className="clearfix">
                              Year:
                              <span className="pull-right flip">
                                {new Date(product.date_created)
                                  .toGMTString()
                                  .slice(11, 16)}
                              </span>
                            </p>
                          </li>
                          {product?.extraField &&
                            Object?.keys(
                              JSON?.parse(product.extraField) || {}
                            ).map((key) => {
                              return (
                                <li>
                                  <p className="clearfix">
                                    {key} :{" "}
                                    <>
                                      {key.toLowerCase() === 'website' ? (
                                        <a href={(JSON?.parse(product.extraField))[key]} target="_blank" rel="noopener noreferrer">
                                          {(JSON?.parse(product.extraField))[key]}
                                        </a>
                                      ) : (
                                        <span className="pull-right flip">
                                          {(JSON?.parse(product.extraField))[key]}
                                        </span>
                                      )}
                                    </>
                                  </p>
                                </li>
                              );
                            })}
                          {/*test*/}
                        </ul>
                      </div>
                      {/*post-details*/}
                    </div>
                    {/* ad details */}
                    {/*Google Section*/}
                    {/*Google Section*/}
                    {/* Google MAP in Description */}
                    {/* Google MAP in Description */}
                    {/*PostVideo*/}
                    {/*PostVideo*/}
                    {/* post description */}
                    <div className="p-4 mt-2 border descriptio w-100">
                      <h4 className="">
                        Description &nbsp;
                        <i className="far fa-file-alt h5" />
                        <hr />
                      </h4>
                      <div className="classiera_entry_content">
                        <p>{product.description}</p>
                      </div>
                      <div className="tags">
                        <span>
                          <i className="fas fa-tags" />
                          Tags :
                        </span>
                        <Link

                          rel="tag"
                          className="ml-2"
                          onClick={() => handleClick("RealEstate")}
                          to={`/ads-listing/category/${"RealEstate"}/`}
                        >
                          RealEstate
                        </Link>
                        <Link
                        to={`/ads-listing/category/${"Services"}/`}
                        onClick={() => handleClick("Services")}
                          rel="tag"
                          className="ml-2"
                        >
                          Services
                        </Link>
                        <Link
                        to={`/ads-listing/category/${"Electronics"}/`}
                        onClick={() => handleClick("Electronics")}
                          rel="tag"
                          className="ml-2"
                        >
                          Electronics
                        </Link>
                        <Link
                        to={`/ads-listing/category/${"Furniture"}/`}
                        onClick={() => handleClick("Furniture")}
                          rel="tag"
                          className="ml-2"
                        >
                          Furniture & Household
                        </Link>
                        {/* <Link
                        to={`/ads-listing/category/${"Removals"}/`}
                        onClick={() => handleClick("Removals")}
                          rel="tag"
                          className="ml-2"
                        >
                          Transportations
                        </Link> */}
                        <Link
                        to={`/ads-listing/category/${"Jobs"}/`}
                        onClick={() => handleClick("Jobs")}
                          rel="tag"
                          className="ml-2"
                        >
                          Jobs
                        </Link>
                        <Link
                        to={`/ads-listing/category/${"Pets"}/`}
                        onClick={() => handleClick("Pets")}
                          rel="tag"
                          className="ml-2"
                        >
                          Pets
                        </Link>
                        <Link
                        to={`/ads-listing/category/${"Education"}/`}
                        onClick={() => handleClick("Education")}
                          rel="tag"
                          className="ml-2"
                        >
                          Education
                        </Link>
                      </div>
                      {/*Post Pagination*/}
                      {/*Post Pagination*/}
                    </div>
                    {/* <CategoryExtraField props={product} /> */}
                    {/* post description */}
                    {/* classiera bid system */}
                    {/* classiera bid system */}
                    {/*comments*/}
                    {/* <Comments />   */}
                    <AddComment id={idads} />
                    {/*comments*/}
                  </div>
                  {/* <AddComment id={idads}/> */}
                  {/* single post */}
                </div>
                {/*col-md-8*/}
                <div className="col-md-4">
                  <aside className="sidebar">
                    <div className="row">
                      {/*Widget for style 1*/}
                      <div
                        className="col-lg-12 col-md-12 col-sm-6 match-height mt-3"
                      // style={{ marginTop: "6rem" }}
                      >
                        <div className="widget-box rounded">
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className="widget-title price">
                              <h3 className="post-price fs-16">
                                ₹&nbsp; {product.price}
                                <span className="ad_type_display fs-16">
                                  Available{" "}
                                </span>
                              </h3>
                            </div>
                            <div>
                              <span className="ad_type_displayd fs-16 ">
                                {isMobile ? (
                                  <BsFillBookmarkFill
                                    id="wishlibyn"
                                    style={{
                                      fontSize: "25px",
                                      marginTop: "20px",
                                    }}
                                    className={
                                      wishlistData?.data?.indexOf(
                                        product.id
                                      ) !== -1
                                        ? "removemob"
                                        : "heartmob"
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      addingWishlist(product.id);
                                    }}
                                  />
                                ) : (
                                  <button
                                    className={
                                      wishlistData?.data?.indexOf(
                                        product.id
                                      ) !== -1
                                        ? "remove-button1 "
                                        : "wishlist-button1 "
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      addingWishlist(product.id);
                                    }}
                                  >
                                    {wishlistData?.data?.indexOf(product.id) !==
                                      -1 ? (
                                      <>
                                        <AiFillDelete />
                                        &nbsp;Remove{" "}
                                      </>
                                    ) : (
                                      <>
                                        <BsFillBookmarkFill />
                                        &nbsp;Add to Wishlist
                                      </>
                                    )}
                                  </button>
                                )}
                              </span>
                            </div>
                          </div>

                          {/*price*/}
                          <div className="widget-content widget-content-post">
                            <div className="author-info border-bottom widget-content-post-area">
                              <div className="media">
                                <div className="media-left">
                                  <img
                                    className="media-object"
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx9tjaExsY-srL4VsHNE_OKGVCJ-eIFNBktw&usqp=CAU"
                                    alt="admin"
                                  />
                                </div>
                                {/*media-left*/}
                                <div className="media-body">
                                  <h6 className="media-heading text-uppercase">
                                    <b className="font-bold">
                                      {product.user_type}&nbsp;
                                    </b>
                                    {/* {verified} */}
                                    {verified ? (
                                      <span className="small classiera_verified">
                                        <Verified style={{ color: "blue" }} />{" "}
                                        Verified
                                      </span>
                                    ) : null}
                                  </h6>
                                  <p className="member_since">
                                    Member Since&nbsp;
                                    {new Date(product.date_created)
                                      .toGMTString()
                                      .slice(0, 16)}
                                  </p>
                                  <NavLink to={`/activeUserAds/${userID}/`}>
                                    {/* <Link to={`/activeUserAds/${userID}`}> */}
                                    See All ADs
                                    {/* </Link> */}
                                  </NavLink>
                                  <Popover
                                    // content={<a onClick={hide}></a>}
                                    content={
                                      <img
                                        src={
                                          qrcode
                                        }
                                        alt="qr code is not avaiable"
                                        width="150"
                                        height="150"
                                      />
                                    }
                                    // title="Title"
                                    trigger="click"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange}
                                    placement="bottom"
                                  >
                                    {/* <Button type="primary">Click me</Button> */}
                                    <Button style={{ marginLeft: "20px" }}>
                                      Show QR
                                    </Button>
                                  </Popover>
                                </div>
                                {/*media-body*/}
                              </div>
                              {/*media*/}
                            </div>
                            {/*author-info*/}
                          </div>
                          {/*widget-content*/}
                          <div className="widget-content widget-content-post">
                            {product.category !== "Jobs" && (
                              <div className="contact-details widget-content-post-area">
                                <h5 className="text-uppercase fs-16 fw-medium">
                                  Seller Details :
                                </h5>
                                <ul
                                  className="list-unstyled fa-ul c-detail"
                                  style={{ marginTop: "-25px" }}
                                >
                                  {/*WhatsAPP*/}
                                  {/*WhatsAPP*/}
                                  {/* <li>
                              <i className="fas fa-li fa-envelope" />
                              <a to="mailto:barik99@gmail.com">
                            <p className="detailFont"> {email}  </p> 
                            {" "}
                              </a>
                            </li> */}
                                </ul>
                                
                                {/* Product Details Send */}

                                {/* Start seler details*/}
                                <div>
                                  {PhoneNumbertemp ? (
                                    <div
                                      className="form-group mx-sm-3 mb-2"
                                      style={{ marginTop: "-30px", height: '26px' }}
                                    >
                                      <label
                                        htmlFor="input"
                                        className="sr-only"
                                      >
                                        Enter Your Number
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="text"
                                        maxLength={10}
                                        value={PhoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        placeholder="Enter Your Number"
                                        onKeyDown={handleKeyDown}
                                      />
                                      {/* <div
                                        style={{ float: "right", position: 'relative', top: '-35px' }}>
                                        <Button
                                          // className="mx-4 mt-2"
                                          variant="contained"
                                          style={{ padding: '5px 16px' }}
                                          onClick={SendOtp}
                                          disabled={error || PhoneNumber.length !== 10}
                                        >
                                          Send OTP
                                        </Button>
                                      </div> */}
                                      {/* <div style={{ float: "right", position: 'relative', top: '-35px' }}>
                                      {localStorage.getItem("access_token") == null ? (
                                      
                                        <Link className="nav-link" to="/login/" title="Login">
                                          Login
                                        </Link>
                                      ) : (
                                        
                                        <Button
                                          variant="contained"
                                          style={{ padding: '5px 16px' }}
                                          onClick={SendOtp}
                                          disabled={error || PhoneNumber.length !== 10}
                                        >
                                          Send OTP
                                        </Button>
                                      )}
                                    </div> */}
                                      <div style={{ float: "right", position: 'relative', top: '-35px' }}>

      {isLoggedIn && isNewUser ? (
        
        <Button
          variant="contained"
          style={{ padding: '5px 16px' }}
          onClick={SendOtp}
          disabled={errorAwi || PhoneNumber.length !== 10}
        >
          Send OTP
        </Button>
    
      ) : (
       
        <Link to={isLoggedIn ? "/businessform/" : "/login/"} title="Login">
        <Button
          variant="contained"
          style={{ padding: '5px 16px' }} 
          disabled={errorAwi || PhoneNumber.length !== 10}
        >
          Send OTP
        </Button>
          {/* {isLoggedIn ? "Business Form" : "Login"} */}
        </Link>
      )}
 </div>

                                      {error && (
                                        <div style={{ color: "red" }}>
                                          {error}
                                        </div>
                                      )}
                                    </div>
                                  ) : otpVeriftemp ? (
                                    <div>
                                      {" "}
                                      <form class="form-inline">
                                        <div
                                          class="col-lg-12 "
                                          style={{ marginTop: "-20px" }}
                                        >
                                          <label
                                            htmlFor="input"
                                            className="sr-only"
                                          >
                                            Enter Your otp
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            id="text"
                                            onChange={(e) => {
                                              setOtpdetails(e.target.value);
                                            }}
                                            placeholder="Enter Your otp"
                                          />
                                        </div>
                                        <Button
                                          variant="contained"
                                          className="mx-4 mt-2"
                                          onClick={otpverifysumbit}
                                        >
                                          Verify otp
                                        </Button>
                                      </form>
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        className="mx-4 detailFont"
                                        style={{ marginTop: "-35px" }}
                                      >
                                        Name: &nbsp; <b>{name}</b>
                                        <br></br>
                                        Phone Number: &nbsp;
                                        <b>{product.phoneNumber}</b>
                                      </div>
                                      <div className="mx-4 detailFont">
                                        Address Info:&nbsp;
                                        <b>
                                          {product.location}&nbsp;
                                          {product.City},&nbsp;
                                          {product.state},&nbsp;
                                          {product.zip_code}
                                        </b>
                                        <br />
                                        <a target="_blank" href={locationLink}>
                                          {" "}
                                          <button
                                            className="productButton mt-2"
                                            style={{ padding: "5px 20px" }}
                                          >
                                            See On Google Map
                                          </button>
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* end */}
                              </div>
                            )}
                            {product.category == "Jobs" && (
                              <Button
                                onClick={OpenModalHandler}
                                variant="contained"
                                size="small"
                              >
                                Apply Now
                              </Button>
                            )}
                            {/*contact-details*/}
                          </div>
                          {/*widget-content*/}
                        </div>
                        {/*widget-box*/}
                      </div>
                      {/*col-lg-12 col-md-12 col-sm-6 match-height*/}
                      {/*Widget for style 1*/}
                      <MessageAdsChat
                        product={product}
                        name={name}
                        email={email}
                      />
                      {/*col-lg-12 col-md-12 col-sm-6 match-height*/}
                      <MapLocationAds
                        props={{ lati: product.lati, long: product.long }}
                      />
                      &nbsp;
                      <div className="custom-banner">
                        <div className="custom-content-wrapper">
                          <div className="custom-free-tag">Free</div>
                          <div className="custom-banner-content">
                            <h1 className="custom-title">
                              POST YOUR ADS FOR FREE
                            </h1>
                          </div>
                          <div>
                            <Link to="/allcatforpost/">
                              <button className="custom-clicklink">
                                Post Ads
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* <Report id={id} /> */}
                      {/*col-lg-12 col-md-12 col-sm-6 match-height*/}
                      {/*Social Widget*/}
                      {/*Social Widget*/}
                      {/*col-lg-12*/}
                      {/*SidebarWidgets*/}
                      {/*SidebarWidgets*/}
                    </div>
                    {/*row*/}
                  </aside>
                  {/*sidebar*/}
                </div>
                {/*col-md-4*/}
              </div>
              <div style={isMobile ? mobstyle : deskstyle}>
                <Owldemo1
                  greeting={product.category}
                  subcategory={product.subCategoryValue}
                />
              </div>
              <div style={isMobile ? mobi : deski}>
                <TrendingAds />
              </div>
              {/* <FooterBlog /> */}

              {/*row*/}
            </div>


          </section>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;

