import React, { useEffect, useState } from 'react';
import ReviewForm from './ReviewForm';
import { Button, Modal } from 'antd';
import Slider from 'react-slick';
import { BsFillStarFill, BsPerson } from 'react-icons/bs';
import './Feedback.css';
import { StarBorderOutlined } from '@mui/icons-material';

const ReviewList = () => {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const [apiData, setApiData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [itemsToLoad, setItemsToLoad] = useState(15); // Holds the number of items to load initially and increases when "Load More" is clicked
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [canLoadMore, setCanLoadMore] = useState(true);

  const [modalVisible, setModalVisible] = useState(false);
  const openReviewPopup = () => {
    setModalVisible(true);
  };
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://demoadmin.hola9.com/api/user/reviewSection/?limit=${itemsToLoad}`);
        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [itemsToLoad]);
  // const handleLoadMore = () => {
  //   setItemsToLoad(itemsToLoad + 30); // Increase the number of items to load
  // };
  const filteredData = apiData.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isLoading) {
        const newDataLength = apiData.length;

        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isLoading, apiData, prevDataLength]);

  // Modify handleLoadMore to set the flag indicating new data is received
  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 30); // Increase the number of items to load
  };



  return (
    <div className="main-container">
      <div className="main-capation text-center mb-3">
        <h2 className="headrev">What Our Users Say About Us</h2>
      </div>
      <div className="rivbtdiv1">
        <Button
          type="primary"
          shape="round"
          size="large"
          className="review-button1"
          onClick={openReviewPopup}
        >
          Feed Back
        </Button>
        <Modal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          centered
          destroyOnClose
          maskStyle={{ backgroundColor: 'transparent' }}
        >
          <ReviewForm />
        </Modal>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <input
          className="searchfilinp"
          placeholder="Search Feedback"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            marginBottom: "10px",
            marginTop: "20px",
            width: "300px",
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid black",
            padding: "10px",
          }}
        />
      </div>

      <Slider {...settings} className='feedslider'>
        {filteredData.map((item) => (
          <div className='card2011'>
            <div key={item.id} className="card3" >
              <div className="feedback-card-content">
                <BsPerson className="person-icon" />
                <div className="rating-and-role">
                  <p className="role">{item.role}</p>
                  <p className="rating">
                    {Array.from({ length: 5 }, (_, index) => (
                      <StarBorderOutlined
                        key={index}
                        style={{ color: index < item.rating ? 'gold' : 'gray' }}
                      />
                    ))}
                  </p>
                </div>
                <h6 className="profile-title">{item.profile}</h6>
                <p className="description">{item.description}</p>
                <p className="description">{item.username}</p>
                <p className="description">{item.userid}</p>
                <div className="details">
                  <p className="created-at">{item.created_at}</p>
                </div>
              </div>
            </div>
          </div>
        ))}

      </Slider>
    </div>
  );
};

export default ReviewList;
