
import { useDispatch } from "react-redux";
import { Col, Row, Tooltip, Modal, Slider } from "antd";
import React, { useState, useEffect, useContext, useRef } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Button, Dropdown, Menu } from "antd";
import "./Allcatads.css";
import { isMobile } from "react-device-detect";
import DynamicFont from "react-dynamic-font";
import { AiFillDelete } from "react-icons/ai";
import {
  BsFillCheckSquareFill,
  BsGeoAltFill,
  BsBuilding,
  BsNewspaper,
  BsFillCartPlusFill,
  BsHeart,
  BsHeartFill,
  BsFillBookmarkFill,
  BsArrowDownUp,
} from "react-icons/bs";
// import BiSolidSortAlt from "react-icons/bi"
import { useSelector } from "react-redux";
import { Input, Space } from "antd";
import YoutubeMagic1 from "./ContentLoader/YoutubeMagic1";
import { add1, remove } from "../store/wishlistSlice";
import { SliderContainer } from "../Utils/SliderContainer";
import { Slide } from "@mui/material";
import { Money, Swipe } from "@mui/icons-material";
import { FilterBy } from "../FilterBy";
import { Pop, Pop2 } from "./Base/Pop";
import { GiMoneyStack } from "react-icons/gi";
// import { Bikes } from "./env";
import verified from "./Ads/Allads/verified.jpg";
import NoDataFound from "./datanotfound/NoDataFound";

const mobistyle = {
  width: "auto",
  height: "140px",
  marginLeft: "16px",
  marginRight: "16px",
  border: "1px solid white",
  background: "transparent",
};
const deskistyle = {
  width: "14rem",
  background: "gray",
  //  borderRadius: "2px",
  border: "1px solid gray",
};

// const JsonSearch = require("search-array").default;

const mobilestyle = {
  height: "140px",
  width: "350px",
  borderRadius: "20px",
};
const desktopstyle = {
  height: "165px",
  width: "350px",
};
const Allcatads = ({ onClick, ...props }) => {
  const intervalRef = useRef();
  const category2 = props.greeting;

  const subcat = props.subcategory;
  const search = props.searchv;
  const [ads, setAds] = useState([]);
  const [limit, setLimit] = useState(24);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(subcat); // Default to 'all' tab
  const [currentTab1, setCurrentTab1] = useState(); // Default to 'all' tab
  const [sortBy, setSortBy] = useState('default'); // Default to 'default' sort
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const wishlistData = useSelector((state) => state.wishlistSlice);
  // const searchAds = useSelector((state) => state.searchAds);
  const { data } = useSelector((state) => state.sortFilter);
  const cityname = localStorage.getItem("selectedCity")
  const navigate = useNavigate();
  const [callLoadMore, setCallLoadMore] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Define parameters based on the current tab, sort, and search query
        let params = { limit: limit, category: category2, subCategory: '', search_key: searchQuery };

        if (currentTab === 'business') {
          params.businessplan = "True";
        } else if (currentTab === 'nearby') {
          params.city = cityname; // Update with the desired city or fetch from user's location
        } else {
          // Set subCategory only if currentTab is neither 'business' nor 'nearby'
          params.subCategory = currentTab;
        }
        // Add sorting logic
        if (sortBy === 'minPriceToMaxPrice') {
          params.sorting_order = 'min_to_max';
        } else if (sortBy === 'maxPriceToMinPrice') {
          params.sorting_order = ' max_to_min';
        } else if (sortBy === 'recentlyAdded') {
          params.sorting_order = 'recently';
        } else if (sortBy === 'older') {
          params.sorting_order = 'older';
        } else if (sortBy === 'default') {
          params.sorting_order = 'default';
        }

        const response = await fetch('https://demoadmin.hola9.com/api/adsapi/categoryAdsByInterval', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        });
        if (response.status === 503) {
          console.error('Service Unavailable (503)');
          setCanLoadMore(false); // Disable further load more attempts
          clearInterval(intervalRef.current);
          return;
        }

        const data = await response.json();

        // Check if the response has no ads and city is Bengaluru with limit 5
        if (data.length === 0 && params.city === cityname && params.limit === 20) {
          setAds([]);
        } else {
          setAds(data);
          setCallLoadMore(true);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();


  }, [limit, currentTab, sortBy, searchQuery, cityname]);
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(async () => {
      if (!loading && callLoadMore && ads.length > 0 && limitIncreaseCount != 5) {
        const initialAdsLength = ads.length;
        if (initialAdsLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(initialAdsLength);
        } else {
          clearInterval(intervalRef.current); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);
    // Wait for the next API call to complete

    // Check if the length of ads has increased after the API call


    return () => clearInterval(intervalRef.current);
  }, [loading, callLoadMore, currentTab, ads.length, limitIncreaseCount]);
  const addingWishlist = (value, e) => {
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
    }
    if (wishlistData?.data?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add1({ value: value }));
    }

    console.log("wishlist value", value, wishlistData);
  };


  const handleLoadMore = () => {
    if (!canLoadMore) {
      return; // Skip if further load more attempts are disabled or the limit has been increased 3 times
    }

    setLoading(true);
    setLimit((prevLimit) => prevLimit + 24);
    setLimitIncreaseCount((prevCount) => prevCount + 1);
  };
  const [cardCount, setCardCount] = useState(22);

  const loadMoreCards = () => {
    // Increment the card count by 30.
    setCardCount(cardCount + 20);
    setLimitIncreaseCount(0);

  };

  const handleTabChange = (tab) => {
    setAds([]);
    setCurrentTab(tab);
    setLimit(21); // Reset limit when changing tabs
  };
  const handleTabChange2 = (tab) => {
    setAds([]);
    setCurrentTab1(tab);
    setLimit(21); // Reset limit when changing tabs
  };

  const handleSortChange = (key) => {
    setAds([]);
    setSortBy(key);
    setLimit(21); // Reset limit when changing sorting
  };

  const handleSearch = (value) => {

    setAds([]);
    setSearchQuery(value);
    setLimit(21); // Reset limit when performing a search
  };

  const menu = (
    <Menu onClick={({ key }) => handleSortChange(key)}>
      <Menu.Item key="default">Default</Menu.Item>
      <Menu.Item key="minPriceToMaxPrice">Min Price to Max Price</Menu.Item>
      <Menu.Item key="maxPriceToMinPrice">Max Price to Min Price</Menu.Item>
      <Menu.Item key="recentlyAdded">Recently Added Ads</Menu.Item>
      <Menu.Item key="older">Previously Added Ads</Menu.Item>
    </Menu>
  );

  return (
    <div className="ad-list-container mgntp-mnd">
      {/* Add tab buttons */}
      <div className="tab-buttons RE-tab-buttons white-space-bytn1"
        style={{ overflowX: 'scroll' }}>
        {category2 === "Services" && (
          <>
            <button
              className={`bytn1 ${currentTab === "Appliance Repair"
                }`}
              onClick={() => handleTabChange("Appliance Repair")}
            >
              Appliance Repair
            </button>
            <button
              className={`bytn1 ${currentTab === "Computer Repair"
                }`}
              onClick={() => handleTabChange("Computer Repair")}
            >
              Computer Repair
            </button>
            <button
              className={`bytn1 ${currentTab === "Home Cleaning"
                }`}
              onClick={() => handleTabChange("Home Cleaning")}
            >
              Home Cleaning
            </button>
            <button
              className={`bytn1 ${currentTab === "Home Help"
                }`}
              onClick={() => handleTabChange("Home Help")}
            >
              Home Help
            </button>
            <button
              className={`bytn1 ${currentTab === "Home Repair"
                }`}
              onClick={() => handleTabChange("Home Repair")}
            >
              Home Repair
            </button>
            <button
              className={`bytn1 ${currentTab === "Interior Designing & Home improvements"
                }`}
              onClick={() => handleTabChange("Interior Designing & Home improvements")}
            >
              Interior Designing & Home improvements
            </button>
            <button
              className={`bytn1 ${currentTab === "Invertor & Batteries"
                }`}
              onClick={() => handleTabChange("Invertor & Batteries")}
            >
              Invertor & Batteries
            </button>
            <button
              className={`bytn1 ${currentTab === "Kitchen Appliance Repair"
                }`}
              onClick={() => handleTabChange("Kitchen Appliance Repair")}
            >
              Kitchen Appliance Repair
            </button>
            <button
              className={`bytn1 ${currentTab === "Mobile & Tablet Repair"
                }`}
              onClick={() => handleTabChange("Mobile & Tablet Repair")}
            >
              Mobile & Tablet Repair
            </button>
            <button
              className={`bytn1 ${currentTab === "Packers and Movers"
                }`}
              onClick={() => handleTabChange("Packers and Movers")}
            >
              Packers and Movers
            </button>
            <button
              className={`bytn1 ${currentTab === "Pest Control"
                }`}
              onClick={() => handleTabChange("Pest Control")}
            >
              Pest Control
            </button>
          </>
        )}
        {category2 === "Bikes" && (
          <>

            <button
              className={`bytn1 ${currentTab === "Bikes" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Bikes")}
            >
              Bikes
            </button>
            <button
              className={`bytn1 ${currentTab === "Spare Parts - Accessories"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Spare Parts - Accessories")}
            >
              Spare Parts - Accessories
            </button>

            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby Ads
            </button>

          </>
        )}
        {category2 === "Cars" && (
          <>
            <button
              className={`bytn1 ${currentTab === "Cars" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Cars")}
            >
              Cars
            </button>
            <button
              className={`bytn1 ${currentTab === "Spare Parts - Accessories1"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Spare Parts - Accessories1")}
            >
              Spare Parts - Accessories
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby Ads
            </button>
          </>
        )}
        {category2 === "RealEstate" && (
          <>
            <button
              className={`bytn1 ${currentTab === "Buy-Residential" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Buy-Residential")}
            >
              Buy-Residential
            </button>
            <button
              className={`bytn1 ${currentTab === "Rent-Residential" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Rent-Residential")}
            >
              Rent-Residential
            </button>
            <button
              className={`bytn1 ${currentTab === "PG-Hostel" ? "active" : ""
                }`}
              onClick={() => handleTabChange("PG-Hostel")}
            >
              PG-Hostel
            </button>
            <button
              className={`bytn1 ${currentTab === "Buy-Commercial" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Buy-Commercial")}
            >
              Buy-Commercial
            </button>
            <button
              className={`bytn1 ${currentTab === "Rent-Commercial" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Rent-Commercial")}
            >
              Rent-Commercial
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}

        {category2 === "Electronics" && (
          <>
            <button
              className={`bytn1 ${currentTab === "Refrigerators - Fridge"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Refrigerators - Fridge")}
            >
              Refrigerators - Fridge
            </button>
            <button
              className={`bytn1 ${currentTab === "Washing Machines"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Washing Machines")}
            >
              Washing Machines
            </button>
            <button
              className={`bytn1 ${currentTab === "Air Coolers​"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Air Coolers​")}
            >
              Air Coolers
            </button>
            <button
              className={`bytn1 ${currentTab === "Air Conditioners - AC"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Air Conditioners - AC")}
            >
              Air Conditioners - AC
            </button>
            <button
              className={`bytn1 ${currentTab === "Water Heaters - Geysers"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Water Heaters - Geysers")}
            >
              Water Heaters - Geysers
            </button>
            <button
              className={`bytn1 ${currentTab === "Laptops Or (Desktops - Computers)​"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Laptops Or (Desktops - Computers)​")}
            >
              Laptops Or (Desktops - Computers)
            </button>
            <button
              className={`bytn1 ${currentTab === "Monitor Or Printers Or Scanners​"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Monitor Or Printers Or Scanners​")}
            >
              Monitor Or Printers Or Scanners
            </button>
            <button
              className={`bytn1 ${currentTab === "Microwave Ovens​"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Microwave Ovens​")}
            >
              Microwave Ovens
            </button>
            <button
              className={`bytn1 ${currentTab === "Water Purifiers"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Water Purifiers")}
            >
              Water Purifiers
            </button>
            <button
              className={`bytn1 ${currentTab === "Air Coolers​"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Mixer - Grinder - Juicer")}
            >
              Mixer - Grinder - Juicer
            </button>
            <button
              className={`bytn1 ${currentTab === "Mixer - Grinder - Juicer"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Induction Cook Tops")}
            >
              Induction Cook Tops
            </button>
            <button
              className={`bytn1 ${currentTab === "Induction Cook Tops"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Gas Stove")}
            >
              Gas Stove
            </button>
          </>
        )}

        {category2 === "Furniture" && (
          <>
            <button
              className={`bytn1 ${currentTab === "Furniture For Home & Office"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Furniture For Home & Office")}
            >
              Furniture For Home & Office
            </button>
            <button
              className={`bytn1 ${currentTab === "Home Decor - Furnishings"
                ? "active"
                : ""
                }`}
              onClick={() => handleTabChange("Home Decor - Furnishings")}
            >
              Home Decor - Furnishings
            </button>
            <button
              className={`bytn1 ${currentTab === "Household" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Household")}
            >
              Household
            </button>
            <button
              className={`bytn1 ${currentTab === "Kitchenware" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Kitchenware")}
            >
              Kitchenware
            </button>
            <button
              className={`bytn1 ${currentTab === "Painting" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Painting")}
            >
              Painting
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}
        {category2 === "Pets" && (
          <>
            <button
              className={`bytn1 ${currentTab === "Pet Food" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Pet Food")}
            >
              Pet Food
            </button>
            <button
              className={`bytn1 ${currentTab === "Pet Shop" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Pet Shop")}
            >
              Pet Shop
            </button>
            <button
              className={`bytn1 ${currentTab === "Pet Clinics" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Pet Clinics")}
            >
              Pet Clinic's
            </button>
            <button
              className={`bytn1 ${currentTab === "Pet Training & Grooming" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Pet Training & Grooming")}
            >
              Pet Training & Grooming
            </button>
            <button
              className={`bytn1 ${currentTab === "Pet Care - Accessories" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Pet Care - Accessories")}
            >
              Pet Care - Accessories
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}
        {category2 === "Mobiles" && (
          <>
            <button
              className={`bytn1 ${currentTab === "Mobile Phones" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Mobile Phones")}
            >
              Mobile Phones
            </button>
            <button
              className={`bytn1 ${currentTab === "Tablets" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Tablets")}
            >
              Tablets
            </button>
            <button
              className={`bytn1 ${currentTab === "Accessories" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Accessories")}
            >
              Accessories
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}
        {category2 === "Jobs" && (
          <>
            <button
              className={`bytn1 ${currentTab === "ContractJobs" ? "active" : ""
                }`}
              onClick={() => handleTabChange("ContractJobs")}
            >
              Contract Jobs
            </button>
            <button
              className={`bytn1 ${currentTab === "FullTime" ? "active" : ""
                }`}
              onClick={() => handleTabChange("FullTime")}
            >
              Full Time
            </button>
            <button
              className={`bytn1 ${currentTab === "Walk-In" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Walk-In")}
            >
              Walk-In
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}
        {category2 === "ScienceAndTech" && (
          <>
            <button
              className={`bytn1 ${currentTab === "ArtificialIntelligence" ? "active" : ""
                }`}
              onClick={() => handleTabChange("ArtificialIntelligence")}
            >
              Artificial Intelligence
            </button>
            <button
              className={`bytn1 ${currentTab === "SpaceExploration" ? "active" : ""
                }`}
              onClick={() => handleTabChange("SpaceExploration")}
            >
              Space Exploration
            </button>
            <button
              className={`bytn1 ${currentTab === "EnvironmentalScience" ? "active" : ""
                }`}
              onClick={() => handleTabChange("EnvironmentalScience")}
            >
              Environmental Science
            </button>
            <button
              className={`bytn1 ${currentTab === "Robotics" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Robotics")}
            >
              Robotics
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}{category2 === "TravelAndAdventure" && (
          <>
            <button
              className={`bytn1 ${currentTab === "CampingGear" ? "active" : ""
                }`}
              onClick={() => handleTabChange("CampingGear")}
            >
              Camping Gear
            </button>
            <button
              className={`bytn1 ${currentTab === "TravelAccessories" ? "active" : ""
                }`}
              onClick={() => handleTabChange("TravelAccessories")}
            >
              Travel Accessories
            </button>
            <button
              className={`bytn1 ${currentTab === "MapsGuides" ? "active" : ""
                }`}
              onClick={() => handleTabChange("MapsGuides")}
            >
              Maps & Guides
            </button>
            <button
              className={`bytn1 ${currentTab === "AdventureSportsEquipment" ? "active" : ""
                }`}
              onClick={() => handleTabChange("AdventureSportsEquipment")}
            >
              Adventure Sports Equipment
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}{category2 === "TechGadgets" && (
          <>
            <button
              className={`bytn1 ${currentTab === "Smartphones" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Smartphones")}
            >
              Smartphones
            </button>
            <button
              className={`bytn1 ${currentTab === "TabletsE-Readers" ? "active" : ""
                }`}
              onClick={() => handleTabChange("TabletsE-Readers")}
            >
              Tablets & e-Readers
            </button>
            <button
              className={`bytn1 ${currentTab === "Laptops" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Laptops")}
            >
              Laptops
            </button>
            <button
              className={`bytn1 ${currentTab === "Smartwatches" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Smartwatches")}
            >
              Smartwatches
            </button>
            <button
              className={`bytn1 ${currentTab === "GamingConsoles" ? "active" : ""
                }`}
              onClick={() => handleTabChange("GamingConsoles")}
            >
              Gaming Consoles
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}{category2 === "ChildrenBooks" && (
          <>
            <button
              className={`bytn1 ${currentTab === "PictureBooks" ? "active" : ""
                }`}
              onClick={() => handleTabChange("PictureBooks")}
            >
              Picture Books
            </button>
            <button
              className={`bytn1 ${currentTab === "ChapterBooks" ? "active" : ""
                }`}
              onClick={() => handleTabChange("ChapterBooks")}
            >
              Chapter Books
            </button>
            <button
              className={`bytn1 ${currentTab === "EducationalStories" ? "active" : ""
                }`}
              onClick={() => handleTabChange("EducationalStories")}
            >
              Educational Stories
            </button>
            <button
              className={`bytn1 ${currentTab === "ActivityBooks" ? "active" : ""
                }`}
              onClick={() => handleTabChange("ActivityBooks")}
            >
              Activity Books
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}{category2 === "HealthAndBeauty" && (
          <>
            <button
              className={`bytn1 ${currentTab === "Skincare Products" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Skincare Products")}
            >
              Skincare Products
            </button>
            <button
              className={`bytn1 ${currentTab === "Haircare Products" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Haircare Products")}
            >
              Haircare Products
            </button>
            <button
              className={`bytn1 ${currentTab === "Makeup & Cosmetics" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Makeup & Cosmetics")}
            >
              Makeup & Cosmetics
            </button>
            <button
              className={`bytn1 ${currentTab === "Personal Care Appliances (Trimmers, Hair Dryers)" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Personal Care Appliances (Trimmers, Hair Dryers)")}
            >
              Personal Care Appliances
            </button>
            <button
              className={`bytn1 ${currentTab === "Fitness Equipment (Yoga Mats, Weights)" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Fitness Equipment (Yoga Mats, Weights)")}
            >
              Fitness Equipment
            </button>
            <button
              className={`bytn1 ${currentTab === "Supplements & Vitamins" ? "active" : ""
                }`}
              onClick={() => handleTabChange("Supplements & Vitamins")}
            >
              Supplements & Vitamins
            </button>
            <button
              className={`bytn1 ${currentTab === "nearby" ? "active" : ""
                }`}
              onClick={() => handleTabChange("nearby")}
            >
              Nearby
            </button>
          </>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
        {/* Add the search bar */}
        <Input.Search
          placeholder="Search by category, title, description, extraField, price, tags..."
          onSearch={handleSearch}
          enterButton
          className="input-search-allcat"
        />
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {/* Add the dropdown menu */}
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{ border: 'none' }} className="bytn1">
              {sortBy === 'default' ? 'Default' : 'Sort by'} <BsArrowDownUp />
            </Button>
          </Dropdown>

          <FilterBy />
        </div>
      </div>
      {ads.length === 0 && !loading &&
        <NoDataFound />
      }
      <section className="inner-section ad-list-part mb-2" id="scroller">
        <div className="mx-2">
          <div className="row content-reverse">
            <div className="col-lg-12 col-md-12 col-xl-12">
              <div className="row">
                {/* {error && <div className="alert alert-warning">{error}</div>} */}
                {/* {loading && <Spiner />} */}

                <div className="ads-container">
                  <div className="card-grid">
                    {ads.slice(0, cardCount).map((ad, index) => (
                      <>
                        {index === 9 && <Pop />}{" "}
                        {/* Render the offer card */}
                        {index === 27 && <Pop2 />}{" "}
                        {index === 36 && <Pop />}{" "}
                        {/* Render the offer card */}
                        {index === 50 && <Pop2 />}{" "}
                        <div
                          key={ad.pk}
                          className="ad-card"
                          onClick={() =>
                            navigate(
                              `/ads-listing/${ad?.pk === undefined ? ad?.pk : ad.pk
                              }/`
                            )
                          }
                        >
                          <div className="glassmorphism">
                            <div>
                              <div className="ad-image">
                                <div className="wtrmrk">Hola9.com</div>
                                <img
                                  className="imghover-scale"
                                  src={
                                    !ad?.fields?.image
                                      ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                                      : ad.fields?.image
                                  }
                                  alt="ad"
                                  onError={(e) => {
                                    e.target.src =
                                      "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
                                    e.onerror = null;
                                  }}
                                />
                              </div>
                              <div className="product-type">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span className="flat-badge booking">
                                    <b>
                                      <span
                                        className="flat-badge booking "
                                        style={{
                                          background:
                                            ad?.fields?.plan === "Silver"
                                              ? "#cce6ff"
                                              : ad?.fields?.plan === "Gold"
                                                ? "gold"
                                                : ad?.fields?.plan === "Platinum"
                                                  ? "green"
                                                  : ad?.fields?.plan === "premium"
                                                    ? "linear-gradient(to right, #800080, #8A2BE2)"
                                                    : ad?.fields?.plan === "featured"
                                                      ? "linear-gradient(to right, #090979, #00d4ff)"
                                                      : // : ad?.fields?.plan === "Free"
                                                      // ? "green"
                                                      "transparent",
                                          color:
                                            ad?.fields?.plan === "Silver"
                                              ? "blue"
                                              : ad?.fields?.plan === "Gold"
                                                ? "black"
                                                : ad?.fields?.plan === "Platinum"
                                                  ? "white"
                                                  : ad?.fields?.plan === "premium"
                                                    ? "white"
                                                    : ad?.fields?.plan === "featured"
                                                      ? "white"
                                                      : // : ad?.fields?.plan === "Free"
                                                      // ? "white"
                                                      "transparent",
                                          padding: "5px",

                                          // backgroundColor: "white",
                                          // float:"left",
                                          // border: "2px solid black",
                                        }}
                                      >
                                        {ad?.fields?.plan}
                                      </span>
                                    </b>
                                  </span>
                                  <img
                                    src={verified}
                                    width={22}
                                    height={22}
                                    style={{
                                      marginRight: "-10px",
                                      borderRadius: "50%",
                                    }} />
                                </div>
                              </div>
                              <ul className="viewsub">
                                <li className="view">
                                  <i
                                    className="fas fa-eye"
                                    style={{ color: "white" }}
                                  />
                                  <span style={{ color: "white" }}>
                                    {ad?.fields?.viewsproduct}
                                  </span>
                                </li>
                                {/* <li className="click">
                                  <i className="fas fa-mouse" />
                                  <span>134</span>
                                </li> */}
                                <li className="rating">
                                  <i
                                    className=""
                                    style={{ color: "white" }}
                                  />
                                  <span style={{ color: "white" }}>
                                    <BsNewspaper />
                                    &nbsp;{" "}
                                    {ad?.fields?.subCategoryValue?.slice(
                                      0,
                                      12
                                    ) + "..."}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="ad-details  m-0 p-0">
                              {/* DESKTOP VIEW */}
                              <p className="titlefont hidden-sm hidden-xs  m-0 p-0">
                                <Tooltip
                                  placement="topLeft"
                                  title={ad?.fields?.title}
                                >
                                  {ad?.fields?.title?.length > 17 ? (
                                    <p className="titlefont">
                                      {ad?.fields?.title?.slice(0, 17)}
                                      {ad?.fields?.title?.length > 17
                                        ? "..."
                                        : null}
                                    </p>
                                  ) : (
                                    <div>
                                      <p className="titlefont">
                                        <DynamicFont
                                          content={ad?.fields?.title}
                                        />
                                      </p>
                                    </div>
                                  )}
                                </Tooltip>
                              </p>
                              {/* DESKTOP VIEW */}

                              {/* MOBILE VIEW */}
                              <p className="titlefont d-lg-none">
                                <Tooltip
                                  placement="topLeft"
                                  title={ad.fields?.title}
                                >
                                  {ad?.fields?.title?.length > 12 ? (
                                    <p className="titlefont">
                                      {ad?.fields?.title?.slice(0, 12)}
                                      {ad?.fields?.title?.length > 12
                                        ? "..."
                                        : null}
                                    </p>
                                  ) : (
                                    <div style={{}}>
                                      <p className="titlefont">
                                        <DynamicFont
                                          content={ad?.fields?.title}
                                        />
                                      </p>
                                    </div>
                                  )}
                                </Tooltip>
                              </p>
                              {/* MOBILE VIEW */}
                              <div
                                className="condiv justify-content-between "
                                style={{ marginTop: "-15px", alignItems: 'center', marginBottom: '6px', gap: '10px' }}
                              >
                                <div className="widhdiv d-flex justify-content-between m-0 p-0  mob-v-space">
                                  <p
                                    className="fs-10 m-0 p-0 mt-1"
                                    style={{
                                      color: "#062544",
                                      fontSize: "90%",
                                      fontFamily: "sans-serif",
                                    }}
                                  >
                                    <BsGeoAltFill
                                      className="mr-1 "
                                      style={{ color: "red" }}
                                    />
                                    <b>{ad?.fields?.City}</b>
                                  </p>
                                  <BsFillBookmarkFill
                                    id="wishlibyn"
                                    style={{
                                      fontSize: "20px",
                                      marginTop: "6px",
                                    }}
                                    className={
                                      wishlistData?.data?.indexOf(ad.pk) !==
                                        -1
                                        ? "removemob"
                                        : "heartmob"
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      addingWishlist(ad.pk);
                                    }}
                                  />
                                </div>
                                <div className="mob-v-space">
                                  {ad?.fields?.category == "RealEstate" ? (
                                    <p
                                      className="conpir fs-14"
                                      style={{
                                        color: "gray",
                                        fontSize: "90%",
                                        fontFamily: "sans-serif",
                                        fontWeight: "bold",
                                        margin: '0',
                                        transform: 'unset'
                                      }}
                                    >
                                      <BsBuilding
                                        className="mr-2"
                                        style={{
                                          color: "green",
                                          fontSize: "90%",
                                        }}
                                      />
                                      {ad?.fields?.subCategoryType?.slice(0, 6)}
                                      {ad?.fields?.subCategoryType?.length > 6
                                        ? "..."
                                        : null}
                                    </p>
                                  ) : (
                                    <p
                                      className="conpir fs-14"
                                      style={{

                                        fontSize: "90%",
                                        fontFamily: "sans-serif",
                                        margin: '0',
                                        transform: 'unset'

                                      }}
                                    >
                                      <BsFillCheckSquareFill
                                        className="mr-1"
                                        style={{
                                          color: "green",
                                          fontSize: "90%",
                                        }}
                                      />
                                      {ad?.fields?.condition?.slice(0, 9)}
                                    </p>
                                  )}
                                  <span className="mob-star">
                                    <i className="fa fa-star" style={{ color: 'gold' }}> <span style={{ color: 'gray' }}>4.5</span></i>
                                  </span>
                                </div>
                              </div>
                              <div className="price-bot-mob">
                                {ad?.fields?.category != "Jobs" ?
                                  (
                                    <p
                                      className="ad-price mr-1"
                                      style={{
                                        fontSize: "90%",
                                        fontFamily: "verdana",
                                        // marginTop: "-15px",
                                        margin: '0'
                                      }}
                                    >
                                      &nbsp;₹ <b>{ad?.fields?.price}</b>
                                    </p>) : (<>
                                      {ad?.fields?.extraField && (
                                        <p className="ad-price mr-1"
                                          style={{
                                            fontSize: "90%",
                                            fontFamily: "verdana",
                                            //  marginTop: "-15px",
                                          }}>

                                          &nbsp;<GiMoneyStack /><b>{(JSON?.parse(ad?.fields?.extraField)["MaxSalery"])}</b>
                                        </p>
                                      )}
                                    </>)}
                                <span className="desk-star">
                                  <i className="fa fa-star" style={{ color: 'gold' }}> <span style={{ color: 'gray' }}>4.5</span></i>
                                </span>
                              </div>
                              <button
                                id="wishbbt"
                                style={{ marginTop: "-15px" }}
                                className={
                                  wishlistData?.data?.indexOf(ad.pk) !== -1
                                    ? "remove-button"
                                    : "wishlist-button"
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  addingWishlist(ad.pk);
                                }}
                              >
                                {wishlistData?.data?.indexOf(ad.pk) !== -1 ? (
                                  <>
                                    <AiFillDelete />
                                    &nbsp;&nbsp;Remove Wishlist
                                  </>
                                ) : (
                                  <>
                                    <BsFillBookmarkFill />
                                    &nbsp;&nbsp;Add To Wishlist
                                  </>
                                )}
                                {/* 
               {showPopup && (
                    <div className="popup-message">
                   <p>Hello hola sk</p>
                  <button onClick={() => setShowPopup(false)}>Close</button>
              </div>
             )} */}

                              </button>

                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  </div></div></div>
            </div></div></div></section>

      {cardCount > ads.length && (
        <>
          {loading && <p className="loading-message"> <YoutubeMagic1 /> </p>}
        </>
      )}
      {cardCount < ads.length && (
        <div
          className="loadbt text-center"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          <button className="loadmore" onClick={loadMoreCards}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default Allcatads;
