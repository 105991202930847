import { useDispatch } from "react-redux";
import { Col, Row, Tooltip, Modal } from "antd";
import { Button, Dropdown, Menu } from "antd";
import "antd/dist/antd.css";
import React, { useState, useEffect, useContext, useRef } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import "./homeads.css";
import { url } from "../../env";
import holawter from "../../images/Hola9logo.jpg";
import { isMobile } from "react-device-detect";
import DynamicFont from "react-dynamic-font";
import { AiFillDelete } from "react-icons/ai";
import {
  BsFillCheckSquareFill,
  BsGeoAltFill,
  BsBuilding,
  BsNewspaper,
  BsFillCartPlusFill,
  BsHeart,
  BsHeartFill,
  BsFillBookmarkFill,
  BsArrowDownUp,
} from "react-icons/bs";
// import BiSolidSortAlt from "react-icons/bi"
import { remove, add1 } from "../../../store/wishlistSlice";
import { useSelector } from "react-redux";
import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";
import { Input, Space } from "antd";
import { Pop2, Pop } from "../../Base/Pop";
import { useInterval } from "react-interval-hook";
import { FilterBy } from "../../../FilterBy";
import NoDataFound from "../../datanotfound/NoDataFound";
import { Money } from "@mui/icons-material";
import { GiMoneyStack } from "react-icons/gi";
import verified from "./verified.jpg";

const AdList = () => {
  const intervalRef = useRef();
  const [ads, setAds] = useState([]);
  const [limit, setLimit] = useState(24);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("all"); // Default to 'all' tab
  const [sortBy, setSortBy] = useState("default"); // Default to 'default' sort
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const wishlistData = useSelector((state) => state.wishlistSlice);
  // const searchAds = useSelector((state) => state.searchAds);
  const { data } = useSelector((state) => state.sortFilter);
  const cityname = localStorage.getItem("selectedCity");
  const navigate = useNavigate();
  const [callLoadMore, setCallLoadMore] = useState(true);
  const [apiError, setApiError] = useState(false);

  const [canLoadMore, setCanLoadMore] = useState(true);
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
  const [resumeLoading, setResumeLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setApiError(false);
        // Define parameters based on the current tab, sort, and search query
        let params = { limit: limit, search_key: searchQuery, is_active: true };

        if (currentTab === "business") {
          params.businessplan = "True";
        } else if (currentTab === "nearby") {
          params.city = cityname; // Update with the desired city or fetch from user's location
        }
        // Add sorting logic
        if (sortBy === "minPriceToMaxPrice") {
          params.sorting_order = "min_to_max";
        } else if (sortBy === "maxPriceToMinPrice") {
          params.sorting_order = " max_to_min";
        } else if (sortBy === "recentlyAdded") {
          params.sorting_order = "recently";
        } else if (sortBy === "older") {
          params.sorting_order = "older";
        } else if (sortBy === "default") {
          params.sorting_order = "default";
        }

        const response = await fetch(
          "https://demoadmin.hola9.com/api/adsapi/allAdsByInerval",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
          }
        );
        if (response.status === 503) {
          console.error("Service Unavailable (503)");
          setApiError(true);
          setCanLoadMore(false); // Disable further load more attempts
          clearInterval(intervalRef.current);
          return;
        }

        const data = await response.json();

        // Check if the response has no ads and city is Bengaluru with limit 5
        if (
          data.length === 0 &&
          params.city === cityname &&
          params.limit === 20
        ) {
          setAds([]);
        } else {
          setAds(data);
          setCallLoadMore(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [limit, currentTab, sortBy, searchQuery, cityname]);
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(async () => {
      if (
        !loading &&
        callLoadMore &&
        ads.length > 0 &&
        limitIncreaseCount != 5
      ) {
        const initialAdsLength = ads.length;
        if (initialAdsLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(initialAdsLength);
        } else {
          clearInterval(intervalRef.current); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);
    // Wait for the next API call to complete

    // Check if the length of ads has increased after the API call

    return () => clearInterval(intervalRef.current);
  }, [loading, callLoadMore, currentTab, ads.length, limitIncreaseCount]);
  const addingWishlist = (value, e) => {
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
    }
    if (wishlistData?.data?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add1({ value: value }));
    }

    console.log("wishlist value", value, wishlistData);
  };

  const handleLoadMore = () => {
    if (!canLoadMore) {
      return; // Skip if further load more attempts are disabled or the limit has been increased 3 times
    }

    setLoading(true);
    setLimit((prevLimit) => prevLimit + 24);
    setLimitIncreaseCount((prevCount) => prevCount + 1);
  };
  const [cardCount, setCardCount] = useState(22);

  const loadMoreCards = () => {
    // Increment the card count by 30.
    setCardCount(cardCount + 20);
    setLimitIncreaseCount(0);
  };

  const handleTabChange = (tab) => {
    setAds([]);
    setCurrentTab(tab);
    setLimit(21); // Reset limit when changing tabs
  };
  // const checkAndUpdateCity = (city) => {
  //   if (city !== cityname) {
  //     // If the stored city name is different, call handleTabChange
  //     handleTabChange("nearby"); // You may need to replace defaultTab with your default tab value
  //     localStorage.setItem("selectedCity", city); // Update the stored city name
  //   }
  // };

  // // Call checkAndUpdateCity with the current city name
  // checkAndUpdateCity(cityname);
  const handleSortChange = (key) => {
    setAds([]);
    setSortBy(key);
    setLimit(21); // Reset limit when changing sorting
  };

  const handleSearch = (value) => {
    setAds([]);
    setSearchQuery(value);
    setLimit(21); // Reset limit when performing a search
  };

  const menu = (
    <Menu onClick={({ key }) => handleSortChange(key)}>
      <Menu.Item key="default">Default</Menu.Item>
      <Menu.Item key="minPriceToMaxPrice">Min Price to Max Price</Menu.Item>
      <Menu.Item key="maxPriceToMinPrice">Max Price to Min Price</Menu.Item>
      <Menu.Item key="recentlyAdded">Recently Added Ads</Menu.Item>
      <Menu.Item key="older">Previously Added Ads</Menu.Item>
    </Menu>
  );

  return (
    <div className="ad-list-container">
      {/* Add tab buttons */}
      <div className="header-container">
        <div className="tab-buttons h-tab-buttons">
          <button
            onClick={() => handleTabChange("all")}
            className={`bytn1 ${currentTab === "all" ? "active" : ""}`}
          >
            All Ads
          </button>
          <button
            onClick={() => handleTabChange("business")}
            className={`bytn1 ${currentTab === "business" ? "active" : ""}`}
          >
            Business Ads
          </button>
          <button
            onClick={() => handleTabChange("nearby")}
            className={`bytn1 ${currentTab === "nearby" ? "active" : ""}`}
          >
            Nearby Ads
          </button>
        </div>

        {/* Add the search bar */}
        <Input.Search
          placeholder="Search by category, title, description, extraField, price, tags..."
          onSearch={handleSearch}
          enterButton
          className="h-bytn1"
          style={{ width: 400, height: 30, margin: "10px 10px 10px 0" }}
        />

        {/* Add the dropdown menu */}
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button style={{ border: "none" }} className="bytn1 h-bytn1">
            {sortBy === "default" ? "Default" : "Sort by"} <BsArrowDownUp />
          </Button>
        </Dropdown>

        <FilterBy />
      </div>
      <br />
      <br />
      {ads.length === 0 && !loading && (
        <p className="no-ads-message">
          <NoDataFound />{" "}
        </p>
      )}
      <section className="inner-section ad-list-part mb-2" id="scroller">
        <div className="mx-2">
          <div className="row content-reverse">
            <div className="col-lg-12 col-md-12 col-xl-12">
              <div className="row">
                {/* {error && <div className="alert alert-warning">{error}</div>} */}
                {/* {loading && <Spiner />} */}
                {/* currentPageData?.length > 0 ? (  insted of ad.is_active line*/}

                <div className="ads-container">
                  <div className="card-grid">
                    {ads.slice(0, cardCount).map((ad, index) => (
                      <>
                        {index === 9 && <Pop />} {/* Render the offer card */}
                        {index === 27 && <Pop2 />}{" "}
                        <div
                          key={ad.pk}
                          className="ad-card"
                          onClick={() =>
                            navigate(
                              `/ads-listing/${ad?.pk === undefined ? ad?.pk : ad.pk
                              }/`
                            )
                          }
                        >
                          <div className="glassmorphism">
                            <div>
                              <div className="ad-image">
                                <div className="wtrmrk">Hola9.com</div>
                                <img
                                  className="imghover-scale"
                                  src={
                                    !ad?.fields?.image
                                      ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                                      : ad.fields?.image
                                  }
                                  alt="ad"
                                  onError={(e) => {
                                    e.target.src =
                                      "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
                                    e.onerror = null;
                                  }}
                                />
                              </div>
                              <div className="product-type">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    className="flat-badge booking"
                                  // style={{ padding: "0" }}
                                  >
                                    <b>
                                      <span
                                        className="flat-badge booking "
                                        style={{
                                          background:
                                            ad?.fields?.plan === "Silver"
                                              ? "#cce6ff"
                                              : ad?.fields?.plan === "Gold"
                                                ? "gold"
                                                : ad?.fields?.plan === "Platinum"
                                                  ? "green"
                                                  : ad?.fields?.plan === "premium"
                                                    ? "linear-gradient(to right, #800080, #8A2BE2)"
                                                    : ad?.fields?.plan === "featured"
                                                      ? "linear-gradient(to right, #090979, #00d4ff)"
                                                      : // : ad?.fields?.plan === "Free"
                                                      // ? "green"
                                                      "transparent",
                                          color:
                                            ad?.fields?.plan === "Silver"
                                              ? "blue"
                                              : ad?.fields?.plan === "Gold"
                                                ? "black"
                                                : ad?.fields?.plan === "Platinum"
                                                  ? "white"
                                                  : ad?.fields?.plan === "premium"
                                                    ? "white"
                                                    : ad?.fields?.plan === "featured"
                                                      ? "white"
                                                      : // : ad?.fields?.plan === "Free"
                                                      // ? "white"
                                                      "transparent",
                                          padding: "5px",

                                          // backgroundColor: "white",
                                          // float:"left",
                                          // border: "2px solid black",
                                        }}
                                      >
                                        {ad?.fields?.plan}
                                      </span>
                                    </b>
                                  </span>
                                  {/* {ad?.fields?.plan === "Silver" ||
                                  ad?.fields?.plan === "Gold" ||
                                  ad?.fields?.plan === "Platinum" ||
                                  ad?.fields?.plan === "premium" ? (
                                    <img
                                      src={verified}
                                      width={22}
                                      height={22}
                                      style={{
                                        marginRight: "-10px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  ) : ad?.fields?.plan === "featured" ? (
                                    "transparent"
                                  ) : null} */}
                                  <img
                                    src={verified}
                                    width={22}
                                    height={22}
                                    style={{
                                      marginRight: "-10px",
                                      borderRadius: "50%",
                                    }} />
                                </div>
                              </div>
                              <ul className="viewsub">
                                <li className="view">
                                  <i
                                    className="fas fa-eye"
                                    style={{ color: "white" }}
                                  />
                                  <span style={{ color: "white" }}>
                                    {ad?.fields?.viewsproduct}
                                  </span>
                                </li>
                                {/* <li className="click">
                                  <i className="fas fa-mouse" />
                                  <span>134</span>
                                </li> */}
                                <li className="rating">
                                  <i className="" style={{ color: "white" }} />
                                  <span style={{ color: "white" }}>
                                    <BsNewspaper />
                                    &nbsp;{" "}
                                    {ad?.fields?.subCategoryValue?.slice(
                                      0,
                                      12
                                    ) + "..."}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="ad-details  m-0 p-0">
                              {/* DESKTOP VIEW */}
                              <p className="titlefont hidden-sm hidden-xs  m-0 p-0">
                                <Tooltip
                                  placement="topLeft"
                                  title={ad?.fields?.title}
                                >
                                  {ad?.fields?.title?.length > 17 ? (
                                    <p className="titlefont">
                                      {ad?.fields?.title?.slice(0, 17)}
                                      {ad?.fields?.title?.length > 17
                                        ? "..."
                                        : null}
                                    </p>
                                  ) : (
                                    <div>
                                      <p className="titlefont">
                                        <DynamicFont
                                          content={ad?.fields?.title}
                                        />
                                      </p>
                                    </div>
                                  )}
                                </Tooltip>
                              </p>
                              {/* DESKTOP VIEW */}

                              {/* MOBILE VIEW */}
                              <p className="titlefont d-lg-none">
                                <Tooltip
                                  placement="topLeft"
                                  title={ad.fields?.title}
                                >
                                  {ad?.fields?.title?.length > 12 ? (
                                    <p className="titlefont">
                                      {ad?.fields?.title?.slice(0, 12)}
                                      {ad?.fields?.title?.length > 12
                                        ? "..."
                                        : null}
                                    </p>
                                  ) : (
                                    <div style={{}}>
                                      <p className="titlefont">
                                        <DynamicFont
                                          content={ad?.fields?.title}
                                        />
                                      </p>
                                    </div>
                                  )}
                                </Tooltip>
                              </p>
                              {/* MOBILE VIEW */}
                              <div
                                className="condiv justify-content-between "
                                style={{ marginTop: "-15px", alignItems: 'center', marginBottom: '6px' }}
                              >
                                <div className="widhdiv d-flex justify-content-between m-0 p-0  mob-v-space">
                                  <p
                                    className="fs-10 m-0 p-0 mt-1"
                                    style={{
                                      color: "#062544",
                                      fontSize: "90%",
                                      fontFamily: "sans-serif",
                                    }}
                                  >
                                    <BsGeoAltFill
                                      className="mr-1 "
                                      style={{ color: "red" }}
                                    />
                                    <b>{ad?.fields?.City}</b>
                                  </p>
                                  <BsFillBookmarkFill
                                    id="wishlibyn"
                                    style={{
                                      fontSize: "20px",
                                      marginTop: "6px",
                                    }}
                                    className={
                                      wishlistData?.data?.indexOf(ad.pk) !== -1
                                        ? "removemob"
                                        : "heartmob"
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      addingWishlist(ad.pk);
                                    }}
                                  />
                                </div>
                                <div className="mob-v-space">
                                  {ad?.fields?.category == "RealEstate" ? (
                                    <p
                                      className="conpir fs-14"
                                      style={{
                                        color: "gray",
                                        fontSize: "90%",
                                        fontFamily: "sans-serif",
                                        fontWeight: "bold",
                                        margin: '0',
                                        transform: 'unset'
                                      }}
                                    >
                                      <BsBuilding
                                        className="mr-2"
                                        style={{
                                          color: "green",
                                          fontSize: "90%",
                                        }}
                                      />
                                      {ad?.fields?.subCategoryType?.slice(0, 6)}
                                      {ad?.fields?.subCategoryType?.length > 6
                                        ? "..."
                                        : null}
                                    </p>
                                  ) : (
                                    <p
                                      className="conpir fs-14"
                                      style={{
                                        fontSize: "90%",
                                        fontFamily: "sans-serif",
                                        margin: '0',
                                        transform: 'unset'
                                      }}
                                    >
                                      <BsFillCheckSquareFill
                                        className="mr-1"
                                        style={{
                                          color: "green",
                                          fontSize: "90%",
                                        }}
                                      />
                                      {ad?.fields?.condition?.slice(0, 9)}
                                    </p>
                                  )}
                                  <span className="mob-star">
                                    <i className="fa fa-star" style={{ color: 'gold' }}> <span style={{ color: 'gray' }}>4.5</span></i>
                                  </span>
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                                {ad?.fields?.category != "Jobs" ? (
                                  <p
                                    className="ad-price mr-1"
                                    style={{
                                      fontSize: "90%",
                                      fontFamily: "verdana",
                                      margin: "0",
                                    }}
                                  >
                                    &nbsp;₹ <b>{ad?.fields?.price}</b>
                                  </p>
                                ) : (
                                  <>
                                    {ad?.fields?.extraField && (
                                      <p
                                        className="ad-price mr-1"
                                        style={{
                                          fontSize: "90%",
                                          fontFamily: "verdana",
                                          margin: "0",
                                        }}
                                      >
                                        &nbsp;
                                        <GiMoneyStack />
                                        <b>
                                          {
                                            JSON?.parse(ad?.fields?.extraField)[
                                            "MaxSalery"
                                            ]
                                          }
                                        </b>
                                      </p>
                                    )}
                                  </>
                                )}
                                <span className="desk-star">
                                  <i className="fa fa-star" style={{ color: 'gold' }}> <span style={{ color: 'gray' }}>4.5</span></i>
                                </span>
                              </div>
                              <button
                                id="wishbbt"
                                style={{ marginTop: "-15px" }}
                                className={
                                  wishlistData?.data?.indexOf(ad.pk) !== -1
                                    ? "remove-button"
                                    : "wishlist-button"
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  addingWishlist(ad.pk);
                                }}
                              >
                                {wishlistData?.data?.indexOf(ad.pk) !== -1 ? (
                                  <>
                                    <AiFillDelete />
                                    &nbsp;&nbsp;Remove Wishlist
                                  </>
                                ) : (
                                  <>
                                    <BsFillBookmarkFill />
                                    &nbsp;&nbsp;Add To Wishlist
                                  </>
                                )}
                                {/* 
               {showPopup && (
                    <div className="popup-message">
                   <p>Hello hola sk</p>
                  <button onClick={() => setShowPopup(false)}>Close</button>
              </div>
             )} */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {cardCount > ads.length && (
        <>
          {loading && (
            <p className="loading-message">
              {" "}
              <YoutubeMagic1 className='youtubemagic-homeads' />{" "}
            </p>
          )}
        </>
      )}
      {cardCount < ads.length && (
        <div
          className="loadbt text-center"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          <button className="loadmore" onClick={loadMoreCards}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default AdList;
