import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SearchOutlined } from "@ant-design/icons";
import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
import MyGallery from "./MyGallery";
import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import "../searchBox/SearchBox.css";
import { Link } from "react-router-dom";
import "./allfilter.css";
import { isMobile } from "react-device-detect";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import Allcatads from "../../../Allcatads";
import { BiSearch } from "react-icons/bi";
import { AiOutlineCloseSquare, AiOutlineFilter } from "react-icons/ai";
import "../../RealEstate/Allfilter/TechGadgetsCat.css";
import SearchBoxTechGadgets from "../searchBox/SearchBoxTechGadgets.js";
import TechGadgetsSlider from "../../../../Shared/CategorySlider/TechGadgetsSlider";
import ChildrenBooksSlider from "../../../../Shared/CategorySlider/ChildrenBooksSlider.jsx";
import SearchBoxChildrenBooks from "../searchBox/SearchBoxChildrenBooks.js";

const mobstyle = {
    width: "auto",
    marginLeft: "25px",
    marginRight: "25px",
};
const deskstyle = {
    padding: "20px",
    marginBottom: "-10px",
    marginRight: "10rem",
    marginLeft: "10rem",
};
const mobi = {
}
const deski = {
    marginLeft: "7rem", marginRight: "7rem"
}
const mobileStyle = {
    width: "80px",
    height: "80px",
    backgroundSize: "cover",
};
const desktopStyle = {
    backgroundSize: "cover",
    paddingTop: "0.5rem",
    width: "150px",
    height: "120px",
};
const mobileview = {
    border: "1px solid gray",
    margin: "0.2rem 5px",
};
const desktopview = {
    border: "2px solid gray",
    width: "10rem",
    height: "11rem",
    marginBottom: "2rem",
    marginLeft: "1rem",
    marginRight: "1rem",

};
const mobilestyle1 = {
    width: "auto",
    flexDirection: "row",
};
const desktopstyle1 = {
    width: "100%",
    flexDirection: "row",
};

const mobilestyle3 = {
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem",
};
const desktopstyle3 = {
    display: "flex",
    flexDirection: "row",
};
const mobilestyle4 = {
    fontSize: "10px",
    textAlign: "center",
    color: "white",
    backgroundColor: "gray",
};
const desktopstyle4 = {
    textAlign: "center",
    color: "white",
    backgroundColor: "gray",
    fontSize: "16px",
};

const mob1 = {
    width: "95%",
    marginLeft: "-60px",
};
const desk1 = {
    borderRadius: "20px",
    backgroundColor: "white",
};

const ChildrenBooksCat = () => {
    const [selectedFilter, setSelectedFilter] = useState(null);

    const [isPopupOpen, setPopupOpen] = useState(false);
    const openPopup = () => {
        setPopupOpen(true);
    };

    const handleFilterClick = () => {
        setPopupOpen(true);
    };
    const handleClosePopup = () => {
        setPopupOpen(false);
    };


    const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'

    const greeting = "ChildrenBooks";

    const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook

    const sliderStyle = {
        width: "100%",
        // marginTop:"2cm",
        // maxWidth: "100vw",
    };
    const disptach = useDispatch();
    const [extraFieldCopy, setExtraFieldCopy] = useState({});
    const [searchSending, setsearchSending] = useState({
        subCategoryValue: "",
        category: "TechGadgets",
    });
    useEffect(() => {
        if (selectedFilter !== null) {
            setsearchSending((prevState) => ({
                ...prevState,
                category: selectedFilter,
            }));
        }
    }, [selectedFilter]);
    useEffect(() => {
        disptach(add({ view: ["ServicesCat"] })); // Dispatching an action using Redux to add the view to the user's track
    }, []);

    // AdsCards logic start

    const [advClose, setadvClose] = useState(true);
    useEffect(() => {
        const isPopupClosed = sessionStorage.getItem('popupClosed');
        const name = localStorage.getItem('name')
        if (isPopupClosed && name) {
            setadvClose(false);
        }
    }, []);
    const handleCloseadv = () => {
        setadvClose(false);
        sessionStorage.setItem('popupClosed', 'true');
    };
    // AdsCards logic end

    const [SearchValue, setSearchValue] = useState("");

    const searchArray = () => {
        if (SearchValue?.trim()) {
            disptach(searchApi(SearchValue));
        }
    };




    return (
        <>


            {/* AdsCards logic start */}
            {advClose ? (
                <div
                    style={{
                        position: "fixed",
                        bottom: "2%",
                        right: "2%",
                        width: "350px",
                        height: "170px",
                        zIndex: "3",
                        border: "0.5px solid gray",
                        background:
                            // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",
                            'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',
                        backgroundSize: "100% 100%",
                        borderRadius: '7px'
                    }}
                >
                    <span style={{ float: 'right', padding: '2px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px', fontSize: "18px", marginRight: '6px' }} onClick={handleCloseadv}>x</span>
                    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>
                        <div style={{ color: 'white' }}>
                            Get the best <br />
                            <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>
                        </div>
                        <div style={{ color: 'whitesmoke', fontWeight: '600' }}>
                            Grow Your Business
                        </div>
                        <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>
                    </div>
                </div>
            ) : null}
            {/* AdsCards logic end */}



            <div>

                {/* For slider image */}
                <ChildrenBooksSlider />

                {/* Search section for science and tech categories */}
                <div>
                    <div className="filmain1-tech">
                        <div className="fildi1-tech">
                            <input
                                className="search-input-tech"
                                name="Brand Name"
                                required
                                style={{
                                    textTransform: "uppercase",
                                }}
                                placeholder="Search For Children's Books"
                                onChange={(e) => setSearchValue(e.target.value.trim())}
                            />
                            <div className="tildiv1-tech">
                                <Link to={`/ads-listing1/`} onClick={searchArray}>
                                    <button className="search-btn1-tech">
                                        <BiSearch style={{ fontSize: "20px" }} />
                                        Search
                                    </button>
                                </Link>
                                &nbsp; &nbsp;
                                <button className="filter-btn1-tech" onClick={handleFilterClick}>
                                    <AiOutlineFilter style={{ fontSize: "20px" }} />
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>

                    {isPopupOpen && (
                        <div className="fildi21-tech" style={isMobile ? mob1 : desk1}>
                            <div>
                                <button onClick={handleClosePopup} style={{ float: "right" }}>
                                    <AiOutlineCloseSquare
                                        style={{ color: "red", fontSize: "20px" }}
                                    />
                                </button>
                                <SearchBoxChildrenBooks />
                            </div>
                        </div>
                    )}
                </div>


                {/* Categories section */}
                <h3
                    style={{
                        textAlign: "center",
                        background: "gray",
                        color: "white",
                        padding: "0.5rem",
                        fontFamily: "verdana",

                    }}
                >
                    Explore the Children's Books Categories
                </h3>

                <div style={isMobile ? mobilestyle3 : desktopstyle3}>

                    <div
                        className="container1"
                        style={isMobile ? mobilestyle1 : desktopstyle1}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex" }} >
                                <div
                                    style={isMobile ? mobileview : desktopview}

                                >
                                    <Link
                                        to={`/ads-listing/`}
                                        onClick={() => {
                                            console.log(searchSending);
                                            for (const key in searchSending) {
                                                if (
                                                    searchSending[key]?.trim() === "" ||
                                                    searchSending[key] === null
                                                ) {
                                                    delete searchSending[key];
                                                }
                                            }
                                            searchSending["extraField"] = { ...extraFieldCopy };
                                            searchSending["subCategoryValue"] = "Smartphones";
                                            disptach(getExtraField(searchSending));
                                        }}
                                    >
                                        <center>
                                            <a >
                                                <img
                                                    alt="imag"
                                                    src="https://i.pinimg.com/originals/09/5c/3a/095c3ae0552597d7434c6a89fb7a09bb.gif"
                                                    style={isMobile ? mobileStyle : desktopStyle}
                                                    className="circleHover-tech"
                                                />
                                                <h5
                                                    className=" mt-3  "
                                                    style={isMobile ? mobilestyle4 : desktopstyle4}
                                                >
                                                    Picture Books
                                                </h5>
                                            </a>
                                        </center>
                                    </Link>
                                </div>

                                <div
                                    style={isMobile ? mobileview : desktopview}

                                >
                                    <Link
                                        to={`/ads-listing/`}
                                        onClick={() => {
                                            console.log(searchSending);
                                            for (const key in searchSending) {
                                                if (
                                                    searchSending[key]?.trim() === "" ||
                                                    searchSending[key] === null
                                                ) {
                                                    delete searchSending[key];
                                                }
                                            }
                                            searchSending["extraField"] = { ...extraFieldCopy };
                                            searchSending["subCategoryValue"] = "Tablets & e-Readers";
                                            disptach(getExtraField(searchSending));
                                        }}
                                    >
                                        <center>
                                            <a>
                                                <img
                                                    alt="imag"
                                                    src="https://static.vecteezy.com/system/resources/previews/048/263/180/non_2x/outline-open-book-with-chapter-and-pictures-cute-concept-of-reading-lovers-kids-learning-and-study-academic-book-literature-encyclopedia-back-to-school-supply-hand-drawn-isolated-doodle-vector.jpg"
                                                    style={isMobile ? mobileStyle : desktopStyle}
                                                    className="circleHover-tech"
                                                />
                                                <h5
                                                    className=" mt-3  "
                                                    style={isMobile ? mobilestyle4 : desktopstyle4}
                                                >
                                                    Chapter Books
                                                </h5>
                                            </a>
                                        </center>
                                    </Link>
                                </div>
                                <div
                                    style={isMobile ? mobileview : desktopview}

                                > <Link
                                    to={`/ads-listing/`}
                                    onClick={() => {
                                        console.log(searchSending);
                                        for (const key in searchSending) {
                                            if (
                                                searchSending[key]?.trim() === "" ||
                                                searchSending[key] === null
                                            ) {
                                                delete searchSending[key];
                                            }
                                        }
                                        searchSending["extraField"] = { ...extraFieldCopy };
                                        searchSending["subCategoryValue"] = "Laptops";
                                        disptach(getExtraField(searchSending));
                                    }}
                                >
                                        <center>
                                            <a>
                                                <img
                                                    alt="imag"
                                                    src="https://easydrawingguides.com/wp-content/uploads/2024/06/429-easy-school-books-coloring-image-1200.png"
                                                    style={isMobile ? mobileStyle : desktopStyle}
                                                    className="circleHover-tech"
                                                // height="80px"
                                                />
                                                <h5
                                                    className=" mt-3  "
                                                    style={isMobile ? mobilestyle4 : desktopstyle4}
                                                >
                                                    Educational Stories
                                                </h5>
                                            </a>
                                        </center>
                                    </Link>
                                </div>
                                <div
                                    style={isMobile ? mobileview : desktopview}

                                >
                                    <Link
                                        to={`/ads-listing/`}
                                        onClick={() => {
                                            console.log(searchSending);
                                            for (const key in searchSending) {
                                                if (
                                                    searchSending[key]?.trim() === "" ||
                                                    searchSending[key] === null
                                                ) {
                                                    delete searchSending[key];
                                                }
                                            }
                                            searchSending["extraField"] = { ...extraFieldCopy };
                                            searchSending["subCategoryValue"] = "Smartwatches";
                                            disptach(getExtraField(searchSending));
                                        }}
                                    >
                                        <center>
                                            <a >
                                                <img
                                                    alt="imag"
                                                    src="https://www.teacharesources.com/wp-content/uploads/2020/04/15965-IMG-2544.jpg"
                                                    style={isMobile ? mobileStyle : desktopStyle}
                                                    className="circleHover-tech"
                                                // height="80px"
                                                />
                                                <h5
                                                    className="mt-3  "
                                                    style={isMobile ? mobilestyle4 : desktopstyle4}
                                                >
                                                    Activity Books
                                                </h5>
                                            </a>
                                        </center>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="wholefil mt-0">
                    {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
                        <div>
                            <h5>
                                {" "}
                                <div className="example-tech">
                                    <Spin />
                                </div>{" "}
                            </h5>{" "}
                        </div>
                    )}
                    {!loading && (
                        <>
                            <div >
                                <Allcatads onClick={openPopup} greeting={greeting} subcategory={"ChildrenBooks"} />
                            </div>
                            <div className="mt-4" style={isMobile ? mobstyle : deskstyle}>
                                <Feature value="ChildrenBooks" />{" "}
                                <div id="PictureBooks">
                                    <Owldemo1
                                        greeting={greeting}
                                        subcategory={"Picture Books"}
                                    />{" "}
                                </div>
                                <div id="ChapterBooks">
                                    <Owldemo1 greeting={greeting} subcategory={"Chapter Books"} />{" "}
                                </div>
                                <div id="EducationalBooks">
                                    <Owldemo1 greeting={greeting} subcategory={"Educational Books"} />{" "}
                                </div>
                                <div id="ActivityBooks">
                                    <Owldemo1 greeting={greeting} subcategory={"Activity Books"} />{" "}
                                </div>
                            </div>
                            <div style={isMobile ? mobi : deski}>
                                <TrendingAds />
                            </div></>
                    )}
                    <NewsArticles selectedCategory={"ChildrenBooks"} />
                    <MyGallery selectedCategory="ChildrenBooks" />
                </div>
            </div>
        </>
    );
};

export default ChildrenBooksCat;
