import React, { useState, useEffect } from 'react';
import { DatePicker } from "antd";
import { Dropdown, Menu, Space } from "antd";
import "./Adminverify.css"
import { FaUser, FaBuilding, FaPhone, FaEnvelope, FaIdCard, FaFileAlt, FaCheck, FaTimes } from 'react-icons/fa';
import YoutubeMagic from '../ContentLoader/YoutubeMagic';

const Hola9Users = () => {
    const [businessData, setBusinessData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);
    const [itemsToLoad, setItemsToLoad] = useState(25);
    const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        fetchData();
    }, [itemsToLoad, startDate, endDate]);

    const fetchData = () => {
        setIsLoading(true);
        fetch(`https://demoadmin.hola9.com/api/userslist?limit=${itemsToLoad}`)
            .then(response => response.json())
            .then(data => {
                let filteredData = data;
                if (startDate && endDate) {
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);
                    filteredData = data.filter(item => {
                        const itemDate = new Date(item.created_at);
                        return (
                            itemDate >= startDateObj && itemDate <= endDateObj
                        )
                    })
                }
                setBusinessData(filteredData);
                setIsLoading(false);
                setIsLoading1(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
                setIsLoading1(false);
            });
    }

    const handleLoadMore = () => {
        setItemsToLoad(itemsToLoad + 25);
        setLimitIncreaseCount(prevCount => prevCount + 1);
    };

    const [prevDataLength, setPrevDataLength] = useState(0);
    const [cardCount, setCardCount] = useState(20);

    const loadMoreCards = () => {
        setCardCount(cardCount + 20);
        setLimitIncreaseCount(0);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isLoading && limitIncreaseCount !== 3) {
                const newDataLength = businessData.length;
                if (newDataLength !== prevDataLength) {
                    handleLoadMore();
                    setPrevDataLength(newDataLength);
                } else {
                    clearInterval(intervalId);
                }
            }
        }, 2000);

        return () => clearInterval(intervalId);
    }, [isLoading, businessData, prevDataLength, limitIncreaseCount]);

    return (
        <>
            <div style={{marginBottom:"20px"}} >
            startDate: <button style={{padding:"2px",border:"1px solid #efefef",marginRight:"12px",borderRadius:"5px"}}><input type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} /></button>
            endDate: <button style={{padding:"2px",border:"1px solid #efefef",borderRadius:"5px"}}><input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} /></button>
            </div>

            {/* <div className="row d-flex">
        <div className="dropdown col-lg-4">
          <span className="col-lg-12 text-center" style={{ fontSize: "10px" }}>
            Start Date :
            <Space direction="vertical" className="mx-3">
              <DatePicker
              value={startDate}
               onChange={(e) => setStartDate(e.target.value)}
              />
            </Space>
          </span>
          
        </div> */}
        {/* <div className="dropdown col-lg-4 d-flex">
          <span className="col-lg-12 text-center" style={{ fontSize: "10px" }}> */}
            {/* Date : {rangetEndDate} */}
            {/* End Date :{" "}
            <Space direction="vertical" className="mx-3">
              <DatePicker
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              />
            </Space>
          </span>

       
        </div>
      </div> */}
            <div className="business-profile-container">
                {isLoading ? (
                    <p><YoutubeMagic /></p>
                ) : (
                    businessData.slice(0, cardCount).map(data => (
                        <BusinessProfileItem key={data.id} data={data} isLoading={isLoading1} />
                    ))
                )}
            </div>
            
            {cardCount > businessData.length && (
                <>
                    {isLoading1 && <p className="loading-message"> <YoutubeMagic /> </p>}
                </>
            )}
            {cardCount < businessData.length && (
                <div className="loadbt text-center" style={{ textAlign: "center", marginTop: "20px" }}>
                    <button className="loadmore" onClick={loadMoreCards}>
                        Load More
                    </button>
                </div>
            )}
        </>
    );
};

const BusinessProfileItem = ({ data, isLoading }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isVerified, setIsVerified] = useState(data.is_verified);
    const [isVerifying, setIsVerifying] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    return (
      <>
      
        <div className={`business-profile-card ${isDeleted ? 'deleted' : ''}`} style={{ border: isVerified ? "1px solid green" : "1px solid red" }}>
            <div className="header">
                <h5 style={{ color: isVerified ? "green" : "red" }}>{data.company_name}</h5>
            </div>
            
            <div className="details">
                <p>Name: {data.name}</p>
                <p>Phone Number: {data.phoneNumber}</p>
                <p>Email: {data.email}</p>
                <hr></hr>
                <p style={{ float: "right" }}>Date: {data.created_at}</p>
            </div>
        </div>
        </>
    );
};

export default Hola9Users;
