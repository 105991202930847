import React, { useState } from "react";
import "../Allfilter/PhotographyCat.css";
import MyGallery from "./MyGallery";
import { Citys } from "../../../Location";
import { Link } from "react-router-dom";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import { useDispatch } from "react-redux";

function PhotographyCat() {
  // const [isOpen, setIsOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [minBudget, setMinBudget] = useState("");
  const [maxBudget, setMaxBudget] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationSearch, setLocationSearch] = useState("");

  const [SearchValue, setSearchValue] = useState("");
  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };

  const dispatch = useDispatch();

  const categories = ["Men", "Women", "Kids", "Camera", "Lens", "Tripod", "Accessories"];

  const ads = [
    {
      title: "black framed sun glass",
      price: "₹ 3,000",
      location: "S.A.S. Nagar, Punjab",
      date: "Nov 14",
      image:
        "https://images.unsplash.com/photo-1618677366787-9727aacca7ea?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bWVucyUyMGFjY2Vzc29yaWVzfGVufDB8fDB8fHww",
      featured: true,
      category: "Men",
    },
    {
      title: "brown leather belt",
      price: "₹ 1,000",
      location: "Jayanagara, Bengaluru",
      date: "Dec 25",
      image:
        "https://images.unsplash.com/photo-1705493655920-20c572928501?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fG1lbnMlMjBhY2Nlc3Nvcnl8ZW58MHx8MHx8fDA%3D",
      featured: false,
      category: "Men",
    },
    {
      title:
        "A Luxury Hublot Wristwatch Decorated with Diamonds on a Furry Surface",
      price: "₹ 10,000",
      location: "koramangala, Bengaluru",
      date: "Dec 14",
      image:
        "https://images.pexels.com/photos/9561297/pexels-photo-9561297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: false,
      category: "Men",
    },
    {
      title: "Mont Blanc Chronograph Watch with Black Leather Strap",
      price: "₹ 10,000",
      location: "Surat, Gujarat",
      date: "Jul 24",
      image:
        "https://images.pexels.com/photos/13273980/pexels-photo-13273980.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: false,
      category: "Men",
    },
    {
      title: "Leather Horsebit Loafers",
      price: "₹ 2,000",
      location: "Pune, Maharastra",
      date: "Jul 24",
      image:
        "https://images.pexels.com/photos/267301/pexels-photo-267301.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: false,
      category: "Men",
    },
    {
      title: "Black and Brown Draven Shoes",
      price: "₹ 3,000",
      location: "Kerala",
      date: "Jun 24",
      image:
        "https://images.pexels.com/photos/6234695/pexels-photo-6234695.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: false,
      category: "Men",
    },
    {
      title: "Elegant Brown Eyeglasses on Dark Fabric Background",
      price: "₹ 4,000",
      location: "S.A.S. Nagar, Goa",
      date: "Feb 14",
      image:
        "https://images.pexels.com/photos/29488630/pexels-photo-29488630/free-photo-of-elegant-brown-eyeglasses-on-dark-fabric-background.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: true,
      category: "Men",
    },
    {
      title: "Brown Leather Wallet",
      price: "₹ 4,000",
      location: "JP Nagar, Bengaluru",
      date: "may 14",
      image:
        "https://images.pexels.com/photos/5963183/pexels-photo-5963183.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: true,
      category: "Men",
    },
    {
      title: "BIYLACLESEN Women 3-in-1 Snowboard Jacket Winter Coats",
      price: "₹ 4,000",
      location: "Nagar, Bengaluru",
      date: "Aug 14",
      image: "https://fakestoreapi.com/img/51Y5NI-I5jL._AC_UX679_.jpg",
      featured: true,
      category: "Women",
    },
    {
      title:
        "Lock and Love Women's Removable Hooded Faux Leather Moto Biker Jacket",
      price: "₹ 4,500",
      location: "T.P nagar, Tamilnadu",
      date: "Aug 20",
      image: "https://fakestoreapi.com/img/81XH0e8fefL._AC_UY879_.jpg",
      featured: false,
      category: "Women",
    },
    {
      title: "Rain Jacket Women Windbreaker Striped Climbing Raincoats",
      price: "₹ 3,200",
      location: "Tower ways, Andrapradesh",
      date: "Sep 20",
      image: "https://fakestoreapi.com/img/71HblAHs5xL._AC_UY879_-2.jpg",
      featured: false,
      category: "Women",
    },
    {
      title: "MBJ Women's Solid Short Sleeve Boat Neck V",
      price: "₹ 1,200",
      location: "M.S, Bihar",
      date: "Oct 29",
      image: "https://fakestoreapi.com/img/71z3kpMAYsL._AC_UY879_.jpg",
      featured: false,
      category: "Women",
    },
    {
      title: "Opna Women's Short Sleeve Moisture",
      price: "₹ 6,200",
      location: "K.L nagar, Jaipur",
      date: "Nov 19",
      image: "https://fakestoreapi.com/img/51eg55uWmdL._AC_UX679_.jpg",
      featured: false,
      category: "Women",
    },
    {
      title: "DANVOUY Womens T Shirt Casual Cotton Short",
      price: "₹ 2,200",
      location: "K.L nagar, Jaipur",
      date: "Nov 19",
      image: "https://fakestoreapi.com/img/61pHAEJ4NML._AC_UX679_.jpg",
      featured: false,
      category: "Women",
    },
    {
      title: "black framed sun glass",
      price: "₹ 3,000",
      location: "S.A.S. Nagar, Punjab",
      date: "Nov 14",
      image:
        "https://images.unsplash.com/photo-1618677366787-9727aacca7ea?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bWVucyUyMGFjY2Vzc29yaWVzfGVufDB8fDB8fHww",
      featured: true,
      category: "Kids",
    },
    {
      title: "brown leather belt",
      price: "₹ 1,000",
      location: "Jayanagara, Bengaluru",
      date: "Dec 25",
      image:
        "https://images.unsplash.com/photo-1705493655920-20c572928501?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fG1lbnMlMjBhY2Nlc3Nvcnl8ZW58MHx8MHx8fDA%3D",
      featured: false,
      category: "Kids",
    },
    {
      title:
        "A Luxury Hublot Wristwatch Decorated with Diamonds on a Furry Surface",
      price: "₹ 10,000",
      location: "koramangala, Bengaluru",
      date: "Dec 14",
      image:
        "https://images.pexels.com/photos/9561297/pexels-photo-9561297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: false,
      category: "Kids",
    },
    {
      title: "Mont Blanc Chronograph Watch with Black Leather Strap",
      price: "₹ 10,000",
      location: "Surat, Gujarat",
      date: "Jul 24",
      image:
        "https://images.pexels.com/photos/13273980/pexels-photo-13273980.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: false,
      category: "Kids",
    },
    {
      title: "Leather Horsebit Loafers",
      price: "₹ 2,000",
      location: "Pune, Maharastra",
      date: "Jul 24",
      image:
        "https://images.pexels.com/photos/267301/pexels-photo-267301.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: false,
      category: "Kids",
    },
    {
      title: "Black and Brown Draven Shoes",
      price: "₹ 3,000",
      location: "Kerala",
      date: "Jun 24",
      image:
        "https://images.pexels.com/photos/6234695/pexels-photo-6234695.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: false,
      category: "Kids",
    },
    {
      title: "Elegant Brown Eyeglasses on Dark Fabric Background",
      price: "₹ 4,000",
      location: "S.A.S. Nagar, Goa",
      date: "Feb 14",
      image:
        "https://images.pexels.com/photos/29488630/pexels-photo-29488630/free-photo-of-elegant-brown-eyeglasses-on-dark-fabric-background.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: true,
      category: "Kids",
    },
    {
      title: "Brown Leather Wallet",
      price: "₹ 4,000",
      location: "JP Nagar, Bengaluru",
      date: "may 14",
      image:
        "https://images.pexels.com/photos/5963183/pexels-photo-5963183.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      featured: true,
      category: "Kids",
    },
  ];

  // Filter ads based on selected filters
  const filteredAds = ads.filter((ad) => {
    // Category filter
    const categoryMatch = selectedCategory
      ? ad.category === selectedCategory
      : true;
    // Location filter
    const locationMatch = selectedLocation
      ? ad.location.includes(selectedLocation)
      : true;
    // Price filter
    const price = parseFloat(
      ad.price
        .replace("₹", "")
        .replace(",", "")
        .trim()
    );
    const minMatch = minBudget ? price >= parseFloat(minBudget) : true;
    const maxMatch = maxBudget ? price <= parseFloat(maxBudget) : true;

    return categoryMatch && locationMatch && minMatch && maxMatch;
  });

  // const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleLocationDropdown = () => setIsLocationOpen(!isLocationOpen);

  const handleApply = () => {
    if (
      minBudget &&
      maxBudget &&
      parseFloat(minBudget) > parseFloat(maxBudget)
    ) {
      alert("Min budget cannot be greater than max budget.");
    }
  };

  return (
    <>
      <div className="image-box">
        <h1>Photography Equipments</h1>
        <img
          className="hobbies1"
          src="https://cdn-wp.photoup.net/wp-content/uploads/2017/02/03144608/Essential-Equipment-For-A-Professional-Real-Estate-Photographer-e1653617847936.jpg"
          alt="Photography Equipments"
        />

        <div className="wholemid">
          <div className="filetr2234">
            <div className="furnifil">
              <input
                name="Brand Name"
                required
                className="realserac"
                style={{
                  borderRadius: "20px",
                  // padding: "2px",
                  // position: "relative",
                  // height: "47px",
                  width: "300px",
                  // zIndex: "2",
                  textTransform: "uppercase",
                  border: "none",
                  // margin
                  // border: "2px solid whiteSmoke",
                }}
                placeholder="Search your Photography Equipment"
                onChange={(e) => setSearchValue(e.target.value.trim())}
                // dropdownClassName="select-dropdown"
              />
              <div className="tildiv">
                <Link to={`/ads-listing1/`} onClick={searchArray}>
                  <button className="search-btn-furniture">Search</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main">
        <div className="leftctr">
          {/* Categories */}
          <div className="Categories">
            <h3 className="headinghb">Categories</h3>
            <div className="category-buttons">
              {categories.map((category, index) => (
                <button
                  key={index}
                  className={`category-button ${
                    selectedCategory === category ? "selected" : ""
                  }`}
                  onClick={() =>
                    setSelectedCategory(
                      category === selectedCategory ? "" : category
                    )
                  }
                >
                  {category}
                </button>
              ))}
            </div>
          </div>

          {/* Location */}
          <div className="Categories">
            <h3 className="headinghb" onClick={toggleLocationDropdown}>
              Location
              <span style={{ marginLeft: "20px", cursor: "pointer" }}>
                {isLocationOpen ? "▲" : "▼"}
              </span>
            </h3>
            {isLocationOpen && (
              <div>
                <input
                  type="text"
                  placeholder="Search location..."
                  onChange={(e) => setLocationSearch(e.target.value)}
                  className="location-search"
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginBottom: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />

                <ul className="scrollable-list">
                  {Citys.filter((location) =>
                    location
                      .toLowerCase()
                      .includes(locationSearch.toLowerCase())
                  ).map((location, index) => (
                    <li
                      key={index}
                      onClick={() =>
                        setSelectedLocation(
                          location === selectedLocation ? "" : location
                        )
                      }
                      className={
                        selectedLocation === location ? "selected-location" : ""
                      }
                    >
                      {location}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Budget */}
          <div className="Categories">
            <h3 className="budget-heading">Budget</h3>
            <div className="budget-inputs">
              <div className="input-group">
                <label className="lbl" htmlFor="minBudget">
                  Min:
                </label>
                <input
                  className="inputbgt"
                  type="number"
                  id="minBudget"
                  value={minBudget}
                  onChange={(e) => setMinBudget(e.target.value)}
                  placeholder="min value"
                />
              </div>
              <div className="input-group">
                <label className="lbl" htmlFor="maxBudget">
                  Max:
                </label>
                <input
                  className="inputbgt"
                  type="number"
                  id="maxBudget"
                  value={maxBudget}
                  onChange={(e) => setMaxBudget(e.target.value)}
                  placeholder="max value"
                />
              </div>
              <button className="apply-btn" onClick={handleApply}>
                Apply
              </button>
            </div>
          </div>
        </div>

        <div className="rightctr">
          {/* Ads Grid */}
          <div className="main-content">
            <div className="ads-grid">
              {filteredAds.map((ad, index) => (
                <div key={index} className="ad-card">
                  {ad.featured && (
                    <div className="featured-badge">Featured</div>
                  )}
                  <img src={ad.image} alt={ad.title} className="ad-image" />
                  <h3 className="ad-price">{ad.price}</h3>
                  <p className="ad-title">{ad.title}</p>
                  <p className="ad-details">
                    {ad.location} • {ad.date}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <MyGallery selectedCategory="Fashion" />
    </>
  );
}

export default PhotographyCat;
