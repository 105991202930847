import React, { useState, useEffect } from 'react';
import "./Adminverify.css"
import { FaUser, FaBuilding, FaPhone, FaEnvelope, FaIdCard, FaFileAlt, FaCheck, FaTimes } from 'react-icons/fa';
import YoutubeMagic from '../ContentLoader/YoutubeMagic';

const Bussinessadminverify = () => {
  const [businessData, setBusinessData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [itemsToLoad, setItemsToLoad] = useState(25); // Holds the number of items to load initially and increases when "Load More" is clicked
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);

  useEffect(() => {
    setIsLoading1(true)
    // Fetch data from the API
    fetch(`https://demoadmin.hola9.com/api/adsapi/businessProfile?limit=${itemsToLoad}`)
      .then(response => response.json())
      .then(data => {
        setBusinessData(data);
        setIsLoading(false); // Set loading to false once data is fetched
        setIsLoading1(false); // Set loading to false once data is fetched
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false in case of an error
        setIsLoading1(false); // Set loading to false in case of an error
      });

  }, [itemsToLoad]);
  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 25);
    setLimitIncreaseCount((prevCount) => prevCount + 1);

  };
  const [prevDataLength, setPrevDataLength] = useState(0);
  const [cardCount, setCardCount] = useState(20);

  const loadMoreCards = () => {
    // Increment the card count by 30.
    setCardCount(cardCount + 20);
      setLimitIncreaseCount(0);

  };
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isLoading && limitIncreaseCount != 3) {
        const newDataLength = businessData.length;
  
        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);
  
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isLoading, businessData, prevDataLength,limitIncreaseCount]);

  return (
    <>
    <div className="business-profile-container">
      {isLoading ? (
        <p><YoutubeMagic/></p>
      ) : (
        businessData.slice(0,cardCount).map((data) => (
          <>
          <BusinessProfileItem key={data.id} data={data} load={isLoading1} />
          </>
        ))
      )}

    </div>
    {cardCount > businessData.length && (
      <>
      {isLoading1 && <p className="loading-message"> <YoutubeMagic/> </p>}
      </>
      )}
      {cardCount < businessData.length && (
                    <div
                      className="loadbt text-center"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      <button className="loadmore" onClick={loadMoreCards}>
                        Load More
                      </button>
                    </div>
                  )}
</>
  );
};

const BusinessProfileItem = ({ data,load }) => {
  const [isVerified, setIsVerified] = useState(data.is_verified);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const handleUpdateVerification = async () => {
    try {
      setIsVerifying(true); // Set loading to true before making the request

      const response = await fetch(`https://demoadmin.hola9.com/api/adsapi/businessProfileDetail/${data.id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_verified: !isVerified }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setIsVerified(!isVerified);
      console.log(`is_verified updated to: ${!isVerified}`);
    } catch (error) {
      console.error('Error updating verification:', error.message);
    } finally {
      setIsVerifying(false); // Set loading to false after the request completes
    }
  };
  const handleDelete = async () => {
    // Show a confirmation alert before proceeding with the deletion
    const userConfirmed = window.confirm('Are you sure you want to delete this data?');

    if (!userConfirmed) {
      return;
    }

    try {
      setIsDeleting(true); // Set loading to true before making the request

      const response = await fetch(`https://demoadmin.hola9.com/api/adsapi/businessProfileDetail/${data.id}/`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
setIsDeleted(true);
      console.log(`Data with ID ${data.id} deleted`);
    } catch (error) {
      console.error('Error deleting data:', error.message);
    } finally {
      setIsDeleting(false); // Set loading to false after the request completes
    }
  };
  const adharurl = data.aadhar_card && data.aadhar_card.replace("http://127.0.0.1:8000", "");
  const pancardurl = data.pan_card && data.pan_card.replace("http://127.0.0.1:8000", "");
  const companydocurl = data.company_document && data.company_document.replace("http://127.0.0.1:8000", "");
  return (
    <>

    <div style={{background:isVerified ? "green" : "red"}} className='Businessadminverify-card'>
      <div style={{width:'100%', height:'100%', background:!isDeleted ? "white" : "red", borderRadius:'10px', padding:'15px 0 0 20px', transform:'translate(0.1px, 3.5px)', paddingBottom:'5px'}}>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'start', marginRight:'10px'}}><h4 style={{margin:'0', }}>{data.company_name}</h4>  
      {isDeleting ? (
          <p tyle={{color:"red",fontWeight:"bold"}}>Deleting...</p>
        ) : ( <i className='fa fa-trash fa-admin-trash'  onClick={handleDelete}/>)}
        </div>
      <i className='fa'>{data.name}</i>
      <span style={{display:'flex', flexDirection:'column', marginTop:'7px'}}>
      <i style={{lineHeight:'11px'}}>{data.phone_number}</i>
      <i>{data.email}</i>
      </span>
      <div style={{display:'flex', width: '100%', justifyContent:'space-between', paddingRight:'7px'}}>
      <span style={{display:'flex', flexDirection:'column', marginTop:'7px'}}>
      <i>
      <FaIdCard size={15}  style={{marginRight:'10px'}} />
        <a href={ data.aadhar_card} target="_blank" rel="noopener noreferrer">
          Aadhar Card
        </a>
      </i>
      <i>
      <FaFileAlt size={15}  style={{marginRight:'10px'}} />
        <a href={data.pan_card} target="_blank" rel="noopener noreferrer">
          PAN Card
        </a>
      </i>
      <i>
      <FaBuilding size={15} style={{marginRight:'10px'}} />
        <a href={data.company_document} target="_blank" rel="noopener noreferrer">
          Company Document
        </a></i>
      </span>
      
      <div style={{display:'flex', flexDirection:'column', justifyContent:'end'}}>
        {isVerifying ? (
          <p style={{color:"navy",fontWeight:"bold"}}>Verifying...</p>
        ) : (
          <button onClick={handleUpdateVerification} style={{ backgroundColor: isVerified ? 'green' : 'red', color: 'white', padding: '5px', borderRadius: '5px', cursor: 'pointer', width:'125px' }}>
            {isVerified ? <> <FaCheck /> verified</> : <><FaTimes /> Not verified</>}
          </button>
        )}
        {/* {isDeleting ? (
          <p tyle={{color:"red",fontWeight:"bold"}}>Deleting...</p>
        ) : (
          <button onClick={handleDelete} style={{ background: 'red', color: 'white', padding: '8px', borderRadius: '5px', cursor: 'pointer', width:'150px', marginBottom:'5px' }}>
            Delete
          </button>
        )} */}
      </div>
      </div>
      </div>
    </div>

    {/* <div className={`business-profile-card ${isDeleted ? 'deleted' : ''}`} style={{ border: isVerified ? "1px solid green" : "1px solid red" }}>
    <div className="header">
      <h5 style={{color:isVerified ? "green" : "red",}}>{data.company_name}</h5>
      {/* <p>ID: {data.id}</p> *
    </div>
    <div className="details">
      <p>Name: {data.name}</p>
      <p>Phone Number: {data.phone_number}</p>
      <p>Email: {data.email}</p>
    </div>

    {/* Display documents with icons *
    <div className="document-row">
      <div className="document-item">
        <FaIdCard size={24} />
        <a href={ data.aadhar_card} target="_blank" rel="noopener noreferrer">
          Aadhar Card
        </a>
      </div>
      <div className="document-item">
        <FaFileAlt size={24} />
        <a href={"https://demoadmin.hola9.com" + pancardurl} target="_blank" rel="noopener noreferrer">
          PAN Card
        </a>
      </div>
      <div className="document-item">
        <FaBuilding size={24} />
        <a href={"https://demoadmin.hola9.com" + companydocurl} target="_blank" rel="noopener noreferrer">
          Company Document
        </a>
      </div>
    </div>

    {/* Display verification status *
    <div className="verification-status">
        {isVerifying ? (
          <p style={{color:"navy",fontWeight:"bold"}}>Verifying...</p>
        ) : (
          <button onClick={handleUpdateVerification} style={{ backgroundColor: isVerified ? 'green' : 'red', color: 'white', padding: '8px', borderRadius: '5px', cursor: 'pointer' }}>
            {isVerified ? <> <FaCheck /> verified</> : <><FaTimes /> Not verified</>}
          </button>
        )}
        {isDeleting ? (
          <p tyle={{color:"red",fontWeight:"bold"}}>Deleting...</p>
        ) : (
          <button onClick={handleDelete} style={{ marginLeft: '10px', background: 'red', color: 'white', padding: '8px', borderRadius: '5px', cursor: 'pointer' }}>
            Delete
          </button>
        )}
      </div>
  </div> */}
 </>
  );
};

export default Bussinessadminverify;
