import React, { useState } from 'react';
import '../Allfilter/ToyAndGamesCat.css'
import MyGallery from './MyGallery';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { searchApi } from '../../../../store/ToggleSearchSlice';

function FarmingAndGardeningCat() {
    const [isOpen, setIsOpen] = useState(false);
    const [isLocationOpen, setIsLocationOpen] = useState(false);
    const [minBudget, setMinBudget] = useState('');
    const [maxBudget, setMaxBudget] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(''); // Track selected location

    const [SearchValue, setSearchValue] = useState("");
      const searchArray = () => {
        if (SearchValue?.trim()) {
          dispatch(searchApi(SearchValue));
        }
      };
    
      const dispatch = useDispatch();
  
    const categories = ['Supplements', 'Workout Equipment', 'Health Monitors', 'Yoga Mats'];
    const locations = ['New York', 'Los Angeles', 'Chicago', 'Houston', 'Miami', 'Other Location'];
  
    const ads = [
        { title: 'Whay Protein Container', price: '₹ 3,000', location: 'S.A.S. Nagar, Punjab', date: 'Nov 14', image: 'https://media.istockphoto.com/id/802330088/photo/3d-render-of-whey-bcaa-and-creatine-bottles.jpg?s=612x612&w=0&k=20&c=uQAP-urubIhk7zlxe_frUP0EEdvy6CX_B-J0wzxvRXI=', featured: true, category: 'Supplements' },
              { title: 'Swisseme Plant Protein - Daily Nutrition For Men & Women', price: '₹ 2700', location: 'PNB Enclave, Dehradun', date: 'Nov 06', image: 'https://swisse.co.in/cdn/shop/files/Artboard_11.jpg?v=1717827440', featured: true, category: 'Supplements' },
              { title: ' Creative Design Custom Size Papier Protein powder packaging', price: '₹ 5000', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKkzSOLebz66fbGfHLnWrpcPxAciIEewEBdA&s', featured: true, category: 'Supplements' },
              { title: 'Small Space Home Gym Equipment 2023: The 22 Best Space-Saving', price: '₹ 15,000', location: 'Rathnapuri, Coimbatore', date: 'Today', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ00wb9PmBrgLdDRRcyOspVp8sFM73WGuKijQ&s', featured: false, category: 'Workout Equipment' },
              { title: 'Hashtag FItness 7in1 wall mount pull up bar for home latpull Down ', price: '₹ 2200', location: 'Sahastradhara Road, Dehradun', date: 'Today', image: 'https://www.hashtagfitness.in/wp-content/uploads/2023/06/61mZ93Xx02L.SL1080.jpg', featured: false, category: 'Workout Equipment' },
              { title: 'Dumbells set ', price: '₹ 1600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://plus.unsplash.com/premium_photo-1671631630555-1cb3ffa7dfe6?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Z3ltJTIwZXF1aXBtZW50fGVufDB8fDB8fHww', featured: false, category: 'Workout Equipment' },
              { title: 'Livenpace Home Heart Health Monitor, Portable Heart Rate', price: '₹ 2,600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://m.media-amazon.com/images/I/61gsTGbiK9L.AC_UF1000,1000_QL80.jpg', featured: false, category: 'Health Monitors' },
              { title: 'Patient monitoring devices | Hospital monitoring solutions', price: '₹ 2,600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://www.hindustantimes.com/ht-img/img/2023/10/18/1600x900/health_monitor_1697617982774_1697618021120.jpg', featured: false, category: 'Health Monitors' },
              { title: 'Take care of yourself with health', price: '₹ 5000', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://vanninhealthcare.com/wp-content/uploads/2020/08/phoebe-pro-patient-monitor.png', featured: true, category: 'Health Monitors' },
              { title: 'Nivia Yoga Mat 10 mm, Blue', price: '₹ 1000', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://m.media-amazon.com/images/I/71b5fW+s18L.jpg', featured: true, category: 'Yoga Mats' },
              { title: 'Cockatoo Printed Yoga Mat - 5mm Anti-Slip PVC Exercise Mat ', price: '₹ 1200', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://www.cockatooindia.com/cdn/shop/files/1_8a0c2af4-dd61-43d4-8744-aaa1dc74a681.jpg?v=1693123957', featured: true, category: 'Yoga Mats' },
              { title: 'COTTON YOGA MAT - Yantras Yellow', price: '₹ 1100', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOWiI8Z5S5TMQxP9jDnhai4eEpt6HPWA7fQg&s', featured: true, category: 'Yoga Mats' },
    ];
  
    // Filter ads based on selected filters
    const filteredAds = ads.filter(ad => {
      // Category filter
      const categoryMatch = selectedCategory ? ad.category === selectedCategory : true;
      // Location filter
      const locationMatch = selectedLocation ? ad.location.includes(selectedLocation) : true;
      // Price filter
      const price = parseFloat(ad.price.replace('₹', '').replace(',', '').trim());
      const minMatch = minBudget ? price >= parseFloat(minBudget) : true;
      const maxMatch = maxBudget ? price <= parseFloat(maxBudget) : true;
  
      return categoryMatch && locationMatch && minMatch && maxMatch;
    });
  
    const toggleDropdown = () => setIsOpen(!isOpen);
    const toggleLocationDropdown = () => setIsLocationOpen(!isLocationOpen);
  
    const handleApply = () => {
      if (minBudget && maxBudget && parseFloat(minBudget) > parseFloat(maxBudget)) {
        alert('Min budget cannot be greater than max budget.');
      }
    };
  
    return (
      <>
        <div className="image-box" style={{ position: 'relative' }}>
          <h1 className="text99-header-awi-24">Farming And Gardening</h1>
          <img
            className="hobbies1"
            src="https://images.pexels.com/photos/5027601/pexels-photo-5027601.jpeg"
            alt="Farming And Gardening"
          />

          <div className="wholemid">
          <div className="filetr2234">
            <div className="furnifil">
              <input
                name="Brand Name"
                required
                className="realserac"
                style={{
                  borderRadius: "20px",
                  // padding: "2px",
                  // position: "relative",
                  // height: "47px",
                  // width: "30%",
                  // zIndex: "2",
                  textTransform: "uppercase",
                  border: "none",
                  // margin
                  // border: "2px solid whiteSmoke",
                }}
                placeholder="Search your Fashion"
                onChange={(e) => setSearchValue(e.target.value.trim())}
                // dropdownClassName="select-dropdown"
              />
              <div className="tildiv">
                <Link to={`/ads-listing1/`} onClick={searchArray}>
                  <button className="search-btn-furniture">Search</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        </div>
  
        <div className="main">
          <div className="leftctr">
            {/* Categories */}
            <div className="Categories">
              <h3 className="headinghb">Categories</h3>
              <div className="category-buttons">
                {categories.map((category, index) => (
                  <button
                    key={index}
                    className={`category-button ${selectedCategory === category ? 'selected' : ''}`}
                    onClick={() => setSelectedCategory(category === selectedCategory ? '' : category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>
  
            {/* Location */}
            <div className="Categories">
              <h3 className="headinghb" onClick={toggleLocationDropdown}>
                Location
                <span style={{ marginLeft: '20px', cursor: 'pointer' }}>
                  {isLocationOpen ? '▲' : '▼'}
                </span>
              </h3>
              {isLocationOpen && (
                <ul className="scrollable-list">
                  {locations.map((location, index) => (
                    <li
                      key={index}
                      onClick={() => setSelectedLocation(location === selectedLocation ? '' : location)}
                      className={selectedLocation === location ? 'selected-location' : ''}
                    >
                      {location}
                    </li>
                  ))}
                </ul>
              )}
            </div>
  
            {/* Budget */}
            <div className="Categories">
              <h3 className="budget-heading">Budget</h3>
              <div className="budget-inputs">
                <div className="input-group">
                  <label className="lbl" htmlFor="minBudget">Min:</label>
                  <input
                    className="inputbgt"
                    type="number"
                    id="minBudget"
                    value={minBudget}
                    onChange={(e) => setMinBudget(e.target.value)}
                    placeholder="min value"
                  />
                </div>
                <div className="input-group">
                  <label className="lbl" htmlFor="maxBudget">Max:</label>
                  <input
                    className="inputbgt"
                    type="number"
                    id="maxBudget"
                    value={maxBudget}
                    onChange={(e) => setMaxBudget(e.target.value)}
                    placeholder="max value"
                  />
                </div>
                <button className="apply-btn" onClick={handleApply}>
                  Apply
                </button>
              </div>
            </div>
          </div>
  
          <div className="rightctr">
            {/* Ads Grid */}
            <div className="main-content">
              <div className="ads-grid">
                {filteredAds.map((ad, index) => (
                  <div key={index} className="ad-card">
                    {ad.featured && <div className="featured-badge">Featured</div>}
                    <img src={ad.image} alt={ad.title} className="ad-image" />
                    <h3 className="ad-price">{ad.price}</h3>
                    <p className="ad-title">{ad.title}</p>
                    <p className="ad-details">
                      {ad.location} • {ad.date}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
  
        <MyGallery selectedCategory="Vehicles and Spares" />
      </>
    );
  }

export default FarmingAndGardeningCat;