// import React, { useEffect, useState } from "react"
// import FeaturedAds from '../../../Ads/FeaturedAds/featuredAds';
import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import CategoryUiSection from "../CategoryUiScetion/CategoryUiSection"; // Importing the 'CategoryUiSection' component
import SearchBox from "../searchBox/SearchBox"; // Importing the 'SearchBox' component
import RealEstateSection from "../section/realEstateSection"; // Importing the 'RealEstateSection' component
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
import MyGallery from "./MyGallery";
import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
import { Spin } from "antd";
import axios from "axios";
import { url } from "../../../env";
import { add } from "../../../../store/Track/trackUserSlice";
import { isMobile, isMobileOnly } from "react-device-detect";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import Headerealestate from "../../../Base/Headerealestate";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import { BsFilter } from "react-icons/bs";
import { AiOutlineCloseSquare, AiOutlineFilter } from "react-icons/ai";
import Allcatads from "../../../Allcatads";
import { BiSearch } from "react-icons/bi";

const mobi = {};
const deski = {
  marginLeft: "7rem",
  marginRight: "7rem",
};
const mobstyle = {
  width: "auto",
  marginLeft: "16px",
  marginRight: "16px",
};
const deskstyle = {
  padding: "20px",
  marginBottom: "-10px",
  marginRight: "10rem",
  marginLeft: "10rem",
};

const mob = {
  display: "flex",
  justifyContentText: "center",
  width: "10px",
  marginTop: "210px",
  marginLeft: "190px",
};
const desk = {
  // width:"100px",
  // marginLeft:"580px",
  // marginTop:"250px"
};

const mob1 = {
  width: "350px",
  marginLeft: "-60px",
  zIndex: 1
};

const desk1 = {
  borderRadius: "20px",
  backgroundColor: "white",
};

const mob11 = {
  marginTop: "-120px",
};

const desk11 = {};
const HomeRealEsate = (props) => {
  const [SearchValue, setSearchValue] = useState();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const dispatch = useDispatch();

  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };
  const handleFilterClick = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const openPopup = () => {
    setPopupOpen(true);
  };
  const greeting = "RealEstate";

  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch(); // Accessing the Redux dispatch function

  useEffect(() => {
    disptach(add({ view: ["HomeRealEsate"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);
  return (
    <>
      {/* <Headerealestate/> */}

      <RealEstateSection />

      <div className="filmain">
        <div className="fildi">
          <input
            name="Brand Name"
            required
            className="realserac"
            style={{
              // padding: "2px",
              // position: "relative",
              // height: "47px",
              // width: "30%",
              // zIndex: "2",
              textTransform: "uppercase",
              border: "none",
              width: "125px",
              // margin
              // border: "2px solid whiteSmoke",
            }}
            placeholder="Search your property"
            onChange={(e) => setSearchValue(e.target.value.trim())}
          // dropdownClassName="select-dropdown"
          />
          <div className="tildiv">
            <Link to={`/ads-listing1/`} onClick={searchArray}>
              <button className="search-btn">
                <BiSearch style={{ fontSize: "20px" }} />
                Search
              </button>
            </Link>
            &nbsp; &nbsp;
            <button className="filter-btn" onClick={handleFilterClick}>
              <AiOutlineFilter style={{ fontSize: "20px" }} />
              Filter
            </button>

          </div>
        </div>
      </div>

      {isPopupOpen && (
        <div className="fildi2" style={isMobile ? mob1 : desk1}>
          <div>
            <button onClick={handleClosePopup} style={{ float: "right" }}>
              <AiOutlineCloseSquare
                style={{ color: "red", fontSize: "20px" }}
              />
            </button>
            <SearchBox />
          </div>
        </div>
      )}
      {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
        <div>
          <h5>
            {" "}
            <div className="example">
              <Spin />
            </div>{" "}
          </h5>{" "}
        </div>
      )}
      {!loading && (
        <>
          <div className="mgntp-mnd"
          // style={isMobile ? mobstyle : deskstyle}
          >
            <Feature value="RealEstate" />{" "}
            {/* Rendering the 'Feature' component with value "RealEstate" */}
            {/* <Owldemo1 greeting={greeting} subcategory={"flats"} /> */}
            <div id="all-cat123">
              <Allcatads
                onClick={openPopup}
                greeting={greeting}
                subcategory={"Buy-Residential"}
                searchv={SearchValue}
              />
              {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Buy-Residential" */}
            </div>
          </div>
          <div style={isMobile ? mobi : deski}>
            <TrendingAds />
          </div>
        </>
      )}
      <div>
        <div className="gallerydiv">
          <NewsArticles selectedCategory={"RealEstate"} />{" "}
          {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
          {/* <MyGallery /> */}
          <MyGallery selectedCategory="RealEstate" />


          <section className="contactwe"d>
            <h2>Contact Us</h2>
            <p>Have questions or need assistance? Contact us today.</p>
            <Link to="/contact/" className="btntgh" style={{ color: "white" }}>
              Contact Us
            </Link>
          </section>
        </div>
      </div>
      {/* <NewsArticles category={"RealEstate"}/> */}
    </>
  );
};

export default HomeRealEsate;
