import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import CategoryUiSection from "../CategoryUiScetion/CategoryUiSection"; // Importing the 'CategoryUiSection' component
import SearchBoxEducation from "../searchBox/SearchBoxEducation"; // Importing the 'SearchBox' component
import RealEstateSection from "../section/realEstateSection"; // Importing the 'RealEstateSection' component
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { searchApi } from "../../../../store/ToggleSearchSlice";

import { SearchOutlined } from "@ant-design/icons";
import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
import MyGallery from "./MyGallery";
import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import {
  All,
  subcategoryRealEstateBuy, // Imported from "../../../env"
  subcategoryRealEstateRent, // Imported from "../../../env"
  subcategoryType1, // Imported from "../../../env"
} from "../../../env";
import { stateMain, locationData } from "../../../Location";
import "../searchBox/SearchBox.css";
import { Link } from "react-router-dom";
import "./allfilter.css";
import { isMobile } from "react-device-detect";
import "./EducationCat.css";

import { CgScrollV } from "react-icons/cg";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import Slider from "react-slick";
import Allcatads from "../../../Allcatads";
import { BsFilter } from "react-icons/bs";
import { AiOutlineCloseSquare, AiOutlineFilter } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";

const tagData = [
  {
    tag: "Career Counseling",
    content: "Nothing is More Important that Your Career, Choose Wisely",
  },
  {
    tag: "Certification & Training",
    content: "Content for Tag 2",
  },
  {
    tag: "Competative Exams Coaching",
    content: "Content for Tag 3",
  },
  {
    tag: "Distance Learning Courses",
    content: "Content for Tag 3",
  },
  {
    tag: "Entrance Exam Coaching",
    content: "Content for Tag 3",
  },
  {
    tag: "Hobby Classes",
    content: "Content for Tag 3",
  },
  {
    tag: "Play Schools & Creche",
    content: "Content for Tag 3",
  },
  {
    tag: "Schools & School Tuitions",
    content: "Content for Tag 3",
  },
  {
    tag: "Study Abroad Consultants",
    content: "Content for Tag 3",
  },
  {
    tag: "Text Books & Study Materials",
    content: "Content for Tag 3",
  },
  {
    tag: "Vocational Skills Training",
    content: "Content for Tag 3",
  },
];
const mobstyle = {
  width: "auto",
  marginLeft: "16px",
  marginRight: "16px",
};
const deskstyle = {
  padding: "20px",
  marginBottom: "-10px",
  marginRight: "10rem",
  marginLeft: "10rem",
};
const mobistyle = {
  display: "flex",
  flexDirection: "column",
  border: "1px solid gray",
  width: "90%",
  margin: "auto 5%",
};
const deskistyle = {
  display: "flex",
  flexDirection: "row",
};
const mobi = {

}
const deski = {
  marginLeft: "7rem", marginRight: "7rem"
}
const mob1 = {
  width: "95%",
  marginLeft: "-60px",
};

const desk1 = {
  borderRadius: "20px",
  backgroundColor: "white",

};
const mobistyle1 = {
  width: "400px",
  height: "200px",
};
const deskistyle1 = {
  width: "500px",
  height: "300px",
  border: "1px solid black",
  border_radius: "1rem",
};

const mobileStyle = {
  backgroundSize: "cover",
  width: "60px",
  height: "60px",
  paddingTop: "10px",
};
const desktopStyle = {
  backgroundSize: "cover",
  width: "75px",
  height: "75px",
  paddingTop: "10px",
};
const mobileStyleS = {
  padding: "1rem",
  margin: "1rem 0rem",
  backgroundColor: "whitesmoke",
};
const desktopStyleS = {
  padding: "1rem",
  margin: "0rem 6rem 1rem",
  backgroundColor: "whitesmoke",
};
const mobtog = {
  display: "none",
};
const desktoggle = {
  // display:"none"
};
const mobtog1 = {};
const desktoggle1 = {
  display: "none",
};

const cardsdata = [
  {
    img: "https://img.freepik.com/free-icon/html-5_318-566077.jpg",
    title: "HTML",
  },
  {
    img:
      "https://png.pngitem.com/pimgs/s/198-1985012_transparent-css3-logo-png-css-logo-transparent-background.png",
    title: "Css",
  },
  {
    img:
      "https://raw.githubusercontent.com/themedotid/bootstrap-icon/HEAD/docs/bootstrap-icon-css.png",
    title: "Bootstrap",
  },
  {
    img:
      "https://repository-images.githubusercontent.com/24195339/87018c00-694b-11e9-8b5f-c34826306d36",
    title: "Angular.Js",
  },
  {
    img:
      "https://cdn.iconscout.com/icon/free/png-256/free-react-1-282599.png?f=webp",
    title: "React.Js",
  },
  {
    img: "https://www.nicepng.com/png/detail/383-3839776_node-js-icon-png.png",
    title: "Node.Js",
  },
  {
    img:
      "https://w7.pngwing.com/pngs/172/554/png-transparent-javascript-html-computer-software-web-browser-watermark-angle-text-rectangle.png",
    title: "Java Script",
  },
  {
    img:
      "https://media.istockphoto.com/id/1365584536/vector/sap-enterprise-resource-planning-construction-concept-module-vector-icons.jpg?s=612x612&w=0&k=20&c=6uUbjuLeVM2g381psDjSvOn2aBOz67VOMx00J-kNCXQ=",
    title: "SAP",
  },
  {
    img:
      "https://upload.wikimedia.org/wikipedia/commons/f/f3/Artificial_Intelligence_%26_AI_%26_Machine_Learning.jpg",
    title: "Artificial Inteligance",
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/1570/1570889.png",
    title: "Data Analytics",
  },
  {
    img:
      "https://img.freepik.com/premium-vector/devops-software-development-methodology-dev-ops-software-vector-stock-illustration_100456-11643.jpg",
    title: "DevOps ",
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/6497/6497701.png",
    title: "UI/UX Developer",
  },
  {
    img:
      "https://st2.depositphotos.com/1092019/12360/i/450/depositphotos_123605182-stock-photo-software-testing-concept-golden-metallic.jpg",
    title: "Software Testing",
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/2092/2092663.png",
    title: "Cyber Security",
  },
];
var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  initialSlide: 0,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      },
    },
  ],
};

const EducationCat = () => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [SearchValue, setSearchValue] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };
  const dispatch = useDispatch();
  const handleFilterClick = () => {
    setPopupOpen(true);
  };
  const [visible, setvisible] = useState(false);
  const [careerStream, setCareerStream] = useState(null);
  const [deliveryMode, setDeliveryMode] = useState(null);
  const [listedBy, setListedBy] = useState(null);

  const handleFilterButtonClick = (filterType) => {
    setSelectedFilter(filterType);
  };
  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const hangletoggle = () => {
    setvisible(true);
  };
  const hangletoggle1 = () => {
    setvisible(false);
  };
  const greeting = "Education";

  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch(); // Accessing the Redux dispatch function

  useEffect(() => {
    disptach(add({ view: ["EduicationCat"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);
  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [extraFieldCopy1, setExtraFieldCopy1] = useState({});
  const [extraFieldCopy2, setExtraFieldCopy2] = useState({});
  const [sub, setsub] = useState();

  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    searchValue: null,
    category: "Education",
    minPrice: null,
    maxPrice: null,
  });
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
    // setsearchSending({ ...searchSending, [name]: value });
    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };
  // Event handler for input field changes

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);

  const [selectedTag, setSelectedTag] = useState(null);

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
  };
  const modalContainerStyle = {
    top: "-50%", // Adjust this value to control the distance from the button

    // width:"100%"
  };
  const [selectedButton, setSelectedButton] = useState();

  const modalStyle = {
    // position: "relative",

    // display:"flex",
    // width:"100%",
    top: "-50%", // Adjust this value to control the distance from the button
    left: 0,
    zIndex: 10, // Ensure the modal is above other elements
    backgroundColor: "white",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  const renderModalContent = () => {
    if (selectedButton === "button1") {
      return (
        <div style={modalStyle} className="brandimg">
          <p
            className={`pricep ${careerStream == "Distance" ? "careerStream" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "carrerstrem": "Distance" });
              setCareerStream("Distance");
            }}>
            Distance
          </p>
          <p
            className={`pricep ${careerStream == "Correspondence" ? "careerStream" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "carrerstrem": "Correspondence" });
              setCareerStream("Correspondence");
            }}>
            Correspondence
          </p>
          <p
            className={`pricep ${careerStream == "Engineering" ? "careerStream" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "carrerstrem": "Engineering" });
              setCareerStream("Engineering");
            }}>
            Engineering
          </p>
          <p
            className={`pricep ${careerStream == 'Management' ? "careerStream" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "carrerstrem": "Management" });
              setCareerStream("Management");
            }}>
            Management
          </p>
          <p
            className={`pricep ${careerStream == "Law" ? "careerStream" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "carrerstrem": "Law" });
              setCareerStream("Law");
            }}>
            Law
          </p>
          <p
            className={`pricep ${careerStream == "Medical" ? "careerStream" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "carrerstrem": "Medical" });
              setCareerStream("Medical");
            }}>
            Medical
          </p>

        </div>
      );
    } else if (selectedButton === "button2") {
      return (
        <div style={modalStyle} className="brandimg">
          <p
            className={`pricep ${deliveryMode == 'Classroom' ? "deliveryMode" : ""}`}
            onClick={() => {
              setExtraFieldCopy1({ "deliverymode": "Classroom" });
              setDeliveryMode("Classroom")
            }}>
            Classroom
          </p>
          <p
            className={`pricep ${deliveryMode == "Correspondence" ? "deliveryMode" : ""}`}
            onClick={() => {
              setExtraFieldCopy1({ "deliverymode": "Correspondence" });
              setDeliveryMode("Correspondence");
            }}>
            Correspondence
          </p>
          <p
            className={`pricep ${deliveryMode == "Home Tution" ? "deliveryMode" : ""}`}
            onClick={() => {
              setExtraFieldCopy1({ "deliverymode": "Home Tution" });
              setDeliveryMode("Home Tution");
            }}>
            Home Tution
          </p>
          <p
            className={`pricep ${deliveryMode == "Online" ? "deliveryMode" : ""}`}
            onClick={() => {
              setExtraFieldCopy1({ "deliverymode": "Online" });
              setDeliveryMode("Online")
            }}>
            Online
          </p>
        </div>
      );
    } else if (selectedButton === "button3") {
      return (
        <div style={modalStyle} className="brandimg">
          <p
            className={`pricep ${listedBy == "Institutions" ? "listedBy" : ""}`}
            onClick={() => {
              setExtraFieldCopy2({ "listedby": "Institutions" });
              setListedBy("Institutions");
            }}>
            Institutions
          </p>
          <p
            className={`pricep ${listedBy == "Freelancers" ? "listedBy" : ""}`}
            onClick={() => {
              setExtraFieldCopy2({ "listedby": "Freelancers" });
              setListedBy("Freelancers")
            }}>
            Freelancers
          </p>

        </div>
      );
    }

    return null;
  };



  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);
  useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('popupClosed');
    const name = localStorage.getItem('name')
    if (isPopupClosed && name) {
      setadvClose(false);
    }
  }, []);
  const handleCloseadv = () => {
    setadvClose(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  // AdsCards logic end




  return (
    <>


      {/* AdsCards logic start */}
      {advClose ? (
        <div
          style={{
            position: "fixed",
            bottom: "2%",
            right: "2%",
            width: "350px",
            height: "170px",
            zIndex: "3",
            border: "0.5px solid gray",
            background:
              // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",
              'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',
            backgroundSize: "100% 100%",
            borderRadius: '7px'
          }}
        >
          <span style={{ float: 'right', padding: '2px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px', fontSize: "18px", marginRight: '6px' }} onClick={handleCloseadv}>x</span>
          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>
            <div style={{ color: 'white' }}>
              Get the best <br />
              <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>
            </div>
            <div style={{ color: 'whitesmoke', fontWeight: '600' }}>
              Grow Your Business
            </div>
            <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>
          </div>
        </div>
      ) : null}
      {/* AdsCards logic end */}


      <h1
        style={{
          color: "whitesmoke",
          padding: "10px 10px",
          background: "#143861",
          fontSize: "26px",
          fontFamily: "verdana",
        }}
      >
        GET OUR CERTIFIED COURSES
      </h1>
      <div
        className="mainwrapper"
        style={isMobile ? mobileStyleS : desktopStyleS}
      >
        <Slider {...settings}>
          {cardsdata.map((index) => {
            return (
              <center>
                <div className="wrapper wrapper-slid-awi-23 pt-6">
                  <Link to="/contact">
                    <img
                      key={index}
                      alt="imag"
                      src={index.img}
                      style={isMobile ? mobileStyle : desktopStyle}
                      className="CircleHover"
                    />
                    <p className="text-center fs-14 mb-0 pt-3">{index.title}</p>
                  </Link>
                </div>
              </center>
            );
          })}
        </Slider>
      </div>
      <div>
        {/* Mobile View */}
        <div
          className="app-container1"
          style={isMobile ? mobtog1 : desktoggle1}
        >
          <button
            type="button"
            onClick={hangletoggle}
            className="btn w-auto text-white bg-dark flex self-center"
          >
            See All Categories
          </button>
          {visible && (
            <div className="tags-column" onClick={hangletoggle1}>
              {tagData.map((tagEntry, index) => (
                <div
                  key={index}
                  className={`tag ${selectedTag === tagEntry.tag ? "selected" : ""
                    }`}
                  onClick={() => handleTagClick(tagEntry.tag)}
                >
                  {tagEntry.tag}
                </div>
              ))}
            </div>
          )}
          <div className="content-container1 ">
            {selectedTag ? (
              <div className="selected-tag-content">
                <h2>{selectedTag}</h2>
                {tagData.map((tagEntry) =>
                  tagEntry.tag === selectedTag ? (
                    <div key={tagEntry.tag}>
                      {tagEntry.tag === "Career Counseling" && (
                        <div>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <div>
                              <img
                                src="https://idreamcareer.com/wp-content/uploads/2020/03/Career-Counselling.jpg"
                                style={isMobile ? mobistyle1 : deskistyle1}
                              />
                            </div>

                            <div
                              style={{
                                margin: "0px 1rem",
                                width: "400px",
                                height: "250px",
                              }}
                            >
                              <b
                                style={{
                                  fontSize: "28px",
                                  fontFamily: "times",
                                  color: "brown",
                                }}
                              >
                                Yes, You Should Take
                              </b>
                              <p
                                className="content"
                                style={{
                                  color: "#002955",
                                  fontSize: "15px",
                                  fontFamily: "Verdana",
                                }}
                              >
                                Nothing is More Important that Your
                                <br /> Career, Choose Wisely...!
                              </p>
                              <Link
                                to={`/ads-listing/`}
                                onClick={() => {
                                  console.log(searchSending);
                                  for (const key in searchSending) {
                                    if (
                                      searchSending[key]?.trim() === "" ||
                                      searchSending[key] === null
                                    ) {
                                      delete searchSending[key];
                                    }
                                  }
                                  searchSending["extraField"] = { ...extraFieldCopy };
                                  searchSending["subCategoryValue"] = "Career Counseling";; // Object Assign to searchSending object
                                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                                }}
                              >
                                <a
                                  className="btn text-white fs-12"
                                  href=""   >
                                  Click Here...!
                                </a></Link>
                            </div>
                          </div>
                        </div>
                      )}

                      {tagEntry.tag === "Certification & Training" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://nbsl.org.uk/images/bullets/People/training-areas.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://t4.ftcdn.net/jpg/04/84/54/89/360_F_484548953_06ErXdNj5weviQyDEnyvxb8jfmBoYxMM.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Certification & Training";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href=""  >
                              Click Here...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Competative Exams Coaching" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://media.istockphoto.com/id/837064622/photo/are-you-ready-chalkboard-on-a-wooden-background.jpg?s=612x612&w=0&k=20&c=fzmtJCgQfsdEvIHL-4ggi0Bp2UB9-ZL4P1zt5ZesUH4="
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://miro.medium.com/v2/resize:fit:1200/1*VJ3LqhALWuyiqgWNB_WjuQ.jpeg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Compitative Exams Coaching";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href=""  >
                              Click Here...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Distance Learning Courses" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://icdistanceeducation.com/wp-content/uploads/2021/03/distance-learning-programs.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://cdn.elearningindustry.com/wp-content/uploads/2015/12/5-ways-transform-distance-learning-content-1024x754.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Distance Learning Courses";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href="" >
                              Click Here...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Entrance Exam Coaching" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://5.imimg.com/data5/JP/DS/GLADMIN-29829629/pmt-500x500.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://cdn.pixabay.com/photo/2015/09/22/15/02/studying-951818_1280.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Entrance Exam Coaching"; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href="" >
                              Click Here...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Hobby Classes" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2018/02/19005407/Hobbies.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://i.pinimg.com/originals/84/e2/33/84e2337b4b95905c95a58ca9428108e5.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Hobby Classes";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href="" >
                              Click Here...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Play Schools & Creche" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://planetadth.com/wp-content/uploads/2023/06/photo_6328044629282372156_w.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://planetadth.com/wp-content/uploads/2023/06/Footprints-1-scaled.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Play Schools-Creche";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href="">
                              Click Here...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Schools & School Tuitions" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://www.99entranceexam.in/wp-content/uploads/2018/03/Best-Schools-in-India.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://top10bucket.com/wp-content/uploads/2023/02/best-5-schools-of-india-800x445.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Schools & School Tuitions";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href="" >
                              Click Here...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Study Abroad Consultants" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/small_Tips_To_Choose_The_Best_Study_Abroad_Consultants_aeadfc998a.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/small_Tips_To_Choose_The_Best_Study_Abroad_Consultants_aeadfc998a.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Study Abroad Consultants";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href="" >
                              Click Here...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Text Books & Study Materials" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://media.istockphoto.com/id/487916256/photo/pen-pencil-study-textbooks.jpg?s=612x612&w=0&k=20&c=F_Ld-0JizqlJx8_4LW3WYjNgov3q89MbCY2JMAy2uIg="
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://burst.shopifycdn.com/photos/library-books.jpg?width=1200&format=pjpg&exif=1&iptc=1"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Text books & Study Material";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href=""   >
                              Click Here...!
                            </a></Link></>
                      )}
                      {tagEntry.tag === "Vocational Skills Training" && (
                        <>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <img
                              src="https://franchiseindia.s3.ap-south-1.amazonaws.com/uploads/content/edu/art/vocational-training-56abb32548.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://cdn.vocationaltraininghq.com/wp-content/uploads/Feature-Image.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Vocational Skill Training";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a
                              className="btn text-white fs-12"
                              href="" >
                              Click Here...!
                            </a></Link></>
                      )}
                    </div>
                  ) : null
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* Desktop View */}
        <div className="app-container1" style={isMobile ? mobtog : desktoggle}>
          <div className="tags-column">
            {tagData.map((tagEntry, index) => (
              <div
                key={index}
                className={`tag ${selectedTag === tagEntry.tag ? "selected" : ""
                  }`}
                onClick={() => handleTagClick(tagEntry.tag)}
              >
                {tagEntry.tag}
              </div>
            ))}
          </div>

          <div className="content-container1 ml-3 mr-3">
            {selectedTag ? (
              <div className="selected-tag-content">
                <h2>{selectedTag}</h2>
                {tagData.map((tagEntry) =>
                  tagEntry.tag === selectedTag ? (
                    <div key={tagEntry.tag}>
                      {tagEntry.tag === "Career Counseling" && (
                        <div>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <div>
                              <img
                                src="https://idreamcareer.com/wp-content/uploads/2020/03/Career-Counselling.jpg"
                                style={isMobile ? mobistyle1 : deskistyle1}
                              />
                            </div>

                            <div
                              style={{
                                margin: "0px 3rem",
                                width: "400px",
                                height: "300px",
                              }}
                            >
                              <b
                                style={{
                                  fontSize: "30px",
                                  fontFamily: "times",
                                  color: "brown",
                                }}
                              >
                                Yes, You Should Take
                              </b>
                              <p
                                className="content"
                                style={{
                                  color: "#002955",
                                  fontSize: "20px",
                                  fontFamily: "Verdana",
                                }}
                              >
                                Nothing is More Important that Your Career,
                                Choose Wisely...!
                              </p>
                              <Link
                                to={`/ads-listing/`}
                                onClick={() => {
                                  console.log(searchSending);
                                  for (const key in searchSending) {
                                    if (
                                      searchSending[key]?.trim() === "" ||
                                      searchSending[key] === null
                                    ) {
                                      delete searchSending[key];
                                    }
                                  }
                                  searchSending["extraField"] = { ...extraFieldCopy };
                                  searchSending["subCategoryValue"] = "Career Counseling"; // Object Assign to searchSending object
                                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                                }}
                              >
                                <a
                                  className="btn text-white fs-12"
                                  href="" >
                                  Click Here...!
                                </a></Link>
                            </div>
                          </div>
                        </div>
                      )}

                      {tagEntry.tag === "Certification & Training" && (
                        <>
                          <h6>
                            Get Training and Certification in Top Certified
                            Institutions All over India{" "}
                          </h6>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://nbsl.org.uk/images/bullets/People/training-areas.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://t4.ftcdn.net/jpg/04/84/54/89/360_F_484548953_06ErXdNj5weviQyDEnyvxb8jfmBoYxMM.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <div className="float-right ">
                            <p>See Ads of the Top Institutions</p>
                            <Link
                              to={`/ads-listing/`}
                              onClick={() => {
                                console.log(searchSending);
                                for (const key in searchSending) {
                                  if (
                                    searchSending[key]?.trim() === "" ||
                                    searchSending[key] === null
                                  ) {
                                    delete searchSending[key];
                                  }
                                }
                                searchSending["extraField"] = { ...extraFieldCopy };
                                searchSending["subCategoryValue"] = "Certification & Training";; // Object Assign to searchSending object
                                disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                              }}
                            >
                              <a className="button fs-12 mt-1" href="">
                                Click This...!
                              </a></Link>
                          </div>
                        </>
                      )}
                      {tagEntry.tag === "Competative Exams Coaching" && (
                        <>
                          <h6>
                            See Our Top Competative Exam Coaching Centers All
                            Over India
                          </h6>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://media.istockphoto.com/id/837064622/photo/are-you-ready-chalkboard-on-a-wooden-background.jpg?s=612x612&w=0&k=20&c=fzmtJCgQfsdEvIHL-4ggi0Bp2UB9-ZL4P1zt5ZesUH4="
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://miro.medium.com/v2/resize:fit:1200/1*VJ3LqhALWuyiqgWNB_WjuQ.jpeg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Compitative Exams Coaching";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a className="button fs-12 mt-1" href="" >
                              Click This...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Distance Learning Courses" && (
                        <>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://icdistanceeducation.com/wp-content/uploads/2021/03/distance-learning-programs.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://cdn.elearningindustry.com/wp-content/uploads/2015/12/5-ways-transform-distance-learning-content-1024x754.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />

                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Distance Learning Courses";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a className="button fs-12 mt-1" href="" >
                              Click This...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Entrance Exam Coaching" && (
                        <>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://5.imimg.com/data5/JP/DS/GLADMIN-29829629/pmt-500x500.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://cdn.pixabay.com/photo/2015/09/22/15/02/studying-951818_1280.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Entrance Exam Coaching";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a className="button fs-12 mt-1" href="" >
                              Click This...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Hobby Classes" && (
                        <>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2018/02/19005407/Hobbies.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://i.pinimg.com/originals/84/e2/33/84e2337b4b95905c95a58ca9428108e5.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Hobby Classes";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a className="button fs-12 mt-1" href="" >
                              Click This...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Play Schools & Creche" && (
                        <>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://planetadth.com/wp-content/uploads/2023/06/photo_6328044629282372156_w.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://planetadth.com/wp-content/uploads/2023/06/Footprints-1-scaled.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Play Schools-Creche";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a className="button fs-12 mt-1" href="">
                              Click This...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Schools & School Tuitions" && (
                        <>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://www.99entranceexam.in/wp-content/uploads/2018/03/Best-Schools-in-India.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://top10bucket.com/wp-content/uploads/2023/02/best-5-schools-of-india-800x445.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Schools & School Tuitions";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a className="button fs-12 mt-1" href="#training" >
                              Click This...!
                            </a>
                          </Link>
                        </>
                      )}
                      {tagEntry.tag === "Study Abroad Consultants" && (
                        <>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/small_Tips_To_Choose_The_Best_Study_Abroad_Consultants_aeadfc998a.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/small_Tips_To_Choose_The_Best_Study_Abroad_Consultants_aeadfc998a.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Study Abroad Consultants";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a className="button fs-12 mt-1" href="" >
                              Click This...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Text Books & Study Materials" && (
                        <>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://media.istockphoto.com/id/487916256/photo/pen-pencil-study-textbooks.jpg?s=612x612&w=0&k=20&c=F_Ld-0JizqlJx8_4LW3WYjNgov3q89MbCY2JMAy2uIg="
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://burst.shopifycdn.com/photos/library-books.jpg?width=1200&format=pjpg&exif=1&iptc=1"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Text books & Study Material";; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a className="button fs-12 mt-1" href="" >
                              Click This...!
                            </a></Link>
                        </>
                      )}
                      {tagEntry.tag === "Vocational Skills Training" && (
                        <>
                          <div
                            className=""
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <img
                              src="https://franchiseindia.s3.ap-south-1.amazonaws.com/uploads/content/edu/art/vocational-training-56abb32548.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                            <img
                              src="https://cdn.vocationaltraininghq.com/wp-content/uploads/Feature-Image.jpg"
                              style={isMobile ? mobistyle1 : deskistyle1}
                            />
                          </div>
                          <Link
                            to={`/ads-listing/`}
                            onClick={() => {
                              console.log(searchSending);
                              for (const key in searchSending) {
                                if (
                                  searchSending[key]?.trim() === "" ||
                                  searchSending[key] === null
                                ) {
                                  delete searchSending[key];
                                }
                              }
                              searchSending["extraField"] = { ...extraFieldCopy };
                              searchSending["subCategoryValue"] = "Vocational Skills Training"; // Object Assign to searchSending object
                              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                            }}
                          >
                            <a className="button fs-12 mt-1" href="" >
                              Click This...!
                            </a></Link>
                        </>
                      )}
                    </div>
                  ) : null
                )}
              </div>
            ) : (
              <>
                <div className="selected-tag-content">
                  <h2>Career Counseling</h2>
                  {tagData.map((tagEntry) => (
                    <div key={tagEntry.tag}>
                      {tagEntry.tag === "Career Counseling" && (
                        <div>
                          <div
                            className=""
                            style={isMobile ? mobistyle : deskistyle}
                          >
                            <div>
                              <img
                                src="https://idreamcareer.com/wp-content/uploads/2020/03/Career-Counselling.jpg"
                                style={isMobile ? mobistyle1 : deskistyle1}
                              />
                            </div>

                            <div
                              style={{
                                margin: "0px 3rem",
                                width: "400px",
                                height: "300px",
                              }}
                            >
                              <b
                                style={{
                                  fontSize: "30px",
                                  fontFamily: "times",
                                  color: "brown",
                                }}
                              >
                                Yes, You Should Take
                              </b>
                              <p
                                className="content"
                                style={{
                                  color: "#002955",
                                  fontSize: "20px",
                                  fontFamily: "Verdana",
                                }}
                              >
                                Nothing is More Important that Your Career,
                                Choose Wisely...!
                              </p>
                              <Link
                                to={`/ads-listing/`}
                                onClick={() => {
                                  console.log(searchSending);
                                  for (const key in searchSending) {
                                    if (
                                      searchSending[key]?.trim() === "" ||
                                      searchSending[key] === null
                                    ) {
                                      delete searchSending[key];
                                    }
                                  }
                                  searchSending["extraField"] = { ...extraFieldCopy };
                                  searchSending["subCategoryValue"] = "Career Counseling";// Object Assign to searchSending object
                                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                                }}
                              >
                                <a
                                  className="btn text-white fs-12"
                                  href=""  >
                                  Click Here...!
                                </a></Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <></>
      </div>
      {/* sdsd */}
      <div>
        {/* <div style={modalContainerStyle} className="bikefilcon123">
           
            <div className="buttondiv123"
              
            >
              <button
                onClick={() => setSelectedButton("button1")}
                className="button321"
              >
                Career Stream
              </button>{" "}
             
              <button
                onClick={() => setSelectedButton("button2")}
                className="button321"
              >
                DeliveryMode
              </button>{" "}
             
              <button
                onClick={() => setSelectedButton("button3")}
                className="button321"
              >
                Listedby
              </button>{" "}
            </div>
            {selectedButton && renderModalContent()}
            <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy, ...extraFieldCopy1, ...extraFieldCopy2 }; // Object Assign to searchSending object

                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <button
                  className="btn houzez-search-button elementor-button elementor-size-sm"
                  style={{
                    background: "#a71616bf",
                    color: "white",
                  }}
                  id="serbtn"
                >
                  Search
                </button>
              </Link>
          </div>
        */}
      </div>
      {loading && (
        <div>
          <h5>
            {" "}
            <div className="example">
              <Spin />
            </div>{" "}
          </h5>{" "}
        </div>
      )}
      <div>
        <div className="filmain">
          <div className="fildi">
            <input
              name="Brand Name"
              required
              className=""
              style={{
                textTransform: "uppercase",
                border: "none",
              }}
              placeholder="Search Here"
              onChange={(e) => setSearchValue(e.target.value.trim())}
            />
            <div className="tildiv">
              <Link to={`/ads-listing1/`} onClick={searchArray}>
                <button className="search-btn"><BiSearch style={{ fontSize: "20px" }} />Search</button>
              </Link>
              &nbsp;
              &nbsp;
              <button className="filter-btn" onClick={handleFilterClick}>
                <AiOutlineFilter style={{ fontSize: "20px" }} />

                Filter
              </button>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="fildi2" style={isMobile ? mob1 : desk1}>
            <div>
              <button onClick={handleClosePopup} style={{ float: "right" }}>
                <AiOutlineCloseSquare
                  style={{ color: "red", fontSize: "20px" }}
                />
              </button>
              <SearchBoxEducation />
            </div>
          </div>
        )}
      </div>
      {!loading && (
        <>
          <div className="mb-3" style={isMobile ? mobstyle : deskstyle}>
            <Feature value="Education" />{" "}

            <Owldemo1 greeting={greeting} subcategory={"Education"} />{" "}
            <div id="career">
              <Owldemo1 greeting={greeting} subcategory={"Career Counseling"} />
            </div>
            <div id="training">
              <Owldemo1
                greeting={greeting}
                subcategory={"Certification & Training"}
              />
            </div>{" "}
            <Owldemo1
              greeting={greeting}
              subcategory={"Compitative Exams Coaching"}
            />{" "}
            <Owldemo1
              greeting={greeting}
              subcategory={"Distance Learning Courses"}
            />{" "}
            <Owldemo1
              greeting={greeting}
              subcategory={"Entrance Exam Coaching"}
            />{" "}
            <Owldemo1 greeting={greeting} subcategory={"Hobby Classes"} />{" "}
            <Owldemo1 greeting={greeting} subcategory={"Play Schools-Creche"} />{" "}
            <Owldemo1
              greeting={greeting}
              subcategory={"Schools & School Tuitions"}
            />{" "}
            <Owldemo1
              greeting={greeting}
              subcategory={"Study Abroad Consultants"}
            />{" "}
            <Owldemo1
              greeting={greeting}
              subcategory={"Text books & Study Material"}
            />{" "}
            <Owldemo1
              greeting={greeting}
              subcategory={"Vocational Skill Training"}
            />{" "}
          </div>
          <div style={isMobile ? mobi : deski}>
            <TrendingAds />

            <NewsArticles selectedCategory={"Education"} />{" "}

            <MyGallery selectedCategory="Education" />


          </div>
        </>
      )}
      {/* <NewsArticles category={"RealEstate"}/> */}
    </>
  );
};

export default EducationCat;





