// import React, { useState } from 'react';
// import './BooksSportsHobbiesCat.css';
// import img from "../../../images/hobbies00.jpg"
// import MyGallery from './MyGallery';

// function BooksSportsHobbiesCat() {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isLocationOpen, setIsLocationOpen] = useState(false);
//   const [minBudget, setMinBudget] = useState('');
//   const [maxBudget, setMaxBudget] = useState('');



//   // Toggle dropdown visibility
//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };



//   // Toggle dropdown visibility
//   const toggleLocationDropdown = () => {
//     setIsLocationOpen(!isLocationOpen);
//   };
  

//   // Handle the apply button click
//   const handleApply = () => {
//     if (minBudget && maxBudget) {
//       alert(`Min Budget: ${minBudget}, Max Budget: ${maxBudget}`);
//     } else {
//       alert('Please enter both minimum and maximum budget values.');
//     }
//   };

//   const ads = [
//     { title: 'Soccer FootBall', price: '₹ 3,000', location: 'S.A.S. Nagar, Punjab', date: 'Nov 14', image: 'https://cdn.pixabay.com/photo/2018/07/28/14/30/soccer-3568168_1280.jpg', featured: true },
//     { title: 'Maurice Lacroix brand new Swiss watch', price: '₹ 1,70,000', location: 'PNB Enclave, Dehradun', date: 'Nov 06', image: 'https://cdn1.ethoswatches.com/media/catalog/product/cache/6e5de5bc3d185d8179cdc7258143f41a/m/a/maurice-lacroix-pontos-pt6248-brz01-330-3-multiple-5.jpg', featured: true },
//     { title: 'Book', price: '₹ 500', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://cdn.pixabay.com/photo/2018/07/01/20/01/music-3510326_1280.jpg', featured: true },
//     { title: 'Puma shoe (motorsport edition)', price: '₹ 3,000', location: 'Rathnapuri, Coimbatore', date: 'Today', image: 'https://i.ebayimg.com/images/g/-FoAAOSwFTpi1gbn/s-l1200.jpg', featured: false },
//     { title: 'Ajanta Wall Clock', price: '₹ 200', location: 'Sahastradhara Road, Dehradun', date: 'Today', image: 'https://bigdeals24x7.com/uploads/product_image/product_Ajanta-Classic-Musical-Quartz-Pendulum-Wall-Clock-with-Decorative-Daimonds-3927-Red_1.webp', featured: false },
//     { title: 'Badminton-sport-shuttlecocks', price: '₹ 2600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://cdn.pixabay.com/photo/2016/05/31/23/21/badminton-1428046_960_720.jpg', featured: false },
//     { title: 'Soccer FootBall', price: '₹ 3,000', location: 'S.A.S. Nagar, Punjab', date: 'Nov 14', image: 'https://cdn.pixabay.com/photo/2018/07/28/14/30/soccer-3568168_1280.jpg', featured: true },
//     { title: 'Maurice Lacroix brand new Swiss watch', price: '₹ 1,70,000', location: 'PNB Enclave, Dehradun', date: 'Nov 06', image: 'https://cdn1.ethoswatches.com/media/catalog/product/cache/6e5de5bc3d185d8179cdc7258143f41a/m/a/maurice-lacroix-pontos-pt6248-brz01-330-3-multiple-5.jpg', featured: true },
//     { title: 'Maurice Lacroix brand new Swiss watch', price: '₹ 1,70,000', location: 'PNB Enclave, Dehradun', date: 'Nov 06', image: 'https://cdn1.ethoswatches.com/media/catalog/product/cache/6e5de5bc3d185d8179cdc7258143f41a/m/a/maurice-lacroix-pontos-pt6248-brz01-330-3-multiple-5.jpg', featured: true },
//     { title: 'Adidas originals Yeezy size 8 brand new', price: '₹ 6,500', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://www.shoesmaster.jp/pub/wp/wp-content/uploads/2016/09/adidas-originals-yeezy-boost-350-v2-retail-list-01.jpg', featured: true },
//     { title: 'Puma shoe (motorsport edition)', price: '₹ 3,000', location: 'Rathnapuri, Coimbatore', date: 'Today', image: 'https://i.ebayimg.com/images/g/-FoAAOSwFTpi1gbn/s-l1200.jpg', featured: false },
//     { title: 'Ajanta Wall Clock', price: '₹ 200', location: 'Sahastradhara Road, Dehradun', date: 'Today', image: 'https://bigdeals24x7.com/uploads/product_image/product_Ajanta-Classic-Musical-Quartz-Pendulum-Wall-Clock-with-Decorative-Daimonds-3927-Red_1.webp', featured: false },
//     { title: 'Badminton-sport-shuttlecocks', price: '₹ 2600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://cdn.pixabay.com/photo/2016/05/31/23/21/badminton-1428046_960_720.jpg', featured: false },
//     { title: 'Soccer FootBall', price: '₹ 3,000', location: 'S.A.S. Nagar, Punjab', date: 'Nov 14', image: 'https://cdn.pixabay.com/photo/2018/07/28/14/30/soccer-3568168_1280.jpg', featured: true },
//     { title: 'Maurice Lacroix brand new Swiss watch', price: '₹ 1,70,000', location: 'PNB Enclave, Dehradun', date: 'Nov 06', image: 'https://cdn1.ethoswatches.com/media/catalog/product/cache/6e5de5bc3d185d8179cdc7258143f41a/m/a/maurice-lacroix-pontos-pt6248-brz01-330-3-multiple-5.jpg', featured: true },
//     { title: 'Maurice Lacroix brand new Swiss watch', price: '₹ 1,70,000', location: 'PNB Enclave, Dehradun', date: 'Nov 06', image: 'https://cdn1.ethoswatches.com/media/catalog/product/cache/6e5de5bc3d185d8179cdc7258143f41a/m/a/maurice-lacroix-pontos-pt6248-brz01-330-3-multiple-5.jpg', featured: true },
//     { title: 'Adidas originals Yeezy size 8 brand new', price: '₹ 6,500', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://www.shoesmaster.jp/pub/wp/wp-content/uploads/2016/09/adidas-originals-yeezy-boost-350-v2-retail-list-01.jpg', featured: true },
//     { title: 'Puma shoe (motorsport edition)', price: '₹ 3,000', location: 'Rathnapuri, Coimbatore', date: 'Today', image: 'https://i.ebayimg.com/images/g/-FoAAOSwFTpi1gbn/s-l1200.jpg', featured: false },
//     { title: 'Ajanta Wall Clock', price: '₹ 200', location: 'Sahastradhara Road, Dehradun', date: 'Today', image: 'https://bigdeals24x7.com/uploads/product_image/product_Ajanta-Classic-Musical-Quartz-Pendulum-Wall-Clock-with-Decorative-Daimonds-3927-Red_1.webp', featured: false },
//     { title: 'Badminton-sport-shuttlecocks', price: '₹ 2600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://cdn.pixabay.com/photo/2016/05/31/23/21/badminton-1428046_960_720.jpg', featured: false },
//   ];

//   return (
//     <>
//       <div className="image-box" style={{ position: 'relative' }}>
//         {/* Replace the source with your actual image */}
//         <img
//           className="hobbies1"
//           src={img}
//           alt="Full Width"
//         />
//         {/* Optional overlay text */}
//         {/* <div className="text99-awi">
//           <h1 className="text99-header-awi-24">
//             Explore Different Job Opportunities
//           </h1>
//         </div> */}
//       </div>

//  <div className="main">
//   <div className="leftctr">



//       {/* Categories Section */}
//       <div className="Categories">
//         {/* Dropdown Heading */}
//         <h3 className="headinghb" onClick={toggleDropdown}>
//           Categories
//           <span style={{ marginLeft: '20px', cursor: 'pointer' }}>
//             {isOpen ? '▲' : '▼'}
//           </span>
//         </h3>

//         {/* Dropdown Content */}
//         {isOpen && (
//           <ul className='scrollable-list'>
//             <li onClick={() => alert('Books, Sports & Hobbies clicked')}>
//               Books, Sports & Hobbies
//             </li>
//             <li onClick={() => alert('Books clicked')}>Books</li>
//             <li onClick={() => alert('Gym Fitness clicked')}>Gym Fitness</li>
//             <li onClick={() => alert('Musical Instruments clicked')}>
//               Musical Instruments
//             </li>
//             <li onClick={() => alert('Sports Equipments clicked')}>
//               Sports Equipments
//             </li>
//             <li onClick={() => alert('Other Categories clicked')}>
//               Other Categories
//             </li>
//           </ul>
//         )}
//       </div>

//       <div className="Categories">
//       {/* Dropdown Heading */}
//       <h3 className="headinghb" onClick={toggleLocationDropdown}>
//         Location
//         <span style={{ marginLeft: '20px', cursor: 'pointer' }}>
//           {isLocationOpen ? '▲' : '▼'}
//         </span>
//       </h3>

//       {/* Dropdown Content */}
//       {isLocationOpen && (
//         <ul className='scrollable-list'>
//           <li onClick={() => alert('New York selected')}>New York</li>
//           <li onClick={() => alert('Los Angeles selected')}>Los Angeles</li>
//           <li onClick={() => alert('Chicago selected')}>Chicago</li>
//           <li onClick={() => alert('Houston selected')}>Houston</li>
//           <li onClick={() => alert('Miami selected')}>Miami</li>
//           <li onClick={() => alert('Other Location selected')}>Other Location</li>
//         </ul>
//       )}
//     </div>

//     <div className="Categories">
//       <h3 className="budget-heading">Budget</h3>
//       <div className="budget-inputs">
//         <div className="input-group">
//           <label className='lbl' htmlFor="minBudget">Min:</label>
//           <input
//               className='inputbgt'
//             type="number"
//             id="minBudget"
//             value={minBudget}
//             onChange={(e) => setMinBudget(e.target.value)}
//             placeholder=" min value"
//           />
//         </div>
//         <div className="input-group">
//           <label className='lbl' htmlFor="maxBudget">Max:</label>
//           <input 
//             className='inputbgt'
//             type="number"
//             id="maxBudget"
//             value={maxBudget}
//             onChange={(e) => setMaxBudget(e.target.value)}
//             placeholder="max value"
//           />
//         </div>
//         <button className="apply-btn" onClick={handleApply}>
//           Apply
//         </button>
//       </div>
//     </div>
//     </div>

    
//   <div className="rightctr">
//    {/* Main Content */}
//    <div className="main-content">
         
//           {/* Ads Grid */}
//           <div className="ads-grid">
//             {ads.map((ad, index) => (
//               <div key={index} className="ad-card">
//                 {ad.featured && (
//                   <div className="featured-badge">Featured</div>
//                 )}
//                 <img
//                   src={ad.image}
//                   alt={ad.title}
//                   className="ad-image"
//                 />
//                 <h3 className="ad-price">{ad.price}</h3>
//                 <p className="ad-title">{ad.title}</p>
//                 <p className="ad-details">
//                   {ad.location} • {ad.date}
//                 </p>
//               </div>
//             ))}
//           </div>
//         </div>

//     </div>
//   </div>
//   {/* <div className="galleryhb">
//     <h3 className='headinghb'>Gallery for Books,Sports,Hobbies</h3>
  

//   </div> */}
   
//   <MyGallery selectedCategory="Hobbies" />
//     </>
//   );
// }

// export default BooksSportsHobbiesCat;



import React, { useState } from 'react';
import './BooksSportsHobbiesCat.css';
import img from "../../../images/hobbies00.jpg";
import MyGallery from './MyGallery';

import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { searchApi } from '../../../../store/ToggleSearchSlice';


function BooksSportsHobbiesCat() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [minBudget, setMinBudget] = useState('');
  const [maxBudget, setMaxBudget] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(''); // To track selected category
  const [SearchValue, setSearchValue] = useState("");
  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };

  const dispatch = useDispatch();
  // Categories Data
  const categories = [
    "Books",
    "Sports Equipment",
    "Musical Instruments",
    "Board Games & Puzzles",
    "Hobby Supplies",
    "Outdoor Equipment",
    "Collectibles",
    "Fitness Equipment",
  
  ];

  const locations = [
    'New York',
    'Los Angeles',
    'Chicago',
    'Houston',
    'Miami',
    'Other Location',
  ];

  // Ads Data with category included
  const ads = [
    { title: 'Soccer FootBall', price: '₹ 3,000', location: 'S.A.S. Nagar, Punjab', date: 'Nov 14', image: 'https://cdn.pixabay.com/photo/2018/07/28/14/30/soccer-3568168_1280.jpg', featured: true, category: 'Sports Equipments' },
    { title: 'Maurice Lacroix brand new Swiss watch', price: '₹ 1,70,000', location: 'PNB Enclave, Dehradun', date: 'Nov 06', image: 'https://cdn1.ethoswatches.com/media/catalog/product/cache/6e5de5bc3d185d8179cdc7258143f41a/m/a/maurice-lacroix-pontos-pt6248-brz01-330-3-multiple-5.jpg', featured: true, category: 'Other Categories' },
    { title: 'Book', price: '₹ 500', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://cdn.pixabay.com/photo/2018/07/01/20/01/music-3510326_1280.jpg', featured: true, category: 'Books' },
    { title: 'Puma shoe (motorsport edition)', price: '₹ 3,000', location: 'Rathnapuri, Coimbatore', date: 'Today', image: 'https://i.ebayimg.com/images/g/-FoAAOSwFTpi1gbn/s-l1200.jpg', featured: false, category: 'Sports Equipments' },
    { title: 'Ajanta Wall Clock', price: '₹ 200', location: 'Sahastradhara Road, Dehradun', date: 'Today', image: 'https://bigdeals24x7.com/uploads/product_image/product_Ajanta-Classic-Musical-Quartz-Pendulum-Wall-Clock-with-Decorative-Daimonds-3927-Red_1.webp', featured: false, category: 'Other Categories' },
    { title: 'Badminton-sport-shuttlecocks', price: '₹ 2,600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://cdn.pixabay.com/photo/2016/05/31/23/21/badminton-1428046_960_720.jpg', featured: false, category: 'Sports Equipments' },
    { title: 'Badminton-sport-shuttlecocks', price: '₹ 2,600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://cdn.pixabay.com/photo/2016/05/31/23/21/badminton-1428046_960_720.jpg', featured: false, category: 'Hobbies' },
    { title: 'Badminton-sport-shuttlecocks', price: '₹ 2,600', location: 'Churan Pur, Ludhiana', date: 'Today', image: 'https://cdn.pixabay.com/photo/2016/05/31/23/21/badminton-1428046_960_720.jpg', featured: false, category: 'Gym Fitness' },
    { title: 'Book', price: '₹ 500', location: 'Mansarovar Garden, Delhi', date: '6 days ago', image: 'https://cdn.pixabay.com/photo/2018/07/01/20/01/music-3510326_1280.jpg', featured: true, category: 'Musical Instruments' },

    // Add more ads here with their respective categories...
  ];

  // Filter ads based on the selected category
  const filteredAds = selectedCategory
    ? ads.filter(ad => ad.category === selectedCategory)
    : ads;

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleLocationDropdown = () => {
    setIsLocationOpen(!isLocationOpen);
  };

  // Handle the apply button click for budget
  const handleApply = () => {
    if (minBudget && maxBudget) {
      alert(`Min Budget: ${minBudget}, Max Budget: ${maxBudget}`);
    } else {
      alert('Please enter both minimum and maximum budget values.');
    }
  };

  return (
    <>
      <div className="image-box" style={{ position: 'relative' }}>
     
          <h1 className="text99-header-awi-24">
            Sports Books Hobbies
          </h1>
      
        <img className="hobbies1" src={img} alt="Full Width" />
        <div className="wholemid">
          <div className="filetr2234">
            <div className="furnifil">
              <input
                name="Brand Name"
                required
                className="realserac"
                style={{
                  borderRadius: "20px",
                
                  textTransform: "uppercase",
                  border: "none",
                  
                }}
                placeholder="Search your Fashion"
                onChange={(e) => setSearchValue(e.target.value.trim())}
                
              />
              <div className="tildiv">
                <Link to={`/ads-listing1/`} onClick={searchArray}>
                  <button className="search-btn-furniture">Search</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
       
      </div>

      <div className="main">
        <div className="leftctr">
          {/* Categories Section - Now as Buttons */}
          <div className="Categories">
            <h3 className="headinghb">Categories</h3>
            <div className="category-buttons">
              {categories.map((category, index) => (
                <button
                  key={index}
                  className={`category-button ${selectedCategory === category ? 'selected' : ''}`}
                  onClick={() => setSelectedCategory(category === selectedCategory ? '' : category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>

          {/* Location Section */}
          <div className="Categories">
            <h3 className="headinghb" onClick={toggleLocationDropdown}>
              Location
              <span style={{ marginLeft: '20px', cursor: 'pointer' }}>
                {isLocationOpen ? '▲' : '▼'}
              </span>
            </h3>
            {isLocationOpen && (
              <ul className="scrollable-list">
                {locations.map((location, index) => (
                  <li key={index} onClick={() => alert(`${location} selected`)}>
                    {location}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Budget Section */}
          <div className="Categories">
            <h3 className="budget-heading">Budget</h3>
            <div className="budget-inputs">
              <div className="input-group">
                <label className="lbl" htmlFor="minBudget">Min:</label>
                <input
                  className="inputbgt"
                  type="number"
                  id="minBudget"
                  value={minBudget}
                  onChange={(e) => setMinBudget(e.target.value)}
                  placeholder="min value"
                />
              </div>
              <div className="input-group">
                <label className="lbl" htmlFor="maxBudget">Max:</label>
                <input
                  className="inputbgt"
                  type="number"
                  id="maxBudget"
                  value={maxBudget}
                  onChange={(e) => setMaxBudget(e.target.value)}
                  placeholder="max value"
                />
              </div>
              <button className="apply-btn" onClick={handleApply}>
                Apply
              </button>
            </div>
          </div>
        </div>

        <div className="rightctr">
          {/* Main Content */}
          <div className="main-content">
            {/* Ads Grid */}
            <div className="ads-grid">
              {filteredAds.map((ad, index) => (
                <div key={index} className="ad-card">
                  {ad.featured && (
                    <div className="featured-badge">Featured</div>
                  )}
                  <img
                    src={ad.image}
                    alt={ad.title}
                    className="ad-image"
                  />
                  <h3 className="ad-price">{ad.price}</h3>
                  <p className="ad-title">{ad.title}</p>
                  <p className="ad-details">
                    {ad.location} • {ad.date}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <MyGallery selectedCategory='Hobbies' />
    </>
  );
}

export default BooksSportsHobbiesCat;
