import React, { useState, useEffect } from 'react';
import YoutubeMagic from '../ContentLoader/YoutubeMagic';

const Transactions = () => {
    const [transactions, settransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);
    const [itemsToLoad, setItemsToLoad] = useState(25);
    const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);



  useEffect(() => {
    fetch(`https://demoadmin.hola9.com/api/payment/transaction-details/?limit=${itemsToLoad}`)
    .then(response => response.json())
      .then(data => {
        settransactions(data);
        setIsLoading(false); // Set loading to false once data is fetched
        setIsLoading1(false); // Set loading to false once data is fetched
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false in case of an error
        setIsLoading1(false); // Set loading to false in case of an error
      });
  }, [itemsToLoad]);
  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 25);
    setLimitIncreaseCount((prevCount) => prevCount + 1);

  };
  const [prevDataLength, setPrevDataLength] = useState(0);
  const [cardCount, setCardCount] = useState(20);

  const loadMoreCards = () => {
    // Increment the card count by 30.
    setCardCount(cardCount + 20);
      setLimitIncreaseCount(0);

  };
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isLoading && limitIncreaseCount != 3) {
        const newDataLength = transactions.length;
  
        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);
  
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isLoading, transactions, prevDataLength,limitIncreaseCount]);

  return (
    <>
    <div className="business-profile-container">
      {isLoading ? (
        <p><YoutubeMagic/></p>
      ) : (
        transactions.slice(0,cardCount).map((data) => (
          <>
          < TransactionsData key={data.id} data={data} load={isLoading1} />
          </>
        ))
      )}

    </div>
    {cardCount > transactions.length && (
      <>
      {isLoading1 && <p className="loading-message"> <YoutubeMagic/> </p>}
      </>
      )}
      {cardCount < transactions.length && (
                    <div
                      className="loadbt text-center"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      <button className="loadmore" onClick={loadMoreCards}>
                        Load More
                      </button>
                    </div>
                  )}
</>
  );
};

const TransactionsData = ({data}) => {

  return (
    <div style={{ boxShadow:'0px 1px 2px gray', padding:'30px' }}>
      {/* <p style={{margin:'0'}}>{data.id}</p> */}
      <p style={{margin:'0', color:'black'}}> <i className='fa fa-envelope'/> &nbsp; {data.email}</p>
      <p style={{margin:'0', color:'black'}}> <i className='fa fa-id-card'/> &nbsp; {data.userID}</p>
      <p style={{margin:'0', color:'black'}}> <i className='fa fa-phone'/> &nbsp; {data.phoneNumber}</p>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
      <p style={{margin:'0', color:'black'}}> <i className='fa fa-paper-plane'/> &nbsp; {data.plan}</p><button style={{background: data.paymentStatus === 'Cancelled' ? 'red' : 'darkblue', color :'white', padding:'2.5px 13px'}}>{data.paymentStatus}</button>
      </div>
      <hr/>
    <div className="">
      
      <span style={{display:'flex', justifyContent:'space-between', gap:'10px'}}>
      <p style={{margin:'0', color:'gray'}}>Payment Token Id </p>
      <h6>{data.payment_token_id}</h6>
      </span>
      <span style={{display:'flex', justifyContent:'space-between'}}>
      <p style={{margin:'0', color:'gray'}}>Payment Id</p>
      <h6>{data.payment_id}</h6>
      </span>
      <span style={{display:'flex', justifyContent:'space-between'}}>
      <p style={{margin:'0', color:'gray'}}>Transaction Id</p>
      <h6>{data.tranid}</h6>
      </span>
      <span style={{display:'flex', justifyContent:'space-between'}}>
      <p style={{margin:'0', color:'gray'}}>Order Payment Amount</p>
      <h6>{data.order_payment_amount}</h6>
      </span>
      <span style={{display:'flex', justifyContent:'space-between'}}>
      <p style={{margin:'0', color:'gray'}}>Order Date</p>
      <h6>{data.ordrDate}</h6>
      </span>
      <span style={{display:'flex', justifyContent:'space-between'}}>
      <p style={{margin:'0', color:'gray'}}>Ads Value</p>
      <h6>{data.adsValue}</h6>
      </span>
      <span style={{display:'flex', justifyContent:'space-between'}}>
      <p style={{margin:'0', color:'gray'}}>Months Value</p>
      <h6>{data.monthsVale}</h6>
      </span>
      <span style={{display:'flex', justifyContent:'space-between'}}>
      <p style={{margin:'0', color:'gray'}}>City</p>
      <h6>{data.City}</h6>
      </span>
    </div>   
  </div>
  );
};


export default Transactions;