

import React, { useState, useEffect } from 'react';
import "./Notification.css"
import { Link } from 'react-router-dom';
import Hola9logo from "../../images/Hola9-logo.jpg";
import { AiFillNotification } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
const InsideNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [prevLength, setPrevLength] = useState(0);
    const storedPrevLength = localStorage.getItem('prevLength');


    useEffect(() => {
        fetch('https://demoadmin.hola9.com/api/adsapi/notifications?user=70')
            .then(response => response.json())
            .then(data => {
                setNotifications(data);
            })
            .catch(error => console.error('Error fetching notifications:', error));
    }, []); // Fetch notifications only once on component mount

    const removeNotification = (index) => {
        const updatedNotifications = [...notifications];
        updatedNotifications.splice(index, 1);
        setNotifications(updatedNotifications);
    };

    const variable23 = notifications.slice(0, 30);
    // const variable23 = notifications.slice(0, notifications.length - 400);
    return (
        <>
            <div style={{ padding: "2.5% 5%" }}>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", margin: "10px" }}>
                    <h1 className='notification-sty-head'>ALL NOTIFICATION's</h1>
                    <button className='notification-sty-button1'  
                            ><span style={{ color: "white" }}>Filter</span></button>
                </div>
                <div className="notification-table24">
                    <table>
                        <tbody>
                            {variable23.map((item, index) => (
                                <tr key={index}>
                                    <td >
                                        <AiFillNotification className='blink-dot' />
                                        {item.message}
                                        <span onClick={() => removeNotification(index)} className="close-btn">
                                        <MdDelete className='notification-sty-iconDel' /></span>

                                       <div>
                                            <p  style={{  fontSize: "9px", marginTop:"1%", float:"right"}}> {item.created_date} </p> 
                                       </div> 
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                    <img src={Hola9logo} className="notification-sty-img" alt="Golo" />
                    <p className='notification-sty-quote'>"Empowering connections, one listing at a time. Discover the potential in your possessions with Hola9."</p>
                    <button className='notification-sty-button2' style={{ width: "15%" }}><Link to='/' style={{ color: "white", textDecoration: "none" }}>Home</Link></button>
                </div>
            </div>
        </>
    )
}

export default InsideNotifications;






//FILTERING CODE 

// import React, { useState, useEffect } from 'react';
// import "./Notification.css"
// import { Link } from 'react-router-dom';
// import Hola9logo from "../../images/Hola9-logo.jpg";
// import { AiFillNotification } from "react-icons/ai";
// import { MdDelete } from "react-icons/md";



// const InsideNotifications = () => {
//     const [notifications, setNotifications] = useState([]);
//     const [prevLength, setPrevLength] = useState(0);
//     const [filterKeyword, setFilterKeyword] = useState('');
//     const storedPrevLength = localStorage.getItem('prevLength');


//     useEffect(() => {
//         fetch('https://demoadmin.hola9.com/api/adsapi/notifications?user=70')
//             .then(response => response.json())
//             .then(data => {
//                 setNotifications(data);
//             })
//             .catch(error => console.error('Error fetching notifications:', error));
//     }, []); // Fetch notifications only once on component mount

//     const removeNotification = (index) => {
//         const updatedNotifications = [...notifications];
//         updatedNotifications.splice(index, 1);
//         setNotifications(updatedNotifications);
//     };

//     const filteredNotifications = notifications.filter(item => {
//         // Assuming 'item.name' contains the name of the product
//         return item.name.toLowerCase().includes(filterKeyword.toLowerCase()) || item.name.toUpperCase().includes(filterKeyword.toUpperCase()) ;
//     });


//     const variable23 = notifications.slice(0, 30);
//     // const variable23 = notifications.slice(0, notifications.length - 400);

//     return (
//         <>
//             <div style={{ padding: "2.5% 5%" }}>
//                 <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", margin: "10px" }}>
//                     <h1 className='notification-sty-head'>ALL NOTIFICATION's</h1>
//                     <input
//                         type="text"
//                         value={filterKeyword}
//                         onChange={(e) => setFilterKeyword(e.target.value)}
//                         placeholder="Filter by message content"
//                         className='notification-sty-button1' 
//                     />
//                     {/* <button className='notification-sty-button1'  value={filterKeyword}
//                             onChange={(e) => setFilterKeyword(e.target.value)}><span style={{ color: "white" }}>Filter</span></button> */}
//                 </div>
//                 <div className="notification-table24">
//                     <table>
//                         <tbody>
//                             {filteredNotifications.map((item, index) => (
//                                 <tr key={index}>
//                                     <td>
//                                         <AiFillNotification className='blink-dot' />
//                                         {item.message}
//                                         <span onClick={() => removeNotification(index)} className="close-btn">
//                                         <MdDelete className='notification-sty-iconDel' /></span>  
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//                 <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
//                     <img src={Hola9logo} className="notification-sty-img" alt="Golo" />
//                     <p className='notification-sty-quote'>"Empowering connections, one listing at a time. Discover the potential in your possessions with Hola9."</p>
//                     <button className='notification-sty-button2' style={{ width: "15%" }}><Link to='/' style={{ color: "white", textDecoration: "none" }}>Home</Link></button>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default InsideNotifications;

