import React, { useContext, createContext, useEffect, useState } from "react";
import "./Header.css";

import { FaPlus } from "react-icons/fa";
import Hola9logo from "../images/Hola9-logo.jpg";
import TopHeader from "./TopHeader/topHeader";
import { Link, useNavigate } from "react-router-dom";
import { location1 } from "../Location";
import Mobileheader from "./TopHeader/Mobileheader";
import { RiMapPinLine } from "react-icons/ri";
import { url } from "../env";
import { decrypt } from "./encryptDecrypt/encryptDecrypt";
import { FaSearchLocation } from "react-icons/fa";
import { useGeolocated } from "react-geolocated";
import { MdOutlineEventNote } from "react-icons/md";
// import { ClipLoader } from "react-spinners";
import axios from "axios";
import { UserContext } from "../../App";
import wishlistSlice, {
  postWishlit,
  removeAllWishlist,
  wishlistGet,
  wishlistUserData,
} from "../../store/wishlistSlice";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { removeUserData, UserProfile } from "../../store/userIdSlice";
import { typeOf } from "react-is";
// import { wishlistUserData } from "../../services/UserServices";
import { Button, Modal, Space, Tooltip } from "antd";
import user from "../../component/images/user.png";
import { userProfileDataApi } from "../../store/UserProfileDetailsSlice";
import { BsFillBookmarksFill, BsHeart } from "react-icons/bs";
import { BiAddToQueue } from "react-icons/bi";
import { FiBell } from "react-icons/fi";
import { FiHelpCircle } from "react-icons/fi";
import { MdOutlineDashboard } from "react-icons/md";
import { getUserProfileData } from "../../store/UserProfileDetailsSlice";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Chat, Logout } from "@mui/icons-material";
import Bussinessform from "../Pages/Contact/Bussinessform";
// import Cwe from "../Pages/Contact/Bussinessform";
import { isMobile } from "react-device-detect";
import Autolocation from "./TopHeader/Autolocation";
import Wishlistpopup from "../Account/Wishlist/Wishlistpopup";
import Popup from "../Home/Popup";
import Pop from "./Pop";
import GetNumber from "../Account/Login/GetNumber";

const ReachableContext = createContext(null);
const UnreachableContext = createContext(null);

const config = {
  title: "Please Allow Location",
  content: (
    <>
      <ReachableContext.Consumer>
        {(name) => `Reachable: ${name}!`}
      </ReachableContext.Consumer>
      <br />
      <UnreachableContext.Consumer>
        {(name) => `Unreachable: ${name}!`}
      </UnreachableContext.Consumer>
    </>
  ),
};
const Header = (props) => {
  const wishlistData = useSelector((state) => state.wishlistSlice);
  const [wishlistCount, setWishlistCount] = useState(0);



  useEffect(() => {
    if (wishlistData?.data) {
      setWishlistCount(wishlistData.data.length);
    } else {
      setWishlistCount(0);
    }
  }, [wishlistData]);

  const userProfileDetails = useSelector((state) => state.userProfileData);

  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const user1 = useContext(UserContext);
  const [loginTemp, setloginTemp] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    removeAll();
    var myItem = localStorage.getItem("currentLocation");
    localStorage.clear();
    localStorage.setItem("currentLocation", myItem);
    // window.location.reload()
    updateLastLoginTime();
    navigate("/");
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  if (props == "true") {
    setloginTemp(true);
  }

  // useEffect(()=>{
  //   window.location.reload()

  // },[])
  var CryptoJS = require("crypto-js");
  const LocationList = location1;
  const [location, setLocation] = useState(null);
  const coords = useGeolocated();
  // const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const [locationFlag, setLocationFlag] = useState(false);
  const [decryptedData, setdecryptedData] = useState(null);
  const [loaderProfile, setloaderProfile] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [results, setResults] = useState([]);
  const [names, setNames] = useState();
  // const [showPopup, setShowPopup] = useState(false);

  // const wishlistData = useSelector((state) => state.wishlistSlice);
  const userData = useSelector((state) => state.userIdSlice);
  const profileD = useSelector((state) => state.userProfileData);
  const dispatch = useDispatch();
  // ads was not getting visible after a day that why we i commented this api
  // useEffect(() => {
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };

  //   fetch(url + "api/adsapi/jobEveryDAYRun", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => console.log(result))
  //     .catch((error) => console.log("error", error));
  // }, []);









  useEffect(() => {
    console.log(
      localStorage.getItem("currentLocation"),
      typeOf(localStorage.getItem("currentLocation"))
    );
    if (localStorage.getItem("access_token") != null) {
      if (userData.data == null) {
        dispatch(UserProfile(localStorage.getItem("access_token")));
      } else {
        dispatch(wishlistGet(userData?.data?.id));
      }
    }
  }, [userData]);
  useEffect(() => {
    if (
      localStorage.getItem("access_token") != null &&
      wishlistData?.data.length > 0
    ) {
      if (Array.isArray(wishlistData?.data)) {
        dispatch(postWishlit(wishlistData?.data, userData?.data?.id));
      }
    }
  }, [wishlistData?.data]);
  useEffect(() => {
    if (
      localStorage.getItem("currentLocation") &&
      !localStorage.getItem("lat")
    ) {
      setLocationFlag(true);
      const options = {
        method: "GET",
        url: "https://google-maps-geocoding.p.rapidapi.com/geocode/json",
        params: {
          address: localStorage.getItem("currentLocation"),
          language: "en",
        },
        headers: {
          "X-RapidAPI-Key":
            "7fded38e7emsh3c4fb60f3b8017cp1c084bjsn32180c499f5f",
          "X-RapidAPI-Host": "google-maps-geocoding.p.rapidapi.com",
        },
      };

      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);

          console.log(
            "lat long ",
            response.data.results[0].geometry.location.lat,
            response.data.results[0].geometry.location.lng
          );
          localStorage.setItem(
            "lat",
            response.data.results[0].geometry.location.lat
          );
          localStorage.setItem(
            "long",
            response.data.results[0].geometry.location.lng
          );
        })
        .catch(function (error) {
          console.error(error);
        });
      // var bytes = CryptoJS.AES.decrypt(localStorage.getItem("currentLocation"), 'my-secret-key@123');
      // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setdecryptedData("bengaluru");
    }
  }, [locationData]);





  // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem("currentLocation")), 'my-secret-key@123').toString();
  // let valueone=JSON.parse((CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123')).toString(CryptoJS.enc.Utf8))
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [idUser, setIDUser] = useState();
  // const [success, setSuccess] = useState("");
  // const [error, setError] = useState("");

  //current location
  function getCurrentLocation(setCurrentLocation) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          try {
            // Make an API call to the geocoding service (Replace 'YOUR_API_KEY' with your actual API key)
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDEnLDetZt4PvT03AWs7ZTyzAdA3K-Sz1g`
            );

            if (!response.ok) {
              throw new Error("Error fetching address.");
            }

            const data = await response.json();

            if (data.results.length > 0) {
              const addressComponents = data.results[0].address_components;

              // Loop through the address components to find the city
              let cityName = "City not found";
              for (const component of addressComponents) {
                if (component.types.includes("locality")) {
                  cityName = component.long_name;
                  break;
                }
              }

              // Update the current location in the state with the city name
              setCurrentLocation(cityName);
            } else {
              console.error("Address not found.");
            }
          } catch (error) {
            console.error("Error getting current address:", error);
          }
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  const [currentLocation, setCurrentLocation] = useState(
    localStorage.getItem("currentLocation") || ""
  );

  useEffect(() => {
    // Get and set the current location when the component mounts
    getCurrentLocation(setCurrentLocation);
  }, []);

  const handleLocationChange = (e) => {
    const selectedLocation = e.target.value;

    if (LocationList.includes(selectedLocation)) {
      setLocation(selectedLocation);
      localStorage.setItem("currentLocation", selectedLocation);
      setLocationData(selectedLocation);
      setCurrentLocation(selectedLocation); // Set the current location in state
      window.location.reload();
    }
  };

  const removeAll = () => {
    dispatch(removeAllWishlist());
    dispatch(removeUserData());
    dispatch(getUserProfileData(data));
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      setloaderProfile(true);
      // let result=decrypt("userdata")
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(url + "api/user/profile/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.errors?.code) {
            alert("token expire");
            localStorage.removeItem("access_token");
          } else {
            setName(result.name);
            console.log('setName', name)
            setEmail(result.email);
            localStorage.setItem("email", result.email);
            localStorage.setItem("name", result.name);
            // ... other state updates
          }
          var emailFromAPI = result.email;
        })
        .catch((error) => console.log("error", error));
      // No need to set loaderProfile here again
    }
  }, [loginTemp, user1.loginRef]);

  const updateLastLoginTime = () => {
    var myHeaders = new Headers();

    var formdata = new FormData();
    formdata.append("user", idUser);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/user/lastLoginTime", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
      })
      .catch((error) => console.log("error", error));
  };
  let barererToken = "Bearer " + localStorage.getItem("access_token");
  const [error, setError] = useState("");


  //Start Of Wishlist Count
  // var [wishlistmain, setWishlist] = useState([]);
  // useEffect(() => {

  //   if (localStorage.getItem("access_token") != null) {
  //     let barererToken = "Bearer " + localStorage.getItem("access_token");
  //     var myHeaders = new Headers();
  //     myHeaders.append("Authorization", barererToken);
  //     var formdata = new FormData();
  //     formdata.append("wishlist", JSON.parse(decrypt("wishlist")));

  //     var requestOptions = {
  //       method: 'POST',
  //       headers: myHeaders,
  //       body: formdata,
  //       redirect: 'follow'
  //     };

  //     fetch("  http://192.168.0.103:8000/api/user/wishlist/", requestOptions)
  //       .then(response => response.json())
  //       .then(result => {
  //         setWishlist(result)
  //       })

  //       .catch(error => console.log('error', error));
  //   }

  // }, [])
  // End of wishlist Count  Done
  // const [toggle, setToggle] = useState(false);

  // const info = () => {
  //   Modal.info({
  //     title: "Hola9 ",
  //     content: (
  //       <div>
  //                   <p>There is no new notification</p>       {" "}
  //       </div>
  //     ),
  //     onOk() {},
  //   });
  // };

  const [isModalVisible, setIsModalVisible] = useState(false);

  // const shownModal = () => {
  //   setIsModalVisible(true);
  // };

  const handleeOk = () => {
    setIsModalVisible(false);
  };

  const handleeCancel = () => {
    setIsModalVisible(false);
  };

  // logout modal
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: "Logout",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you want to Logout ?",
      okText: "Exit",
      onOk: handleOk,
      onCancel: handleCancel,
    });
  };

  const data = [];

  var email1 = email; // Replace with the actual email
  console.log("email1", email1);
  const mailstore = localStorage.getItem("email");

  const [businessver, setBusinessver] = useState(false); // Initialize with null

  useEffect(() => {
    if (mailstore) {
      // Ensure email is not undefined
      var formdata = new FormData();
      formdata.append("email", mailstore);
      console.log("myone", mailstore);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(url + "api/adsapi/businessProfileVerified", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setBusinessver(result.business_profile_verified);
          console.log(result, "resvaerarasrar");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [email]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (localStorage.getItem("access_token") === null) {
        setShowPopup(true);
      }
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  const [business, setBusiness] = useState(false); // Initialize with null
  const dataUser = useContext(UserContext);
  const userid = localStorage.getItem("userid");

  useEffect(() => {
    // Check if dataUser.userid exists before making the API call
    if (userid) {
      var formdata = new FormData();
      formdata.append("userid", userid);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(url + "api/adsapi/checkBusinessPlan", requestOptions)
        .then(response => response.json())
        .then(result => {
          setBusiness(result.businessPlan);
          console.log(result, "resvaerarasrar");
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [userid]);

  const handlecl = () => {
    if (business === true) {
      navigate('/businessdashboard/');
    } else {
      navigate('/dashboard/');
    }
  };

  const got = props.greeting;








  //code -3 

  const [battery, setBattery] = useState(53); // Assuming battery value ranges from 0 to 100
  const fetchingProfilePercentage = async () => {
    const userId = localStorage.getItem('userid');
    const newAvival= 112
    try {
      const response = await fetch(`https://demoadmin.hola9.com/api/user/profile-completion/?user=${userId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      };
      const data = await response.json()
      setBattery(data.percentage_filled)

    } catch (error) {
      console.log("Error", error)
    }
  }

  useEffect(() => {
    fetchingProfilePercentage();

  }, []);

  // Calculate the width of the second bar based on the battery percentage
  const secondBarWidth = `${battery}%`;


  return (
    <>
      {/* <TopHeader /> */}
      <Mobileheader />
      {showPopup && <GetNumber onClose={closePopup} />}
      {/* <div style={style}>
      <ClipLoader color={"#123abc"} loading={loaderProfile} />
    </div> */}
      <nav
        className="navbar sticky-top navbar-expand-lg navbar-light  p-4 hidden-sm hidden-xs visible-md-block visible-lg-block"
        style={{ height: "45px", marginTop: "-14px", background: "white" }}
      >
        <div className="container-fluid">
          <Link title="Logo" to="/" className="site__brand__logo">
            <img
              src={Hola9logo}
              style={{ width: "90px", height: "40px" }}
              alt="Golo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">

            <ul
              className="navbar-nav me-auto mx-auto"
              style={{ margin: "auto" }}
            >
              <li className="nav-item">
                <Link
                  className="nav-link active thisoneoneon"
                  aria-current="page"
                  to="/"
                  title="Home"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                {localStorage.getItem("access_token") == null ? (
                  <Link className="nav-link" to="/login/" title="About Us">
                    About Us
                  </Link>
                ) : (
                  <Link className="nav-link" to="/aboutUs/" title="About Us">
                    About Us
                  </Link>
                )}
              </li>
              <li className="nav-item">
                <Link className="nav-link" title="Contact Us" to="/contact/">
                  Contact Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" title="Blog" to="/blogs-listing/">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                {localStorage.getItem("access_token") == null ? (
                  <Link className="nav-link" to="/login/" title="Careers">
                    Careers
                  </Link>
                ) : (
                  <Link className="nav-link" title="Careers" to="/career/">
                    Careers
                  </Link>
                )}
              </li>

              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  title="All Ads"
                  to="/business-profile/"
                >
                  Business Profile
                </Link>
              </li> */}
            </ul>

            <div className="dropdown show">
              <Autolocation />
            </div>
            {/* {locationFlag?
            <div className="dropdown show">
              <a  className=" dropdown-toggle " href="#" role="button"
                id="dropdownMenuLink"  data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
               &nbsp;&nbsp; <FaLocationArrow />{decryptedData.city} {location!=null?decryptedData.city:location}
               </a>&nbsp;
               <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
               <input className='inpstyle' required placeholder='location' type="text" list="locations" style={{width:'100%'}} 
                onChange={(e) =>setLocation(e.target.value)}  />
             <datalist id="locations">
            {LocationList.map(result=>{
             return <option value={result} >{result}</option>
            })
            }
            </datalist>
            </ul>
               </div>:null} */}
            &nbsp;
            &nbsp;
            &nbsp;
            <div className="dropdown show">
              <Link
                className=" dropdown-toggle"
                to="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {userProfileDetails.status ? (
                  <div class="loader "></div>
                ) : (
                  <img
                    className="m-0 p-0"
                    alt="profile"
                    style={{
                      borderRadius: "15px",
                      height: "30px",
                      width: "30px",
                    }}
                    src={
                      userProfileDetails?.data.length === 0 ||
                        userProfileDetails?.data[0]?.fields?.image == "undefined"
                        ? user
                        : userProfileDetails?.data[0]?.fields?.image
                    }
                  />
                )}
              </Link>
              {localStorage.getItem("access_token") == null ? (
                <div
                  className="dropdown-menu"
                  style={{ width: "10rem", marginTop: "10px" }}
                  aria-labelledby="dropdownMenuLink"
                >    <span
                  className="dropdown-indicator1 text-white pt-1 mb-4 ml-1"
                  style={{ background: "transparent" }}
                >
                    ▲
                  </span>
                  {/* <span
                  className="dropdown-indicator1 text-black pt-1 mb-1"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <span
                  className="dropdown-indicator1 text-black pt-1 mb-1"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span> */}
                  <Link className="dropdown-item" to="/signup/">
                    Signup
                  </Link>
                  <Link className="dropdown-item" to="/login/">
                    Login
                  </Link>
                </div>
              ) : (

                <div

                  className="dropdown-menu"
                  style={{ width: "15rem", marginTop: "10px" }}
                  aria-labelledby="dropdownMenuLink"
                >
                  <span
                    className="dropdown-indicator1 text-white pt-1 mb-4 ml-1"
                    style={{ background: "transparent" }}
                  >
                    ▲
                  </span>
                  {/* <Link
                    className="dropdown-item capitalizer"
                    to="/dashboard/profile"
                  >
                    {name}
                  </Link> */}

                  <span className="popuptext text-center" style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "8px",
                    color: "#007BFF",
                    textTransform: "capitalize",
                    letterSpacing: "0.9px",
                    textAlign: "center",
                    background: "linear-gradient(to right, #007BFF, #00BFFF)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4))",
                    transition: "transform 0.3s ease-in-out",
                    cursor: "pointer",
                  }}>{name}</span>
                  <br />

                  {/* progressive bar */}

                  <div>
                    <div style={{
                      height: '22px',
                      width: '200px',
                      border: '0.5px solid black',
                      // backgroundColor: '#dbe2e7',
                      background:"transparent",
                      margin: '10px',
                      position: 'relative',
                      padding:"1.4px"
                    }}>
                      <div style={{
                        height: '100%',
                        width: secondBarWidth,
                        backgroundColor: '#90c1ec'
                      
                      }}>
                        {/* Percentage Text */}
                        <div style={{
                          position: 'absolute',
                          fontSize: "12px", padding: "5px",
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: 'black'
                        }}>
                          {battery}%
                        </div>
                      </div>
                    </div>


                  </div>


                  {/* businessdashboard */}
                  {business === true ? (
                    <Link className="popuptext fw-bold" to="/businessdashboard/" style={{ marginTop: "-5px", marginBottom: "-5px" }}>
                      <MdOutlineDashboard className="bellbell" /> Dashboard
                    </Link>) : (<Link className="popuptext fw-bold" to="/dashboard/" style={{ marginTop: "-5px", marginBottom: "-5px" }}>
                      <MdOutlineDashboard className="bellbell" /> Dashboard
                    </Link>)}
                  <hr />
                  {business === true ? (
                    <Link
                      className="popupprofile"
                      to="/businessdashboard/ads"
                      style={{ paddingTop: "0px", paddingBottom: "5px" }}
                    >
                      <span className="popuptext">
                        &nbsp;
                        <BiAddToQueue className="bellbell" />
                        &nbsp;&nbsp; My Ads
                      </span>
                    </Link>) : (<Link
                      className="popupprofile"
                      to="/dashboard/DashAds"
                      style={{ paddingTop: "0px", paddingBottom: "5px" }}
                    >
                      <span className="popuptext">
                        &nbsp;
                        <BiAddToQueue className="bellbell" />
                        &nbsp;&nbsp; My Ads
                      </span>
                    </Link>)}
                  <br />

                  {business === true ? (
                    <Link
                      className="popupprofile"
                      to="/allCategories/"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <span className="popuptext">
                        &nbsp;
                        <MdOutlineEventNote className="bellbell" />
                        &nbsp;&nbsp; Upgrade Package
                      </span>
                    </Link>) : (<Link
                      className="popupprofile"
                      to="/businessform"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <span className="popuptext">
                        &nbsp;
                        <MdOutlineEventNote className="bellbell" />
                        &nbsp;&nbsp; Buy Packages
                      </span>
                    </Link>)}
                  <hr />


                  {business === true ? (
                    <Link className="popuptext fw-bold" to="/businessdashboard/chatandcommu" style={{ paddingTop: "0px", paddingBottom: "5px" }}> &nbsp;
                      <Chat className="bellbell" />  &nbsp;Chat's
                    </Link>) : (<Link className="popuptext fw-bold" to="/dashboard/Chats" style={{ paddingTop: "0px", paddingBottom: "5px" }}> &nbsp;
                      <Chat className="bellbell" />  &nbsp;Chat's
                    </Link>)}
                  <br />
                  <div className=" text-dark" >
                    <Link className="popuptext fw-bold" to="/innerNotific" style={{ paddingTop: "2px", paddingBottom: "0px", paddingRight: "10px" }}>
                      <span className="popuptext" >
                        &nbsp;
                        <FiBell
                          className="bellbell"
                        /> <sup><BsFillExclamationCircleFill style={{ color: "red", marginLeft: "-12px", marginTop: "-15px" }} /></sup>
                        &nbsp;&nbsp; Notification
                      </span>
                    </Link>

                    <Modal
                      style={{ borderRadius: "10px" }}
                      title="Hola9"
                      visible={isModalVisible}
                      onOk={handleeOk}
                      onCancel={handleeCancel}
                    >
                      <p>No Notifiations Found</p>
                    </Modal>
                  </div>

                  {localStorage.getItem("access_token") == null ? (



                    <Link to="/login">
                      <button
                        className="cartt"
                        type="button"
                        title="My-Cart"
                        style={{
                          height: "40px",
                          marginLeft: "-5px",
                          color: "black",
                          marginTop: "-10px",
                          marginRight: "0px",
                          padding: "-5px",
                        }}
                      >
                        <span
                          className="badge badge-secondary"
                          style={{ marginTop: "10px" }}
                        >
                          {wishlistCount}
                        </span>
                        <br />
                        <BsHeart
                          className="cart"
                          style={{ marginTop: "-22px" }}
                        />
                      </button>
                    </Link>

                  ) : (
                    <>
                      {business === true ? (
                        <Link to="/businessdashboard/MyFavorites">
                          <button
                            className=""
                            type="button"
                            title="My-Favorites"
                            style={{

                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "transparent",
                              border: "none",
                              marginLeft: "8px",
                              color: "white",
                              position: "relative",
                              marginBottom: "-10px",
                            }}
                          >

                            <span
                              className="countcard"
                              style={{
                                fontSize: "10px",
                                fontWeight: "bolder",
                                color: "black",
                                backgroundColor: "transparent",
                                zIndex: "1",
                                position: "absolute",
                                top: "-2.5px",
                                left: "-12px"

                              }}
                            >
                              {wishlistCount}
                            </span>
                            {/* <br /> */}
                            <span className="popuptext">
                              <BsFillBookmarksFill
                                className="cart"
                                style={{ fontSize: "30px", marginLeft: "-5px" }}
                              />&nbsp;&nbsp;
                              My Wishlist
                            </span>
                          </button>
                        </Link>) : (<Link to="/dashboard/MyFavorites/">
                          <button
                            className=""
                            type="button"
                            title="My-Favorites"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "transparent",
                              border: "none",
                              marginLeft: "8px",
                              color: "white",
                              position: "relative",
                              marginBottom: "-10px",
                            }}
                          >
                            <span
                              className="countcard"
                              style={{
                                fontSize: "10px",
                                fontWeight: "bolder",
                                color: "black",
                                backgroundColor: "transparent",
                                zIndex: "1",
                                position: "absolute",
                                top: "-2.5px",
                                left: "-12px"
                              }}
                            >
                              {wishlistCount}
                            </span>

                            {/* <br /> */}
                            <span className="popuptext">
                              <BsFillBookmarksFill
                                className="cart"
                                style={{ fontSize: "30px", marginLeft: "-5px" }}
                              />&nbsp;&nbsp;
                              My Wishlist
                            </span>
                          </button>
                        </Link>)}</>
                  )}
                  <br />
                  {/* <Link
                    className="popupprofile"
                    to="/"
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <span className="popuptext">
                      &nbsp;
                      <FiHelpCircle className="bellbell" />
                      &nbsp;&nbsp; help
                    </span>
                  </Link> */}
                  <hr />
                  <button className="dropdown-item " style={{ marginTop: "-10px" }} onClick={showConfirm}>
                    <Logout style={{ marginLeft: "-16px" }} />
                    <b>Logout</b>
                  </button>


                  {/* <Modal
                    title="confirmation"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <p>Are you sure you want to Logout?</p>
                  </Modal> */}
                </div>
              )}
            </div>
            &nbsp;
            <div>
              <div>
                {localStorage.getItem("access_token") == null ? (
                  <Link to="/login">
                    <button className="button-92 text-white">
                      For Business
                    </button>
                  </Link>

                ) : (
                  <>
                    {businessver === true ? (
                      <Link to="/allCategories/">
                        <button class="button-92 text-white">For Business</button>
                      </Link>
                    ) : (
                      <Link to="/businessform">
                        <button className="button-92 text-white">
                          For Business
                        </button>
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
            &nbsp;
            <div>
              {localStorage.getItem("access_token") == null ? (
                <Link to="/allcatforpost/">
                  <button id="testbutton">
                    <FaPlus
                      className="text-white fs-12"
                      style={{ marginTop: "-2px" }}
                    />
                    &nbsp;Post Ads
                  </button>
                </Link>
              ) : (
                <Link to="/allcatforpost/">
                  <button id="testbutton">
                    <FaPlus
                      className="text-white fs-12"
                      style={{ marginTop: "-2px" }}
                    />
                    &nbsp;Post Ads
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export const callingloginCheck = () => {
  console.log("callinglogin system");
};

export default Header;
