// import React from "react";
// import ReactDOM from "react-dom";
// import Gallery from "react-grid-gallery";
// import './MyGallery.css';

// const IMAGES = [
//   {
//     src: "https://www.ecorealestate.com.au/wp-content/uploads/2022/03/5b97ab84-d198-4aab-83ac-53650b8ba153-Biggenden.jpg",
//     thumbnail:
//       "https://www.ecorealestate.com.au/wp-content/uploads/2022/03/5b97ab84-d198-4aab-83ac-53650b8ba153-Biggenden.jpg",
//     thumbnailWidth: 320,
//     thumbnailHeight: 212,
//     tags: [
//       { value: "Ocean", title: "Ocean" },
//       { value: "People", title: "People" }
//     ],
//     caption: "Boats (Jeshu John - designerspics.com)"
//   },

//   {
//     src: "https://images.adsttc.com/media/images/5c49/0f33/284d/d13e/b300/00a4/newsletter/-_Featuded_Image.jpg?1548291884",
//     thumbnail:
//       "https://images.adsttc.com/media/images/5c49/0f33/284d/d13e/b300/00a4/newsletter/-_Featuded_Image.jpg?1548291884",
//     thumbnailWidth: 720,
//     thumbnailHeight: 212
//   },
//   {
//     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXKakuju2juAfcjXwGCi1pFbX_vkH7xNvOWP8pZtAwfcRvQnnPM9MGrClNBv7tI6k__rw&usqp=CAU",
//     thumbnail:
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXKakuju2juAfcjXwGCi1pFbX_vkH7xNvOWP8pZtAwfcRvQnnPM9MGrClNBv7tI6k__rw&usqp=CAU",
//     thumbnailWidth: 320,
//     thumbnailHeight: 174,
//     caption: "After Rain (Jeshu John - designerspics.com)"
//   },
//   {
//     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgVtX0pKoDyavjXHM-YBwHBLXSD9tYl-gL7Q&usqp=CAU",
//     thumbnail:
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgVtX0pKoDyavjXHM-YBwHBLXSD9tYl-gL7Q&usqp=CAU",
//     thumbnailWidth: 320,
//     thumbnailHeight: 212,
//     tags: [
//       { value: "Ocean", title: "Ocean" },
//       { value: "People", title: "People" }
//     ],
//     caption: "Boats (Jeshu John - designerspics.com)"
//   },

//   {
//     src: "https://cdn.pixabay.com/photo/2017/07/08/02/16/house-2483336__340.jpg",
//     thumbnail:
//       "https://cdn.pixabay.com/photo/2017/07/08/02/16/house-2483336__340.jpg",
//     thumbnailWidth: 420,
//     thumbnailHeight: 212
//   },
//   {
//     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyZb3aG65RP2oInlV-0QqTPQjn5d4gK_Lv9A&usqp=CAU",
//     thumbnail:
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyZb3aG65RP2oInlV-0QqTPQjn5d4gK_Lv9A&usqp=CAU",
//     thumbnailWidth: 320,
//     thumbnailHeight: 174,
//     caption: "After Rain (Jeshu John - designerspics.com)"
//   },
//   {
//     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3DfER5-NYpnXfsKk5y7Lgxgrs3Ruw6Ib-hQ&usqp=CAU",
//     thumbnail:
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3DfER5-NYpnXfsKk5y7Lgxgrs3Ruw6Ib-hQ&usqp=CAU",
//     thumbnailWidth: 220,
//     thumbnailHeight: 212,
//     tags: [
//       { value: "Ocean", title: "Ocean" },
//       { value: "People", title: "People" }
//     ],
//     caption: "Boats (Jeshu John - designerspics.com)"
//   },

//   ];

// const MyGallery = () => {
//   return (
//     <>
//     <div className="row">
// <h5 className="text-center fs-18">Featured Gallery</h5>
// <div className="col-lg-1"></div>

//     <div className="col-lg-10 col-sm-12 mx-1">
//       <Gallery
//       images={IMAGES}
//       enableLightbox={true}
//       // maxRows={3}
//       backdropClosesModal
//       // currentImage={3}
//       // isOpen={ true}
//     />
//     </div>
//     </div>
//     </>
//   )
// }

// export default MyGallery































import React from "react";
import Gallery from "react-grid-gallery";
import './MyGallery.css';

const categories = [
  {
    category: "RealEstate",
    images: [

      {
        src: "https://www.ecorealestate.com.au/wp-content/uploads/2022/03/5b97ab84-d198-4aab-83ac-53650b8ba153-Biggenden.jpg",
        thumbnail:
          "https://www.ecorealestate.com.au/wp-content/uploads/2022/03/5b97ab84-d198-4aab-83ac-53650b8ba153-Biggenden.jpg",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: "Boats (Jeshu John - designerspics.com)"
      },

      {
        src: "https://images.adsttc.com/media/images/5c49/0f33/284d/d13e/b300/00a4/newsletter/-_Featuded_Image.jpg?1548291884",
        thumbnail:
          "https://images.adsttc.com/media/images/5c49/0f33/284d/d13e/b300/00a4/newsletter/-_Featuded_Image.jpg?1548291884",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
      },

      {
        src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXKakuju2juAfcjXwGCi1pFbX_vkH7xNvOWP8pZtAwfcRvQnnPM9MGrClNBv7tI6k__rw&usqp=CAU",
        thumbnail:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXKakuju2juAfcjXwGCi1pFbX_vkH7xNvOWP8pZtAwfcRvQnnPM9MGrClNBv7tI6k__rw&usqp=CAU",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: "After Rain (Jeshu John - designerspics.com)"
      },

      {
        src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgVtX0pKoDyavjXHM-YBwHBLXSD9tYl-gL7Q&usqp=CAU",
        thumbnail:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgVtX0pKoDyavjXHM-YBwHBLXSD9tYl-gL7Q&usqp=CAU",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: "Boats (Jeshu John - designerspics.com)"
      },

      {
        src: "https://cdn.pixabay.com/photo/2017/07/08/02/16/house-2483336__340.jpg",
        thumbnail:
          "https://cdn.pixabay.com/photo/2017/07/08/02/16/house-2483336__340.jpg",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
      },

      {
        src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyZb3aG65RP2oInlV-0QqTPQjn5d4gK_Lv9A&usqp=CAU",
        thumbnail:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyZb3aG65RP2oInlV-0QqTPQjn5d4gK_Lv9A&usqp=CAU",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: "After Rain (Jeshu John - designerspics.com)"
      },

      {
        src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3DfER5-NYpnXfsKk5y7Lgxgrs3Ruw6Ib-hQ&usqp=CAU",
        thumbnail:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3DfER5-NYpnXfsKk5y7Lgxgrs3Ruw6Ib-hQ&usqp=CAU",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: "Boats (Jeshu John - designerspics.com)"
      },

    ]
  },



  {
    category: "Furniture",
    images: [

      {
        src: "https://images.pexels.com/photos/18783351/pexels-photo-18783351/free-photo-of-cafe-table-with-chairs.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail:
          "https://images.pexels.com/photos/18783351/pexels-photo-18783351/free-photo-of-cafe-table-with-chairs.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: "Boats (Jeshu John - designerspics.com)"
      },

      {
        src: "https://images.pexels.com/photos/18767556/pexels-photo-18767556/free-photo-of-cat-relaxing-under-a-seaside-cafe-table.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail:
          "https://images.pexels.com/photos/18767556/pexels-photo-18767556/free-photo-of-cat-relaxing-under-a-seaside-cafe-table.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      },
      {
        src: "https://images.pexels.com/photos/18778277/pexels-photo-18778277/free-photo-of-furniture-and-umbrella-by-the-pool-in-a-tropical-resort.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail:
          "https://images.pexels.com/photos/18778277/pexels-photo-18778277/free-photo-of-furniture-and-umbrella-by-the-pool-in-a-tropical-resort.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        caption: "After Rain (Jeshu John - designerspics.com)"
      },
      {
        src: "https://images.pexels.com/photos/18761757/pexels-photo-18761757/free-photo-of-chairs-and-table-on-beach.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail:
          "https://images.pexels.com/photos/18761757/pexels-photo-18761757/free-photo-of-chairs-and-table-on-beach.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: "Boats (Jeshu John - designerspics.com)"
      },

      {
        src: "https://images.pexels.com/photos/4906249/pexels-photo-4906249.jpeg?auto=compress&",
        thumbnail:
          "https://images.pexels.com/photos/4906249/pexels-photo-4906249.jpeg?auto=compress&",
      },
      {
        src: "https://images.pexels.com/photos/18868451/pexels-photo-18868451/free-photo-of-table-and-chairs-in-front-of-a-restaurant-by-the-street.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail:
          "https://images.pexels.com/photos/18868451/pexels-photo-18868451/free-photo-of-table-and-chairs-in-front-of-a-restaurant-by-the-street.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        caption: "After Rain (Jeshu John - designerspics.com)"
      },
      {
        src: "https://images.pexels.com/photos/16385144/pexels-photo-16385144/free-photo-of-a-dining-room-in-classic-design.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail:
          "https://images.pexels.com/photos/16385144/pexels-photo-16385144/free-photo-of-a-dining-room-in-classic-design.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: "Boats (Jeshu John - designerspics.com)"
      },

    ]
  },

  {
    category: "Pets",
    images: [
      {
        src: "https://images.pexels.com/photos/18832962/pexels-photo-18832962/free-photo-of-white-cat-lying-on-floor.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18832962/pexels-photo-18832962/free-photo-of-white-cat-lying-on-floor.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Pets", title: "Pets" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Pets (Example.com)"
      },
      {
        src: "https://images.pexels.com/photos/18714456/pexels-photo-18714456/free-photo-of-german-shorthaired-pointer-running-with-a-ball-in-mouth.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18714456/pexels-photo-18714456/free-photo-of-german-shorthaired-pointer-running-with-a-ball-in-mouth.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Pets", title: "Pets" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Pets (Example.com)"
      },
      {
        src: "https://images.pexels.com/photos/162520/farmer-man-shepherd-dog-162520.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/162520/farmer-man-shepherd-dog-162520.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Pets", title: "Pets" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Pets (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/18842795/pexels-photo-18842795/free-photo-of-dogs-on-footpath-in-forest.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18842795/pexels-photo-18842795/free-photo-of-dogs-on-footpath-in-forest.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Pets", title: "Pets" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Pets (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/18842985/pexels-photo-18842985/free-photo-of-portrait-of-a-labrador-retriever-standing-outdoors.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18842985/pexels-photo-18842985/free-photo-of-portrait-of-a-labrador-retriever-standing-outdoors.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Pets", title: "Pets" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Pets (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/383557/pexels-photo-383557.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/383557/pexels-photo-383557.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Pets", title: "Pets" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Pets (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/18842150/pexels-photo-18842150/free-photo-of-a-golden-retriever-and-people-in-the-park-in-autumn.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18842150/pexels-photo-18842150/free-photo-of-a-golden-retriever-and-people-in-the-park-in-autumn.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Pets", title: "Pets" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Pets (Example.com)"
      },
      // Add more image data for Pets category
    ]
  },



  {
    category: "Bikes",
    images: [
      {
        src: "https://images.pexels.com/photos/18813492/pexels-photo-18813492/free-photo-of-man-on-a-motorbike-on-a-highway.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18813492/pexels-photo-18813492/free-photo-of-man-on-a-motorbike-on-a-highway.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Bikes", title: "Bikes" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Bikes (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/18749192/pexels-photo-18749192/free-photo-of-engine-of-a-new-bike.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18749192/pexels-photo-18749192/free-photo-of-engine-of-a-new-bike.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Bikes", title: "Bikes" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Bikes (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/18750297/pexels-photo-18750297/free-photo-of-motorcycle-parked-on-street-in-summer.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18750297/pexels-photo-18750297/free-photo-of-motorcycle-parked-on-street-in-summer.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Bikes", title: "Bikes" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Bikes (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/18867998/pexels-photo-18867998/free-photo-of-motorbike-by-the-road.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18867998/pexels-photo-18867998/free-photo-of-motorbike-by-the-road.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Bikes", title: "Bikes" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Bikes (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/16748699/pexels-photo-16748699/free-photo-of-a-motorbike-on-a-road.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/16748699/pexels-photo-16748699/free-photo-of-a-motorbike-on-a-road.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Bikes", title: "Bikes" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Bikes (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/11964873/pexels-photo-11964873.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/11964873/pexels-photo-11964873.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Bikes", title: "Bikes" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Bikes (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/2611691/pexels-photo-2611691.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/2611691/pexels-photo-2611691.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Bikes", title: "Bikes" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Bikes (Example.com)"
      },
      // Add more image data for Pets category
    ]
  },

  {
    category: "Cars",
    images: [
      {
        src: "https://images.pexels.com/photos/18823659/pexels-photo-18823659/free-photo-of-dark-blue-image-of-a-car-on-a-winter-night-with-stars.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18823659/pexels-photo-18823659/free-photo-of-dark-blue-image-of-a-car-on-a-winter-night-with-stars.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Cars", title: "Cars" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Cars (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/18818786/pexels-photo-18818786/free-photo-of-japanese-sports-cars-on-the-parking-lot-at-night.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18818786/pexels-photo-18818786/free-photo-of-japanese-sports-cars-on-the-parking-lot-at-night.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Cars", title: "Cars" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Cars (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/18847999/pexels-photo-18847999/free-photo-of-toyota-land-cruiser-on-a-seaside-cliff.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18847999/pexels-photo-18847999/free-photo-of-toyota-land-cruiser-on-a-seaside-cliff.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Cars", title: "Cars" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Cars (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/14445436/pexels-photo-14445436.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/14445436/pexels-photo-14445436.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Cars", title: "Cars" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Cars (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/12152814/pexels-photo-12152814.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/12152814/pexels-photo-12152814.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Cars", title: "Cars" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Cars (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/10689099/pexels-photo-10689099.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/10689099/pexels-photo-10689099.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Cars", title: "Cars" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Cars (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/10394779/pexels-photo-10394779.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/10394779/pexels-photo-10394779.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Cars", title: "Cars" },
          { value: "Vechile", title: "Vechile" }
        ],
        caption: "Cars (Example.com)"
      },
      // Add more image data for Pets category
    ]
  },

  {
    category: "Electronics",
    images: [
      {
        src: "https://images.pexels.com/photos/2582937/pexels-photo-2582937.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/2582937/pexels-photo-2582937.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Electronics", title: "Electronics" },
          { value: "Electronics", title: "Electronics" }
        ],
        caption: "Electronics (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/6044839/pexels-photo-6044839.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/6044839/pexels-photo-6044839.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Electronics", title: "Electronics" },
          { value: "Electronics", title: "Electronics" }
        ],
        caption: "Electronics (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/2653726/pexels-photo-2653726.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/2653726/pexels-photo-2653726.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Electronics", title: "Electronics" },
          { value: "Electronics", title: "Electronics" }
        ],
        caption: "Electronics (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/7601069/pexels-photo-7601069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/7601069/pexels-photo-7601069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Electronics", title: "Electronics" },
          { value: "Electronics", title: "Electronics" }
        ],
        caption: "Electronics (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/12700430/pexels-photo-12700430.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/12700430/pexels-photo-12700430.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Electronics", title: "Electronics" },
          { value: "Electronics", title: "Electronics" }
        ],
        caption: "Electronics (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/607812/pexels-photo-607812.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/607812/pexels-photo-607812.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 ",
        tags: [
          { value: "Electronics", title: "Electronics" },
          { value: "Electronics", title: "Electronics" }
        ],
        caption: "Electronics (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/16592625/pexels-photo-16592625/free-photo-of-air-conditioner-in-a-house.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/16592625/pexels-photo-16592625/free-photo-of-air-conditioner-in-a-house.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Electronics", title: "Electronics" },
          { value: "Electronics", title: "Electronics" }
        ],
        caption: "Electronics (Example.com)"
      },
      // Add more image data for Pets category
    ]
  },

  {
    category: "Mobiles",
    images: [
      {
        src: "https://images.pexels.com/photos/4032368/pexels-photo-4032368.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/4032368/pexels-photo-4032368.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Mobiles", title: "Mobiles" },
          { value: "Mobiles", title: "Mobiles" }
        ],
        caption: "Mobiles (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/221179/pexels-photo-221179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/221179/pexels-photo-221179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Mobiles", title: "Mobiles" },
          { value: "Mobiles", title: "Mobiles" }
        ],
        caption: "Mobiles (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/4123561/pexels-photo-4123561.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/4123561/pexels-photo-4123561.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Mobiles", title: "Mobiles" },
          { value: "Mobiles", title: "Mobiles" }
        ],
        caption: "Mobiles (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/18831091/pexels-photo-18831091/free-photo-of-smart-phone-on-a-rock.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/18831091/pexels-photo-18831091/free-photo-of-smart-phone-on-a-rock.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Mobiles", title: "Mobiles" },
          { value: "Mobiles", title: "Mobiles" }
        ],
        caption: "Mobiles (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/14730424/pexels-photo-14730424.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/14730424/pexels-photo-14730424.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Mobiles", title: "Mobiles" },
          { value: "Mobiles", title: "Mobiles" }
        ],
        caption: "Mobiles (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/16812192/pexels-photo-16812192/free-photo-of-samsung-smart-phone-in-hand.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/16812192/pexels-photo-16812192/free-photo-of-samsung-smart-phone-in-hand.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Mobiles", title: "Mobiles" },
          { value: "Mobiles", title: "Mobiles" }
        ],
        caption: "Mobiles (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/163184/instagram-cell-phone-tablet-device-163184.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/163184/instagram-cell-phone-tablet-device-163184.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Mobiles", title: "Mobiles" },
          { value: "Mobiles", title: "Mobiles" }
        ],
        caption: "Mobiles (Example.com)"
      },


      // Add more image data for Pets category
    ]
  },

  {
    category: "Education",
    images: [
      {
        src: "https://images.pexels.com/photos/7583935/pexels-photo-7583935.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/7583935/pexels-photo-7583935.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Education", title: "Education" },
          { value: "Education", title: "Education" }
        ],
        caption: "Education (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/7112/woman-typing-writing-windows.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/7112/woman-typing-writing-windows.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Education", title: "Education" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Education (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/7114/laptop-mobile.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/7114/laptop-mobile.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Education", title: "Education" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Education (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/3885136/pexels-photo-3885136.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/3885136/pexels-photo-3885136.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Education", title: "Education" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Education (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/6392970/pexels-photo-6392970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/6392970/pexels-photo-6392970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Education", title: "Education" },
          { value: "Education", title: "Education" }
        ],
        caption: "Education (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/159775/library-la-trobe-study-students-159775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/159775/library-la-trobe-study-students-159775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Education", title: "Education" },
          { value: "Education", title: "Education" }
        ],
        caption: "Education (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/3768126/pexels-photo-3768126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/3768126/pexels-photo-3768126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Education", title: "Education" },
          { value: "Animals", title: "Animals" }
        ],
        caption: "Education (Example.com)"
      },


      // Add more image data for Pets category
    ]
  },

  {
    category: "Jobs",
    images: [
      {
        src: "https://images.pexels.com/photos/4126739/pexels-photo-4126739.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/4126739/pexels-photo-4126739.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Jobs", title: "Jobs" },
          { value: "Jobs", title: "Jobs" }
        ],
        caption: "Jobs (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/7482648/pexels-photo-7482648.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/7482648/pexels-photo-7482648.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Jobs", title: "Jobs" },
          { value: "Jobs", title: "Jobs" }
        ],
        caption: "Jobs (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Jobs", title: "Jobs" },
          { value: "Jobs", title: "Jobs" }
        ],
        caption: "Jobs (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/7550298/pexels-photo-7550298.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/7550298/pexels-photo-7550298.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Jobs", title: "Jobs" },
          { value: "Jobs", title: "Jobs" }
        ],
        caption: "Jobs (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/5668858/pexels-photo-5668858.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/5668858/pexels-photo-5668858.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Jobs", title: "Jobs" },
          { value: "Jobs", title: "Jobs" }
        ],
        caption: "Jobs (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/5647270/pexels-photo-5647270.jpeg?auto=compress&cs=tinysrgb&w=",
        thumbnail: "https://images.pexels.com/photos/5647270/pexels-photo-5647270.jpeg?auto=compress&cs=tinysrgb&w=",
        tags: [
          { value: "Jobs", title: "Jobs" },
          { value: "Jobs", title: "Jobs" }
        ],
        caption: "Jobs (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/5673464/pexels-photo-5673464.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/5673464/pexels-photo-5673464.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Jobs", title: "Jobs" },
          { value: "Jobs", title: "Jobs" }
        ],
        caption: "Jobs (Example.com)"
      },
      // Add more image data for Pets category
    ]
  },



  {
    category: "Services",
    images: [
      {
        src: "https://images.pexels.com/photos/3825580/pexels-photo-3825580.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/3825580/pexels-photo-3825580.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Services", title: "Services" },
          { value: "Services", title: "Services" }
        ],
        caption: "Services (Example.com)"
      },


      {
        src: "https://images.pexels.com/photos/6169668/pexels-photo-6169668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/6169668/pexels-photo-6169668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Services", title: "Services" },
          { value: "Services", title: "Services" }
        ],
        caption: "Services (Example.com)"
      },



      {
        src: "https://images.pexels.com/photos/3807329/pexels-photo-3807329.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/3807329/pexels-photo-3807329.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Services", title: "Services" },
          { value: "Services", title: "Services" }
        ],
        caption: "Services (Example.com)"
      },


      {
        src: "https://images.pexels.com/photos/3965543/pexels-photo-3965543.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/3965543/pexels-photo-3965543.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Services", title: "Services" },
          { value: "Services", title: "Services" }
        ],
        caption: "Services (Example.com)"
      },


      {
        src: "https://images.pexels.com/photos/6169668/pexels-photo-6169668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/6169668/pexels-photo-6169668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Services", title: "Services" },
          { value: "Services", title: "Services" }
        ],
        caption: "Services (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/9775922/pexels-photo-9775922.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/9775922/pexels-photo-9775922.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Services", title: "Services" },
          { value: "Services", title: "Services" }
        ],
        caption: "Services (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/4482013/pexels-photo-4482013.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        thumbnail: "https://images.pexels.com/photos/4482013/pexels-photo-4482013.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        tags: [
          { value: "Services", title: "Services" },
          { value: "Services", title: "Services" }
        ],
        caption: "Services (Example.com)"
      },


      // Add more image data for Pets category
    ]
  }, {
    category: "ScienceAndTech",
    images: [
      {
        src: "https://www.eschoolnews.com/files/2024/12/k-12-tech-innovation-news.jpeg",
        thumbnail: "https://www.eschoolnews.com/files/2024/12/k-12-tech-innovation-news.jpeg",
        tags: [
          { value: "ScienceAndTech", title: "ScienceAndTech" },
          { value: "ScienceAndTech", title: "ScienceAndTech" }
        ],
        caption: "ScienceAndTech (Example.com)"
      },

      {
        src: "https://itbrief.co.uk/uploads/story/2024/01/18/img-JNeKQbOkaqcUvmzWHbN9FPrQ.webp",
        thumbnail: "https://itbrief.co.uk/uploads/story/2024/01/18/img-JNeKQbOkaqcUvmzWHbN9FPrQ.webp",
        tags: [
          { value: "ScienceAndTech", title: "ScienceAndTech" },
          { value: "ScienceAndTech", title: "ScienceAndTech" }
        ],
        caption: "ScienceAndTech (Example.com)"
      },

      {
        src: "https://www.indiancentury.in/wp-content/uploads/2024/12/Technology.jpeg",
        thumbnail: "https://www.indiancentury.in/wp-content/uploads/2024/12/Technology.jpeg",
        tags: [
          { value: "ScienceAndTech", title: "ScienceAndTech" },
          { value: "ScienceAndTech", title: "ScienceAndTech" }
        ],
        caption: "ScienceAndTech (Example.com)"
      },

      {
        src: "https://enewsroom.in/wp-content/uploads/2023/08/Deep-Tech2-min.jpg",
        thumbnail: "https://enewsroom.in/wp-content/uploads/2023/08/Deep-Tech2-min.jpg",
        tags: [
          { value: "ScienceAndTech", title: "ScienceAndTech" },
          { value: "ScienceAndTech", title: "ScienceAndTech" }
        ],
        caption: "ScienceAndTech (Example.com)"
      },

      {
        src: "https://media.licdn.com/dms/image/D5612AQE7tPm6kOGUVw/article-cover_image-shrink_720_1280/0/1723029132327?e=2147483647&v=beta&t=XDwHdN3mzELk5FbYAMRe8zn_taJuMqnQFk3LeTQoQKA",
        thumbnail: "https://media.licdn.com/dms/image/D5612AQE7tPm6kOGUVw/article-cover_image-shrink_720_1280/0/1723029132327?e=2147483647&v=beta&t=XDwHdN3mzELk5FbYAMRe8zn_taJuMqnQFk3LeTQoQKA",
        tags: [
          { value: "ScienceAndTech", title: "ScienceAndTech" },
          { value: "ScienceAndTech", title: "ScienceAndTech" }
        ],
        caption: "ScienceAndTech (Example.com)"
      },

      {
        src: "https://miro.medium.com/v2/resize:fit:1024/1*34bgCFIL1LccF5I0z7RLuQ.jpeg",
        thumbnail: "https://miro.medium.com/v2/resize:fit:1024/1*34bgCFIL1LccF5I0z7RLuQ.jpeg",
        tags: [
          { value: "ScienceAndTech", title: "ScienceAndTech" },
          { value: "ScienceAndTech", title: "ScienceAndTech" }
        ],
        caption: "ScienceAndTech (Example.com)"
      },

      {
        src: "https://agritechdigest.com/wp-content/uploads/2024/03/image-21.jpeg",
        thumbnail: "https://agritechdigest.com/wp-content/uploads/2024/03/image-21.jpeg",
        tags: [
          { value: "ScienceAndTech", title: "ScienceAndTech" },
          { value: "ScienceAndTech", title: "ScienceAndTech" }
        ],
        caption: "ScienceAndTech (Example.com)"
      },
      // Add more image data for Pets category
    ]
  },
 
{
  category: "Hobbies",
  images : [
    {
      src: "https://images.pexels.com/photos/7583935/pexels-photo-7583935.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/7583935/pexels-photo-7583935.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 320,
      thumbnailHeight: 212,
      tags: [
        { value: "Hobbies", title: "Hobbies" },
        { value: "Hobbies", title: "Hobbies" }
      ],
      caption: "Hobbies (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/7112/woman-typing-writing-windows.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/7112/woman-typing-writing-windows.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 720,
      thumbnailHeight: 212,
      tags: [
        { value: "Hobbies", title: "Hobbies" },
        { value: "Animals", title: "Animals" }
      ],
      caption: "Hobbies (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/7114/laptop-mobile.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/7114/laptop-mobile.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      tags: [
        { value: "Hobbies", title: "Hobbies" },
        { value: "Animals", title: "Animals" }
      ],
      caption: "Education (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/3885136/pexels-photo-3885136.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/3885136/pexels-photo-3885136.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 320,
      thumbnailHeight: 212,
      tags: [
        { value: "Hobbies", title: "Hobbies" },
        { value: "Animals", title: "Animals" }
      ],
      caption: "Hobbies (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/6392970/pexels-photo-6392970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/6392970/pexels-photo-6392970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 420,
      thumbnailHeight: 212,
      tags: [
        { value: "Hobbies", title: "Hobbies" },
        { value: "Hobbies", title: "Hobbies" }
      ],
      caption: "Hobbies (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/159775/library-la-trobe-study-students-159775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/159775/library-la-trobe-study-students-159775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      tags: [
        { value: "Hobbies", title: "Hobbies" },
        { value: "Hobbies", title: "Hobbies" }
      ],
      caption: "Hobbies (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/3768126/pexels-photo-3768126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/3768126/pexels-photo-3768126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 220,
      thumbnailHeight: 212,
      tags: [
        { value: "Hobbies", title: "Hobbies" },
        { value: "Animals", title: "Animals" }
      ],
      caption: "Hobbies (Example.com)"
    },

    
    // Add more image data for Pets category
  ]
},

 
{
  category: "BooksAndMedia",
  images : [
    {
      src: "https://images.pexels.com/photos/7583935/pexels-photo-7583935.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/7583935/pexels-photo-7583935.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 320,
      thumbnailHeight: 212,
      tags: [
        { value: "Books", title: "Books" },
        { value: "Media", title: "Media" }
      ],
      caption: "BooksAndMedia (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/7112/woman-typing-writing-windows.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/7112/woman-typing-writing-windows.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 720,
      thumbnailHeight: 212,
      tags: [
        { value: "Books", title: "Books" },
        { value: "Media", title: "Media" }
      ],
      caption: "BooksAndMedia (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/7114/laptop-mobile.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/7114/laptop-mobile.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      tags: [
        { value: "Books", title: "Books" },
        { value: "Media", title: "Media" }
      ],
      caption: "BooksAndMedia (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/3885136/pexels-photo-3885136.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/3885136/pexels-photo-3885136.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 320,
      thumbnailHeight: 212,
      tags: [
        { value: "Books", title: "Books" },
        { value: "Media", title: "Media" }
      ],
      caption: "BooksAndMedia (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/6392970/pexels-photo-6392970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/6392970/pexels-photo-6392970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 420,
      thumbnailHeight: 212,
      tags: [
        { value: "Books", title: "Books" },
        { value: "Media", title: "Media" }
      ],
      caption: "BooksAndMedia (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/159775/library-la-trobe-study-students-159775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/159775/library-la-trobe-study-students-159775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      tags: [
        { value: "Books", title: "Books" },
        { value: "Media", title: "Media" }
      ],
      caption: "HoBooksAndMediabbies (Example.com)"
    },

    {
      src: "https://images.pexels.com/photos/3768126/pexels-photo-3768126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnail: "https://images.pexels.com/photos/3768126/pexels-photo-3768126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      thumbnailWidth: 220,
      thumbnailHeight: 212,
      tags: [
        { value: "Books", title: "Books" },
        { value: "Media", title: "Media" }
      ],
      caption: "BooksAndMedia (Example.com)"
    },

    
    // Add more image data for Pets category
  ]
},

  {
    category: "Fashion",
    images: [
      {
        src: "https://images.pexels.com/photos/11955680/pexels-photo-11955680.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        thumbnail: "https://images.pexels.com/photos/11955680/pexels-photo-11955680.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [
          { value: "Fashion", title: "Fashion" },
          { value: "Fashion", title: "Fashion" }
        ],
        caption: "Fashion (Example.com)"
      },

      
      {
        src: "https://images.pexels.com/photos/5531010/pexels-photo-5531010.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnail: "https://images.pexels.com/photos/5531010/pexels-photo-5531010.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnailWidth: 720,
        thumbnailHeight: 212,
        tags: [
          { value: "Fashion", title: "Fashion" },
          { value: "Fashion", title: "Fashion" }
        ],
        caption: "Fashion (Example.com)"
      },



      {
        src: "https://images.pexels.com/photos/965989/pexels-photo-965989.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnail: "https://images.pexels.com/photos/965989/pexels-photo-965989.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        tags: [
          { value: "Fashion", title: "Fashion" },
          { value: "Fashion", title: "Fashion" }
        ],
        caption: "Fashion (Example.com)"
      },
      

      {
        src: "https://images.pexels.com/photos/322207/pexels-photo-322207.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnail: "https://images.pexels.com/photos/322207/pexels-photo-322207.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [
          { value: "Fashion", title: "Fashion" },
          { value: "Fashion", title: "Fashion" }
        ],
        caption: "Fashion (Example.com)"
      },


      {
        src: "https://images.pexels.com/photos/6714190/pexels-photo-6714190.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnail: "https://images.pexels.com/photos/6714190/pexels-photo-6714190.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnailWidth: 420,
        thumbnailHeight: 212,
        tags: [
          { value: "Fashion", title: "Fashion" },
          { value: "Fashion", title: "Fashion" }
        ],
        caption: "Fashion (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/11164725/pexels-photo-11164725.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnail: "https://images.pexels.com/photos/11164725/pexels-photo-11164725.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        tags: [
          { value: "Fashion", title: "Fashion" },
          { value: "Fashion", title: "Fashion" }
        ],
        caption: "Fashion (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/23319147/pexels-photo-23319147/free-photo-of-man-arm-over-boots.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnail: "https://images.pexels.com/photos/23319147/pexels-photo-23319147/free-photo-of-man-arm-over-boots.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnailWidth: 220,
        thumbnailHeight: 212,
        tags: [
          { value: "Fashion", title: "Fashion" },
          { value: "Fashion", title: "Fashion" }
        ],
        caption: "Fashion (Example.com)"
      },

      
      // Add more image data for Fashion category
    ]
  },
  {
    category: "Vehicles and Spares",
    images: [
      {
        src: "https://images.pexels.com/photos/29762048/pexels-photo-29762048/free-photo-of-colorful-scooters-parked-on-a-charming-portuguese-street.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnail: "https://images.pexels.com/photos/29762048/pexels-photo-29762048/free-photo-of-colorful-scooters-parked-on-a-charming-portuguese-street.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [
          { value: "Vehicles&Spares", title: "Vehicles&Spares" },
          { value: "Vehicles&Spares", title: "Vehicles&Spares" }
        ],
        caption: "Vehicles & Spares (Example.com)"
      },

      
      {
        src: "https://images.pexels.com/photos/3757226/pexels-photo-3757226.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnail: "https://images.pexels.com/photos/3757226/pexels-photo-3757226.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnailWidth: 720,
        thumbnailHeight: 212,
        tags: [
          { value: "Vehicles&Spares", title: "Vehicles&Spares" },
          { value: "Vehicles&Spares", title: "Vehicles&Spares" }
        ],
        caption: "Vehicles & Spares (Example.com)"
      },



      {
        src: "https://images.pexels.com/photos/4480535/pexels-photo-4480535.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnail: "https://images.pexels.com/photos/4480535/pexels-photo-4480535.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        tags: [
          { value: "Vehicles & Spares", title: "Vehicles & Spares" },
          { value: "Vehicles & Spares", title: "Vehicles & Spares" }
        ],
        caption: "Vehicles & Spares (Example.com)"
      },
      

      {
        src: "https://images.pexels.com/photos/20095286/pexels-photo-20095286/free-photo-of-close-up-of-an-engine-and-a-car-in-the-background-in-a-garage.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnail: "https://images.pexels.com/photos/20095286/pexels-photo-20095286/free-photo-of-close-up-of-an-engine-and-a-car-in-the-background-in-a-garage.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [
          { value: "Vehicles & Spares", title: "Vehicles & Spares" },
          { value: "Vehicles & Spares", title: "Vehicles & Spares" }
        ],
        caption: "Vehicles & Spares (Example.com)"
      },


      {
        src: "https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnail: "https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg?auto=compress&cs=tinysrgb&w=600",
        thumbnailWidth: 420,
        thumbnailHeight: 212,
        tags: [
          { value: "Vehicles & Spares", title: "Vehicles & Spares" },
          { value: "Vehicles & Spares", title: "Vehicles & Spares" }
        ],
        caption: "Vehicles & Spares (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/29057946/pexels-photo-29057946/free-photo-of-heavy-cargo-trucks-on-indian-highway.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnail: "https://images.pexels.com/photos/29057946/pexels-photo-29057946/free-photo-of-heavy-cargo-trucks-on-indian-highway.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        tags: [
          { value: "Vehicles & Spares", title: "Vehicles & Spares" },
          { value: "Vehicles & Spares", title: "Vehicles & Spares" }
        ],
        caption: "Vehicles & Spares (Example.com)"
      },

      {
        src: "https://images.pexels.com/photos/16663684/pexels-photo-16663684/free-photo-of-trucks-near-warehouse.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnail: "https://images.pexels.com/photos/16663684/pexels-photo-16663684/free-photo-of-trucks-near-warehouse.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        thumbnailWidth: 220,
        thumbnailHeight: 212,
        tags: [
          { value: "Vehicles & Spares", title: "Vehicles & Spares" },
          { value: "Vehicles & Spares", title: "Vehicles & Spares" }
        ],
        caption: "Vehicles & Spares (Example.com)"
      },

      
      // Add more image data for Fashion category
    ]
  },
  // Add more categories with their respective image data
];



const MyGallery = ({ selectedCategory }) => {
  const selectedCategoryData = categories.find((categoryData) => categoryData.category === selectedCategory);

  if (!selectedCategoryData) {
    return null;
  }

  return (
    <div className="gallery-container">
      <h5 className="text-center fs-18">{selectedCategoryData.category} Gallery</h5>
      <div className="gallery-grid">
        <Gallery
          images={selectedCategoryData.images}
          enableLightbox={true}
          backdropClosesModal
          rowHeight={180} // Adjust as needed for the row size
          margin={2} // Controls the space between thumbnails
        />
      </div>
    </div>
  );
}

export default MyGallery;
