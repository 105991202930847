import React, { useRef } from "react";
import "./Manufacturing.css";

const Manufacturing = () => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -250, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 250, behavior: "smooth" });
  };

  return (
    <div className="containerMnf">
      <h1 className="headingMnf">Manufacturing</h1>

      <button className="arrowMnf leftArrowMnf" onClick={scrollLeft}>
        &#9665;
      </button>

      <div className="cardsContainerMnf" ref={scrollRef}>
        {/* Electronics Manufacturing */}
        <div className="cardMnf">
          <img
            src="https://cgu-odisha.ac.in/wp-content/uploads/2023/05/electronic-engineering-1-1024x682.jpg"
            alt="Electronics Manufacturing"
            className="cardImgMnf"
          />
          <h2 className="cardTitleMnf">
            <span className="titleTopMnf">Electronics</span>
            <span className="titleBottomMnf">Manufacturing</span>
          </h2>
        </div>
        

        {/* Plastic Manufacturing */}
        <div className="cardMnf">
          <img
            src="https://cdn.prod.website-files.com/66fca06391de4d1fb7bb3080/672026a7440936432f298cdd_Plastics_Factory_edit2.webp"
            alt="Plastic Manufacturing"
            className="cardImgMnf"
          />
          <h2 className="cardTitleMnf">
            <span className="titleTopMnf">Plastic</span>
            <span className="titleBottomMnf">Manufacturing</span>
          </h2>
        </div>

        {/* Automobile Manufacturing */}
        <div className="cardMnf">
          <img
            src="https://www.ibef.org/assets/images/Automobile-3.jpg"
            alt="Automobile Manufacturing"
            className="cardImgMnf"
          />
          <h2 className="cardTitleMnf">
            <span className="titleTopMnf">Automobile</span>
            <span className="titleBottomMnf">Manufacturing</span>
          </h2>
        </div>

        {/* Textile Manufacturing */}
        <div className="cardMnf">
          <img
            src="https://img.freepik.com/free-photo/spools-multi-colored-thread-textile-material-generated-by-ai_188544-19539.jpg"
            alt="Textile Manufacturing"
            className="cardImgMnf"
          />
          <h2 className="cardTitleMnf">
            <span className="titleTopMnf">Textile</span>
            <span className="titleBottomMnf">Manufacturing</span>
          </h2>
        </div>

      {/* Food Manufacturing */}
          <div className="cardMnf">
          <img
            src="https://png.pngtree.com/thumb_back/fh260/background/20241201/pngtree-advanced-robotic-food-production-and-quality-control-image_16718636.jpg"
            alt="Food Manufacturing"
            className="cardImgMnf"
          />
          <h2 className="cardTitleMnf">
            <span className="titleTopMnf">Food</span>
            <span className="titleBottomMnf">Manufacturing</span>
          </h2>
        </div>


         {/* Metal Manufacturing */}
         <div className="cardMnf">
          <img
            src="https://rmi.org/wp-content/uploads/2023/07/steel-rolls-storage-iStock-1390249924-500x334.jpg"
            alt="Metal Manufacturing"
            className="cardImgMnf"
          />
          <h2 className="cardTitleMnf">
            <span className="titleTopMnf">Metal</span>
            <span className="titleBottomMnf">Manufacturing</span>
          </h2>
        </div>


         {/* Rubber Manufacturing */}
         <div className="cardMnf">
          <img
            src="https://t4.ftcdn.net/jpg/09/79/33/89/360_F_979338916_819lavepF8Y2gAZENSIK3C8tz7YqVU8n.jpg"
            alt="Rubber Manufacturing"
            className="cardImgMnf"
          />
          <h2 className="cardTitleMnf">
            <span className="titleTopMnf">Rubber</span>
            <span className="titleBottomMnf">Manufacturing</span>
          </h2>
        </div>


         {/* Design Studio Manufacturing */}
         <div className="cardMnf">
          <img
            src="https://img.freepik.com/free-photo/technology-equipment-blue-laboratory-generated-by-ai_188544-19546.jpg"
            alt="Design Studio Manufacturing"
            className="cardImgMnf"
          />
          <h2 className="cardTitleMnf">
            <span className="titleTopMnf">Design Studio</span>
            <span className="titleBottomMnf">Manufacturing</span>
          </h2>
        </div>


        {/* Rubber Manufacturing */}
        <div className="cardMnf">
          <img
            src="https://www.beltecno-global.com/hubfs/Image-1-Dangote-Refinery.jpg"
            alt="Rubber Manufacturing"
            className="cardImgMnf"
          />
          <h2 className="cardTitleMnf">
            <span className="titleTopMnf">Rubber</span>
            <span className="titleBottomMnf">Manufacturing</span>
          </h2>
        </div>




      </div>

      <button className="arrowMnf rightArrowMnf" onClick={scrollRight}>
        &#9655;
      </button>
    </div>
  );
};

export default Manufacturing;
