

import React, { useEffect, useState } from "react";
// import "./MobileSlider.css";
import "./TechGadgetsSlider.css"
import { Select } from "antd";
import { Citys, cities, location1 } from "../../component/Location";
import { Mobiles, TechGadgets } from "../../component/env";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getExtraField } from "../../store/ProductFilterSlice";
const images = [
    "https://img.freepik.com/free-photo/modern-stationary-collection-arrangement_23-2149309647.jpg?t=st=1735802973~exp=1735806573~hmac=02eda3e0865bce8c94a1913a3dff5d259f981046a19307be9866372163328b0d&w=900",
    "https://img.freepik.com/free-photo/desk-gadgets_181624-23300.jpg?t=st=1735803072~exp=1735806672~hmac=65b4746eaaaa1fcfaf738d8368d29339f46ed2f34c811b245a2f0a0efcf9fefb&w=900",
    "https://img.freepik.com/free-photo/modern-stationary-collection-arrangement_23-2149309648.jpg?t=st=1735803104~exp=1735806704~hmac=f5c36a759bb4bd8978c9b81d5e386ac4836865a65380d12c13edc99f08351045&w=900",
    "https://img.freepik.com/free-photo/modern-stationary-collection-arrangement_23-2149309643.jpg?t=st=1735803138~exp=1735806738~hmac=28ed8c6b609dea91596c7627ce4145e408e8560781d86b13c46b00b56a95a2e9&w=900",
    "https://img.freepik.com/free-photo/top-view-devices-supplies-composition_23-2149552421.jpg?t=st=1735803204~exp=1735806804~hmac=4980c033bfe12dc6db59ace38d93acd061e695f1443b1f4a97cd1adfe456c151&w=900",
    "https://img.freepik.com/free-photo/modern-office-desk-composition_23-2147915791.jpg?t=st=1735803238~exp=1735806838~hmac=a000292a3ab18c37dca12bff2fc22e9ae755d6a03ec23568beca562c15fbd103&w=900"
];

const TechGadgetsSlider = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [visible1, setVisible1] = useState(false);
    const handleDropdownVisibleChange = (open) => {
        setVisible1(open);
    };
    const [visible2, setVisible2] = useState(false);
    const handleDropdownVisibleChange2 = (open) => {
        setVisible2(open);
    };
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000);
        return () => clearInterval(interval);
    }, [images.length]);

    const mobilestyle = {
        height: "200px",
        width: "100%",
    };
    const desktopstyle = {
        height: "340px",
        width: "90%",
        marginLeft: "1.5cm",
        marginRight: "2.5cm",
        borderRadius: "20px",
    };
    const mobstyle = {
        display: "flex",
        flexDirection: "column",
    };
    const deskstyle = {
        display: "flex",
        flexDirection: "row",
    };

    const [selectedFilter, setSelectedFilter] = useState(null);

    const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
    const disptach = useDispatch();
    const dispatch = useDispatch();
    // Accessing the Redux dispatch function
    const [errorMessage, setErrorMessage] = useState(null);
    const [extraFieldCopy, setExtraFieldCopy] = useState({});
    const [city, setCity] = useState();
    const [category, setCategory] = useState();
    const [cityError, setCityError] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [searchSending, setsearchSending] = useState({
        subCategoryType: null,
        subCategoryValue: null,
        City: null,
        searchValue: null,
        category: "TechGadgets",
        minPrice: null,
        maxPrice: null,
    }); // State for search form data

    //   const disptach = useDispatch(); // Initializing useDispatch hook

    // Event handler for input field changes
    const handleChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        setExtraFieldCopy({ [name]: value });
        // setsearchSending({ ...searchSending, [name]: value });
        // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
    };

    useEffect(() => {
        // Update searchSending state with the selected filter value
        if (selectedFilter !== null) {
            setsearchSending((prevState) => ({
                ...prevState,
                category: selectedFilter,
            }));
        }
    }, [selectedFilter]);
    return (
        <>
            <div
                className="techslide tech-slide-awi-26 mt-4 mb-3"
            // style={isMobile ? mobstyle : deskstyle}
            >
                <div className="search-form-awi-26">
                    <h1
                        style={{
                            fontSize: "20px",
                            fontFamily: "times",
                            borderBottom: "1px double white",
                            borderTop: "1px double white",
                            fontWeight: "bold",
                            color: "brown",
                        }}
                    >
                        SEARCH FOR TECH GADGETS
                    </h1>
                    <div className="form-group2 mt-3">
                        <h6
                            style={{
                                fontFamily: "verdana",
                                color: "gray",
                                fontWeight: "bold",
                                fontSize: "14px",
                            }}
                        >
                            Select City :
                        </h6>
                        <Select
                            open={visible1}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                            style={{
                                width: "15rem",
                                padding: "5px 0px",
                            }}
                            placeholder="Select City"
                            onChange={(value) => setCity(value)}
                            options={Citys.map((value) => ({
                                value: value,
                                label: value,
                            }))}
                            showSearch={true}
                            filterOption={(inputValue, option) =>
                                option.label.toLowerCase().includes(inputValue.toLowerCase())
                            }
                        />
                        {cityError && <p className="error-message">{cityError}</p>}
                    </div>
                    <div className="form-group2">
                        <h6
                            style={{
                                fontFamily: "verdana",
                                color: "gray",
                                fontWeight: "bold",
                                fontSize: "14px",
                            }}
                        >
                            Select Category :
                        </h6>
                        <Select
                            open={visible2}
                            onDropdownVisibleChange={handleDropdownVisibleChange2}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                            style={{ width: "15rem" }}
                            placeholder="select category"
                            value={category || null}
                            onChange={(value) => setCategory(value)}
                            options={TechGadgets.map((value) => ({
                                value: value,
                                label: value,
                            }))}
                        />
                        {categoryError && <p className="error-message">{categoryError}</p>}
                    </div>
                    <Link
                        to={`/ads-listing/`}
                        onClick={(e) => {
                            if (!city || !category) {
                                e.preventDefault(); // Prevent the default navigation behavior
                                console.log("Please select both City and Category.");
                                setErrorMessage("Please select both City and Category."); // Set an error message
                            } else {
                                setErrorMessage(null); // Clear any previous error message

                                for (const key in searchSending) {
                                    if (
                                        searchSending[key]?.trim() === "" ||
                                        searchSending[key] === null
                                    ) {
                                        delete searchSending[key];
                                    }
                                }
                                searchSending["extraField"] = { ...extraFieldCopy };
                                searchSending["subCategoryValue"] = category;
                                searchSending["City"] = city;
                                disptach(getExtraField(searchSending));
                            }
                        }}
                    >
                        <button className="buttonno12">Search</button>
                    </Link>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>

                <div className="carousel-innertech24-awi-26">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className={`carousel-item${index === activeIndex ? " active" : ""
                                }`}
                        >
                            <img
                                src={image}
                                alt={`Slide ${index}`}
                                style={isMobile ? mobilestyle : desktopstyle}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default TechGadgetsSlider;
