import React from 'react'
import CommonSectionCategory from '../../../Shared/CommonSectionCategory';
import { categoryModel } from '../../../Model/categoryCons';

const ScienceAndTechCategory = () => {
    return (
        <>
            <CommonSectionCategory props={categoryModel.ScienceAndTech} />
        </>
    )
}

export default ScienceAndTechCategory;