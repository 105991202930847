import React, { useContext, useEffect, useState } from "react";
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Homeads from "../Ads/Allads/Homeads";
import Scroll from "../Ads/category/Scroll";
import Feature from "../Ads/FeaturedAds/Feature";
import HomeSection from "./homeSection/homeSection";
import YouTubeChannel from "../Ads/Video/youTubeChannel";
import Review1 from "../Pages/review/Review1";
import { url } from "../env";
import { UserContext } from "../../App";
import Spiner from "../Spiner";
import { Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { Ad } from 'react-google-adsense';
import { add, refreshTele } from "../../store/Track/trackUserSlice";
import YoutubeMagic from "../ContentLoader/YoutubeMagic";
import YoutubeMagic1 from "../ContentLoader/YoutubeMagic1";
import TrendingAds from "../Ads/Trending Ads/TrendingAds";
import { Modal } from "antd";
import { isMobile } from "react-device-detect";
import any2 from ".././images/packer.webp";
import any3 from ".././images/interior.webp";
import any4 from ".././images/ragent.jpeg";
import any1 from ".././images/pet.jpg";
import any5 from ".././images/mobilesc.jpg";
import any6 from ".././images/caragent.png";
import any7 from ".././images/bikew.webp";
import { Link } from "react-router-dom";
import { AutoPlay, Banner } from "../AutoPlay";
import OwlDemoMultiPle from "../../Shared/Category/OwlDemoMultiPle";
import Popup from "./Popup";
import Adsvid from "./Popup";
import ReviewForm from "../Pages/review/ReviewForm";
import TopSellers from "../Ads/Allads/TopSellers";
import CityMap from "../Pages/About/CityMap";
import Footcat from "../Base/FootCategory/Footcat";
import Recentads from "../Ads/FeaturedAds/RecentAds";
import HomeSideCards from "./HomeSideCards";
// import { useDispatch, useSelector } from "react-redux";



const mobstyle = {
  width: "auto",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "-54px"
}
const deskstyle = {
  marginLeft: "11.5rem", marginRight: "11.5rem",
}
const mob = {
  marginRight: "5px",
  marginLeft: "5px",
  marginTop: "54px"

}
const desk = {
  marginLeft: "7rem", marginRight: "7rem", marginTop: "54px"
}
const deskr = {
  marginLeft: "7rem", marginRight: "7rem"
}
export const Home = () => {


  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const t = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => {
      clearTimeout(t);
    };
  }, []);
  // modal on homepage
  const [visible, setVisible] = useState(false);
  const [showCount, setShowCount] = useState(0);

  let count = 0;

  useEffect(() => {
    if (count <= 2) {
      const timer = setTimeout(() => {
        setVisible(true);
        count++;
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  const hola9 = useContext(UserContext);
  // const greeting = "RealEstate";
  // const greeting1 = "Furniture";
  // const greeting2 = "Electronics";
  // const greeting3 = "Cars";
  // const greeting4 = "Bikes";
  // const greeting5 = "Pets";
  // const greeting6 = "Mobiles";
  // const greeting7 = "Services";
  // const greeting8 = "Education";
  // const greeting9 = "Jobs";
  // const greeting4 = "Jobs";

  const telemetry = useSelector((state) => state.telemetry);
  const disptach = useDispatch();

  // const [loading,setLoading]=useState(false)

  const getProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(url + "api/adsapi");
      if (!response.statusText.OK == false) {
        throw Error("Could not fetch data");
      } else {
        hola9.sethola9DataApp(response.data.reverse());
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    disptach(add({ view: ["home"] }));
  }, []);

  const postTelemetry = () => {
    var formdata = new FormData();
    formdata.append("data", JSON.stringify(telemetry));

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/user/trackingTele", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        disptach(refreshTele());
      })
      .catch((error) => console.log("error", error));
  };
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [any2, any4, any3, any7, any1, any5, any6]; // Replace with the appropriate image sources

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 7000);

    return () => clearInterval(interval);
  }, [images.length]);
  useEffect(() => {
    // alert("are you closing window")
    // const handleTabClose = (event) => {
    //   event.preventDefault();
    //   if (
    //     !(
    //       Object.keys(telemetry?.form).length === 0 &&
    //       Object.keys(telemetry.views).length === 0 &&
    //       telemetry.product.length === 0
    //     )
    //   ) {
    //     postTelemetry();
    //   }
    //   return (event.returnValue = "Are you sure you want to exit?");
    // };
    // window.addEventListener("beforeunload", handleTabClose, " after clicking");
  }, []);
  const [showBanner, setShowBanner] = useState(true);

  const handleBannerClose = () => {
    setShowBanner(false);
  };
  const [modalVisible, setModalVisible] = useState(false);

  const openReviewPopup = () => {
    setModalVisible(true);
  };

  const wishlistData = useSelector((state) => state.wishlistSlice);
  const [showNotification, setShowNotification] = useState(false);
  const [prevWishlistCount, setPrevWishlistCount] = useState(0);

  const [wishlistCount, setWishlistCount] = useState(0);

  useEffect(() => {
    const prevCount = localStorage.getItem('prevWishlistCount');
    if (prevCount) {
      setWishlistCount(wishlistData.data.length);
    } else {
      setWishlistCount(0);
    }
  }, [wishlistData]);

  useEffect(() => {
    const prevCount = localStorage.getItem('prevWishlistCount');
    if (wishlistCount > parseInt(prevCount)) {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }

    localStorage.setItem('prevWishlistCount', wishlistCount.toString());
  }, [wishlistCount]);
  document.title = "Hola9 - Home";
  return (
    <>
      <HomeSection />
      <Scroll />
      <div className="homeaal12343">
        {/* <div style={isMobile? mob: desk}>
      <Nearbyads />
      </div>
      <div style={isMobile? mob: desk}>
      <AutoPlay />
      </div> */}
        <div style={isMobile ? mob : desk}>
          <TrendingAds />
        </div>
        <div style={isMobile ? mob : deskr}>
          <Recentads />
        </div>
        <div style={isMobile ? mobstyle : deskstyle}>
          <Homeads />
        </div>

        {/* <div className="Home-homeads-div">
      <div className="Home-homeads-sub-div">
        <HomeSideCards />
      </div>
      <Homeads />
      </div> */}
        <div>

          {showBanner && (
            <div className="new-banner1">
              <div className="content-wrapper1">
                <div className="free-tag">Free</div>
                <div className="banner-content">
                  <h1 className="title1">POST YOUR ADS FOR FREE</h1>
                </div>
                <div>
                  <Link to="/allcatforpost/">
                    <button className="clicklink1">Post Ads</button>
                  </Link>
                </div>
                <button className="close-button1" onClick={handleBannerClose} style={{ color: "black" }}>
                  X {/* Add any close button icon or text here */}
                </button>

              </div>

            </div>

          )}
        </div>
        <br />

        <Review1 />
        <AutoPlay />
        <TopSellers />
        <Banner />
        <Footcat />

        {/* <div style={{marginTop:"250px" , marginLeft:"-50px"}}>
      <CityMap />
      </div> */}

        <div style={{ marginTop: "250px" }}></div>


      </div>
      <div className="rivbtdiv">
        <Button
          type="primary"
          shape="round"
          size="large"
          className="review-button"
          onClick={openReviewPopup}
        >
          Feed Back
        </Button>
      </div>


      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        centered
        destroyOnClose
        maskStyle={{ backgroundColor: 'transparent' }}

      >
        <ReviewForm />

      </Modal>

    </>
  );
};

export default Home;
