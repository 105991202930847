

import React, { useEffect, useState } from "react";
import "./MobileSlider.css";
import "./ScienceAndTechSlider.css"
import { Select } from "antd";
import { Citys, cities, location1 } from "../../component/Location";
import { Mobiles } from "../../component/env";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getExtraField } from "../../store/ProductFilterSlice";
const images = [
    "https://www.unomaha.edu/college-of-information-science-and-technology/news/photo8035",
    "https://www.epfl.ch/campus/events/wp-content/uploads/2024/06/SST_Visuel_1920X1080_Simple_2-1536x864.jpg",
    "https://physicsworld.com/wp-content/uploads/2023/12/atom-web-129757751-Shutterstock_Roman-Sigaev.jpg",
    "https://www.state.gov/wp-content/uploads/2021/07/Artificial-Intelligence-AI-2560x1440.jpg",
    "https://cmsadmin.amritmahotsav.nic.in/writereaddata/Portal/Images/Technological-Midelstone.jpg",
    "https://perkinswill.com/wp-content/uploads/2019/05/c02-cut-scaled.jpg",
    "https://www.thequadstudio.com/uploads/image/202106/53270aa4475b6c78aeca83360a28c6ec.jpg"
];

const ScienceAndTechSlider = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [visible1, setVisible1] = useState(false);
    const handleDropdownVisibleChange = (open) => {
        setVisible1(open);
    };
    const [visible2, setVisible2] = useState(false);
    const handleDropdownVisibleChange2 = (open) => {
        setVisible2(open);
    };
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000);
        return () => clearInterval(interval);
    }, [images.length]);

    const mobilestyle = {
        height: "200px",
        width: "100%",
    };
    const desktopstyle = {
        height: "340px",
        width: "90%",
        marginLeft: "1.5cm",
        marginRight: "2.5cm",
        borderRadius: "20px",
    };
    const mobstyle = {
        display: "flex",
        flexDirection: "column",
    };
    const deskstyle = {
        display: "flex",
        flexDirection: "row",
    };

    const [selectedFilter, setSelectedFilter] = useState(null);

    const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
    const disptach = useDispatch();
    const dispatch = useDispatch();
    // Accessing the Redux dispatch function
    const [errorMessage, setErrorMessage] = useState(null);
    const [extraFieldCopy, setExtraFieldCopy] = useState({});
    const [city, setCity] = useState();
    const [category, setCategory] = useState();
    const [cityError, setCityError] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [searchSending, setsearchSending] = useState({
        subCategoryType: null,
        subCategoryValue: null,
        City: null,
        searchValue: null,
        category: "ScienceAndTech",
        minPrice: null,
        maxPrice: null,
    }); // State for search form data

    //   const disptach = useDispatch(); // Initializing useDispatch hook

    // Event handler for input field changes
    const handleChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        setExtraFieldCopy({ [name]: value });
        // setsearchSending({ ...searchSending, [name]: value });
        // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
    };

    useEffect(() => {
        // Update searchSending state with the selected filter value
        if (selectedFilter !== null) {
            setsearchSending((prevState) => ({
                ...prevState,
                category: selectedFilter,
            }));
        }
    }, [selectedFilter]);
    return (
        <>
            <div
                className="scienceslide scienceslide-awi-24 mt-4 mb-3"
            // style={isMobile ? mobstyle : deskstyle}
            >

                <div className="carousel-innerscience24-awi-24">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className={`carousel-item${index === activeIndex ? " active" : ""
                                }`}
                        >
                            <img
                                src={image}
                                alt={`Slide ${index}`}
                                style={isMobile ? mobilestyle : desktopstyle}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ScienceAndTechSlider;
