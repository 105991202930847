import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Checkbox } from "antd";
import Payments from "../Payment/Payments";
import "./AddProducts.css";
import validator from "validator";
import { Image, Container, Row, Col } from "react-bootstrap";
import "../Allads/ImageUpload/ImageUpload.css";
import { useParams } from "react-router-dom";
import { FaCut, FaAdn } from "react-icons/fa";
import "./AddProducts.css";
import { DatePicker, Space, Select, Spin, Alert } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import BiCurrentLocation from "react-icons/bi";
import { useGeolocated } from "react-geolocated";
import { Button, Modal } from "antd";
import { TbPlayerTrackNext } from "react-icons/tb";
import {
  Category,
  localUrl,
  url,
  PhotographyEquipment,
  CameraType,
  CamerasBrandName,
  CamerasCondition,
  CamerasMegapixelRange,
  LensType,
  LensesBrandName,
  LensesMountType,
  LensesCondition,
  LensesFocalLengthRange,
  TripodType,
  TripodsMaterial,
  TripodsMaxHeight,
  TripodsLoadCapacity,
  TripodsCondition,
  AccessoryType,
  AccessoriesBrandName,
  AccessoriesCondition,
} from "../../env";
import { stateMain, location1 } from "../../Location";
import { decrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
import { isMobile } from "react-device-detect";

// import Success from "../../Pages/success/success";
// import ReactDOM from 'react-dom';
// import $ from 'jquery';
// import ImageUpload from "./ImageUpload/ImageUpload";
// import { SignalWifiStatusbarNullSharp } from "@mui/icons-material";
// import Spiner from "../../Spiner";
import MultiImageInput from "react-multiple-image-input";
import { CreateAds } from "../../../error/errorMessage";

import { UserContext } from "../../../App";
import GoogleAutoComplteforAddProduct from "../../Home/GoogleAutoComplte/GoogleAutoComplteforAddProduct";
import { useDispatch, useSelector } from "react-redux";
import { add } from "../../../store/Track/trackUserSlice";
import { addActivePlan } from "../../../store/activePlanSlice";
import SubcategoryCommonInput from "../../../Shared/SubCategory/subcategoryCommonInput";
import { subCategoryInput } from "../../../Model/SubCategoryCons";
import "antd/dist/antd.css";
import { HolaAutoComplete } from "../components/HolaAutoComplete";
import { LocalSeeRounded } from "@mui/icons-material";
import { getUserPlan } from "../../../store/allPlanDetails";
import axios from "axios";
import { BsExclamationTriangle, BsNutFill } from "react-icons/bs";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { IoVideocamOutline } from "react-icons/io5";
import { set } from "react-ga";
// import { content } from "html2canvas/dist/types/css/property-descriptors/content";
// import Service1 from "./Service1";

const array = [];
console.log("!!! 747 array,", array);

const mobileStyle = {
  width: "100%",
  // margin: "5px",
  justifyContent: "center",
};
const desktopStyle = {
  width: 300,
  //  margin: "5px",
};
const mobileStyle1 = {
  padding: "1px",
};
const desktopStyle1 = {
  padding: "50px",
  //  margin: "5px",
};
const mobstyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "15px",
  marginTop: "35px",
  gap: "30px",
};
const deskstyle = {
  display: "flex",
  flexDirection: "row",
  gap: "20%",
  marginLeft: "20px",
  marginTop: "35px",
};
const { Option } = Select;
const AddPhotographyEquipment = (props) => {
  // geocode
  const UserData = useContext(UserContext);
  const [address, setAddress] = useState();
  const [addressObj, setAddressObj] = useState();
  const [selectedLocation, setSelectedLocation] = useState("");

  const getCityName = (address_components) => {
    let city = "";
    address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
    });
    return city;
  };

  useEffect(() => {
    const detectUserLocation = () => {
      if ("geolocation" in navigator) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permissionStatus) => {
            if (permissionStatus.state === "granted") {
              navigator.geolocation.getCurrentPosition(
                async (position) => {
                  const { latitude, longitude } = position.coords;
                  const geocoder = new window.google.maps.Geocoder();
                  const latlng = { lat: latitude, lng: longitude };

                  geocoder.geocode(
                    { location: latlng },
                    async (results, status) => {
                      if (status === "OK" && results[0]) {
                        setAddress({
                          label: getCityName(results[0].address_components),
                          value: { place_id: results[0].place_id },
                        });
                      }
                    }
                  );
                },
                (error) => {
                  console.error("Error getting user location:", error);
                }
              );
            } else if (permissionStatus.state === "prompt") {
              navigator.geolocation.getCurrentPosition(
                () => {
                  detectUserLocation();
                },
                (error) => {
                  console.error("Error getting user location:", error);
                }
              );
            }
          })
          .catch((error) => {
            console.error("Error checking geolocation permission:", error);
          });
      } else {
        console.error("Geolocation not available in this browser.");
      }
    };

    // Automatically detect user's location when the component mounts
    detectUserLocation();
  }, []);

  useEffect(() => {
    const func = async () => {
      const geocodeObj =
        address &&
        address.value &&
        (await geocodeByPlaceId(address.value.place_id));
      UserData.setsearchBoxLocaity(address?.label);
      const addressObject =
        geocodeObj && getCityName(geocodeObj[0].address_components);

      setAddressObj(addressObject);
    };
    func();
  }, [address]);

  useEffect(() => {
    // Update the selected location whenever the address changes
    setSelectedLocation(address?.label || "");
  }, [address]);
  // geo end
  const disptach = useDispatch();
  useEffect(() => {
    disptach(add({ view: ["contactForm"] }));
  }, []);

  //loading1 button
  //  const [loading1s, setloadings] = useState<boolean[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [visible, setVisible] = useState(false);
  // const [style1, setStyle1] = useState({color:"red!important"});
  // const [style2, setStyle2] = useState("cont");
  // const [buttoncheck,setbuttoncheck]=useState(null)

  // after cgha
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const dataUser = useContext(UserContext);

  const crop = {
    // unit: "%",
    // aspect: 6 / 6,
    width: "auto",
    height: "auto",
  };

  const { categories } = useParams();

  const [rentErr, setRentErr] = useState(false);
  const [typeErr, setTypeErr] = useState(false);
  const [newError, setNewError] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [catErr, setCatErr] = useState(false);
  const [subCat, setSubCat] = useState(false);
  const [afterSubmitFlg, setAfterSubmitFlg] = useState(false);
  const [images, setImages] = useState({});
  const [id, setId] = useState();
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [jobprice, setJobPrice] = useState(10);
  const [jobtags, setJobTags] = useState("");
  const [tags, setTags] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(categories); //13
  // const [subcategory, setSubcategory] = useState("") //13
  const [subcategoryForAll, setSubcategoryForAll] = useState("");
  const [brand, setBrand] = useState("");
  const [condition, setCondition] = useState("Good");
  const [state, setState] = useState("");
  const [city, setCity] = useState(null);
  const [locality, setLocality] = useState("");
  const [cordinates, setcordinates] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [date, setDate] = useState("");
  const [active, setActive] = useState("DashAds");
  const [loading1, setloading1] = useState(false);
  //loader
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState(null);
  const [storeadsFlag, setstoreadsFlag] = useState(false);
  const [realEsateSubCategory, setrealEsateSubCategory] = useState(null);

  const [subCategoryValue, setsubCategoryValue] = useState(null); //28
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedFruit, setSelectedFruit] = useState(null);

  const [subCategoryType, setsubCategoryType] = useState(null);
  const userDataValue = localStorage.getItem("userdata")
    ? decrypt("userdata")
    : null;
  const [successvalue, setSuccessValue] = useState(false);
  const [errorvalue, setErrorValue] = useState(false);
  const [message, setMessage] = useState(null);
  const [MoreImage, setMoreImage] = useState(false);
  const [phoneNumber, setphoneNumber] = useState("");
  // const [lat,setLat]=useState(false)
  // const [long,setLong]=useState(false)
  const [lat, setLat] = useState(false);
  const [long, setLong] = useState(false);
  ///category real esate

  const [BuildUpArea, setBuildUpArea] = useState(null);
  const [Flor, setFlor] = useState(null);
  const [ApartMentType, setApartMentType] = useState(null);
  const [Availability, setAvailability] = useState(null);
  const [FurnishedType, setFurnishedType] = useState(null);
  const [Property, setProperty] = useState(null);
  const [Parking, setParking] = useState(null);
  const [PowerBackup, setPowerBackup] = useState(null);
  const [Gym, setGym] = useState(null);
  const [Garden, setGarden] = useState(null);
  const [Pool, setPool] = useState(null);
  const [Lift, setLift] = useState(null);

  const [formDisable, setFormDisable] = useState(true);
  // when ads limit over

  const [adsLimitCheck, setAdsLimitCheck] = useState(false);
  const [currentSelectedplan, setcurrentSelectedplan] = useState(null);

  //label change code
  const [labelBrand, setLableBrand] = useState("Brand");
  useEffect(() => {
    setLableBrand(subCategoryValue === "Animal" ? "Breed" : "Brand");
  }, [subCategoryValue]);
  const [colorCheck, setcolor] = useState(null);
  const [sizeCheck, setsize] = useState(null);

  const [oldPetsCheck, setoldPets] = useState(null);

  // state error
  //checkbox

  var planCategory =
    dataUser?.activePlan[dataUser?.activePlan?.length - 1]?.fields?.category;

  // all ads data

  const [allAdsCount, setAllAdsCount] = useState([]);

  // userPlan

  const { loading, data } = useSelector((state) => state.planData);

  console.log("~~~databyplan187", data);

  // for change plan
  const [updateAds_left, setUpdateAds_left] = useState(null);
  const [updateCategory, setUpdateCategory] = useState(null);
  const [updateFeatured_ads, setUpdateFeatured_ads] = useState(null);
  const [updateAds_limit, setUpdateAds_limit] = useState(null);
  const [updateTop_listing, setUpdateTop_listing] = useState(null);
  const [updateSupport, setUpdateSupport] = useState(null);
  const [updateAds_timimg, setUpdateAds_timimg] = useState(null);
  let userid = localStorage.getItem("userid");
  // let userid=localStorage.getItem("userid")
  // aminities checkbox value
  const [coordinates, setCoordinates] = useState("");
  const [aminities, setAminities] = useState([]);

  window.onload = () => {
    getLocation();
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  // const MyComponent = () => {
  useEffect(() => {
    getLocation();
  }, []);

  const showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const coordinatesInput = document.getElementById("coordinatesInput");

    // Update the input field with the latitude and longitude
    coordinatesInput.value = `${latitude}, ${longitude}`;
    setCoordinates(`${latitude}, ${longitude}`);
    setLat(`${latitude}`);
    setLong(`${longitude}`);
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
    }
  };

  console.log("aminites", aminities);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserPlan(userid));
  }, []);
  const getData = async () => {
    let headersList = {
      Accept: "*/*",
    };

    let bodyContent = new FormData();
    bodyContent.append("user", userid);

    let response = await fetch(localUrl + "adsapi/allPlanData", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });
    let data = await response.json();
    setAllAdsCount(data);
  };
  const [updateState, setUpdateState] = useState(null);
  const [cityWithState, setCityWithState] = useState(null);
  useEffect(() => {
    Object.keys(stateMain)?.filter((val) => {
      if (val === updateState) {
        setCityWithState(stateMain[val]);
      }
    });
  }, [updateState]);
  useEffect(() => {
    getData();
  }, []);

  const [dataByPlan, setDataByplan] = useState([]);
  const [plan, setPlan] = useState("");
  const [adsCategory, setAdsCategory] = useState("");
  const [usertype, setusertype] = useState("");

  const [dayslimit, setDaysLimit] = useState(null);
  const [planCategory1, setPlanCategory] = useState(null);
  const [planType, setPlanType] = useState(null);
  const [expireData, setExpireDate] = useState(false);
  // error for without choosing plan
  const [planError, setPlanError] = useState(false);
  //set new loader
  const [newLoader, setNewLoader] = useState(false);

  // set New modal
  const [openNewModal, setOpenNewModal] = useState(false);

  useEffect(() => {
    if (plan !== "") {
      setAdsCategory(data?.postAdsForm[plan]?.category[0]);
    }
  }, [plan]);

  useEffect(() => {
    setCity(null);
  }, [state]);

  useEffect(() => {
    setsubCategoryValue(null);
  }, [category]);

  const [business, setBusiness] = useState(false); // Initialize with null
  const [leftPlan, setLeftPlan] = useState([]); // Initialize with null
  // const dataUser = useContext(UserContext);
  const useridst = localStorage.getItem("userid");

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("userid", useridst);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/adsapi/checkBusinessPlan", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBusiness(result.businessPlan);
        setLeftPlan(result.leftPlan);
        console.log(result, "resvaerarasrar");
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    if (adsCategory !== "" && plan !== "") {
      let days;
      if (plan === "Silver") {
        days = 15;
      } else if (plan === "Gold") {
        days = 30;
      } else if (plan === "Free") {
        days = 7;
      } else if (plan === "Platinum") {
        days = 60;
      } else if (plan === "featured") {
        days = 90;
      } else if (plan === "premium") {
        days = 90;
      }
      setDaysLimit(days);
      setPlanCategory(adsCategory);
      setPlanType(plan);
      setExpireDate(false);
      setPlanError(false);
    }
  }, [adsCategory]);

  useEffect(() => {
    console.log(data.leftPlan, "jj");
    var today = new Date();

    var datevalue =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    console.log(datevalue);

    setDate(datevalue);
  }, []);

  const navigate = useNavigate();

  if (localStorage.getItem("access_token") == null) {
    navigate("/login/");
  }

  const CategoryList = Category;
  const coords = useGeolocated();
  const handlePay = () => {
    let formdata = {};
    // formdata.append("image", fileInput.files[0], "/C:/Users/USER/Pictures/Screenshots/Screenshot (4).png");
    formdata["ad_title"] = title;
    formdata["price"] = price;
    formdata["tags"] = tags;
    formdata["description"] = description;
    formdata["category"] = category;
    formdata["subCategoryValue"] = subCategoryValue;
    // formdata["brand"] = brand;
    // formdata["condition"] = condition;
    formdata["state"] = state;
    formdata["city"] = city;
    formdata["location"] = dataUser?.selectedLocation;
    formdata["zip_code"] = zipcode;
    formdata["user"] = id;

    if (image !== null) {
      formdata["images"] = image;
    }
    console.log("checking value", formdata);
    localStorage.setItem("payAdsData", JSON.stringify(formdata));
  };
  const name = userDataValue?.name;
  const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;

    setrealEsateSubCategory(value);
    if (checked) {
      console.log("checkined");
    } else {
      // setfeatureTrue(null)
      console.log("//unchecked");
    }
  };
  const [categorysubCategoryFlag, setcategorysubCategoryFlag] = useState(false);

  const selector = useSelector((state) => state.activePlan);
  const user1 = useContext(UserContext);
  useEffect(() => {
    console.log("##pricing plan ,selector ", selector, user1.pricing);
    if (parseInt(dataUser?.activePlan) <= 0) {
      setFormDisable(false);
    }
  }, [selector]);

  const selectorExtraSlice = useSelector(
    (state) => state.adsPostingExtraFiledSlice
  );
  useEffect(() => {
    console.log("#### checking add product ", selectorExtraSlice);
  }, [selectorExtraSlice]);
  useEffect(() => {
    if (true) {
      var formdata = new FormData();
      formdata.append("user", localStorage.getItem("userid"));

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(localUrl + "adsapi/getPricingViews", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("## pricing service response result", result);
          disptach(addActivePlan({ plan: result }));
          dataUser.setActivePlan(result);
        })
        .catch((error) => console.log("error", error));
    }
    console.log("###value user active plan", dataUser?.activePlan);
  }, []);
  // useEffect(() => {
  //   getLocationSearchMethod();
  // }, []);
  const getLocationSearchMethod = () => {
    const options = {
      method: "GET",
      url: "https://google-maps-geocoding.p.rapidapi.com/geocode/json",
      params: {
        address: dataUser?.locality,
        language: "en",
      },
      headers: {
        "X-RapidAPI-Key": "7fded38e7emsh3c4fb60f3b8017cp1c084bjsn32180c499f5f",
        "X-RapidAPI-Host": "google-maps-geocoding.p.rapidapi.com",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        console.log(
          "lat long ",
          response.data.results[0].geometry.location.lat,
          response.data.results[0].geometry.location.lng
        );

        setLat(response.data.results[0].geometry.location.lat);
        setLong(response.data.results[0].geometry.location.lng);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  // const updateCurrentPlan = (props) => {
  //   debugger;
  //   let userdataid = props?.toString();
  //   var formdata = new FormData();
  //   formdata.append("user", userdataid);

  //   var requestOptions = {
  //     method: "POST",
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(url + "api/adsapi/updatePlanLimit", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => console.log(result))
  //     .catch((error) => console.log("error", error));
  // };

  const [selectedSkincareBrand, setSelectedSkincareBrand] = useState(null);
  const [
    selectedSkincareProductType,
    setSelectedSkincareProductType,
  ] = useState(null);
  const [selectedSkinType, setSelectedSkinType] = useState(null);
  const [selectedSkincareCondition, setSelectedSkincareCondition] = useState(
    null
  );

  const [selectedHaircareBrand, setSelectedHaircareBrand] = useState(null);
  const [
    selectedHaircareProductType,
    setSelectedHaircareProductType,
  ] = useState(null);
  const [selectedHairType, setSelectedHairType] = useState(null);
  const [selectedHaircareCondition, setSelectedHaircareCondition] = useState(
    null
  );

  const [selectedMakeupBrand, setSelectedMakeupBrand] = useState(null);
  const [selectedMakeupProductType, setSelectedMakeupProductType] = useState(
    null
  );
  const [selectedSkinTone, setSelectedSkinTone] = useState(null);
  const [selectedMakeupCondition, setSelectedMakeupCondition] = useState(null);

  const [selectedAppliancesBrand, setSelectedAppliancesBrand] = useState(null);
  const [selectedApplianceType, setSelectedApplianceType] = useState(null);
  const [selectedPowerSource, setSelectedPowerSource] = useState(null);
  const [
    selectedAppliancesCondition,
    setSelectedAppliancesCondition,
  ] = useState(null);

  const [selectedFitnessBrand, setSelectedFitnessBrand] = useState(null);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedFitnessCondition, setSelectedFitnessCondition] = useState(
    null
  );

  const [selectedSupplementsBrand, setSelectedSupplementsBrand] = useState(
    null
  );
  const [selectedSupplementType, setSelectedSupplementType] = useState(null);
  const [selectedTargetGroup, setSelectedTargetGroup] = useState(null);
  const [
    selectedSupplementsCondition,
    setSelectedSupplementsCondition,
  ] = useState(null);

  const [MachingCriteria, setMachingCriteria] = useState("");
  const [MinExperience, setMinExperience] = useState("");
  const [MaxExperience, setMaxExperience] = useState("");
  const [Industryy, setIndustryy] = useState("");
  const [Nationalityy, setNationalityy] = useState("");
  const [ContractTenure, setContractTenure] = useState("");
  const [SelectTenuree, setSelectTenuree] = useState("");
  const [MinSalery, setMinSalery] = useState("");
  const [MaxSalery, setMaxSalery] = useState("");
  const [FullTimePartTime, setFullTimePartTime] = useState("");
  const [Categoryy, setCategoryy] = useState("");
  const [NoOfOpenings, setNoOfOpenings] = useState("");
  const [Timingss, setTimingss] = useState("");
  const [FromTime, setFromTime] = useState("");
  const [ToTime, setToTime] = useState("");
  const [website, setwebsite] = useState("");

  const handleSkincareBrandChange = (value) => {
    setSelectedSkincareBrand(value);
    setError(false);
    setHandleSubCatVal(value);
  };

  const handleSkincareProductTypeChange = (value1) => {
    setSelectedSkincareProductType(value1);
    setError(false);
    setHandleSubCatVal(value1);
  };

  const handleSkinTypeChange = (value3) => {
    setSelectedSkinType(value3);
    setError(false);
    setHandleSubCatVal(value3);
  };

  const handleSkincareConditionChange = (value4) => {
    setSelectedSkincareCondition(value4);
    setError(false);
    setHandleSubCatVal(value4);
  };

  const handleHaircareBrandChange = (value5) => {
    setSelectedHaircareBrand(value5);
    setError(false);
    setHandleSubCatVal(value5);
  };

  const handleHaircareProductTypeChange = (value6) => {
    setSelectedHaircareProductType(value6);
    setError(false);
    setHandleSubCatVal(value6);
  };

  const handleHairTypeChange = (value7) => {
    setSelectedHairType(value7);
    setError(false);
    setHandleSubCatVal(value7);
  };

  const handleHaircareConditionChange = (value8) => {
    setSelectedHaircareCondition(value8);
    setError(false);
    setHandleSubCatVal(value8);
  };

  const handleMakeupBrandChange = (value9) => {
    setSelectedMakeupBrand(value9);
    setError(false);
    setHandleSubCatVal(value9);
  };

  const handleMakeupProductTypeChange = (value10) => {
    setSelectedMakeupProductType(value10);
    setError(false);
    setHandleSubCatVal(value10);
  };

  const handleSkinToneChange = (value11) => {
    setSelectedSkinTone(value11);
    setError(false);
    setHandleSubCatVal(value11);
  };

  const handleMakeupConditionChange = (value12) => {
    setSelectedMakeupCondition(value12);
    setError(false);
    setHandleSubCatVal(value12);
  };

  const handleAppliancesBrandChange = (value13) => {
    setSelectedAppliancesBrand(value13);
    setError(false);
    setHandleSubCatVal(value13);
  };

  const handleApplianceTypeChange = (value14) => {
    setSelectedApplianceType(value14);
    setError(false);
    setHandleSubCatVal(value14);
  };

  const handlePowerSourceChange = (value15) => {
    setSelectedPowerSource(value15);
    setError(false);
    setHandleSubCatVal(value15);
  };

  const handleAppliancesConditionChange = (value16) => {
    setSelectedAppliancesCondition(value16);
    setError(false);
    setHandleSubCatVal(value16);
  };

  const handleFitnessBrandChange = (value17) => {
    setSelectedFitnessBrand(value17);
    setError(false);
    setHandleSubCatVal(value17);
  };

  const handleEquipmentTypeChange = (value18) => {
    setSelectedEquipmentType(value18);
    setError(false);
    setHandleSubCatVal(value18);
  };

  const handleMaterialChange = (value19) => {
    setSelectedMaterial(value19);
    setError(false);
    setHandleSubCatVal(value19);
  };

  const handleFitnessConditionChange = (value20) => {
    setSelectedFitnessCondition(value20);
    setError(false);
    setHandleSubCatVal(value20);
  };

  const handleSupplementsBrandChange = (value21) => {
    setSelectedSupplementsBrand(value21);
    setError(false);
    setHandleSubCatVal(value21);
  };

  const handleSupplementTypeChange = (value22) => {
    setSelectedSupplementType(value22);
    setError(false);
    setHandleSubCatVal(value22);
  };

  const handleTargetGroupChange = (value23) => {
    setSelectedTargetGroup(value23);
    setError(false);
    setHandleSubCatVal(value23);
  };

  const handleSupplementsConditionChange = (value24) => {
    setSelectedSupplementsCondition(value24);
    setError(false);
    setHandleSubCatVal(value24);
  };

  const qrcodeGenrate = (props) => {
    console.log("|datauser vsalue", dataUser?.activePlan);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkxMTQ1MzkwLCJpYXQiOjE2NTk2MDkzOTAsImp0aSI6IjM5MzJjZjJkZTYyNTQwMjRiNTEyZDk4ZTE4ZDM1Mjk3IiwidXNlcl9pZCI6MjJ9.6uTC3ZTlxdqyhBewkVN5O4MduQ6O6YCNB6p9QJhOF3w"
    );

    var formdata = new FormData();
    formdata.append("product", props);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/user/qrCodeAds", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };


  const imageUpload = (props) => {
    setloading1(true);
    setNewLoader(true);
    qrcodeGenrate(props);
    console.log("WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW", images);
    for (var file in images) {
      console.log(images[file]);

      var formdata = new FormData();
      formdata.append("imageList", images[file]);
      formdata.append("adsId", props);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(localUrl + `adsapi/adsUpload`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setloading1(true);
          setNewLoader(false);
          console.log(result);
          setOpenNewModal(true);
          // navigate(`/ads-listing/${props}/`);
        })
        .catch((error) => {
          setloading1(true);
          setNewLoader(false);
          setError("Somethig went wrong..");
        });
    }
    setloading1(false);
  };

  const handleInputChange = () => {
    setAfterSubmitFlg(false);
  };

  const [ErrorExtraField, setErrorExtraField] = useState(false);
  useEffect(() => {
    if (category === "HealthAndBeauty") {
      setPrice(jobprice);
      setTags(tags);
    } else {
      setPrice("");
      setTags("");
    }
  }, [category]);

  const [expiryDate, setExpiryDate] = useState(""); // State to manage expiry date

  const [uploadedImages, setUploadedImages] = useState(null);

  const addNewProduct = async () => {
    // setNewLoader(true);
    // setIsLoading(true);

    var aKeys = Object.keys(finalObj).sort();
    var bKeys = Object.keys(obj).sort();

    // setErrorExtraField(true);
    // setAdsLimitCheck(true);
    // setIsLoading(false);


    // if (dataUser?.activePlan[0]?.fields?.adsLeft == 0) {
    //   console.log("~~~ plan is zero");
    //   setAdsLimitCheck(true);
    // } else
    // Validate required fields
    if (
      expireData ||
      !("0" in images) ||
      !title ||
      !tags ||
      !city ||
      !description ||
      !category ||
      !price ||
      !state ||
      !usertype ||
      // !dataUser?.locality ||
      !zipcode ||
      zipcode.length < 6 ||
      !phoneNumber ||
      phoneNumber.length < 10
    ) {

      let formdata = new FormData();
      if (true) {
        setNewLoader(false);
        for (var file in images) {
          formdata.append("image", images[file]);
          console.log("~~~image");
          break;
        }
      }


      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      );


      formdata.append("title", title);
      formdata.append("description", description);
      formdata.append("category", category);
      formdata.append("brand", brand);
      // formdata.append("Engine", Engine);
      // formdata.append("Year", Year);
      formdata.append("subCategoryType", subCategoryType);
      formdata.append("subCategoryValue", subCategoryValue);
      formdata.append("zip_code", zipcode);
      formdata.append("condition", condition);
      // Ensure user ID is appended as an integer
      formdata.append("user", userDataValue.id);

      formdata.append("price", price);
      formdata.append("tags", tags);
      formdata.append("locality", selectedLocation);
      formdata.append("state", state);
      formdata.append("City", city);
      formdata.append("lati", lat);
      formdata.append("long", long);
      formdata.append("phoneNumber", phoneNumber);
      // formdata.append("expiry_date", expiryDate ? new Date(expiryDate).toISOString().split("T")[0] : null);
      formdata.append("BuildUpArea", BuildUpArea);
      formdata.append("Flor", Flor);
      formdata.append("ApartMentType", ApartMentType);
      formdata.append("Availability", Availability);
      formdata.append("FurnishedType", FurnishedType);
      formdata.append("Property", Property);
      formdata.append("Parking", Parking);
      formdata.append("PowerBackup", PowerBackup);
      formdata.append("Gym", Gym);
      formdata.append("Garden", Garden);
      formdata.append("Pool", Pool);
      formdata.append("Lift", Lift);
      formdata.append("colorCheck", colorCheck);
      formdata.append("sizeCheck", sizeCheck);

      formdata.append("oldPetsCheck", oldPetsCheck);

      formdata.append("uuid", localStorage.getItem("uuid"));
      formdata.append("date_created", date);
      formdata.append("PlanCategory", updateCategory);
      formdata.append("featured_ads", updateFeatured_ads);
      formdata.append("ads_limit", updateAds_limit);
      formdata.append("ads_timing", updateAds_timimg);
      formdata.append("top_listing", updateTop_listing);
      formdata.append("support", updateSupport);
      formdata.append("adsType", planCategory1);
      formdata.append("plan", planType);
      formdata.append("DaysLimit", dayslimit);
      formdata.append("user_type", usertype);
      formdata.append("expiry", expireData);

      // Add Extra Field Data based on subcategory
      const extraFieldData = {};
      if (subCategoryValue === "Skincare Products") {
        extraFieldData.skincare_brand = selectedSkincareBrand;
        extraFieldData.skincare_product_type = selectedSkincareProductType;
        extraFieldData.skin_type = selectedSkinType;
        extraFieldData.skincare_condition = selectedSkincareCondition;
      } else if (subCategoryValue === "Haircare Products") {
        extraFieldData.haircare_brand = selectedHaircareBrand;
        extraFieldData.haircare_product_type = selectedHaircareProductType;
        extraFieldData.hair_type = selectedHairType;
        extraFieldData.haircare_condition = selectedHaircareCondition;
      } else if (subCategoryValue === "Makeup & Cosmetics") {
        extraFieldData.makeup_brand = selectedMakeupBrand;
        extraFieldData.makeup_product_type = selectedMakeupProductType;
        extraFieldData.skin_tone = selectedSkinTone;
        extraFieldData.makeup_condition = selectedMakeupCondition;
      } else if (subCategoryValue === "Personal Care Appliances (Trimmers, Hair Dryers)") {
        extraFieldData.appliance_brand = selectedAppliancesBrand;
        extraFieldData.appliance_type = selectedApplianceType;
        extraFieldData.power_source = selectedPowerSource;
        extraFieldData.appliances_condition = selectedAppliancesCondition;
      } else if (subCategoryValue === "Fitness Equipment (Yoga Mats, Weights)") {
        extraFieldData.fitness_brand = selectedFitnessBrand;
        extraFieldData.equipment_type = selectedEquipmentType;
        extraFieldData.material = selectedMaterial;
        extraFieldData.fitness_condition = selectedFitnessCondition;
      } else if (subCategoryValue === "Supplements & Vitamins") {
        extraFieldData.supplements_brand = selectedSupplementsBrand;
        extraFieldData.supplement_type = selectedSupplementType;
        extraFieldData.target_group = selectedTargetGroup;
        extraFieldData.supplements_condition = selectedSupplementsCondition;
      }

      // If extra fields exist, append them
      if (Object.keys(extraFieldData).length > 0) {
        formdata.append("extraField", JSON.stringify(extraFieldData));
      }


      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };


      fetch(url + "api/adsapi/", requestOptions)
        .then((response) => response.json())
        .then((result) => {

          if (result?.detail) {
            setErrorValue(true);
            setSuccessValue(false);
            alert("token expire");
            localStorage.removeItem("access_token");
            navigate("/");
            setloading1(false);
          }
          console.log("result", result);
          if (result.status !== "OK" && result?.DaysLimit && result?.expiry) {
            setPlanError(true);
          } else if (!result?.id) {
            alert("Ads not posted");
          } else {
            imageUpload(result.id);
          }
          // setIsLoading(false);//
          console.log(result);
        });
    }

    setstoreadsFlag(false);

    // setIsLoading(false);
  };


  const [files, setFiles] = useState([]);
  ///function for multiple image upload
  const fileSelectedHandler = (e) => {
    console.log("imag efiles that one", e.target.files);
    files.push(e.target.files[0]);
    setFiles(files);
    console.log("!!!!!!!!!!!state", files);
  };

  useEffect(() => {
    console.log("@@@@@  datause.locality", dataUser?.locality);
    if (dataUser?.locality) {
      getLocationSearchMethod();
    }
  }, [dataUser?.locality]);
  useEffect(() => {
    var today = new Date();
    var datevalue =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    console.log(datevalue);
    setDate(datevalue);
  }, []);

  //for adding multiple values their

  const [finalObj, SetFinalObj] = useState({});
  const [obj, setObj] = useState({});
  const [extraFeildToggle, setExtraFeildToggle] = useState(false);
  useEffect(() => {
    if (subCategoryValue && subCategoryInput[category] !== undefined) {
      let subcategoryy = subCategoryInput[category];
      if (subcategoryy[subCategoryValue] !== undefined) {
        setExtraFeildToggle(true);
        let values = subcategoryy[subCategoryValue];
        let obj1 = values;
        Object.keys(obj1).forEach((key) => {
          let varkey = [];
          varkey = obj1[key].map((result) => {
            if (typeof result === "object" && result !== null) {
              return result;
            } else {
              return { value: result };
            }
          });
          obj1[key] = varkey;
        });

        console.log(obj1, subcategoryy, subcategoryy[subCategoryValue]);
        setObj(obj1);
      } else {
        setExtraFeildToggle(false);
      }
    } else {
      setExtraFeildToggle(false);
    }
  }, [subCategoryValue]);

  useEffect(
    (e) => {
      console.log("!!!category changes");
      // setsubCategoryType(e.currentTarget)
    },
    [category]
  );
  const onChangeplan = (e) => {
    setcurrentSelectedplan(e.target.value);
    console.log(`checked = ${e.target.value}`);
  };
  console.log("obj", obj);
  const style = {
    position: "fixed",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
  };
  document.title = "Hola9 - AddProducts";
  const [change, setChange] = useState("1");
  const [changeState, setChangeState] = useState("2");
  const [changeCity, setChangeCity] = useState("1");
  const [changeSubCat, setChangeSubCat] = useState("1");
  const [subCateReal, setSubCateReal] = useState("1");
  const [subCateRealType, setSubCateRealType] = useState("1");

  const [visible1, setVisible1] = useState(false);
  const [visible41, setVisible41] = useState(false);
  const [visible42, setVisible42] = useState(false);
  const [visible43, setVisible43] = useState(false);
  const [visible44, setVisible44] = useState(false);
  const [visible45, setVisible45] = useState(false);
  const [visible46, setVisible46] = useState(false);
  const [visible47, setVisible47] = useState(false);
  const [visible48, setVisible48] = useState(false);
  const [visible49, setVisible49] = useState(false);
  const [visible50, setVisible50] = useState(false);
  const [visible51, setVisible51] = useState(false);
  const [visible52, setVisible52] = useState(false);
  const [visible53, setVisible53] = useState(false);
  const [visible54, setVisible54] = useState(false);
  const [visible55, setVisible55] = useState(false);
  const [visible56, setVisible56] = useState(false);
  const [visible57, setVisible57] = useState(false);
  const [visible58, setVisible58] = useState(false);
  const [visible59, setVisible59] = useState(false);
  const [visible60, setVisible60] = useState(false);
  const [visible61, setVisible61] = useState(false);
  const [visible62, setVisible62] = useState(false);
  const [visible63, setVisible63] = useState(false);
  const [visible64, setVisible64] = useState(false);
  const [visible65, setVisible65] = useState(false);

  const handleDropdownVisibleChange41 = (open) => {
    setVisible41(open);
  };
  const handleDropdownVisibleChange42 = (open) => {
    setVisible42(open);
  };
  const handleDropdownVisibleChange43 = (open) => {
    setVisible43(open);
  };
  const handleDropdownVisibleChange44 = (open) => {
    setVisible44(open);
  };
  const handleDropdownVisibleChange45 = (open) => {
    setVisible45(open);
  };
  const handleDropdownVisibleChange46 = (open) => {
    setVisible46(open);
  };
  const handleDropdownVisibleChange47 = (open) => {
    setVisible47(open);
  };
  const handleDropdownVisibleChange48 = (open) => {
    setVisible48(open);
  };
  const handleDropdownVisibleChange49 = (open) => {
    setVisible49(open);
  };
  const handleDropdownVisibleChange50 = (open) => {
    setVisible50(open);
  };
  const handleDropdownVisibleChange51 = (open) => {
    setVisible51(open);
  };
  const handleDropdownVisibleChange52 = (open) => {
    setVisible52(open);
  };
  const handleDropdownVisibleChange53 = (open) => {
    setVisible53(open);
  };
  const handleDropdownVisibleChange54 = (open) => {
    setVisible54(open);
  };
  const handleDropdownVisibleChange55 = (open) => {
    setVisible55(open);
  };
  const handleDropdownVisibleChange56 = (open) => {
    setVisible56(open);
  };
  const handleDropdownVisibleChange57 = (open) => {
    setVisible57(open);
  };
  const handleDropdownVisibleChange58 = (open) => {
    setVisible58(open);
  };
  const handleDropdownVisibleChange59 = (open) => {
    setVisible59(open);
  };
  const handleDropdownVisibleChange60 = (open) => {
    setVisible60(open);
  };
  const handleDropdownVisibleChange61 = (open) => {
    setVisible61(open);
  };
  const handleDropdownVisibleChange62 = (open) => {
    setVisible62(open);
  };
  const handleDropdownVisibleChange63 = (open) => {
    setVisible63(open);
  };
  const handleDropdownVisibleChange64 = (open) => {
    setVisible64(open);
  };
  const handleDropdownVisibleChange65 = (open) => {
    setVisible65(open);
  };

  const handleScroll = () => {
    setVisible1(false);
  };

  const [onevisible, setonevisible] = useState(true);
  const [onevisible1, setonevisible1] = useState(false);
  const [onevisible2, setonevisible2] = useState(false);
  const [onevisible3, setonevisible3] = useState(false);
  const [onevisible4, setonevisible4] = useState(false);

  const [handleSubCatVal, setHandleSubCatVal] = useState("");
  const [imageError, setImageError] = useState(false);

  const handlevis1 = (e) => {
    e.preventDefault();
    if (
      subCategoryValue == "" ||
      Object.entries(images).length === 0 ||
      handleSubCatVal == ""
    ) {
      setChangeSubCat(!changeSubCat);
      if (subCategoryValue) {
        setChangeSubCat(changeSubCat);
      }
      setError(true);
      if (handleSubCatVal !== "") {
        setError(false);
      }
      setImageError(true);
      if (Object.entries(images).length !== 0) {
        setImageError(false);
      }
    } else {
      getLocation();
      setonevisible(false);
      setonevisible1(true);
      setonevisible2(true);
      setonevisible3(false);
    }
  };

  const handlevis2 = () => {
    setonevisible(true);
    setonevisible1(false);
    setonevisible3(true);
    setonevisible2(false);
    setonevisible4(false);
  };
  const handlevis3 = () => {
    setonevisible3(true);
    setonevisible2(false);
    setonevisible4(true);
  };
  const handlevis4 = () => {
    setonevisible3(false);
    setonevisible2(true);
    setonevisible1(true);
    setonevisible(false);
    setonevisible4(false);
  };

  const isFormValid = () => {
    return (
      title &&
      phoneNumber &&
      tags &&
      price &&
      // website &&
      state &&
      city &&
      address &&
      zipcode &&
      description
    );
  };

  const [showVideoForm, setShowVideoForm] = useState(false);

  const handleCheckboxChange = (e) => {
    setShowVideoForm(e.target.checked);
  };

  const fileInputRef = useRef(null);
  const [uploadedVideos, setUploadedVideos] = useState([]);

  const handleIconClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleRemoveVideo = (index) => {
    const newUploadedVideos = [...uploadedVideos];
    newUploadedVideos.splice(index, 1);
    setUploadedVideos(newUploadedVideos);
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    let validVideo = null;

    for (let i = 0; i < files.length; i++) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(files[i]);

      // Wait for metadata to be loaded
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      // Check if video duration is less than or equal to 30 seconds
      if (video.duration <= 30) {
        validVideo = files[i];
        break; // Stop checking further files once a valid video is found
      }
    }

    // Update state with the valid uploaded video (clearing previous videos)
    setUploadedVideos(validVideo ? [validVideo] : []);
  };

  return (
    <div className="container shadow bg-light">
      {/* <Spin tip="Loading..." spinning={newLoader} /> */}
      {!formDisable && <h2 className="text-danger">Your plan has expired</h2>}

      <fieldset disabled={!formDisable}>
        <div>
          <div
            className="white mx-auto"
            style={isMobile ? mobileStyle1 : desktopStyle1}
          >
            <h1
              style={{
                fontFamily: "serif",
                fontSize: "40px",
                fontWeight: "bold",
                color: "#4a4a4a",
              }}
            >
              Post Your <span style={{ color: "red" }}>{categories}</span> Ads
              Here
            </h1>
            <br />

            {onevisible1 && (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "whitesmoke",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "2px dotted black",
                }}
              >
                <div
                  style={{
                    backgroundColor: "navy",
                    color: "white",
                    padding: "10px 15px",
                    borderRadius: "20px",
                    fontWeight: "700",
                    marginLeft: "15px",
                  }}
                >
                  1
                </div>
                <h2
                  style={{
                    fontFamily: "sans-serif",
                    fontWeight: "lighter",
                  }}
                >
                  Ads Details
                </h2>
                <div
                  onClick={handlevis2}
                  style={{
                    marginRight: "15px",
                    fontSize: "25px",
                    fontWeight: "bolder",
                    color: "blue",
                  }}
                >
                  {" "}
                  <TbPlayerTrackNext />{" "}
                </div>
              </div>
            )}
            <br></br>
            {onevisible && (
              <>
                <div className="row p-2">
                  <div className="col-lg-12">
                    <label>Upload Images</label>
                    <MultiImageInput
                      max={10}
                      images={images}
                      setImages={setImages}
                      cropConfig={{ crop, ruleOfThirds: true }}
                      theme={{
                        background: "#ffffff",
                        outlineColor: "#111111",
                        textColor: "rgba(255,255,255,0.6)",
                        buttonColor: "#ff0e1f",
                        modalColor: "#ffffff",
                      }}
                    />
                  </div>
                  {imageError ? (
                    <p style={{ color: "red" }}>Please Upload the Images</p>
                  ) : null}
                  {MoreImage ? (
                    <div className="col-lg-8">
                      <div>
                        <form className="padingmanage">
                          <input type="file" onChange={fileSelectedHandler} multiple />
                        </form>
                        <Container>
                          <Row>
                            {files.map((result) => {
                              return (
                                <Col xs={2} md={3}>
                                  {" "}
                                  <a alt="" href="" className="block-icon">
                                    <Image
                                      src={URL.createObjectURL(result)}
                                      thumbnail
                                    />

                                    <FaCut
                                      className=" icon-tag"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // this.removeItem(result)
                                      }}
                                    />
                                  </a>{" "}
                                </Col>
                              );
                            })}
                          </Row>
                        </Container>
                      </div>
                    </div>
                  ) : null}
                  {!("0" in images) && (
                    <small className="text-danger ml-2">{error}</small>
                  )}

                  {/* <div className="col-lg-6 col-sm-12">
              <label>Video upload</label>
              <div style={{border:'2px solid black',padding:'20px'}}>
               <VideoInput height={200}/>
               </div>
              </div> */}
                </div>

                <div className="row p-2">
                  <div className="col-lg-12">
                    <label>
                      Upload Videos &nbsp;{" "}
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        style={{
                          visibility: "visible",
                          opacity: "1",
                          zIndex: "1",
                          background: "black",
                          WebkitAppearance: "checkbox",
                          cursor: "pointer",
                          position: "relative",
                        }}
                      />{" "}
                    </label>
                    {showVideoForm && (
                      <>
                        <div
                          style={{
                            border: "2px solid black",
                            padding: "20px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {uploadedVideos.map((video, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                marginRight: "10px",
                              }}
                            >
                              <video
                                src={URL.createObjectURL(video)}
                                width="80"
                                height="60"
                                controls
                              />
                              <p
                                onClick={() => handleRemoveVideo(index)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  background: "darkred",
                                  border: "none",
                                  cursor: "pointer",
                                  color: "white",
                                  fontSize: "10px",
                                  borderRadius: "50%",
                                  padding: "0 3px",
                                  marginTop: "-7px",
                                  marginRight: "3px",
                                }}
                              >
                                X
                              </p>
                            </div>
                          ))}

                          <label
                            htmlFor="fileInput"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <IoVideocamOutline
                              style={{ fontSize: "60px" }}
                              onClick={handleIconClick}
                            />
                            <p
                              style={{
                                fontSize: "75%",
                                marginBottom: "0",
                              }}
                            >
                              ADD VIDEO
                            </p>
                            {/* <i style={{color:'red', fontSize:'60%'}}>video length should be below 30seconds</i> */}
                          </label>

                          <input
                            ref={fileInputRef}
                            type="file"
                            accept="video/*"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="row p-3" style={{ marginBottom: "-20px" }}>
                  <div
                    className="col-lg-6 col-sm-12"
                    style={{ display: "none" }}
                  >
                    <label className="mb-0 ">Category</label>
                    {/* <Select
                  className="custom-select1"
                  style={{
                    // padding: "10px",
                    width: "100%",
                    zIndex: "-10px",
                    // borderRadius: "3px",
                  }}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  allowClear
                  status={change ? "" : "error"}
                  onChange={(value) => {
                    console.log("1276", value);
                    setChange(value);
                    setCategory(value);
                    setcategorysubCategoryFlag(
                      value === "RealEstate" ? true : false
                    );
                    if (category === "RealEstate") {
                      setsubCategoryValue(null);
                      setsubCategoryType(null);
                    } else {
                      setsubCategoryValue("");
                    }
                  }}
                  showSearch
                  placeholder="Select Category"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[{ value: "Education", label: "Education" }]}
                /> */}
                    <input
                      className="myinput"
                      type="text"
                      name="category"
                      placeholder="enter category here"
                      value={category}
                      style={{
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                    />

                    {!change && (
                      <small className="text-danger ml-2">
                        *Select right category
                      </small>
                    )}
                    {change == false ? (
                      <span className="text-danger ml-2">
                        select right category
                      </span>
                    ) : change && !category && afterSubmitFlg ? (
                      <small className="text-danger ml-2">
                        {CreateAds.Category}
                      </small>
                    ) : null}

                    {/* {!category && afterSubmitFlg && <span className="text-danger ml-2">{CreateAds.Category}</span>} */}
                  </div>

                  {category != "RealEstate" ? (
                    <div className="col-lg-6 col-sm-12">
                      <label className="mb-0">Sub Category</label>

                      <Select
                        className="custom-select1"
                        style={{
                          // padding: "10px",
                          width: "100%",
                          zIndex: "-10px",
                          // borderRadius: "3px",
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        allowClear
                        value={subCategoryValue}
                        status={changeSubCat ? "" : "error"}
                        onChange={(value) => {
                          console.log("1276", value);

                          setsubCategoryValue("");
                          setAfterSubmitFlg(false);
                          setTimeout(() => {
                            setsubCategoryValue(value);
                          }, 0);

                          setChangeSubCat(value);

                          SetFinalObj({});
                        }}
                        showSearch
                        placeholder="Select Sub Category"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={
                          category == "PhotographyEquipment"
                            ? PhotographyEquipment.sort().map((result) => {
                              return { value: result, label: result };
                            })
                            : null
                        }
                      />

                      {!changeSubCat && (
                        <small className="text-danger ml-2">
                          *Select right sub category
                        </small>
                      )}
                      {subcategoryForAll && subCat == false ? (
                        <small className="text-danger ml-2">
                          *Select right category
                        </small>
                      ) : changeSubCat &&
                        !subCategoryValue &&
                        afterSubmitFlg ? (
                        <small className="text-danger ml-2">
                          *Please Enter The Sub Category
                        </small>
                      ) : null}
                    </div>
                  ) : null}

                  {/* Photographic equipment sub cat */}
                  {subCategoryValue === "Cameras" ? (
                    <>
                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible52}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange52
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Camera Type"
                            options={CameraType.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleSkincareBrandChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div>

                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible49}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange49
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Cameras Brand Name"
                            options={CamerasBrandName.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleSkincareProductTypeChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>

                        <div>
                          <Select
                            open={visible50}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange50
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Cameras Condition"
                            options={CamerasCondition.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleSkinTypeChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible51}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange51
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Cameras Megapixel Range"
                            options={CamerasMegapixelRange.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleSkincareConditionChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* Lenses sub cat */}
                  {subCategoryValue === "Lenses" ? (
                    <>
                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible44}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange44
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Lens Type"
                            options={LensType.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleHaircareBrandChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible41}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange41
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Lenses Brand Name"
                            options={LensesBrandName.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleHaircareProductTypeChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>

                        <div>
                          <Select
                            open={visible42}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange42
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Lenses Mount Type"
                            options={LensesMountType.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleHairTypeChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible43}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange43
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Lenses Condition"
                            options={LensesCondition.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleHaircareConditionChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                        <div>
                          <Select
                            open={visible45}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange45
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Lenses Focal Length Range"
                            options={LensesFocalLengthRange.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleHaircareConditionChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                        

                      </div>
                    </>
                  ) : null}

                  {/* Tripods sub cat */}
                  {subCategoryValue === "Tripods" ? (
                    <>
                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible44}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange44
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Tripod Type"
                            options={TripodType.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleMakeupBrandChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                        <div>
                          <Select
                            open={visible43}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange43
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Tripods Material"
                            options={TripodsMaterial.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleMakeupProductTypeChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div>

                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible45}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange45
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Tripods Max-Height"
                            options={TripodsMaxHeight.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleSkinToneChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                        <div>
                          <Select
                            open={visible62}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange62
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Tripods Load Capacity"
                            options={TripodsLoadCapacity.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleMakeupConditionChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>

                      </div>

                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible61}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange61
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Tripods Condition"
                            options={TripodsCondition.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleMakeupConditionChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* Accessories sub cat */}
                  {subCategoryValue === "Accessories" ? (
                    <>
                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible44}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange44
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Accessory Type"
                            options={AccessoryType.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleAppliancesBrandChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                        <div>
                          <Select
                            open={visible43}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange43
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Accessories Brand Name"
                            options={AccessoriesBrandName.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleApplianceTypeChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div>

                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible45}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange45
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Accessories Condition"
                            options={AccessoriesCondition.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handlePowerSourceChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div>
{/* 
                      <div style={isMobile ? mobstyle : deskstyle}>
                        <div>
                          <Select
                            open={visible62}
                            onDropdownVisibleChange={
                              handleDropdownVisibleChange62
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            dropdownMatchSelectWidth={false}
                            onPopupScroll={handleScroll}
                            className="classclass"
                            placeholder="Appliances Condition"
                            options={AppliancesCondition.map((result) => {
                              return { value: result, label: result };
                            })}
                            onChange={handleAppliancesConditionChange}
                          />
                          {error && (
                            <div className="text-danger ml-2">
                              *Please select this field
                            </div>
                          )}
                        </div>
                      </div> */}
                    </>
                  ) : null}
                </div>
                {/* <Button
                  varient="outlined"
                  onClick={subCategoryValue === "" ? null : handlevis1}
                  style={{ display: "flex", float: "right", color: "red" }}
                >
                  {" "}
                  Next{" "}
                </Button> */}
                <Button
                  varient="outlined"
                  onClick={handlevis1}
                  style={{
                    display: "flex",
                    float: "right",
                    color: "red",
                  }}
                >
                  {" "}
                  Next{" "}
                </Button>
              </>
            )}
            <br />
            <br />
            {/* <div classname="my-5">
                {category === "Services" ? <Service1 /> : null}
              </div> */}
            {/* <div className="row-hover"> */}
            {onevisible3 && (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "whitesmoke",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "2px dotted black",
                }}
              >
                <div
                  style={{
                    backgroundColor: "navy",
                    color: "white",
                    padding: "10px 15px",
                    borderRadius: "20px",
                    fontWeight: "700",
                    marginLeft: "15px",
                  }}
                >
                  2
                </div>
                <h2
                  style={{
                    fontFamily: "sans-serif",
                    fontWeight: "lighter",
                  }}
                >
                  Personal Details
                </h2>
                <div
                  onClick={handlevis4}
                  style={{
                    marginRight: "15px",
                    fontSize: "25px",
                    fontWeight: "bolder",
                    color: "blue",
                  }}
                >
                  {" "}
                  <TbPlayerTrackNext />{" "}
                </div>
              </div>
            )}
            <br></br>
            {onevisible2 && (
              <>
                {/* <div className="row-hover"> */}
                <div className="row p-3" style={{ marginTop: "-20px" }}>
                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Title</label>
                    <input
                      className="myinput"
                      type="text"
                      name="title"
                      placeholder="enter title here"
                      value={title}
                      style={{
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    {!title && afterSubmitFlg && (
                      <small className="text-danger ml-2">
                        {CreateAds.title}
                      </small>
                    )}
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Phone Number</label>
                    <input
                      // className="inpstyle"
                      className="myinput"
                      name="phoneNumber"
                      placeholder="Enter phone number"
                      maxLength={10}
                      type="number"
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      style={{
                        width: "100%",
                        zIndex: "-50px",
                        border: "1px solid #5d5d5d3d",
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                      onChange={(e) => {
                        setphoneNumber(e.target.value);
                      }}
                    />
                    {phoneNumber.length < 10 && phoneNumber.length > 0 ? (
                      <small className="text-danger ml-2">
                        Enter valid Number
                      </small>
                    ) : phoneNumber.length > 10 ? (
                      <small className="text-danger ml-2">
                        Enter only 10 digit Number
                      </small>
                    ) : !phoneNumber && afterSubmitFlg ? (
                      <small className="text-danger ml-2">
                        {CreateAds.PhoneNumber}
                      </small>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex" style={{ marginTop: "-5px" }}>
                  {/* <div className="col-lg-6 col-sm-12">
                          <label className="mb-0 mt-2">Company Name</label>
                          <input
                            className="myinput"
                            autoComplete="none"
                            type="text"
                            name="tags"
                            placeholder="Enter Company Name"
                            value={tags}
                            onChange={(e) => {
                              const { value } = e.target;
                              // for only alphabet
                              const re = /^[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
                              // for only numeric
                              //  const re = /^[0-9]+$/;
                              if (value === "" || re.test(value)) {
                                console.log(value);
                                setTags(value);
                              }
                            }}
                            style={{
                              padding: "7px",
                              borderRadius: "3px",
                            }}
                          />
                          {!tags && afterSubmitFlg && (
                            <small className="text-danger ml-2">
                              {CreateAds.Tags}
                            </small>
                          )}
                        </div> */}

                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Price</label>
                    <input
                      autoComplete="none"
                      className="myinput"
                      type="number"
                      name="price"
                      placeholder="Enter Price"
                      value={price} // Bind the value to state
                      onChange={(e) => setPrice(e.target.value)} // Update price state on input change
                      style={{
                        border: "1px solid #5d5d5d3d",
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Tags</label>
                    <input
                      className="myinput"
                      autoComplete="none"
                      type="text"
                      name="tags"
                      placeholder="Enter tags here"
                      value={tags}
                      onChange={(e) => {
                        const { value } = e.target;
                        // for only alphabet
                        const re = /^[A-Z a-z]+$/;
                        // for only numeric
                        //  const re = /^[0-9]+$/;
                        if (value === "" || re.test(value)) {
                          console.log(value);
                          setTags(value);
                        }
                      }}
                      style={{
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                    />
                    {!tags && afterSubmitFlg && (
                      <small className="text-danger ml-2">
                        {CreateAds.Tags}
                      </small>
                    )}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">State</label>

                    <Select
                      className="custom-select1"
                      style={{
                        // padding: "10px",
                        width: "100%",
                        zIndex: "-10px",
                        // borderRadius: "3px",
                      }}
                      autoComplete="none"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      allowClear
                      status={changeState ? "" : "error"}
                      onChange={(value) => {
                        setChangeState(value);
                        console.log("1553", value);
                        setUpdateState(value);
                        setState(value);
                        setChangeCity("");
                      }}
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={Object.keys(stateMain)?.map((result) => {
                        return { value: result, label: result };
                      })}
                    />

                    {!changeState && (
                      <small className="text-danger ml-2">
                        *Select right state
                      </small>
                    )}
                    {changeState == false ? (
                      <small className="text-danger ml-2">
                        *Choose right state
                      </small>
                    ) : changeState && !state && afterSubmitFlg ? (
                      <small className="text-danger ml-2">
                        {CreateAds.State}
                      </small>
                    ) : null}

                    {/* {!state && afterSubmitFlg && <span className="text-danger ml-2">{newError==false?"choose right state":newError==true?"good job" :CreateAds.State}</span>} */}
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">City</label>

                    <Select
                      className="custom-select1"
                      autoComplete="none"
                      style={{
                        // padding: "10px",
                        width: "100%",
                        zIndex: "-10px",
                        // borderRadius: "3px",
                      }}
                      value={city}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      allowClear
                      status={changeCity ? "" : "error"}
                      onChange={(value) => {
                        setChangeCity(value);
                        console.log("1553", value);

                        // if (Object.keys(stateMain)?.includes(value)) {
                        //   setNewError(true);
                        // } else {
                        //   setNewError(false);

                        //   console.log("not match");
                        // }

                        setCity(value);
                      }}
                      showSearch
                      placeholder="Select City Name"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={
                        !changeCity &&
                        updateState &&
                        cityWithState?.map((result) => {
                          return { value: result, label: result };
                        })
                      }
                    />
                    {/* <input
                  className="myinput"
                  type="text"
                  name="city"
                  list="city"
                  placeholder="enter city here"
                  value={city}
                  style={{
                    borderRadius: "3px",
                    padding: "7px",
                    width: "100%",
                    zIndex: "-50px",
                  }}
                  // onChange={(e) => setCity(e.target.value)}
                  onChange={(e) => {
                    console.log("1276", e.target.value);

                    if (cityWithState.includes(e.target.value)) {
                      setCityErr(true);
                    } else {
                      setCityErr(false);

                      console.log("not match");
                    }

                    setCity(e.target.value);
                  }}
                /> */}

                    {/* <datalist id="city">
                  {updateState &&
                    cityWithState?.map((result) => {
                      return <option value={result}>{result}</option>;
                    })}
                </datalist> */}
                    {!changeCity && (
                      <small className="text-danger ml-2">
                        *Select right city
                      </small>
                    )}
                    {city == false ? (
                      <small className="text-danger ml-2">
                        *Choose right city
                      </small>
                    ) : changeCity && !city && afterSubmitFlg ? (
                      <small className="text-danger ml-2">
                        {CreateAds.City}
                      </small>
                    ) : null}

                    {/* {!city && afterSubmitFlg && <span className="text-danger ml-2">{CreateAds.City}</span>} */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Location</label>
                    {/* <GoogleAutoComplteforAddProduct /> */}
                    {/* <input type="text" /> */}
                    <div className="App">
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyDEnLDetZt4PvT03AWs7ZTyzAdA3K-Sz1g"
                        selectProps={{
                          isClearable: true,
                          value: address,
                          onChange: (val) => {
                            setAddress(val);
                          },
                          placeholder: "Enter City Name",
                          styles: {
                            input: (provided) => ({
                              ...provided,
                              boxShadow: 0,
                              padding: "4px",
                              "&:hover": {
                                border: "1px solid purple",
                              },
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              boxShadow: 0,
                              "&:hover": {
                                border: "1px solid purple",
                              },
                            }),
                          },
                        }}
                        autocompletionRequest={{
                          types: ["(cities)"], // Restrict autocomplete to cities only
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Coordinates</label>
                    <div className="d-flex">
                    <input
                        type="text"
                        className="myinput"
                        id="coordinatesInput"
                        readOnly
                        value={coordinates}
                      />
                      {/* <FaAdn  /> 
                    </div>
                  </div> */}
                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Zip Code</label>
                    <input
                      className="myinput"
                      name="zipcode"
                      placeholder="enter zipcode here"
                      value={zipcode}
                      type="number"
                      maxLength={6}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      style={{
                        border: "1px solid #5d5d5d3d",
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                      onChange={(e) => setZipcode(e.target.value)}
                    />
                    {zipcode.length < 6 && zipcode.length > 0 ? (
                      <span className="text-danger ml-2">
                        Please enter valid zipcode
                      </span>
                    ) : zipcode.length > 6 ? (
                      <span className="text-danger ml-2">
                        Please enter valid zipcode
                      </span>
                    ) : !zipcode && afterSubmitFlg ? (
                      <small className="text-danger ml-2">
                        {CreateAds.ZipCode}
                      </small>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  {/* <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Zip Code</label>
                    <input
                      className="myinput"
                      name="zipcode"
                      placeholder="enter zipcode here"
                      value={zipcode}
                      type="number"
                      maxLength={6}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      style={{
                        border: "1px solid #5d5d5d3d",
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                      onChange={(e) => setZipcode(e.target.value)}
                    />
                    {zipcode.length < 6 && zipcode.length > 0 ? (
                      <span className="text-danger ml-2">
                        Please enter valid zipcode
                      </span>
                    ) : zipcode.length > 6 ? (
                      <span className="text-danger ml-2">
                        Please enter valid zipcode
                      </span>
                    ) : !zipcode && afterSubmitFlg ? (
                      <small className="text-danger ml-2">
                        {CreateAds.ZipCode}
                      </small>
                    ) : null}
                  </div> */}
                  {/* <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Date</label>
                    <input
                      className="myinput p-2"
                      style={{
                        border: "1px solid #8080804d",
                        borderRadius: "3px",
                        padding: "6px",
                      }}
                      type="text"
                      name="date"
                      placeholder="enter date here"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      disabled
                    />
                  </div> */}
                </div>
                {/* <div className="col-lg-6 col-sm-12">
                  <label className="mb-0 mt-2">User Name</label>
                  <input
                    className="myinput"
                    style={{
                      borderRadius: "3px",
                      padding: "7px",
                    }}
                    type="text"
                    name="id"
                    disable={true}
                    placeholder="USER ID"
                    value={name}
                  />
                </div> */}
                <div className="col-lg-12 col-sm-12 mb-3">
                  <label className="mb-0 mt-2">Description</label>
                  <textarea
                    className="myinput border"
                    type="text"
                    name="brand"
                    style={{ resize: "none" }}
                    placeholder="Write something about your product"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {!description && afterSubmitFlg && (
                    <small className="text-danger ml-2">{CreateAds.disc}</small>
                  )}
                </div>

                {/* <Button
                  style={{ marginTop: "50px" }}
                  type="primary"
                  className="btn btn-block style1 text-white p-1"
                  onClick={handlevis3}
                >
                  {" "}
                  submit{" "}
                </Button> */}
                <Button
                  style={{ marginTop: "50px" }}
                  type="primary"
                  className="btn btn-block style1 text-white p-1"
                  disabled={!isFormValid()}
                  onClick={addNewProduct}
                >
                  {" "}
                  submit{" "}
                </Button>
              </>
            )}
            {onevisible4 && (
              <>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <div className="col-lg-9 col-sm-12 mb-3">
                    {/* <small className="text-danger">*Choose Your Plan. </small> */}
                    <span className="px-3">Choose Plan :</span>
                    {business === true ? (
                      <Select
                        defaultValue="Choose"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        style={{
                          width: 150,
                        }}
                        onChange={(value) => {
                          // getDataByPlan(value);
                          setPlan(value);
                          setAdsCategory("");
                        }}
                        options={leftPlan?.map((val) => {
                          return { value: val, label: val };
                        })}
                      />
                    ) : (
                      <Select
                        defaultValue="Choose"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        style={{
                          width: 150,
                        }}
                        onChange={(value) => {
                          // getDataByPlan(value);
                          setPlan(value);
                          setAdsCategory("");
                        }}
                        options={data?.leftPlan?.map((val) => {
                          return { value: val, label: val };
                        })}
                      />
                    )}
                    <span className="px-3">
                      Days limit for this post : {dayslimit}
                    </span>
                  </div>
                  <div className="col-lg-3">
                    <>
                      <label className="mb-0 mt-1">User Type: &nbsp;</label>

                      <Select style={{ width: "10rem", marginRight: "-2rem" }}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        className="classclass"
                        placeholder="User Type"
                        options={["Employer", "Recruiter"].map((result) => ({
                          value: result,
                          label: result,
                        }))}
                        onChange={(value) => {
                          // getDataByPlan(value);
                          setusertype(value);
                        }}
                      />{!usertype && afterSubmitFlg ? (
                        <small className="text-danger ml-2">
                          {CreateAds.userty}
                        </small>
                      ) : null}
                    </>
                  </div>
                </div>
                <Button
                  style={{ marginTop: "32px" }}
                  type="primary"
                  className="btn btn-block style1 text-white p-1"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  POST ADD
                </Button>
              </>
            )}
            {planError ? (
              <>
                <p className="text-danger">Please choose a plan !</p>
              </>
            ) : null}

            {true ? (
              <>
                {data.leftPlan && (
                  <Modal
                    // title="Basic Modal"
                    open={isModalOpen}
                    onOk={() => navigate("/pricing/")}
                    onCancel={handleCancel}
                  >
                    <div className="mt-2 text-center">
                      <BsExclamationTriangle className="fs-22 text-warning" />{" "}
                      &nbsp;&nbsp;
                      <span className="text-danger">
                        Please choose a plan !
                      </span>
                    </div>
                  </Modal>
                )}
              </>
            ) : (
              <Link to="/payment" className="headline">
                {" "}
                **For Premium ADs
                <button
                  type="button"
                  class="btn style1 btn-block"
                  onClick={handlePay}
                >
                  Pay & Add Add
                </button>
              </Link>
            )}
            {active === "Payments" && <Payments />}
            {ErrorExtraField ? (
              <>
                <Modal
                  zIndex={9999}
                  style={{ zIndex: "99999" }}
                  title="Warning"
                  open={adsLimitCheck}
                  okType="danger"
                  onOk={() => setAdsLimitCheck(false)}
                  onCancel={() => setAdsLimitCheck(false)}
                >
                  <p>Please fill the extra fields </p>
                </Modal>
              </>
            ) : null}
            {/* {successvalue ? (
                  <div className="text-success">Succesfully Stored</div>
                ) : null} */}
            {errorvalue ? <div className="text-danger">Error </div> : null}
          </div>
        </div>

        {loading1 && (
          <Modal
            title="under process"
            open={loading1}
            onOk={() => {
              navigate("/dashboard");
            }}
          >
            It is under <span className="text-warning">verification</span>
            <p className="p-0 m-0"> It will approve within 24 hours.</p>
          </Modal>
        )}
        {openNewModal && (
          <Modal
            title="Ads post successfully"
            open={openNewModal}
            icon=<CheckCircleFilled />
            fill="green"
            onOk={() => {
              navigate("/dashboard");
            }}
            onCancel={() => navigate("/")}
          >
            <p className="p-0 m-0">Your ads have been posted successfully.</p>
            <p className="p-0 m-0">
              It is under <span className="text-warning">verification</span>
            </p>
            <p className="p-0 m-0"> It will approve within 24 hours.</p>
          </Modal>
        )}
      </fieldset>
    </div>
  );
};

export default AddPhotographyEquipment;
