import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Category, localUrl } from "../env";
import "./NewsArticles.css";
// import { Card } from 'antd';
// const { Meta } = Card;

const categories = [
  {
    category: "RealEstate",
    id: 1,
    image: "https://media.istockphoto.com/id/1063301176/photo/real-estate-is-a-best-option-to-invest-where-to-invest-concept-investmets-newspaper-with.jpg?s=2048x2048&w=is&k=20&c=iIvgTA-4-gaCF7l6CH2Z2LA9lQop4jFk2Q8eYV4gUzg=",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },
  {
    category: "RealEstate",
    id: 2,
    image: "https://images.pexels.com/photos/13515672/pexels-photo-13515672.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },
  {
    category: "RealEstate",
    id: 3,
    image: "https://images.pexels.com/photos/18604178/pexels-photo-18604178/free-photo-of-a-view-of-a-city-at-sunset-with-buildings-and-mountains.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },
  {
    category: "RealEstate",
    id: 4,
    image: "https://images.pexels.com/photos/11951876/pexels-photo-11951876.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },
  {
    category: "RealEstate",
    id: 5,
    image: "https://images.pexels.com/photos/1838640/pexels-photo-1838640.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },
  {
    category: "RealEstate",
    id: 6,
    image: "https://images.pexels.com/photos/13203194/pexels-photo-13203194.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },



  {
    category: "Furniture",
    id: 1,
    image: "https://news.uthm.edu.my/wp-content/uploads/2024/08/bamboo-1024x702.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },
  {
    category: "Furniture",
    id: 2,
    image: "https://www.wondercraft.ai/_next/image?url=https%3A%2F%2Fwondercraft-podcast-assets.s3.amazonaws.com%2Fpodcast_images%2Fb43ad7f1-519b-420c-b468-4913e213fd07.jpg&w=1080&q=75",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },
  {
    category: "Furniture",
    id: 3,
    image: "https://images.pexels.com/photos/276528/pexels-photo-276528.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Furniture",
    id: 4,
    image: "https://images.pexels.com/photos/609768/pexels-photo-609768.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Furniture",
    id: 5,
    image: "https://images.pexels.com/photos/37347/office-sitting-room-executive-sitting.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Furniture",
    id: 6,
    image: "https://images.pexels.com/photos/90319/pexels-photo-90319.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },


  {
    category: "Pets",
    id: 1,
    image: "https://www.carrymypet.com/images/e3f7cbca-6585-41d7-a7de-eef73d951f9e.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Pets",
    id: 2,
    image: "https://i.ytimg.com/vi/XDXwUdUWl18/maxresdefault.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Pets",
    id: 3,
    image: "https://www.dogster.com/wp-content/uploads/2024/12/Dog-of-the-Week-Winners.png",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Pets",
    id: 4,
    image: "https://i.ytimg.com/vi/R419CZ_Kv7I/maxresdefault.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Pets",
    id: 5,
    image: "https://americanpetproducts.org/hubfs/DogCat-Cover-Social-v2.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Pets",
    id: 6,
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9oF4yaoXiEHO2rDLrxnConorjPCFDnFnWLw&s",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },



  {
    category: "Bikes",
    id: 1,
    image: "https://imgd.aeplcdn.com/370x208/n/cw/ec/132013/jawa-42-bobber-left-side-view2.gif?isig=0&q=80",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Bikes",
    id: 2,
    image: "https://bikes.tractorjunction.com/_next/image?url=https%3A%2F%2Fimages.tractorjunction.com%2FAther_November_Offer_Festive_Bike_Jn_990x550_pxl_65fce11037.jpg%3Fformat%3Dwebp&w=3840&q=75",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Bikes",
    id: 3,
    image: "https://www.loomsolar.com/cdn/shop/articles/electric_scooty_price_in_india_800x.jpg?v=1647010741",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Bikes",
    id: 4,
    image: "https://motoroctane.com/wp-content/uploads/2021/04/used-adventure-bikes-1.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Bikes",
    id: 5,
    image: "https://static.autox.com/uploads/2023/10/Lead.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Bikes",
    id: 6,
    image: "https://www.credr.com/blog/wp-content/uploads/2024/04/From-Activa-to-Shine-02.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },

  {
    category: "Cars",
    id: 1,
    image: "https://motoroctane.com/wp-content/uploads/2022/09/3-new-cars-on-avinya-platform-1.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Cars",
    id: 2,
    image: "https://motoroctane.com/wp-content/uploads/2024/01/2024-Renault-cars.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Cars",
    id: 3,
    image: "https://motoroctane.com/wp-content/uploads/2024/02/upcoming-diesel-cars.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Cars",
    id: 4,
    image: "https://motoroctane.com/wp-content/uploads/2024/10/Tata-Issues-3.png",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Cars",
    id: 5,
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvUcw03Yx30hcvNdLa1SS8BoYuLnnl5Dxd1g&s",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Cars",
    id: 6,
    image: "https://www.bizzbuzz.news/h-upload/2024/10/13/1939333-top-5-cars-to-buy-with-sunroof-under-10-lakhs-1.webp",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },

  {
    category: "Electronics",
    id: 1,
    image: "https://media.licdn.com/dms/image/D5612AQFjAGrgy7q4eg/article-cover_image-shrink_720_1280/0/1713167596760?e=2147483647&v=beta&t=xhILjTmLDYE21ITMcoJz8mUJ3Gdiwj-Efy1t3PCYvcQ",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Electronics",
    id: 2,
    image: "https://www.emsolutions.uk.com/wp-content/uploads/September-News-The-Circuit.jpg.webp",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Electronics",
    id: 3,
    image: "https://www.csfusion.org/wp-content/uploads/2024/05/DALL%C2%B7E-2024-05-20-10.23.00-A-widescreen-image-showcasing-the-latest-advancements-in-compound-semiconductor-technology-for-2024.-The-image-features-modern-semiconductor-devices-s.webp",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Electronics",
    id: 4,
    image: "https://electronicsmaker.com/wp-content/uploads/2014/07/PIC24F-GB2_7x5.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Electronics",
    id: 5,
    image: "https://miro.medium.com/v2/resize:fit:1400/1*Z0pVqNSIIba7UYrrSM9z-w.jpeg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Electronics",
    id: 6,
    image: "https://www.voltas.in/images/publications/news_BusinessWeek_24062024.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },

  {
    category: "Mobiles",
    id: 1,
    image: "https://i.ytimg.com/vi/TD0Are31diM/sddefault.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Mobiles",
    id: 2,
    image: "https://in-exstatic-vivofs.vivo.com/gdHFRinHEMrj3yPG/1663574850804/a4f4fd024620f8bc3779e0856cb446b9.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Mobiles",
    id: 3,
    image: "https://static.digit.in/Best-Mobile-Phones-in-2024.png",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Mobiles",
    id: 4,
    image: "https://s3b.cashify.in/gpro/uploads/2019/10/08090121/Banner_15.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Mobiles",
    id: 5,
    image: "https://www.newsx.com/wp-content/uploads/2024/11/SAMSUNG-GALAXY-S25.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Mobiles",
    id: 6,
    image: "https://pragativadi.com/core/uploads/2022/09/IMG_20220915_133134.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },

  {
    category: "Education",
    id: 1,
    image: "https://blogassets.leverageedu.com/blog/wp-content/uploads/2020/12/16205930/education-News-1.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Education",
    id: 2,
    image: "https://i.vimeocdn.com/video/1922824967-1cea200dab8cd2bc18d2ef8971bc900ccbf2f20441df831fc89910901097e99c-d?f=webp",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Education",
    id: 3,
    image: "https://www.narayanaschools.in/blog/wp-content/uploads/2024/12/The-role-of-active-learning.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Education",
    id: 4,
    image: "https://indianvaidyas.com/assets/uploads/newsimage/WhatsApp_Image_2023-10-05_at_10_29_20_AM.jpeg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Education",
    id: 5,
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSacvI84VIkUh9YEVJDQqKMfO8QXsuzBUtkpA&s",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Education",
    id: 6,
    image: "https://5.imimg.com/data5/GLADMIN/Default/2021/2/NB/ZU/NQ/61808765/distance-education-courses.jpeg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },

  {
    category: "Jobs",
    id: 1,
    image: "https://www.practicemock.com/blog/wp-content/uploads/2023/08/Employment-News-Latest-Updates-on-Government-Jobs-1.png",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Jobs",
    id: 2,
    image: "https://media.licdn.com/dms/image/D4E12AQEY2_Liz-ixxw/article-cover_image-shrink_720_1280/0/1715246836067?e=2147483647&v=beta&t=VcOmzbuGoVsCJt-EX6_DfGSxoimc3pOf8bB9Vq705Kw",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Jobs",
    id: 3,
    image: "https://trainingmagazineme.com/wp-content/uploads/2015/07/jobcareernews.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Jobs",
    id: 4,
    image: "https://www.jobinterviewnews.co.in/wp-content/uploads/2024/03/Add-a-subheading-33.png",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Jobs",
    id: 5,
    image: "https://cdn.dnaindia.com/sites/default/files/2021/02/04/955261-gds-recruitment-2021.jpg?im=FitAndFill=(1200,900)",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Jobs",
    id: 6,
    image: "https://www.ippbonline.com/documents/51100601/51128282/599276/d42c665e-36be-5a45-c9b1-b654c1911a65?t=1717668670301",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },

  {
    category: "Services",
    id: 1,
    image: "https://assets-news.housing.com/news/wp-content/uploads/2020/09/18190153/Efficient-and-affordable-home-services-at-your-doorstep-with-Housing-Edge-FB-1200x700-compressed.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Services",
    id: 2,
    image: "https://st4.depositphotos.com/1010613/24354/i/450/depositphotos_243548726-stock-photo-woman-looking-male-technician-checking.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Services",
    id: 3,
    image: "https://www.usnews.com/object/image/00000183-cd52-d6f1-a3ab-fff368cf0000/home-repair-experiences.png?update-time=1665597164514&size=responsiveFlow640",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Services",
    id: 4,
    image: "https://s3n.cashify.in/cashify/web/15c85e3962eb45c8bdeccfeb3b2be0aa.webp",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Services",
    id: 5,
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDnUhJMCH7UMpQez-OaXBknxc2QwZ1kfktYQ&s",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "Services",
    id: 6,
    image: "https://www.priscadesign.com/wp-content/uploads/2016/02/Prisca-1.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },
  {
    category: "ScienceAndTech",
    id: 1,
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJIq4a3_Go5QaUTyqLiHZ2KVTjQreFZqRdEA&s",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "ScienceAndTech",
    id: 2,
    image: "https://snworksceo.imgix.net/jhn/4227d552-6815-457f-82ed-f94368875c69.sized-1000x1000.jpg?w=1000",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "ScienceAndTech",
    id: 3,
    image: "https://lh5.googleusercontent.com/proxy/9qLuoNgbyYYkWUPEgrhGNaNZNS8yk6HxfXJ4_di8nRHaq1A0WOFb7seF7FhcY9evX0EwrUXAe-ticXLOOe01dTqR7sSvqqf408Z0kPbw8WesGJFd7d0YUWdeUDtwN1BgP9RR0rF-QWE",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "ScienceAndTech",
    id: 4,
    image: "https://cdn.vanguardngr.com/wp-content/uploads/2021/05/Digital-technology.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "ScienceAndTech",
    id: 5,
    image: "https://commonwealthchamber.com/wp-content/uploads/2022/02/singapore-india.jpg",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  }, {
    category: "ScienceAndTech",
    id: 6,
    image: "https://commonwealthchamber.com/wp-content/uploads/2024/10/Nigeria-joins-Commonwealth-AI-Academy-to-boost-skills-in-AI-and-innovation.png",
    title: "Static Article 1",
    description: "This is a static description for Article 1.",
  },
  // Add more categories with their respective image data
];

const NewsArticles = ({ selectedCategory }) => {

  // const [articles, setArticles] = useState([]);
  // const [error, setError] = useState(null);
  // const getArticles = async () => {
  //   try {
  //     const response = await axios.get(localUrl + "blogsapi/");
  //     if (!response.statusText.OK === false) {
  //       throw Error("Could not fetch the data");
  //     } else {
  //       let value = response.data.map((result) => {
  //         if (props.category == result.category) {
  //           return result;
  //         } else {
  //           return "null";
  //         }
  //       });
  //       value.push("null");
  //       value = value.filter(function(e) {
  //         return value.indexOf(e) == value.lastIndexOf(e);
  //       });
  //       setArticles(value);
  //       // setArticles(response.data);
  //     }
  //   } catch (err) {
  //     setError("Could not fetch the data from this resources");
  //   }
  // };
  // useEffect(() => {
  //   getArticles();
  // }, []);

  // State to manage the visible articles
  const [visibleArticles, setVisibleArticles] = useState(4);

  // Function to load more articles
  const loadMoreArticles = () => {
    setVisibleArticles((prevVisible) => prevVisible + 3);
  };

  const selectedCategoryData = categories.filter((categoryData) => categoryData.category === selectedCategory);

  if (!selectedCategoryData) {
    return null;
  }


  return (
    <div>
      <h3 className="bg-info text-white rounded p-2 mx-2">News & Articles</h3>
      <div className="container-estate">
        {selectedCategoryData.slice(0, visibleArticles).map((article) => (
          <div className="card-estate" key={article.id}>
            <img
              className="card-img-top1"
              src={article.image}
              alt="Card image"
            />
            <div className="card-body1">
              <h5 className="card-title1">
                {article.title.slice(0, 25)}
              </h5>
              <p className="card-text1">
                {article.description.slice(0, 100)}
              </p>
              <div className="card-body12">
                <Link to={`/blogs-listing/${article.id}`} className="card-link1">
                  See Blog
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Load More Button */}
      {visibleArticles < selectedCategoryData.length && (
        <div className="text-center mt-3">
          <button
            className="btn Load-more-btn text-white"
            onClick={loadMoreArticles}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default NewsArticles;
